.header-links {
  &__list, &__item, &__link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text{
    margin-left: 12px;
    transition: color var(--default-transition-duration);
  }

  &__item {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;

    svg {
      path {
        transition: fill var(--default-transition-duration);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .header-links {
        &__text{
          color: var(--color-blue-gray);
        }

        &__icon {
          svg {
            path {
              fill: var(--color-blue-gray);
            }
          }
        }
      }
    }
  }

  &__link {
    padding: 7px;
    margin: -7px;
  }

  &__icon {
    display: inline-flex;
    position: relative;
  }

  &__counter {
    background: var(--primary-color);
    border-radius: 50%;
    padding: 2px 5px 1px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: block;
    text-align: center;
    position: absolute;
  }
}

@include respond-up('large') {
  .header-links {

    &__counter {
      top: -7px;
      right: -8px;
    }

    &__item {
      margin-right: 40px;
    }
  }
}

@include respond('medium') {
  .header-links {
    //отрисовано в ките такое поведение, потом используется поведение как на телефоне
    //&__link{
    //  position: relative;
    //  padding-right: 26px;
    //}
    //
    //&__counter {
    //  top: 9px;
    //  right: 0;
    //}


    &__item {
      margin-right: 42px;
    }

    //поведение с телефона
    &__item {
      position: relative;
    }

    &__counter {
      top: -7px;
      left: 15px;
    }

    &__text {
      display: none;
    }
  }
}

@include respond-down('small') {
  .header-links {
    &__item {
      position: relative;

      &:not(:last-child) {
        margin-right: 18px;
      }
    }


    &__counter {
      top: -7px;
      left: 15px;
    }

    &__text {
      display: none;
    }
  }
}
