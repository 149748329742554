.notification {
  background: #FFFFFF;
  box-shadow: 0px 12px 40px rgba(125, 131, 146, 0.21);

  animation-name: notification-in;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &_out {
    animation-name: notification-out;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  &__wrapper {

  }

  &__line {
    display: flex;
    align-items: flex-start;
  }

  &__container {
    flex: 1 1 auto;
  }

  &__close {
    flex: 0 0 56px;
    height: 56px;
    width: 56px;
    background: {
      image: url('../images/svg/notification-close.svg');
      position: 50% 50%;
      repeat: no-repeat;
    };
    position: relative;
    right: -20px;
  }
}

@include respond-up('medium') {
  .notification {
    &__container {
      padding: 20px 0;
    }

    &__close {
      height: 82px;
    }
  }
}

@include respond-down('small') {
  .notification {
    &__container {
      padding: 14px 0;
    }
  }
}

@keyframes notification-in {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes notification-out {
  0% {
    opacity: 1;
    pointer-events: none;
  }

  99% {
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }

  100% {
    height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }
}