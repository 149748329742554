.simple-slider {
  &__main,
  &__bottom {
    border-top: 1px solid var(--color-border);
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__bottom-button-wrap {
    border-radius: 100%;
    overflow: hidden;
    flex: 0 0 auto;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-slider {
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
  }

  .swiper-pagination-current {
    padding-right: 5px;
  }

  .swiper-pagination-total {
    padding-left: 5px;
  }

  &__content-slider-item {
    max-width: max-content;

    &:nth-child(3n+1) {
      .simple-slider {
        &__picture {
          padding-top: 68%;
        }
      }
    }

    &:nth-child(3n+2) {
      .simple-slider {
        &__picture {
          padding-top: 73%;
        }
      }
    }

    &:nth-child(3n) {
      .simple-slider {
        &__picture {
          padding-top: 75%;
        }
      }
    }
  }

  &__bottom-description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    flex: 0 1 auto;
  }

  &__tab {
    &:first-child {
      .tabs__link {
        margin-left: -18px;
      }
    }
  }
}

@include respond-up('large') {
  .simple-slider {
    //padding: 140px 0 100px;

    &__content-slider-item {
      &:last-child {
        padding-right: 0 !important;
      }
    }

    .tns-ovh {
      // Эта мазафака тут считает для слайдера отступы, чтобы слайдер работал как хочет дизайнер
      margin: 0 calc((100vw - (var(--max-row-width) * 1px)) / -2 - #{$spacer-large}px);
      padding: 0 calc((100vw - (var(--max-row-width) * 1px)) / 2 + #{$spacer-large}px);
    }

    &__main {
      padding-top: 40px;
      margin-top: 40px;
    }

    &__bottom {
      margin-top: 60px;
    }

    &__bottom-description {
      max-width: 685px;
      font-size: 16px;
      line-height: 1.5em;
    }

    &__content-items {
      margin-top: 40px;
    }

    &__arrows {
      top: 42px;
    }

    &__content-slider-item {
      &:nth-child(3n+1) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 657px;
            height: 452px;
          }
        }
      }

      &:nth-child(3n+2) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 482px;
            height: 356px;
          }
        }
      }

      &:nth-child(3n) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 549px;
            height: 411px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .simple-slider {
    &__main {
      position: relative;
    }

    &__bottom {
      padding-top: 20px;
    }

    &__arrows {
      position: absolute;
      right: 0;
    }

    &__bottom-button-wrap {
      .button__link-arrow {
        width: 66px;
        height: 66px;
      }
    }

    .tns-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .simple-slider {
    //padding: 70px 0 60px;

    &__tabs-wrap,
    .tns-ovh {
      margin: 0 -#{$spacer-medium}px;
    }

    &__tabs,
    .tns-inner {
      padding: 0 #{$spacer-medium}px;
    }

    &__main {
      padding-top: 30px;
      margin-top: 30px;
    }

    &__bottom {
      margin-top: 30px;
    }

    &__bottom-description {
      max-width: calc((452 / 768) * 100vw);
    }

    &__content-items {
      margin-top: 30px;
    }

    &__arrows {
      top: 36px;
    }

    &__content-slider-item {
      &:nth-child(3n+1) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 378px;
            height: 260px;
          }
        }
      }

      &:nth-child(3n+2) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 278px;
            height: 204px;
          }
        }
      }

      &:nth-child(3n) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 316px;
            height: 236px;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .simple-slider {
    &__bottom-description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__content-slider-item {
      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
}

@include respond-down('small') {
  .simple-slider {
    //padding: 40px 0 25px;

    &__tabs-wrap,
    .tns-ovh {
      margin: 0 -#{$spacer-small}px;
    }

    &__tabs,
    .tns-inner {
      padding: 0 #{$spacer-small}px;
    }

    &__main {
      padding-top: 20px;
      margin-top: 20px;
    }

    &__bottom {
      margin-top: 20px;
      padding-top: 15px;
    }

    &__bottom-button-wrap {
      margin-left: 20px;

      .button__link-arrow {
        width: 54px;
        height: 54px;
      }
    }

    &__content-items {
      margin-top: 20px;
    }

    &__arrows {
      .arrows {
        &__wrap {
          justify-content: center;
          margin-top: 23px;
        }

        &__left,
        &__right {
          display: none;
        }
      }

      .swiper-pagination-bullet {
        margin: 7px;

        &.swiper-pagination-bullet-active {
          position: relative;

          &:before {
            position: absolute;
            content: "";
            width: 21px;
            height: 21px;
            border: 1px solid var(--color-blue-gray);
            border-radius: 50%;
            top: -7px;
            left: -7px;
          }
        }
      }
    }

    &__content-slider-item {
      &:nth-child(3n+1) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 183px;
            height: 126px;
          }
        }
      }

      &:nth-child(3n+2) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 138px;
            height: 99px;
          }
        }
      }

      &:nth-child(3n) {
        .simple-slider {
          &__content-slider-item-inner,
          &__picture {
            width: 154px;
            height: 115px;
          }
        }
      }
    }

    .tns-nav {
      margin-top: 25px;
    }
  }
}