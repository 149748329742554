.index-page {
  &__title {

  }
  &__hero {

  }

  &__filter {

  }

  &__projects {

  }

  &__payments {

  }

  &__actions {

  }

  &__about {
    background-color: var(--color-background);
  }

  &__advantages {

  }

  &__news {

  }

  &__reviews {
    .review {
      &__content-head {
        grid-area: head;
      }

      &__content-date {
        display: none;
      }

      &__content {
        display: grid;
      }

      &__content-text {
        grid-area: content-text;
      }

      &__content-button-wrap {
        grid-area: content-button;
      }
    }
  }
}

@include respond-up('large') {
  .index-page {
    &__title {
      margin-bottom: 40px;
    }

    &__filter {
      margin-top: 50px;
    }

    &__projects, &__actions, &__news, &__about {
      padding-top: 120px;
    }

    &__projects, &__actions {
      padding-bottom: 120px;
    }

    &__reviews {
      padding-top: 140px;

      .review {
        &__content {
          grid-template-columns: 1fr 220px;
          grid-template-areas: 'head content-button'
                               'content-text content-text';
        }

        &__content-head {
          padding-bottom: unset;
          margin-bottom: unset;
          border-bottom: unset;
        }

        &__content-text {
          margin-top: 26px;
          padding-top: 40px;
          border-top: 1px solid var(--color-border);
        }
      }
    }
  }
}

@include respond('medium') {
  .index-page {
    &__title {
      margin-bottom: 30px;
    }

    &__filter {
      margin-top: 30px;
    }

    &__projects {
      padding: 96px 0 70px;
    }

    &__actions {
      padding: 70px 0;
    }

    &__about {
      padding-top: 70px;
    }

    &__news {
      padding-top: 70px;
    }

    &__reviews {
      padding-top: 90px;
    }
  }
}

@include respond-down('medium') {
  .index-page {
    &__reviews {
      .review {
        &__content {
          grid-template-areas: 'head'
                               'content-text'
                               'content-button';
        }

        &__content-button-wrap {
          padding-top: 25px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__header {
      position: relative;
    }

    &__filter-header {
      .index-filter-form {
        &__button-icon {
          svg {
            width: 24px;
            height: 24px;
          }
        }

        &__button {
          height: 52px;
        }
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__filter {
      display: none;
    }

    &__projects {
      padding: 40px 0 30px;
    }

    &__actions {
      padding: 30px 0;
    }

    &__about {
      padding-top: 30px;
    }

    &__news {
      padding-top: 30px;
    }

    &__reviews {
      padding-top: 40px;
    }
  }
}