// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.payment-documents {
  display: grid;

  &__title-wrap {
    grid-area: title;
    display: flex;
  }

  &__button-wrap {
    grid-area: button;
    display: flex;
  }

  &__items-wrap {
    grid-area: items;
    display: flex;
  }

  &__items {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:first-child {
      .payment-documents {
        &__item-icon {
          background-color: var(--color-white-opacity-79);
        }

        &__item-title {
          color: var(--color-primary-dark);
        }

        &__item-number {
          color: var(--color-primary-dark);
        }
      }
    }
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    background-color: var(--color-white-opacity-20);

    svg {
      width: 18px;
      height: 22px;
    }
  }

  &__item-title {
    font-style: normal;
    font-weight: normal;
    text-align: center;
    color: #fff;
  }

  &__item-number {
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
    color: #fff;
  }
}

@include respond-up('large') {
  .payment-documents {
    padding-top: 120px;
    grid-column-gap: 95px;
    grid-template-rows: max-content 1fr;
    grid-template-areas: 'title items'
                         'button items';

    &__items-wrap {
      justify-content: flex-end;
    }

    &__item {
      padding: 69px 33px;
    }

    &__item-title {
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.5em;
    }

    &__button-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .payment-documents {
    &__button {
      max-width: 237px;
      max-height: 54px;
    }

    &__item-number {
      font-size: 22px;
    }
  }
}

@include respond('medium') {
  .payment-documents {
    padding-top: 90px;
    grid-row-gap: 30px;
    grid-template-areas: 'title button'
                         'items items';

    &__items-wrap {
      justify-content: center;
    }

    &__item {
      padding: 60px 17px;
    }

    &__item-title {
      margin-top: 15px;
      font-size: 15px;
      line-height: 1.35em;
    }

    &__title-wrap {
      max-width: calc((334 / 768) * 100vw);
    }

    &__button-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond-down('medium') {
  .payment-documents {

  }
}

@include respond-down('small') {
  .payment-documents {
    padding-top: 40px;
    grid-template-areas: 'title'
                         'items'
                         'button';

    &__items-wrap {
      overflow: scroll;
      margin: 22px -#{$spacer-small}px 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__items {
      padding: 0 #{$spacer-small}px;
    }

    &__item {
      padding: 36px 22px;
    }

    &__item-title {
      margin-top: 12px;
      font-size: 14px;
      line-height: 1.25em;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__item-number {
      font-size: 18px;
    }
  }
}