@include respond-up('large') {
  .index-catalog-filter-form {
    .filter-fields_catalog {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: unset;

      .filter-fields {
        &__field {
          grid-area: unset !important;

          &_project {
            grid-column: 1/5 !important;
            grid-row: 1 !important;
          }

          &_address {
            grid-column: 5/9 !important;
            grid-row: 1 !important;
          }

          &_rooms {
            grid-column: 9/13 !important;
            grid-row: 1 !important;

            .hint__text {
              left: unset;
              right: -10px;

              &::before {
                left: unset;
                right: 12px;
              }
            }
          }

          &_renovation, &_date, &_action, &_price, &_payment, &_floor, &_sold, &_reserved, &_area {
            grid-column: span 3 !important;
            pointer-events: none;
            transition: {
              property: opacity, transform;
              duration: .3s;
            };
            .filter-fields__label,
            .custom-select,
            .range-input {
              opacity: 0;
              transform: translateY(20px);
              transition: {
                property: opacity, transform;
                duration: .3s;
              };
            }
          }

          &_renovation, &_date, &_floor, &_area {
            .filter-fields__label,
            .custom-select,
            .range-input {
              transition-delay: .15s;
            }
          }

          &_action, &_price, &_payment {
            .filter-fields__label,
            .custom-select,
            .range-input {
              transition-delay: .1s;
            }
          }
        }

        &__euro-block {
          margin-right: 0;
          flex: 0 1 100%;
        }
      }
    }

    .filter-form {
      &__link_reset {
        margin-left: auto;
      }

      &__buttons {
        padding: 45px 0 72px;

        .button__link {
          z-index: unset;
        }
      }

      &__accordionable {
        height: 79px;
        transition: {
          property: height;
          duration: .5s;
          timing-function: ease-in-out;
        };
      }

      &__accordion-button-wrapper {
        display: flex;
      }

      &__tags {
        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
        transition: {
          property: opacity, transform;
          duration: .3s;
        };
        margin-top: 36px;
      }

      &__bottom-fields {
        justify-content: space-between;
        padding: 34px 0 50px;
      }

      &__view-icon-wrapper {
        margin-right: 221px;
      }

      &__top-inner {
        &._opened {
          .filter-form__tags {
            opacity: 1;
            pointer-events: all;
            transition-delay: .4s;
            transform: translateY(0);
          }
          .filter-fields_catalog {
            .filter-fields__field {
              &_renovation, &_date, &_floor, &_action, &_price, &_payment, &_sold, &_reserved, &_area {
                pointer-events: all;
                .filter-fields__label,
                .custom-select,
                .range-input {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
              &_renovation, &_date, &_floor, &_area {
                .filter-fields__label,
                .custom-select,
                .range-input {
                  transition-delay: .2s;
                }
              }
              &_action, &_price, &_payment {
                .filter-fields__label,
                .custom-select,
                .range-input {
                  transition-delay: .3s;
                }
              }

              &_sold, &_reserved {
                transition-delay: .4s;
              }
            }
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .index-catalog-filter-form {
    .filter-fields {
      grid-template-areas:
        "project project project address address address"
        "rooms rooms rooms area area area"
        "floor floor renovation renovation date date"
        "action action price price payment payment";
    }
  }
}

@include respond-down('small') {
  .index-catalog-filter-form {
    .filter-fields {
      &__field {
        &_area {
          order: 20;
        }

        &_rooms {
          flex-wrap: nowrap;
        }
      }

      &__euro-block {
        flex: 0 1 100%;
      }

      &__euro-block,
      &__rooms-block {
        margin-bottom: 0;
      }

      &__euro-block {
        .hint__text {
          left: unset;
          right: -10px;

          &::before {
            left: unset;
            right: 12px;
          }
        }
      }
    }
  }
}