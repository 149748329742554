.view-icon {
  display: flex;

  &__link {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &._active, &:hover {
      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }
  }
}