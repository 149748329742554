.arrow-wrapper {
  z-index: 100;
  position: relative;
  &__up {
    position: fixed;
    width: 54px;
    height: 54px;
    border: 1px solid var(--color-blue-gray);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity .4s;

    &._hidden {
      opacity: 0;
    }
  }
}

@include respond-up('large') {
  .arrow-wrapper {
    &__up {
      bottom: 100px;
      right: 90px;
    }
  }
}

@include respond('medium') {
  .arrow-wrapper {
    &__up {
      bottom: 88px;
      right: 38px;
    }
  }
}

@include respond-down('small') {
  .arrow-wrapper {
    display: none;
  }
}