.vacancy {
  &__info-text {
    color: var(--color-gray-dark);
  }

  &__info {
    color: var(--color-primary-dark);
    margin-top: 10px;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__salary {
    color: var(--primary-color);
  }

  &__bottom {
    display: flex;
  }

  &__date {
    color: var(--color-gray-dark);
  }
}

@include respond-up("large") {
  .vacancy {
    &__date {
      padding-bottom: 35px;
      margin-top: -35px;
    }

    &__bottom {
      padding-top: 35px;
      padding-bottom: 120px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 100vw;
        background: var(--color-border);
        left: calc((var(--max-row-width) * 1px / 2) - 50vw - #{$spacer-large}px);
        top: 0;
      }
    }

    &__article-button {
      margin-top: 25px;
    }

    &__article-bottom {
      margin-top: 60px;
    }

    &__info-block {
      margin-top: 40px;
    }

    &__info-wrapper {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    &__article {
      margin-top: 60px;
    }

    &__description-wrapper {
      grid-column: 1/8;
      padding-bottom: 65px;
    }

    &__info-block-wrapper {
      grid-column: 10/13;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background: var(--color-border);
        left: -50px;
        top: 0;
      }
    }

    &__description-wrapper,
    &__info-block-wrapper {
      padding-top: 70px;
    }
  }
}

@include respond-up("medium") {
  .vacancy {
    &__bottom {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }
}

@include respond("medium") {
  .vacancy {
    grid-template-areas:
      "info-block info-block info-block info-block info-block info-block"
      "description description description description description description";

    &__date {
      padding-bottom: 45px;
      margin-top: -15px;
    }

    &__article {
      margin-top: 50px;
    }

    &__article-button {
      margin-top: 28px;
    }

    &__article-bottom {
      margin-top: 50px;
    }

    &__description-wrapper {
      padding-top: 70px;
      padding-bottom: 50px;
    }

    &__info-block {
      margin-top: 30px;
      width: 100%;
      display: grid;
      --grid-gap: 0 var(--grid-column-gap);
      grid-gap: var(--grid-gap);
      grid-template-columns: var(--grid-template-columns);
      grid-template-areas: "experience experience occupation occupation occupation .";
    }

    &__info-wrapper {
      &:nth-child(2) {
        grid-area: experience;
      }

      &:first-child {
        grid-area: occupation;
      }
    }

    &__info-block-wrapper {
      padding: 40px 0;
    }

    &__description-wrapper,
    &__info-block-wrapper {
      grid-column: 1/7;
    }
  }
}

@include respond-down("medium") {
  .vacancy {
    &__bottom {
      padding-top: 30px;
      padding-bottom: 70px;
    }

    &__info-block-wrapper {
      grid-area: info-block;
      border-bottom: 1px solid var(--color-border);
    }

    &__description-wrapper {
      grid-area: description;
    }

    &__description-wrapper {
      border-bottom: 1px solid var(--color-border);
    }
  }
}

@include respond-down("small") {
  .vacancy {
    grid-template-areas:
      "info-block info-block info-block info-block"
      "description description description description";

    &__date {
      padding-bottom: 30px;
      margin-top: -15px;
    }

    &__bottom {
      flex-direction: column-reverse;
      padding-top: 25px;
      padding-bottom: 40px;

      .share {
        margin-bottom: 20px;
      }
    }

    &__description-wrapper,
    &__info-block-wrapper {
      grid-column: 1/5;
    }

    &__info-block-wrapper {
      padding: 30px 0;
    }

    &__article {
      margin-top: 40px;
    }

    &__article-button {
      margin-top: 20px;
    }

    &__article-bottom {
      margin-top: 40px;
    }

    &__description-wrapper {
      padding-top: 30px;
      padding-bottom: 40px;
    }

    &__info-block {
      margin-top: 25px;
    }

    &__info-wrapper {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}
