.header {
  z-index: 9000;
  position: relative;
  height: var(--header-height);
  display: flex;

  --animate-header-from: -126px;
  --animate-header-to: -60px;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__inner-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  &_sticky {
    .header {
      &__inner {
        position: fixed;
        box-shadow: 0 8px 10px rgba(125, 131, 146, 0.08);
        animation-name: show-header;
        animation-iteration-count: 1;
        animation-duration: 0.4s;
      }

      &__phone-icon {
        display: flex;
      }

      &__lower {
        position: relative;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &__phone-icon {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 1px solid var(--color-border);

    svg {
      width: unset;
      height: unset;
    }
  }

  &__header-block {
    flex: 1 0 auto;
    display: flex;
    position: relative;
  }

  &__upper {
    z-index: 500;
  }

  &__upper,
  &__lower {
    display: flex;
    justify-content: space-between;
    padding: var(--header-padding);
    align-items: center;
    //transition: height var(--default-transition-duration);
  }

  &__lower {
    background: white;
    color: var(--color-primary-dark);
    position: relative;
  }

  &__logo-block {
    background: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;

    svg {
      path {
        fill: white;
      }
    }
  }

  &__logo-block-wrapper {
    display: flex;
  }

  &__menu {
    flex-direction: row;
    margin: 0 calc(0px - var(--menu-padding));
  }

  &__menu-item {
    padding: 0 var(--menu-padding);

    flex-direction: row;
  }

  &__menu-link,
  &__menu-button,
  &__menu-item,
  &__menu {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__menu-button-wrapper {
    display: flex;
    width: 50px;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    pointer-events: auto;
    opacity: 1;
    transition: opacity var(--default-transition-duration);


    &._hidden {
      opacity: 0;
      display: none;
      pointer-events: none;
    }
  }

  &__menu-button-right, &__menu-button-left, &__menu-button-center {
    display: flex;
    transition: transform .467s cubic-bezier(.55, 0, .1, 1);
  }

  &__phone,
  &__callback {
    font-weight: 500;
    padding: 7px;
    margin: -7px;
  }

  &__phone-block,
  &__phone,
  &__callback {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__anniversary-link{
    padding: 10px 18px;
    background-color: var(--primary-color);
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  &__catalog-button {
    display: none;
  }
}

@include respond-up('large') {
  .header {
    --menu-padding: 13px;
    --header-padding: 0 45px;

    --animate-header-from: -126px;
    --animate-header-to: -60px; // Смотри: .header__inner - там должен быть такой же translateY

    &__logo-description-wrapper {
      width: 53px;
    }

    &__menu-button {
      &:hover {
        .header {
          &__menu-button-left {
            transform: translateX(12px);
          }

          &__menu-button-center {
            transform: scale(1.8);
          }

          &__menu-button-right {
            transform: translateX(-12px);
          }
        }
      }
    }

    &_sticky {
      --header-padding: 0 35px;

      .header-links {
        &__list {
          transform: translate(-180px, 63px);
        }

        &__text {
          display: none;
        }
      }

      .header {
        &__inner {
          transform: translateY(-60px);
        }

        &__phone-icon {
          top: 15px;
          right: 95px;
        }

        &__lower,
        &__logo-block {
          height: 66px;
        }

        &__logo-block {
          padding: 0 21px;
          transform: translateY(60px);
        }

        &__logo {
          width: 35px;
          height: 42px;
        }

        &__logo-letters {
          display: none;
        }

        &__anniversary-link{
          display: none;
        }
      }

      .drop-menu {
        &__wrap {
          top: 126px;
          position: fixed;
          z-index: 50;
        }

        &__button-close {
          display: none;
        }
      }
    }

    &_opened-menu {
      .header {
        &__lower {
          box-shadow: inset 0 -1px 0 0 var(--color-border);
        }
      }
    }

    &__logo-block {
      padding: 0 42px;
    }

    &__phone {
      --phone-padding: 46px;
      font-size: 17px;
      line-height: 135%;
    }

    &__part-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__anniversary-link{
      margin-right: 45px;
      &_mobile{
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__phone-icon {
      display: none;
      position: absolute;
    }

    &_sticky {
      .header {
        &__phone-icon_mob {
          display: none;
        }
      }
      .header-links {
        &__icon {
          svg {
            path {
              fill: var(--color-border);
            }
          }
        }
      }
    }

    &__phone {
      margin-right: var(--phone-padding);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 18px;
        background: var(--color-white-opacity-35);
        right: calc(0px - var(--phone-padding) / 2);
        top: calc(50% - 9px);
      }
    }

    &__upper,
    &__lower {
      height: 60px;
    }

    &__upper {
      background: var(--color-primary-dark);
      color: white;
    }

    &__lower {
      z-index: 100;
    }

    &__logo {
      width: 40px;
      height: 48px;
    }

    &__logo-letters {
      margin-top: 8px;
      display: flex;
    }

    &__logo-block-wrapper {
      flex-direction: column;
      align-items: flex-end;
    }

    &__logo-description {
      display: none;
    }

    &__header-block {
      flex-direction: column;
    }

    &__menu-double-button {
      margin-right: -10px;
    }

    &__anniversary-link{
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .header {
    --header-height-half: 48px;
    --menu-padding: 11px;

    --header-padding: 0 40px 0 25px;

    &__phone {
      --phone-padding: 44px;
      font-size: 16px;
      line-height: 140%;
    }

    &__logo-block {
      padding: 0 29px;
    }

    &__cross {

      &.cross {
        height: 48px;

        &:after,
        &:before {
          top: 22px;
        }

        .cross__inner {
          &:after,
          &:before {
            top: 22px;
          }
        }
      }
    }

    .drop-menu {
      &__button-close {
        display: flex;
        position: absolute;
        right: 30px;
        top: -2px;
      }
    }

    &_sticky {
      .header-links {
        &__list {
          transform: translate(-166px, 48px);
        }
      }

      .header {
        &__inner {
          transform: translateY(-38px);
        }

        &__phone-icon {
          top: 11px;
          right: 90px;
        }

        &__upper {
          height: 38px;
        }

        &__lower {
          height: 58px;
        }

        &__logo-block {
          padding: 0 15px;
          height: 58px;
          transform: translateY(38px);
        }

        &__logo {
          width: 31px;
          height: 38px;
        }

        &__logo-letters {
          display: none;
        }

        //temp
        &__menu-item {
          &:nth-last-child(4),
          &:nth-last-child(5) {
            display: none;
          }
        }
      }

      .drop-menu {
        &__wrap {
          top: 96px;
        }

        &__button-close {
          display: none;
        }
      }
    }

    //temp
    &__menu-item {
      &:last-child,
      &:nth-last-child(2),
      &:nth-last-child(3) {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    .drop-menu {
      &__wrap {
        position: fixed;
        z-index: 150;
      }
    }

    &_sticky {
      .drop-menu {
        &__block {
          padding-top: 15px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    &__anniversary-link{
      &_desktop{
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    --header-padding: 0 8px;

    --animate-header-from: -66px;
    --animate-header-to: 0;

    &_opened-menu {
      .header {
        &__menu-button-left,
        &__menu-button-center,
        &__menu-button-right {
          opacity: 0;
        }

        &__menu-button-wrapper {
          position: relative;

          &:before,
          &:after {
            opacity: 1;
          }
        }

        .index-page__filter-header-wrap {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &__with-sticky-block {
      .header__inner {
        box-shadow: unset;
      }
    }

    &__phone-icon {
      display: flex;
      margin-right: 20px;

      &_large {
        display: none;
      }
    }

    &__menu-button-wrapper {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background: var(--color-border);
        width: 22px;
        height: 2px;
        opacity: 0;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transition: opacity var(--default-transition-duration);
      }

      &:before {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

      &:after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }
    }

    &__menu-button-left,
    &__menu-button-center,
    &__menu-button-right {
      transition: opacity var(--default-transition-duration);
    }

    .header-links {
      &__icon {
        svg {
          path {
            fill: var(--color-border);
          }
        }
      }
    }

    &__lower {
      z-index: 180;
    }

    &_sticky {
      .header-links {
        &__list {
          margin-right: 20px;
        }
      }

      .header {
        &__inner {
          height: var(--header-height);
        }

        &__phone-icon {
          &_large {
            display: none;
          }
        }

        &__logo-description,
        &__logo-letters {
          display: none;
        }

        &__catalog-button{
          position: fixed;
          top: 0;
        }
      }
    }

    .drop-menu {
      &__button-close {
        display: none;
      }
    }

    &__menu,
    &__phone-block {
      display: none;
    }

    &__header-block {
      background: white;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__upper,
    &__lower {
      height: 100%;
    }

    &__upper {
      padding-right: 12px;
    }

    &__logo-block-wrapper {
      flex-direction: row;
      align-items: center;
    }

    &__logo-description-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      justify-content: flex-end;
    }

    &__logo {
      height: 23px;
      width: 20px;
    }

    &__logo-letters {
      height: 10px;
      width: 31px;
      margin-bottom: 4px;
    }

    &__logo-description {
      height: 9px;
      width: 55px;
    }

    &__logo-letters,
    &__logo-description {
      display: flex;
    }

    &__logo-block {
      padding: 0 19px;
    }

    //temp
    &__menu-item {
      &:last-child,
      &:nth-last-child(2) {
        display: none;
      }
    }

    &__anniversary-link{
      font-size: 15px;
    }

    &__catalog-button {
      display: flex;
      padding: 6px 8px;
      flex: 0 0 auto;
      background-color: #fff;
      position: absolute;
      top: var(--header-height);
      left: 0;
      right: 0;
    }

    &__catalog-button-title {
      background-color: var(--color-background);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      padding: 14px 16px 16px;
      flex: 0 1 100%;
    }

    &__catalog-button-icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 20px;
      background-color: var(--primary-color);
      flex: 0 0 auto;
    }

    &__catalog-button-icon {
      display: flex;
      padding: 10px;
      background-color: var(--color-white-opacity-10);
      border-radius: 100%;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &_index {
      height: var(--header-height);

      .header {
        &__inner {
          height: var(--header-height);
          position: relative;
        }
      }
    }
  }
}

@media print {
  .header {
    &__no-print {
      display: none;
    }

    .header-links__list {
      display: none;
    }

    &__upper {
      background: #fff;
      display: block;
      color: #000;
    }

    &__callback {
      display: none;
    }

    &__phone-block {
      display: flex;
      align-items: center;
    }

    &__phone {
      display: flex;
      color: #000;
    }

    &__lower {
      display: none;
    }

    &__logo-block {
      background: transparent;
    }

    &__logo-block-wrapper {
      svg {
        path {
          fill: #000;
        }
      }
    }
  }
}

@keyframes show-header {
  0% {
    transform: translateY(var(--animate-header-from));
    opacity: 0;
  }
  100% {
    transform: translateY(var(--animate-header-to));
    opacity: 1;
  }
}