.product-page-in-future-link {
  display: flex;
  border-bottom: 1px solid var(--color-border);

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;
    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }

  &__image-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 65%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    display: block;
    font-style: normal;
    font-weight: 500;
  }

  &__description {
    display: block;
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    margin-top: 15px;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description-wrap {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .product-page-in-future-link {
    padding: 0 0 30px;

    &:hover {
      .product-page-in-future-link {
        &__image {
          transform: scale(1.12);
        }

        &__icon {
          transform: translate3d(18px, -20px, 0);
          &_dub {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }

    &__image-wrap {
      margin-right: 42px;
    }

    &__picture {
      width: 247px;
      height: 161px;
    }

    &__image {
      transition: transform 2s cubic-bezier(0,0,.2,1);
    }

    &__title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond-up('medium') {
  .product-page-in-future-link {
    &__image-wrap {
      flex: 0 0 auto;
    }

    &__text-wrap {
      flex: 0 1 auto;
    }
  }
}

@include respond('medium') {
  .product-page-in-future-link {
    &__image-wrap {
      margin-right: 35px;
    }

    &__picture {
      width: 214px;
      height: 139px;
    }
  }
}

@include respond-down('medium') {
  .product-page-in-future-link {
    border-top: 1px solid var(--color-border);
    padding: 20px 0;


    &__title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
    }
  }
}

@include respond-down('small') {
  .product-page-in-future-link {
    flex-wrap: wrap;

    &__picture {
      width: 162px;
      height: 105px;
    }

    &__text-wrap {
      margin-top: 15px;
    }

    &__icon-wrap {
      margin-top: 20px;
    }
  }
}