// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.payment-sizes {
  &__item {
    border-top: 1px solid var(--color-border);
    display: flex;

    &:last-child {
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__price {
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
    color: var(--primary-color);
    flex: 0 0 auto;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    strong {
      font-weight: 500;
      color: var(--primary-color);
    }
  }

  &__text {
    &:first-child {
      border-right: 1px solid var(--color-border);
    }
  }

  &__text-wrap {
    display: flex;
    width: 100%;
  }
}

@include respond-up('large') {
  .payment-sizes {
    padding-top: 120px;

    &__list {
      margin-top: 50px;
    }

    &__item {
      padding: 40px 0;
    }

    &__text-wrap {
      max-width: 748px;
      flex: 0 1 auto;
    }

    &__text {
      &:first-child {
        width: 360px;
        padding-right: 50px;
        flex: 0 0 auto;
      }
      &:last-child {
        padding-left: 50px;
        flex: 0 1 auto;
      }
    }
  }
}

@include respond-up('medium') {
  .payment-sizes {
    &__item {
      justify-content: space-between;
    }

    &__price {
      font-size: 22px;
    }

    &__text {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .payment-sizes {
    padding-top: 90px;

    &__list {
      margin-top: 30px;
    }

    &__item {
      padding: 30px 0;
    }

    &__text-wrap {
      max-width: 520px;
    }

    &__text {
      &:first-child {
        padding-right: 30px;
      }
      &:last-child {
        padding-left: 30px;
      }
    }

    &__price {
      margin-right: 69px;
    }
  }
}

@include respond-down('medium') {
  .payment-sizes {
    &__item {
      align-items: flex-start;
    }

    &__text {
      flex: 0 0 50%;
    }
  }
}

@include respond-down('small') {
  .payment-sizes {
    padding-top: 40px;

    &__list {
      margin-top: 22px;
    }

    &__item {
      padding: 20px 0;
      flex-direction: column;
    }

    &__price {
      font-size: 18px;
    }

    &__text-wrap {
      margin-top: 18px;
    }

    &__text {
      font-size: 15px;
      line-height: 1.35em;
      &:first-child {
        padding-right: 16px;
      }
      &:last-child {
        padding-left: 16px;
      }
    }
  }
}