.our-values-slider-block {
  background-color: var(--color-background);

  &__inner {
    position: relative;
  }

  &__main-title {
    font-style: normal;
    font-weight: bold;
    border-bottom: 1px solid var(--color-border);
  }

  &__description-wrapper {
    background-color: #fff;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    display: flex;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    display: flex;
  }

  &__images-slider-wrap {
    position: absolute;
  }

  &__arrows {
    position: absolute;
    z-index: 15;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 69%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title,
  &__description {
    transition: transform .5s, opacity .5s;
  }

  &__text-slider-wrap {
    overflow: hidden;
  }

  &__text-slider-item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;
      .our-values-slider-block {
        &__title,
        &__description {
          transform: translateY(20px);
          opacity: 0;
        }
      }
    }
    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      .our-values-slider-block {
        &__title,
        &__description {
          transform: translateY(0);
          opacity: 1;
          transition-delay: .3s;
        }
      }
    }
    &.animate_out {
      transform: translateX(0);
      z-index: 1;
      .our-values-slider-block {
        &__title,
        &__description {
          transform: translateY(20px);
          opacity: 0;
        }
      }
    }
  }

  &__item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;
      .our-values-slider-block {
        &__picture {
          transform: translateX(100%);
        }
        &__image {
          transform: translateX(-100%);
        }
      }
    }
    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      .our-values-slider-block {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image {
          animation: animate-image .9s;
        }
      }
    }
    &.animate_out {
      transform: translateX(0);
      z-index: 1;
      .our-values-slider-block {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }
}

@include respond-up('large') {
  .our-values-slider-block {
    padding: 120px 0 195px;

    &__main-title {
      font-size: 26px;
      max-width: 389px;
      width: 100%;
      padding-bottom: 40px;
      top: 75px;
      left: 55px;
    }

    &__description-wrapper {
      padding: 75px 241px 66px 55px;
      grid-column: 1/8;
    }

    &__description {
      margin-top: 18px;
    }

    &__text-slider-wrap {
      margin-top: 60px;
      min-height: 187px;
    }

    &__images-slider-wrap {
      top: 83px;
      right: 0;
    }

    &__arrows {
      right: 0;
      top: 13px;
    }

    &__picture {
      width: 686px;
      height: 475px;
    }
  }
}

@include respond-up('medium') {
  .our-values-slider-block {
    &__main-title {
      line-height: 1.25em;
    }

    &__title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .our-values-slider-block {
    padding: 70px 0;

    &__description-wrapper {
      padding: 40px calc((119 / 768) * 100vw) 85px 25px;
      max-height: 457px;
    }

    &__main-title {
      font-size: 23px;
      padding-bottom: 30px;
    }

    &__description {
      margin-top: 15px;
    }

    &__arrows {
      right: 0;
      top: 34px;
    }

    &__text-slider-wrap {
      margin-top: 40px;
      min-height: calc((232 / 768) * 100vw);
    }

    &__images-slider-wrap {
      top: 94px;
      right: -#{$spacer-medium}px;
    }

    &__picture {
      width: calc((328 / 768) * 100vw);
      height: calc((306 / 768) * 100vw);
      max-height: 395px;
      max-width: 491px;
    }
  }
}

@include respond-down('medium') {
  .our-values-slider-block {
    &__description-wrapper {
      grid-column: 1/5;
    }
  }
}

@include respond-down('small') {
  .our-values-slider-block {
    padding: 30px 0 calc((281 / 375) * 100vw);
    position: relative;

    &__inner {
      position: unset;
    }

    &__main-title {
      font-size: 20px;
      line-height: 1.35em;
      padding-bottom: 25px;
    }

    &__title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 12px;
    }

    &__text-slider-wrap {
      margin-top: 25px;
      min-height: calc((194 / 375) * 100vw);
    }

    &__description-wrapper {
      padding: 33px #{$spacer-small}px 145px;
      max-height: 429px;
    }

    &__picture {
      width: calc((333 / 375) * 100vw);
      height: calc((306 / 375) * 100vw);
    }

    &__images-slider-wrap {
      bottom: 30px;
      right: 0;
    }

    &__arrows {
      top: 349px;
      left: calc(#{$spacer-small}px + 24px);
    }
  }
}