.breadcrumbs {
  color: var(--color-gray-dark);
  opacity: .8;

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link {
    display: block;
    padding: 5px;
    margin: -5px;
  }

  &__item, &__item_delimiter {
    margin-right: 12px;
  }

  &__item_delimiter {
    color: var(--color-border);
  }
}

@include respond-up('large') {
  .breadcrumbs {
    padding-bottom: 25px;
  }
}

@include respond('medium') {
  .breadcrumbs {
    padding-bottom: 16px;
  }
}

@include respond-down('small') {
  .breadcrumbs {
    padding-bottom: 18px;

    &__list{
      overflow: scroll;
      overflow: -moz-scrollbars-none; /* Firefox */

      &::-webkit-scrollbar{
        display: none;
      }

      section::-webkit-scrollbar {
        width: 0 !important
      }

      white-space: nowrap;
    }

    &__item {
      &:last-child {
        margin-right: -20px;
        padding-right: 20px;

        .breadcrumbs {
          &__name {
            margin-right: -20px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media print {
  .breadcrumbs {
    display: none;
  }
}