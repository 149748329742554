.product-info-price-block {
  display: flex;
  flex-direction: column;

  &__bottom-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__price-wrap {
    flex: 0 0 auto;
  }

  &__price-title {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__price-title-name {
    &_small {
      display: none;
    }
  }

  &__price {
    font-style: normal;
    font-weight: bold;
  }

  &__mortgage-wrap {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
  }

  &__mortgage-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-blue-gray);
    border-radius: 100%;
    width: 39px;
    height: 39px;
    flex: 0 0 auto;
  }

  &__mortgage-text {
    flex: 0 1 auto;
    display: flex;
    align-items: baseline;
  }

  &__mortgage-title {
    flex: 0 0 auto;
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__mortgage-price {
    font-style: normal;
    font-weight: 500;
    color: var(--primary-color);
  }

  &__bottom-buttons {
    display: flex;
  }

  &_layout {
    .product-info-price-block {
      &__price-title-name {
        &_medium-up {
          display: none;
        }

        &_small {
          display: block;
        }
      }
    }
  }
}

@include respond-up('large') {
  .product-info-price-block {
    &_apartment {
      .product-info-price-block {
        &__price-wrap {
          width: 240px;
          margin-right: 29px;
        }
      }
    }

    &_layout {
      .product-info-price-block {
        &__price-wrap {
          margin-right: 40px;
        }
      }
    }

    &__price {
      font-size: 26px;
      margin-top: 10px;
    }

    &__mortgage-title {
      margin-right: 4px;
      line-height: 1.15em;
      letter-spacing: -0.02em;
    }

    &__bottom-buttons {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .product-info-price-block {
    &__price-title {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__price {
      line-height: 1.25em;
    }

    &__mortgage-icon {
      margin-right: 10px;
    }

    &__mortgage-title {
      font-size: 15px;
    }

    &__mortgage-price {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .product-info-price-block {
    &__price-wrap {
      margin-right: 60px;
    }

    &__price {
      font-size: 23px;
      margin-top: 8px;
    }

    &__mortgage-title {
      margin-right: 6px;
      line-height: 1.35em;
    }

    &__bottom-buttons {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .product-info-price-block {

  }
}

@include respond-down('small') {
  .product-info-price-block {
    &__price-wrap {
      width: calc((162 / 375) * 100vw);
      margin-right: 14px;
    }

    &__price-title {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__price-title-name {
      &_medium-up {
        display: none;
      }

      &_small {
        display: block;
      }
    }

    &__price {
      font-size: 20px;
      line-height: 1.35em;
      margin-top: 2px;
    }

    &__mortgage-icon {
      margin-right: 8px;
    }

    &__mortgage-text {
      flex-direction: column;
      align-items: flex-start;
    }

    &__mortgage-title {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__mortgage-price {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__bottom-buttons {
      flex-direction: column;
      margin-top: 25px;
    }
  }
}

@media print {
  .product-info-price-block {
    &__bottom-buttons {
      display: none;
    }
  }
}