.project-map {
  position: relative;

  &__main-icon {
    width: 96px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-icon-img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: 50% 50%;
  }

  &__object-icon {
    width: 66px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__object-icon-img {
    width: 24px;
    height: 24px;
  }

  &__cluster-icon {
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-family: var(--font);
    font-size: 19px;
    box-shadow: 0 4px 10px rgba(12, 44, 128, 0.12)
  }

  &__hint-container {
    display: none;
  }

  &__project-hint {
    background-color: #fff;
    width: max-content;
    font-weight: bold;
    display: flex;
  }

  &__bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 10;
    background: linear-gradient(180deg, #FFFFFF 17.87%, rgba(255, 255, 255, 0) 87.6%);
  }

  &__project-hint-picture {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__filter-item {
    display: flex;
    height: max-content;
    font-style: normal;
    font-size: 15px;

    input {
      flex: 0 0 auto;
    }

    label {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      cursor: pointer;
    }

    input[type=checkbox] {
      display: none;
      &:checked {
        + label {
          color: var(--primary-color);

          svg {
            path {
              &[stroke] {
                stroke: var(--primary-color);
              }
              &[fill] {
                fill: var(--primary-color);
              }            }

            circle {
              &[stroke] {
                stroke: var(--primary-color);
              }
              &[fill] {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }

  &__filter-button {
    background-color: var(--color-primary-dark);
    color: #fff;
    display: flex;
    flex: 0 0 auto;
  }

  &__filter-wrap {
    position: relative;
  }

  &__filter {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 50%, 0);
    width: 100%;
  }

  &__filter-input {
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    transition: opacity .4s, transform .4s;
    display: flex;

    &._opened {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__filter-input-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__filter-item-icon {
    display: flex;
    flex: 0 0 auto;
  }

  &__filter-item-title {
    display: flex;
    flex: 0 0 auto;
  }

  &__filter-button-title,
  &__filter-button-icon {
    display: flex;
  }
}

@include respond-up('large') {
  .project-map {
    margin-top: 138px;

    &__canvas {
      height: 900px;
    }

    &__project-hint-picture {
      width: 157px;
      height: 157px;
    }

    &__filter-button {
      padding: 30px 28px;
      max-height: 158px;
    }

    &__filter-button-icon {
      margin-top: 58px;
    }

    &__filter-input {
      padding: 40px 115px 40px 40px;
    }

    &__filter-input-wrap {
      margin: -15px -22px;
    }

    &__filter-item {
      label {
        padding: 15px 22px;
      }
    }
  }
}

@include respond-up('medium') {
  .project-map {
    &__filter-input-close {
      display: none;
    }

    &__bg {
      height: 242px;
    }

    &__project-hint-text-wrap {
      padding: 16px 16px 16px 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__project-hint-type {
      display: flex;
      flex-wrap: wrap;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 1.2em;
      color: var(--color-gray-dark);
      width: 121px;
    }

    &__project-hint-title {
      display: flex;
      flex-wrap: wrap;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2em;
      width: 121px;
    }

    &__filter-item {
      font-weight: 500;
      line-height: 1.2em;
    }

    &__filter-item-icon {
      margin-right: 16px;
    }

    &__filter-button {
      flex-direction: column;
    }

    &__filter-input {
      flex: 0 1 auto;
      width: 100%;
    }
  }
}

@include respond('medium') {
  .project-map {
    margin-top: 90px;

    &__canvas {
      height: 800px;
    }

    &__project-hint-picture {
      width: 155px;
      height: 155px;
    }

    &__filter-button {
      padding: 30px 23px;
      max-height: 151px;
    }

    &__filter-button-icon {
      margin-top: 51px;
    }

    &__filter-input {
      padding: 22px 36px 22px 25px;
    }

    &__filter-input-wrap {
      margin: -11px -12px;
    }

    &__filter-item {
      max-height: 46px;

      label {
        padding: 11px 12px;
      }
    }
  }
}

@include respond-down('small') {
  .project-map {
    margin-top: 40px;

    &__canvas {
      height: 500px;
    }

    &__bg {
      height: 129px;
    }

    &__project-hint {
      display: none;
    }

    &__filter-item-icon {
      margin-right: 10px;
    }

    &__filter-button {
      padding: 13px 18px 13px 20px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      width: 261px;
    }

    &__filter-button-icon {
      margin-right: 10px;
    }

    &__filter-input {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 15;
      filter: drop-shadow(0px 12px 40px rgba(125, 131, 146, 0.21));
      transform: translate3d(0, 20px, 0);

      &._opened {
        transform: translate3d(0, 0, 0);

        .project-map {
          &__filter-input-close-icon {
            svg {
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    &__filter-input-wrap {
      flex-direction: column;
      width: 261px;
      padding: 30px 0;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 30px;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 30px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
      }
    }

    &__filter-input-close {
      padding: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__filter-input-close-icon {
      svg {
        transform: rotate(0);
        transition: transform .4s;

        path {
          stroke: var(--color-border);
        }
      }
    }

    &__filter-item {
      font-weight: normal;
      line-height: 1.35em;

      label {
        padding: 8px 10px;
        width: 100%;
      }
    }

    &__filter-wrap {
      z-index: 20;
    }
  }
}