.layout-slider {
  position: relative;

  &__arrow-wrapper {
    pointer-events: none;
  }

  &__arrows {
    pointer-events: all;
  }

  &__empty {
    color: var(--color-gray-dark);
    flex: 0 0 100%;
    text-align: center;
    padding: 30px 0;
    font-weight: bold;
  }

  &__pagination {
    height: 23px;
    .swiper-pagination-bullet {
      margin: 7px 7px !important;
      width: 9px;
      height: 9px;
      background: var(--color-blue-gray);
      opacity: 1;
      transition: {
        property: background-color;
        duration: .3s;
      };

      &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        width: 19px;
        height: 19px;
        border-radius: 100%;
        z-index: 1;
        border: 1px solid var(--color-blue-gray);
        transform: scale(0);
        transition: {
          property:transform;
          duration: .3s;
        };
      }
    }

    .swiper-pagination-bullet-active {
      position: relative;
      background-color: var(--primary-color);

      &::before {
        transform: scale(1);
      }
    }
  }
}

@include respond-up('large') {
  .layout-slider {
    &__item {
      width: 314px;
      flex: 0 0 314px;

      &:first-child, &:last-child {
        width: 284px;
        flex: 0 0 284px;
      }
    }

    &__arrow-wrapper {
      padding-bottom: 87px;
    }
  }
}

@include respond-up('medium') {
  .layout-slider {
    &__slider {
      display: flex !important;
    }

    &__arrow-wrapper {
      position: relative;
      z-index: 100;
    }

    &__arrows {
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }
}

@include respond('medium') {
  .layout-slider {
    &__item {
      width: 244px;
      flex: 0 0 244px;

      &:first-child, &:last-child {
        width: 223px;
        flex: 0 0 223px;
      }
    }

    &__arrow-wrapper {
      padding-bottom: 59px;
    }
  }
}

@include respond-down('medium') {
  .layout-slider {

  }
}

@include respond-down('small') {
  .layout-slider {
    &__arrows {
      display: none;
    }

    &__arrow-wrapper {
      padding-bottom: 37px;
    }
  }
}