.renovation {
  &__tabs, &__faq-container {
    background-color: var(--color-background);

    .tabs__counter {
      margin: 0 10px 0 0;
    }
  }


  &__slider-subtitle {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }

  &__slider-title {
    font-weight: bold;
    line-height: 125%;
    color: var(--color-primary-dark);
  }

  &__list {
    position: relative;

    &._before {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: var(--large-indent);
        background-color: white;
      }
    }
  }

  &__head {
    border-top: 1px solid var(--color-blue-gray);
  }

  &__layouts-block {
    display: grid;
  }

  &__layouts-title {
    grid-area: title;
  }

  &__layouts-form {
    grid-area: form;
  }

  &__layouts-list {
    grid-area: list;
  }
}

@include respond-up('large') {
  .renovation {
    &__list {
      padding: 0 var(--large-indent);
      position: relative;

      &._before {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: var(--large-indent);
          background-color: white;
        }
      }
    }

    &__faq-container {
      padding-top: 120px;
    }

    &__slider-wrapper {
      margin-bottom: 124px;
    }

    &__slider-head {
      margin-bottom: 50px;
    }

    &__slider-title {
      grid-column: 1/7;
      font-size: 36px;
    }

    &__slider-subtitle {
      grid-column: 7/13;
    }

    &__head {
      margin-top: 70px;
      margin-bottom: 110px;
      padding-top: 40px;
    }

    &__blocks {
      margin-bottom: 120px;
    }

    &__layouts-block {
      padding: 120px 0;
    }

    &__layouts-list {
      margin-top: 40px;
    }
  }
}


@include respond-up('medium') {
  .renovation {
    &__layouts-block {
      grid-template-columns: 1fr auto;
      grid-template-areas:
              "title form"
              "list list";
    }
  }
}

@include respond('medium') {
  .renovation {
    &__head {
      padding-top: 30px;
      margin: 40px 0 156px;
    }

    &__slider-title {
      grid-column: 1/4;
      font-size: 30px;
    }

    &__faq-container {
      padding-top: 70px;
    }

    &__slider-wrapper {
      margin-bottom: 94px;
    }

    &__slider-head {
      margin-bottom: 30px;
    }

    &__slider-subtitle {
      grid-column: 4/7;
    }

    &__blocks {
      margin-bottom: 90px;
    }

    &__layouts-block {
      padding: 70px 0 100px;
    }

    &__layouts-title {
      max-width: 387px;
    }

    &__layouts-list {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .renovation {
    &__layouts-list {
      .catalog-grid__item:last-child {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .renovation {
    &__head {
      padding-top: 20px;
      margin: 30px 0 40px;
    }

    &__slider-head {
      grid-row-gap: 12px;
      margin-bottom: 30px;
    }

    &__faq-container {
      padding-top: 30px;
    }

    &__slider-wrapper {
      margin-bottom: 33px;
    }

    &__slider-title {
      grid-row: 1;
      font-size: 22px;
      grid-column: 1/6;
    }

    &__slider-subtitle {
      grid-row: 2;
      font-size: 15px;
      grid-column: 1/6;
    }

    &__blocks {
      margin-bottom: 40px;
    }

    &__layouts-block {
      grid-template-columns: 100%;
      grid-template-areas:
              "title"
              "list"
              "form";
    }

    &__button {
      width: 100%;
    }

    &__layouts-block {
      padding: 30px 0;
    }

    &__layouts-list {
      margin: 22px 0 20px;
      border-bottom: 1px solid var(--color-border);
    }
  }
}