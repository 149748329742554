.tags {
  &__list {
    display: flex;
    flex-wrap: wrap;

    &._mini {
      .tags {
        &__item {
          background: var(--color-background);
          font-weight: normal;
          font-size: 13px;
          line-height: 125%;
          color: var(--color-gray-dark);
          transition: color var(--default-transition-duration);
          border: none;
          margin-right: 5px;

          &:hover {
            color: var(--color-primary-dark);
          }
        }

        &__link {
          padding: 4px 5px;
        }
      }
    }
  }

  &__item {
    border: 1px solid var(--color-border);
    margin-right: 7px;
    transition: border-color, background-color, color var(--default-transition-duration);

    &:last-child {
      margin-right: 0;
    }

    &._active {
      border-color: var(--primary-color);
      background: var(--primary-color);
      color: white;
    }

    &:hover {
      border-color: var(--color-blue-gray);

      &._active {
        border-color: var(--primary-color);
      }
    }
  }

  &__link {
    display: block;
    padding: 9px 12px;
  }
}