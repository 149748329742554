.about-awards {
  position: relative;

  &__select {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid var(--color-border);
  }

  &__select-wrap {
    position: absolute;

    &::after {
      content: '';
      position: absolute;
      background-image: url('../images/svg/icons/arrow-down-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 15px;
      width: 10px;
      height: 8px;
    }
  }

  &__top {
    display: flex;
  }

  &__title {
    flex: 0 0 auto;
  }

  &__tabs-wrap {
    flex: 0 1 auto;
  }

  &__content-item-wrap {
    display: grid;
  }

  &__award-item {
    display: flex;
    flex-direction: column;

    &._hidden {
      display: none;
    }
  }

  &__award-item-title {
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
    color: var(--color-gray-dark);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__content-item {
    border-top: 1px solid var(--color-border);
  }
}

@include respond-up('large') {
  .about-awards {
    margin-top: 146px;

    &__select-wrap {
      top: 0;
    }

    &__select {
      min-width: 221px;
    }

    &__top {
      min-height: 57px;
      flex-direction: column;
    }

    &__title {
      margin-right: 151px;
    }

    &__content-items {
      margin-top: 43px;
    }

    &__content-item-wrap {
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 60px;
    }

    &__award-item {
      padding: 22px 30px 0 30px;

      &:nth-child(5n+1) {
        padding: 22px 30px 0 0;
      }

      &:nth-child(5n) {
        padding: 22px 0 0 30px;
      }

      &:not(:nth-child(5n)) {
        border-right: 1px solid var(--color-border);
      }

      &:nth-child(n+6) {
        border-top: 1px solid var(--color-border);
      }
    }

    &__tabs-wrap {
      overflow-x: scroll;
      margin-left: -17px;
      margin-top: 43px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .about-awards {

    &__select-wrap {
      right: 0;
    }

    &__select {
      padding: 17px 40px 17px 15px;
    }

    &__award-item-title {
      font-size: 14px;
      margin-top: 40px;
    }

    &__picture {
      width: 104px;
      height: 147px;
    }
  }
}

@include respond('medium') {
  .about-awards {
    margin-top: 90px;

    &__select-wrap {
      top: -7px;
    }

    &__content-items {
      margin-top: 30px;
    }

    &__content-item-wrap {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 40px;
    }

    &__award-item {
      padding: 20px 29px 0 29px;

      &:nth-child(3n+1) {
        padding: 20px 29px 0 0;
      }

      &:nth-child(3n) {
        padding: 20px 0 0 29px;
      }

      &:not(:nth-child(3n)) {
        border-right: 1px solid var(--color-border);
      }

      &:nth-child(n+4) {
        border-top: 1px solid var(--color-border);
      }
    }

    &__tabs-wrap {
      margin: 30px 0 0 -#{$spacer-medium}px;
      //max-width: calc((455 / 768) * 100vw);
    }

    &__tabs {
      padding-left: calc(#{$spacer-medium}px - 18px);
    }

    &__select {
      min-width: calc((238 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .about-awards {
    &__top {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .about-awards {
    margin-top: 40px;

    &__content-items {
      margin-top: 92px;
    }

    &__award-item-title {
      font-size: 13px;
      margin-top: 20px;
    }

    &__picture {
      width: 87px;
      height: 123px;
    }

    &__tabs-wrap {
      margin: 8px -#{$spacer-small}px 0;
    }

    &__tabs {
      padding: 0 calc(#{$spacer-small}px - 12px);
    }

    &__award-item {
      &:nth-child(2n+1) {
        padding: 20px 16px 0 0;
        border-right: 1px solid var(--color-border);
      }

      &:nth-child(2n) {
        padding: 20px 0 0 16px;
      }

      &:nth-child(n+3) {
        border-top: 1px solid var(--color-border);
      }
    }

    &__content-item-wrap {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
    }

    &__select-wrap {
      top: 100px;
      left: 0;
      right: 0;
    }

    &__select {
      padding: 12px 34px 12px 12px;
      width: 100%;
    }
  }
}