.layout-ways-to-buy {
  background-color: var(--color-background);

  &__link {
    display: block;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    display: flex;
  }

  &__item {
    position: relative;
  }

  &__number {
    display: flex;
    font-style: normal;
    font-weight: normal;
    color: var(--primary-color);
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 68%;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-text {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
  }
}

@include respond-up('large') {
  .layout-ways-to-buy {
    padding: 120px 0;

    &__items-wrap {
      margin-top: 40px;
    }

    &__items {
      // Чтобы слайдер делал так как хочет дизайнер, приходится в тини делать centre: true,
      // в таком случае выбранный айтем находится по средине экрана,
      // и нужно двигать весь список на левую границу
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 198px);
    }

    &__picture {
      width: 380px;
      height: 260px;
    }

    &__item-text {
      top: 25px;
      left: 30px;
    }

    &__item-title {
      margin-top: 22px;
    }
  }
}

@include respond-up('medium') {
  .layout-ways-to-buy {
    &__number {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    .tns-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .layout-ways-to-buy {
    padding: 70px 0;

    &__items-wrap {
      margin-top: 30px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 175px) * -1);
    }

    &__item {
      display: flex;
    }

    &__picture {
      width: 333px;
      height: 228px;
    }

    &__item-text {
      top: 22px;
      left: 25px;
    }

    &__item-title {
      margin-top: 16px;
    }
  }
}

@include respond-down('medium') {
  .layout-ways-to-buy {

  }
}

@include respond-down('small') {
  .layout-ways-to-buy {
    padding: 40px 0;

    &__items-wrap {
      margin-top: 22px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 151px) * -1);
    }

    &__number {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
      margin-top: 15px;
    }

    &__picture {
      width: 302px;
      height: 206px;
    }

    &__item-text {
      top: 20px;
      left: 22px;
    }

    &__arrows {
      display: none;
    }

    .tns-nav {
      margin-top: 25px;
    }
  }
}