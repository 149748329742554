.client-compilations {

  &__tabs-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    .tabs {
      &__item {
        .tabs {
          &__link {
            font-weight: 500;
            padding: 17px 19px;
          }
        }
      }
    }
  }

  &__list-wrapper {
    margin-top: 42px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__item-wrapper {
    background: white;
    padding: 22px 30px;
  }

  &__card-footer-wrapper {
    margin-top: 20px;
  }

  &__secondary-button {
    &.button_secondary {
      border: none;
      box-shadow: 0 0 0 1px var(--color-blue-gray) inset;

      &:hover {
        box-shadow: 0 0 0 1px var(--primary-color) inset;
        transition: box-shadow var(--default-transition-duration);;
      }
    }
  }

  &__alert-button {
    &.button {
      background-color: var(--color-red-opacity-15);
    }

    .button__link {
      color: var(--color-red);
    }
  }

  &__create-button {
    display: block;
  }

  &__share-wrap{
    margin: 20px 0;
    display: flex;
    align-content: flex-start;
    justify-content: right;
    &:first-child{
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  &__share-link{
    display: flex;
  }

  &__share-text{
    margin-left: 10px;
  }

  .ya-share2 {
    position: relative;

    .ya-share2__link_more {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      display: block;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include respond-up('large') {
  .client-compilations {
  }
}

@include respond-down('medium') {
  .client-compilations {
    padding: 0 var(--grid-spacer);
  }
}
