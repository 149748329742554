.product-page-advantages {
  &__top,
  &__controls {
    display: flex;
  }

  &__strip {
    height: 1px;
    background-color: var(--color-border);
    overflow: hidden;
    width: 160px;
    flex: 0 0 auto;
    margin-right: 50px;
  }

  &__arrows {
    flex: 0 1 auto;
  }

  &__item-top {
    position: relative;
  }

  &__item-description {
    position: absolute;
    z-index: 10;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: transform .4s, opacity .4s;
  }

  &__item-bottom {
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
  }

  &__items {
    display: flex;
  }

  &__item {
    &:hover {
      .product-page-advantages {
        &__item-description {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        &__item-top-bg {
          opacity: 1;
        }

        &__item-icon {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__item-top-bg {
    will-change: opacity;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    transition: opacity .4s;
    opacity: 0;
    background: linear-gradient(359.84deg, rgba(0, 9, 33, 0.67) 3.76%, rgba(3, 13, 44, 0) 99.86%);
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 66%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-icon {
    display: flex;
    transition: transform .4s;
    svg {
      stroke: var(--primary-color);
    }
  }

  &__strip-filling {
    background-color: var(--color-primary-dark);
    height: 100%;
    transition: width .9s;
  }
}

@include respond-up('large') {
  .product-page-advantages {
    &__items {
      margin-top: 40px;
    }

    &__item-description {
      bottom: 35px;
      left: 35px;
      right: 35px;
    }

    &__item-bottom {
      margin-top: 19px;
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__items {
      // Чтобы слайдер делал так как хочет дизайнер, приходится в тини делать centre: true,
      // в таком случае выбранный айтем находится по средине экрана,
      // и нужно двигать весь список на левую границу
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 287px);
    }

    &__picture {
      width: 574px;
      height: 380px;
    }
  }
}

@include respond-up('medium') {
  .product-page-advantages {
    &__top {
      align-items: center;
      justify-content: space-between;
    }

    &__controls {
      align-items: flex-end;
    }

    &__item-description {
      font-size: 15px;
      line-height: 1.35em;
    }

    .tns-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .product-page-advantages {
    &__items {
      margin-top: 30px;
    }

    &__item-bottom {
      margin-top: 16px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 202px) * -1);
    }
  }
}

@include respond-down('medium') {
  .product-page-advantages {
    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-description {
      right: 18px;
      bottom: 21px;
      left: 18px;
    }
  }
}

@include respond-down('small') {
  .product-page-advantages {
    &__items {
      margin-top: 22px;
    }

    &__item-bottom {
      margin-top: 14px;
    }

    &__item-description {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 169px) * -1);
    }

    &__controls {
      display: none;
    }

    .tns-nav {
      margin-top: 25px;
    }
  }
}