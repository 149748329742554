.about-advantages-slider {
  &__top-wrap {
    display: flex;
  }

  &__top {
    display: flex;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 1 auto;
  }

  &__title {
    flex: 0 0 auto;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 68%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-inner {
    display: flex;
    position: relative;

    &:hover {
      .about-advantages-slider {
        &__hover {
          opacity: 0;

          svg {
            transform: rotate(45deg);
          }
        }

        &__item-description {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        &__bg {
          background-color: var(--color-black-opacity-40);
        }
      }
    }
  }

  &__bg {
    transition: background-color .4s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    background: linear-gradient(359.84deg, rgba(0, 9, 33, 0.48) 3.76%, rgba(3, 13, 44, 0) 99.86%);
    pointer-events: none;
  }

  &__text-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 20;
    pointer-events: none;
  }

  &__hover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: var(--color-black-opacity-40);
    border-radius: 100%;
    width: 54px;
    height: 54px;
    z-index: 20;
    pointer-events: none;
    opacity: 1;
    transition: opacity .4s;

    svg {
      transition: transform .4s;

      path {
        stroke: #fff;
      }
    }
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: #fff;
  }

  &__item-description {
    font-size: 16px;
    line-height: 1.5em;
    color: #fff;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity .4s, transform .4s;
  }
}

@include respond-up('large') {
  .about-advantages-slider {
    padding: 120px 0;

    &__list {
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 292px);
    }

    &__slider-wrap {
      margin-top: 35px;
    }

    &__button {
      margin-top: 35px;
    }

    &__title {
      max-width: 334px;
      margin-right: 176px;
    }

    &__description {
      max-width: 482px;
    }

    &__item-inner,
    &__picture {
      width: 584px;
      height: 397px;
    }

    &__text-wrap {
      padding: 40px;
    }

    &__arrows-wrap {
      margin-top: 90px;
    }
  }
}

@include respond-up('medium') {
  .about-advantages-slider {
    .tns-nav {
      display: none;
    }

    &__top-wrap {
      flex-direction: column;
    }

    &__arrows-wrap {
      display: flex;
      justify-content: flex-end;
    }

    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__item-description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .about-advantages-slider {
    padding: 90px 0 70px;

    &__list{
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 226px) * -1);
    }

    &__slider-wrap {
      margin-top: 25px;
    }

    &__text-wrap {
      padding: 25px;
    }

    &__item-inner,
    &__picture {
      width: 452px;
      height: 307px;
    }

    &__top {
      justify-content: space-between;
    }

    &__title,
    &__wrap {
      flex: 0 0 48%;
    }

    &__arrows-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .about-advantages-slider {
    &__button {
      margin-top: 25px;
    }
  }
}

@include respond-down('small') {
  .about-advantages-slider {
    padding: 40px 0 30px;

    &__arrows-wrap {
      display: none;
    }

    &__top {
      flex-direction: column;
    }

    &__slider-wrap {
      margin-top: 40px;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__text-wrap {
      padding: 18px;
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__item-inner,
    &__picture {
      width: 337px;
      height: 229px;
    }

    &__wrap {
      margin-top: 20px;
    }

    .tns-nav {
      margin-top: 15px;
    }

    &__list {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 169px) * -1);
    }
  }
}