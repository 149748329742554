@use "sass:math";

.simple-offer {
  border-top: 1px solid var(--color-border);

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    display: block;
  }

  &__title {
    font-weight: 500;
    line-height: 135%;
    color: var(--color-primary-dark);
  }

  &__description {
    line-height: 150%;
    color: var(--color-gray-dark);

  }
}

@include respond-up('large') {
  .simple-offer {
    margin-top: 83px;
    padding-top: 40px;

    &__button-second {
      margin-left: 20px;
    }

    &__img-container {
      grid-column: 1/7;
    }

    &__picture {
      padding-top: math.div(549, 584) * 100%;
    }

    &__text-block {
      padding-top: 90px;
      grid-column: 8/13;
    }

    &__title {
      font-size: 19px;
      margin-bottom: 18px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 35px;
    }
  }
}

@include respond('medium') {
  .simple-offer {
    padding-top: 30px;
    margin-top: 40px;

    &__button {
      width: 70%;
    }

    &__img-container {
      grid-column: 1/4;
      padding-right: 25px;
    }

    &__link {
      width: var(--grid-column2);
    }

    &__picture {
      padding-top: math.div(326, 307) * 100%;
    }

    &__text-block {
      grid-column: 4/7;
      padding-top: 15px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 16px;
    }

    &__description {
      margin-bottom: 25px;
      font-size: 15px;
    }
  }
}

@include respond-down('medium') {
  .simple-offer {
    &__button-second {
      margin-top: 15px;
    }
  }
}

@include respond-down('small') {
  .simple-offer {
    padding-top: 20px;
    margin-top: 30px;

    &__button {
      width: 100%;
    }

    &__img-container {
      grid-column: 1/4;
      grid-row: 1;
      margin-bottom: 25px;
    }

    &__picture {
      padding-top: math.div(235, 250) * 100%;
    }

    &__text-block {
      grid-row: 2;
      grid-column: 1/5;
      padding-left: 35px;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 12px;
    }

    &__description {
      font-size: 14px;
      margin-bottom: 25px;
    }
  }
}
