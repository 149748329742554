.tabs {
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  font-size: var(--tabs-font-size);
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  svg {
    path {
      transition: fill var(--default-transition-duration);
    }
  }

  &__link {
    display: block;
    width: max-content;
    transition: background-color var(--default-transition-duration);
    position: relative;

    &:before {
      position: absolute;
      content: "";
      transition: background-color var(--default-transition-duration);
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
  }

  &_zero {
    font-size: var(--tabs-font-size-first);

    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              background: white;
            }

            &__number {
              border-color: var(--primary-color);
              background: var(--primary-color);
              color: white;
            }
          }
        }
      }

      &__link {
        padding: 24px 35px;
        display: flex;
        align-items: center;
        background: var(--color-background);
      }

      &__number {
        font-size: 12px;
        line-height: 100%;
        padding: 2px;
        border: 1px solid var(--color-border);
        background: var(--t);
        border-radius: 50%;
        margin-right: 15px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        transition: border-color, background-color, color var(--default-transition-duration);
      }
    }
  }

  &_first {
    font-size: var(--tabs-font-size-first);
    background: var(--color-background);

    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              background: white;
            }

            &__counter {
              border-color: var(--primary-color);
              background: var(--primary-color);
              color: white;
            }
          }
        }
      }

      &__link {
        padding: 24px 35px;
        display: flex;
        align-items: center;
        background: var(--color-background);
      }

      &__counter {
        font-size: 12px;
        line-height: 100%;
        padding: 2px;
        border: 1px solid var(--color-border);
        background: var(--t);
        border-radius: 50%;
        margin-left: 15px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        transition: color, background-color, border-color var(--default-transition-duration);
      }
    }
  }

  &_second {
    .tabs {
      &__item {
        &._active, &:hover {
          .tabs {
            &__link {
              color: var(--primary-color);
            }
          }
        }

        &._active {
          .tabs {
            &__link {
              &:before {
                background: var(--primary-color);
              }
            }
          }
        }
      }

      &__link {
        padding: 5px 18px 15px;
        color: var(--color-gray-dark);

        &:before {
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          bottom: 0;
          left: calc(50% - 2px);
        }
      }
    }
  }

  &_third {
    .tabs {
      &__item {
        &:hover {
          .tabs {
            &__link {
              &:before {
                background: var(--color-border);
              }
            }
          }
        }

        &._active, &._active:hover {
          .tabs {
            &__link {
              color: var(--primary-color);

              &:before {
                background: var(--primary-color);
              }
            }
          }
        }
      }

      &__link {
        padding: 5px 18px 15px;
        color: var(--color-gray-dark);
        position: relative;

        &:before {
          position: absolute;
          width: calc(100% - 36px);
          bottom: 0;
          height: 2px;
          left: 18px;
          right: 18px;
        }
      }
    }
  }

  &_fourth {
    .tabs {
      &__item {
        margin-right: 4px;


        &:hover {
          .tabs {
            &__link {
              background: white;
            }
          }
        }

        &._active, &._active:hover {
          .tabs {
            &__link {
              color: var(--primary-color);
              background: white;

              svg {
                path {
                  fill: var(--primary-color);
                }
              }

              &:before {
                background: var(--primary-color);
              }
            }
          }
        }
      }

      &__link {
        padding: var(--tabs-4-padding);
        color: var(--color-gray-dark);
        position: relative;
        background: var(--color-grey-super-light);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          path {
            fill: var(--color-border);
          }
        }

        &:before {
          position: absolute;
          width: 100%;
          top: 0;
          height: 2px;
          left: 0;
          right: 0;
        }
      }

      &__icon {
        margin-right: 10px;
        display: flex;
      }
    }
  }

  &_fifth {
    padding: var(--tabs-5-list-padding);
    font-size: var(--tabs-font-size-fifth);

    .tabs {
      &__item {
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }

      &__link {
        padding: var(--tabs-5-padding);
        position: relative;
      }
    }
  }

  &_white {
    background: white;

    .tabs {
      &__item {
        &:hover {
          .tabs {
            &__link {
              color: var(--primary-color);
            }
          }
        }

        &._active, &._active:hover {
          .tabs {
            &__link {
              background: var(--color-primary-dark);
              color: white;
            }
          }
        }
      }

      &__link {
        color: var(--color-primary-dark);
      }
    }
  }

  &_blue {
    background: var(--primary-color);

    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              color: var(--primary-color);
              background: white;
            }
          }
        }
      }

      &__link {
        color: white;
      }
    }
  }

  &_sixth {
    font-size: var(--tabs-font-size-first);

    .tabs {
      &__item {
        margin-right: 15px;
        border-radius: 50px;

        &:last-child {
          margin-right: 0;
        }

        &._active, &:hover {
          background: var(--color-background);
        }

        &._active {
          .tabs {
            &__link {
              color: var(--primary-color);
            }
          }
        }
      }

      &__link {
        padding: 7px 26px;
        color: var(--color-gray-dark);
      }
    }
  }

  &_seventh {
    .tabs {
      &__item {
        position: relative;

        &._active {
          .tabs {
            &__link {
              color: var(--primary-color);
              padding-left: 24px;

              &:before {
                background: var(--primary-color);
              }
            }
          }
        }
      }

      &__link {
        &:before {
          position: absolute;
          width: 6px;
          height: 6px;
          left: 0;
        }
      }
    }
  }

  &_eights {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 15px;

    .tabs {
      &__item {
        flex-direction: column;

        &._active {
          .tabs {
            &__link {
              color: var(--color-primary-dark);

              &:before {
                background: var(--primary-color);
              }
            }
          }
        }

        &._opened {
          .tabs {
            &__link {
              color: var(--color-primary-dark);

              &:before {
                background: var(--primary-color);
              }
            }

            &__sub-list {
              //opacity: 1;
            }
          }
        }
      }

      &__list-wrapper {
        height: 0;
        overflow: hidden;
        transition: {
          property: height;
          duration: .5s;
          timing-function: ease-in-out;
        };
      }

      &__link {
        color: var(--color-gray-dark);
        padding-left: 30px;
        display: flex;
        align-items: center;
        height: 36px;

        &:before {
          width: 4px;
          height: 36px;
          left: 0;
          top: 0;
          bottom: 0;
        }

        svg {
          path {
            fill: var(--color-border);
          }
        }
      }

      &__icon {
        margin-right: 19px;
      }

      &__sub-list {
        transition: background-color, color var(--default-transition-duration);
        flex-direction: column;
        padding-left: 63px;
        padding-top: 12px;
        padding-bottom: 23px;
      }

      &__sub-item {
        margin-bottom: 11px;

        &:last-child {
          margin-bottom: 0;
        }

        &._active {
          .tabs {
            &__sub-link {
              color: var(--primary-color);
              background: var(--color-background);
            }
          }
        }
      }

      &__sub-link {
        padding: 4px 12px;
        border-radius: 50px;
      }
    }
  }

  &_ninth {
    margin: 0 -15px;

    .tabs {
      &__item {

        &._active, &:hover {
          .tabs {
            &__link {
              color: var(--color-primary-dark);
            }
          }
        }
      }

      &__link {
        color: var(--color-gray-dark);
        padding: 15px;
      }
    }
  }
}

@include respond-up('large') {
  .tabs {
    &_zero,
    &_first {
      .tabs {
        position: relative;

        &__item {
          &:first-child {
            position: static;

            &._active {
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background-color: #fff;
                height: 100%;
                width: var(--large-indent);
              }
            }

            .tabs__link {
              padding: 24px 35px 24px 0;
            }
          }
        }
      }
    }

    &_zero,
    &_first {
      .tabs {
        &__link {
          letter-spacing: -0.03em;
        }
      }
    }

    &_seventh {
      flex-direction: column;
      width: 258px;

      .tabs {
        &__item {
          box-shadow: 0 1px 0 var(--color-border);
          justify-content: flex-start;

          &:hover {
            .tabs {
              &__link {
                color: var(--primary-color);
              }
            }
          }
        }

        &__link {
          padding: 18px 18px 18px 0;

          &:before {
            top: calc(50% - 3px);
          }
        }
      }
    }
  }
  :root {
    --tabs-font-size: 17px;
    --tabs-font-size-first: 18px;
    --tabs-font-size-fifth: 16px;
    --tabs-4-padding: 15px 19px;
    --tabs-5-padding: 11px 20px;
    --tabs-5-list-padding: 6px;
  }
}

@include respond-down('medium') {
  .tabs {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &_seventh {
      .tabs {
        &__item {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        &__link {
          &:before {
            top: calc(50% - 2px);
          }
        }
      }
    }

    &__item{
      &._mobile-hide{
        display: none;
      }
    }
  }
  :root {
    --tabs-font-size: 16px;
    --tabs-font-size-first: 16px;
    --tabs-font-size-fifth: 15px;
    --tabs-4-padding: 12px 19px;
    --tabs-5-padding: 7px 16px;
    --tabs-5-list-padding: 5px;
  }
}

@include respond('medium') {
  .tabs {
    &_zero,
    &_first {
      .tabs {
        &__item {
          &:first-child {
            .tabs__link {
              padding: 24px 35px 24px #{$spacer-medium}px;
            }
          }
        }
      }
    }
  }
}

@include respond('small') {
  .tabs {
    &_zero,
    &_first {
      .tabs {
        &__item {
          &:first-child {
            .tabs__link {
              padding: 14px 19px 14px #{$spacer-small}px;
            }
          }
        }
      }
    }

    &_third {
      .tabs {
        &__item {
          .tabs__link {
            padding: 14px 12px;

            &:before {
              width: calc(100% - 24px);
              left: 12px;
              right: 12px;
            }
          }
        }
      }
    }
  }
}
