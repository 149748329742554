.arrows {
  &__wrap {
    display: flex;
    font-size: 15px;
    line-height: 135%;
  }

  &__link {
    padding: 12px;
    margin: -10px -12px -12px;
    display: inline-block;
  }

  &__left {
    padding-right: 30px;
  }

  &__delimiter {
    padding: 0 5px;
  }

  &__right {
    padding-left: 30px;
  }
}
