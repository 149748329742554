.tour-3d{
  position: relative;
  &_grey {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: var(--color-background);
    }
  }
  &__link{
    position: relative;
    display: block;
  }

  &__image-container{
    position: relative;
    display: block;
  }

  &__picture{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link-wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__link-icon{
    background-color: var(--color-blue-gray-opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }

  &__link-text{
    font-style: normal;
    font-weight: bold;
    color: #fff;
  }
}

@include respond-up('large'){
  .tour-3d{
    //padding-top: 100px;
    &__link-text{
      font-size: 26px;
    }
  }
}

@include respond-up('medium'){
  .tour-3d{
    &_grey {
      padding-top: 0;
    }
    &__image-container{
      padding-bottom: 199px;
    }

    &__link-icon{
      width: 54px;
      height: 54px;
      margin-bottom: 16px;
    }

    &__link-text{
      font-size: 26px;
      line-height: 125%;
    }
  }
}
@include respond('medium'){
  .tour-3d{
    //padding-top: 60px;
  }
}

@include respond-down('medium'){
  .tour-3d{

    &__link-text{
      font-size: 23px;
    }
  }
}

@include respond-down('small'){
  .tour-3d{
    &_grey {
      //padding-top: 0;
    }
    //padding-top: 25px;
    &__image-container{
      padding-bottom: 143px;
    }

    &__link-icon{
      width: 44px;
      height: 44px;
      margin-bottom: 12px;
    }

    &__link-text{
      font-size: 20px;
      line-height: 135%;
    }
  }
}