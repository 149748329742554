.catalog-page {
  &__list {
  }
}

@include respond-up('large') {
  .catalog-page {
    padding-bottom: 120px;

    &__list {

    }
  }
}

@include respond-up('medium') {
  .catalog-page {
    &__heading-button-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .catalog-page {
    padding-bottom: 90px;
  }
}

@include respond-down('medium') {
  .catalog-page {

  }
}

@include respond-down('small') {
  .catalog-page {
    padding-bottom: 40px;

    &__heading-button-wrap {
      margin-top: 20px;
      width: 100%;
      transition: top .4s var(--default-bezier);
      top: 0;

      &._sticky {
        top: var(--menu-translate);
        position: fixed;
        right: 0;
        left: 0;
        z-index: 3000;
        margin-top: 0;
        padding: 10px 22px;
        background: #FFF;
        border-bottom: 1px solid var(--color-border);
      }
    }

    &__heading-button {
      width: 100%;
    }

    &__heading {
      padding-bottom: 20px;
    }
  }
}