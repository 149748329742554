.small-text {
  font-style: normal;
  font-weight: normal;

  &._medium {
    font-weight: 500;
  }
}

@include respond-up('medium') {
  .small-text {
    font-size: 15px;
    line-height: 135%;
  }
}

@include respond-down('small') {
  .small-text {
    font-size: 14px;
    line-height: 125%;
  }
}
