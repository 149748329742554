.customer-heading {
  h1 {
    margin: 0;
    padding: 0;
  }

  &__button-part, &__title-part{
    display: flex;
    align-items: center;
  }

  &__messengers{
    display: flex;
    align-items: center;
  }

  &__messenger-link{
    &_tg{
      margin-left: 15px;
    }
  }

  &__logout {
    display: flex;
    align-items: center;

    svg {
      display: block;
    }
  }

  &__logout-icon {
    margin-right: 10px;
  }

  &__notifies-link{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius: 100%;
  }
}

@include respond-up('medium') {
  .customer-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__messengers-title{
      margin-right: 18px;
    }

    &__logout {

    }

    &__logout-icon {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 100px;
    }

    &__notifies-link{
      width: 42px;
      height: 42px;
    }
  }
}

@include respond-up('large') {
  .customer-heading {
    padding-bottom: 75px;
    &__messengers{
      margin-right: 70px;
    }

    &__button{
      width: 162px;
      height: 38px;
    }

    &__notifies-link{
      margin-left: 35px;
    }
  }
}

@include respond('medium') {
  .customer-heading {
    padding-bottom: 45px;
    &__messengers{
      margin-right: 30px;
    }

    &__notifies-link{
      margin-left: 16px;
    }
  }
}

@include respond-down('small') {
  .customer-heading {
    padding-bottom: 30px;

    &__title-part{
      margin-bottom: 20px;
    }

    &__button-part{
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    &__messengers-title{
      margin-right: 14px;
    }

    &__notifies-link{
      width: 32px;
      height: 32px;
      margin-left: 20px;
    }
  }
}