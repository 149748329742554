.contacts-depts {
  &__phone-wrap {
    display: flex;
    align-items: center;
  }

  &__item {
    border-top: 1px solid var(--color-border);

    &:last-child {
      border-bottom: 1px solid var(--color-border);
    }

    &._opened {
      .contacts-depts {
        &__link-icon {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__phone {
    font-style: normal;
    font-weight: normal;

    &_primary {
      flex: 0 0 auto;
    }

    &__secondary {
      flex: 0 1 auto;
    }
  }

  &__address {
    font-style: normal;
    font-weight: normal;
    margin-top: 12px;
  }

  &__email {
    display: flex;
    width: max-content;
    font-style: normal;
    font-weight: normal;
    line-height: 1.35em;
    color: var(--primary-color);

    &_secondary {
      margin-top: 12px;
    }
  }

  &__working-time {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__info-additional-title {
    font-style: normal;
    font-weight: 500;
  }

  &__info-additional-text {
    font-style: normal;
    font-weight: normal;
    margin-top: 12px;
  }

  &__info-additional {
    border-top: 1px solid var(--color-border);
  }

  &__info {
    flex: 0 0 auto;
  }

  &__email-wrap {
    flex: 0 1 auto;
    display: flex;
    justify-content: space-between;
  }

  &__item-top {
    display: flex;
  }

  &__fax {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
  }

  &__link-title {
    font-style: normal;
    font-weight: 500;
  }

  &__link {
    display: flex;
  }

  &__link-icon {
    svg {
      transition: transform .4s;
      transform: rotate(-90deg);
    }
  }

  &__office-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--color-background);

    svg {
      path,
      circle {
        transition: fill .4s, stroke .4s;
      }
    }
  }

  &__office-link-icon {
    display: block;
  }
}

@include respond-up('large') {
  .contacts-depts {
    &__link {
      grid-column: 1/5;
      pointer-events: none;
    }

    &__item-wrap {
      grid-column: 5/13;
    }

    &__item {
      padding: 30px 0;
    }

    &__link-icon {
      display: none;
    }

    &__office-link {
      &:hover {
        svg {
          path {
            fill: var(--primary-color);
          }

          circle {
            stroke: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-depts {
    &__office-link {
      width: 54px;
      height: 54px;
    }

    &__phone {
      font-size: 16px;
      line-height: 1.5em;

      &_primary {
        margin-right: 68px;
      }
    }

    &__address {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__email-wrap {
      min-width: 278px;
    }

    &__email {
      font-size: 16px;
    }

    &__working-time {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 30px;
    }

    &__info-additional-title {
      font-size: 17px;
      line-height: 1.35em;
    }

    &__info-additional-text {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__info-additional {
      padding-top: 25px;
      margin-top: 25px;
    }

    &__item-top {
      justify-content: space-between;
    }

    &__fax {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__link-title {
      font-size: 19px;
      line-height: 1.35em;
    }
  }
}

@include respond('medium') {
  .contacts-depts {
    &__link {
      padding: 20px 0;
      grid-column: 1/7;
    }

    &__item-wrap {
      grid-column: 1/7;
    }

    &__item-content {
      padding-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .contacts-depts {
    &__item-wrap {
      height: 0;
      transition: height 0.3s, opacity 0.3s;
      overflow: hidden;
    }

    &__link {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .contacts-depts {
    &__link {
      padding: 18px 0;
      grid-column: 1/5;
    }

    &__item-wrap {
      grid-column: 1/5;
    }

    &__phone {
      font-size: 15px;
      line-height: 1.35em;

      &_primary {
        margin-right: 66px;
      }
    }

    &__address {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__email {
      font-size: 15px;
    }

    &__working-time {
      font-size: 14px;
      line-height: 1.25em;
      margin-top: 6px;
    }

    &__info-additional-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__info-additional-text {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__info-additional {
      padding-top: 15px;
      margin-top: 20px;
    }

    &__item-top {
      flex-direction: column;
    }

    &__fax {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__email-wrap {
      margin-top: 12px;
      flex-direction: column;
    }

    &__link-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-content {
      padding-bottom: 18px;
    }

    &__office-link {
      width: 44px;
      height: 44px;
      margin-top: 12px;
    }
  }
}