.catalog-grid {
  display: grid;
}

@include respond-up('large') {
  .catalog-grid {

  }
}

@include respond-up('medium') {
  .catalog-grid {

  }
}

@include respond('medium') {
  .catalog-grid {

  }
}

@include respond-down('medium') {
  .catalog-grid {

  }
}

@include respond-down('small') {
  .catalog-grid {

  }
}