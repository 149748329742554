.seller-flats {
  display: flex;
  flex-direction: column;

  &__content {
    margin-top: 20px;

    .product-page__product-info {
      border-top: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);
      margin-top: 30px;
    }

    .product-info-price-block {
      &__bottom-wrap {
        flex-direction: column;
        align-items: flex-start;
      }

      &__mortgage-wrap {
        margin-top: 15px;
      }
    }
  }

  &__print {
    display: none;
  }
}

@include respond('medium') {
  .seller-flats {
    margin-top: 50px;

    &__top {
      padding: 0 #{$spacer-medium}px;
    }
  }
}

@include respond('small') {
  .seller-flats {
    margin-top: 30px;

    &__top {
      padding: 0 #{$spacer-small}px;
    }
  }
}

@media print {
  .seller-flats {
    &__top {
      display: none;
    }

    &__content {
      display: none;
    }

    &__print {
      display: flex;
    }
  }
}