.hero-slider-info {
  position: absolute;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //opacity: 0;
  pointer-events: none;

  &._active {
    opacity: 1;
    pointer-events: auto;
    .hero-slider-info {
      &__title,
      &__description,
      &__button {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      &__description {
        transition-delay: .1s;
      }

      &__button {
        transition-delay: .2s;
      }
    }
  }

  &__title-wrap,
  &__description-wrap,
  &__button-wrap {
    overflow: hidden;
  }

  &__title,
  &__description,
  &__button {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    transition: opacity .4s, transform .7s;
  }

  &__button {
    transition-delay: .2s;
  }

  &__title,
  &__description,
  &__button {
    color: #fff;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    line-height: 1.15em;
    //transition-delay: .1s;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    transition-delay: .1s;
  }
}

@include respond-up('large') {
  .hero-slider-info {
    left: 154px;
    top: 205px;
    max-width: 584px;

    &__title {
      font-size: 55px;
    }

    &__description {
      margin-top: 25px;
    }

    &__button {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .hero-slider-info {
    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .hero-slider-info {
    left: #{$spacer-medium}px;
    bottom: 147px;
    max-width: calc((452 / 768) * 100vw);

    &__title {
      font-size: 36px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .hero-slider-info {
    &__button {
      margin-top: 25px;
    }
  }
}

@include respond-down('small') {
  .hero-slider-info {
    left: #{$spacer-small}px;
    right: #{$spacer-small}px;
    bottom: 110px;
    //max-width: calc((452 / 768) * 100vw);

    &__title {
      font-size: 26px;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 15px;
    }
  }
}