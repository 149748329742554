@use "sass:math";

.customer-compilation-project {
  &__name {
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    color: var(--color-primary-dark);
  }

  &__info-list {
    display: grid;
  }

  &__info-key {
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
    color: var(--color-primary-dark);
  }

  &__info-value {
    padding-top: 8px;
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }

  &__description-wrapper {
    border-top: 1px solid var(--color-border);
  }

  &__logo {
    grid-column: 1/6;
  }

  &__description {
    grid-column: 1/6;
  }

  &__logo-picture {
    display: flex;
    width: 208px;
    height: 70px;
  }

  &__logo-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left top;
  }

  &__description {
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: var(--color-primary-dark);
    margin-top: 26px;
  }

  &__image-wrapper {
    grid-column: 7/12;
  }

  &__picture {
    display: flex;
    position: relative;
    width: 100%;
    padding-top: math.div(400, 480) * 100%;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__genplan-wrapper {
    padding-top: 50px;
    overflow: hidden;
  }

  &__genplan {
    padding-top: 30px;
    border-top: 1px solid var(--color-border);
    margin-top: 30px;
  }
}

@include respond-up('large') {
  .customer-compilation-project {
    &__name {
      margin-bottom: 40px;
    }

    &__info-list {
      width: 66%;
      grid-column-gap: 18px;
      padding-bottom: 50px;
    }

    &__description-wrapper {
      padding-top: 50px;
      display: grid;
      grid-column-gap: var(--grid-column-gap);
      grid-template-columns: repeat(11, 1fr);
    }

  }
}

@include respond-up('medium') {
  .customer-compilation-project {
    &__info-list {
      grid-template-columns: repeat(4, auto);
    }
  }
}

@include respond('medium') {
  .customer-compilation-project {

  }
}

@include respond-down('medium') {
  .customer-compilation-project {
    &__name {
      margin-bottom: 20px;
    }

    &__info-list {
      grid-gap: 46px 20px;
      padding-bottom: 30px;
    }

    &__description-wrapper {
      padding-top: 30px;
    }

    &__image-wrapper {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .customer-compilation-project {
    &__info-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      max-width: 450px;
    }
  }
}