.agency-layout {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-background);
  --grid-spacer: 30px;

  &__container {
    padding: 40px;
    max-width: 1100px;
    margin: 0 auto;
  }

  &__head {
    margin-bottom: 20px;
  }
}