/*@font-face {*/
/*    font-family: 'TT Hoves Hairline';*/
/*    src: url('TTHoves-HairlineItalic.eot');*/
/*    src: local('TT Hoves Hairline Italic'), local('TTHoves-HairlineItalic'),*/
/*        url('TTHoves-HairlineItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-HairlineItalic.woff2') format('woff2'),*/
/*        url('TTHoves-HairlineItalic.woff') format('woff'),*/
/*        url('TTHoves-HairlineItalic.ttf') format('truetype');*/
/*    font-weight: 100;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-ExtraLightItalic.eot');*/
/*    src: local('TT Hoves ExtraLight Italic'), local('TTHoves-ExtraLightItalic'),*/
/*        url('TTHoves-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-ExtraLightItalic.woff2') format('woff2'),*/
/*        url('TTHoves-ExtraLightItalic.woff') format('woff'),*/
/*        url('TTHoves-ExtraLightItalic.ttf') format('truetype');*/
/*    font-weight: 200;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-Thin.eot');*/
/*    src: local('TT Hoves Thin'), local('TTHoves-Thin'),*/
/*        url('TTHoves-Thin.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-Thin.woff2') format('woff2'),*/
/*        url('TTHoves-Thin.woff') format('woff'),*/
/*        url('TTHoves-Thin.ttf') format('truetype');*/
/*    font-weight: 100;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-DemiBold.eot');*/
/*    src: local('TT Hoves DemiBold'), local('TTHoves-DemiBold'),*/
/*        url('TTHoves-DemiBold.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-DemiBold.woff2') format('woff2'),*/
/*        url('TTHoves-DemiBold.woff') format('woff'),*/
/*        url('TTHoves-DemiBold.ttf') format('truetype');*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-BoldItalic.eot');*/
/*    src: local('TT Hoves Bold Italic'), local('TTHoves-BoldItalic'),*/
/*        url('TTHoves-BoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-BoldItalic.woff2') format('woff2'),*/
/*        url('TTHoves-BoldItalic.woff') format('woff'),*/
/*        url('TTHoves-BoldItalic.ttf') format('truetype');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-Black.eot');*/
/*    src: local('TT Hoves Black'), local('TTHoves-Black'),*/
/*        url('TTHoves-Black.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-Black.woff2') format('woff2'),*/
/*        url('TTHoves-Black.woff') format('woff'),*/
/*        url('TTHoves-Black.ttf') format('truetype');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-ExtraLight.eot');*/
/*    src: local('TT Hoves ExtraLight'), local('TTHoves-ExtraLight'),*/
/*        url('TTHoves-ExtraLight.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-ExtraLight.woff2') format('woff2'),*/
/*        url('TTHoves-ExtraLight.woff') format('woff'),*/
/*        url('TTHoves-ExtraLight.ttf') format('truetype');*/
/*    font-weight: 200;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'TT Hoves';
    src: url('TTHoves-Bold.eot');
    src: local('TT Hoves Bold'), local('TTHoves-Bold'),
        url('TTHoves-Bold.eot?#iefix') format('embedded-opentype'),
        url('TTHoves-Bold.woff2') format('woff2'),
        url('TTHoves-Bold.woff') format('woff'),
        url('TTHoves-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-ThinItalic.eot');*/
/*    src: local('TT Hoves Thin Italic'), local('TTHoves-ThinItalic'),*/
/*        url('TTHoves-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-ThinItalic.woff2') format('woff2'),*/
/*        url('TTHoves-ThinItalic.woff') format('woff'),*/
/*        url('TTHoves-ThinItalic.ttf') format('truetype');*/
/*    font-weight: 100;*/
/*    font-style: italic;*/
/*}*/

@font-face {
    font-family: 'TT Hoves';
    src: url('TTHoves-Italic.eot');
    src: local('TT Hoves Italic'), local('TTHoves-Italic'),
        url('TTHoves-Italic.eot?#iefix') format('embedded-opentype'),
        url('TTHoves-Italic.woff2') format('woff2'),
        url('TTHoves-Italic.woff') format('woff'),
        url('TTHoves-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-BlackItalic.eot');*/
/*    src: local('TT Hoves Black Italic'), local('TTHoves-BlackItalic'),*/
/*        url('TTHoves-BlackItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-BlackItalic.woff2') format('woff2'),*/
/*        url('TTHoves-BlackItalic.woff') format('woff'),*/
/*        url('TTHoves-BlackItalic.ttf') format('truetype');*/
/*    font-weight: 900;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-ExtraBold.eot');*/
/*    src: local('TT Hoves ExtraBold'), local('TTHoves-ExtraBold'),*/
/*        url('TTHoves-ExtraBold.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-ExtraBold.woff2') format('woff2'),*/
/*        url('TTHoves-ExtraBold.woff') format('woff'),*/
/*        url('TTHoves-ExtraBold.ttf') format('truetype');*/
/*    font-weight: 800;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-DemiBoldItalic.eot');*/
/*    src: local('TT Hoves DemiBold Italic'), local('TTHoves-DemiBoldItalic'),*/
/*        url('TTHoves-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-DemiBoldItalic.woff2') format('woff2'),*/
/*        url('TTHoves-DemiBoldItalic.woff') format('woff'),*/
/*        url('TTHoves-DemiBoldItalic.ttf') format('truetype');*/
/*    font-weight: 600;*/
/*    font-style: italic;*/
/*}*/

@font-face {
    font-family: 'TT Hoves';
    src: url('TTHoves-MediumItalic.eot');
    src: local('TT Hoves Medium Italic'), local('TTHoves-MediumItalic'),
        url('TTHoves-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('TTHoves-MediumItalic.woff2') format('woff2'),
        url('TTHoves-MediumItalic.woff') format('woff'),
        url('TTHoves-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-ExtraBoldItalic.eot');*/
/*    src: local('TT Hoves ExtraBold Italic'), local('TTHoves-ExtraBoldItalic'),*/
/*        url('TTHoves-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-ExtraBoldItalic.woff2') format('woff2'),*/
/*        url('TTHoves-ExtraBoldItalic.woff') format('woff'),*/
/*        url('TTHoves-ExtraBoldItalic.ttf') format('truetype');*/
/*    font-weight: 800;*/
/*    font-style: italic;*/
/*}*/

@font-face {
    font-family: 'TT Hoves';
    src: url('TTHoves-Regular.eot');
    src: local('TT Hoves Regular'), local('TTHoves-Regular'),
        url('TTHoves-Regular.eot?#iefix') format('embedded-opentype'),
        url('TTHoves-Regular.woff2') format('woff2'),
        url('TTHoves-Regular.woff') format('woff'),
        url('TTHoves-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'TT Hoves Hairline';*/
/*    src: url('TTHoves-Hairline.eot');*/
/*    src: local('TT Hoves Hairline'), local('TTHoves-Hairline'),*/
/*        url('TTHoves-Hairline.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-Hairline.woff2') format('woff2'),*/
/*        url('TTHoves-Hairline.woff') format('woff'),*/
/*        url('TTHoves-Hairline.ttf') format('truetype');*/
/*    font-weight: 100;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-LightItalic.eot');*/
/*    src: local('TT Hoves Light Italic'), local('TTHoves-LightItalic'),*/
/*        url('TTHoves-LightItalic.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-LightItalic.woff2') format('woff2'),*/
/*        url('TTHoves-LightItalic.woff') format('woff'),*/
/*        url('TTHoves-LightItalic.ttf') format('truetype');*/
/*    font-weight: 300;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'TT Hoves';*/
/*    src: url('TTHoves-Light.eot');*/
/*    src: local('TT Hoves Light'), local('TTHoves-Light'),*/
/*        url('TTHoves-Light.eot?#iefix') format('embedded-opentype'),*/
/*        url('TTHoves-Light.woff2') format('woff2'),*/
/*        url('TTHoves-Light.woff') format('woff'),*/
/*        url('TTHoves-Light.ttf') format('truetype');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'TT Hoves';
    src: url('TTHoves-Medium.eot');
    src: local('TT Hoves Medium'), local('TTHoves-Medium'),
        url('TTHoves-Medium.eot?#iefix') format('embedded-opentype'),
        url('TTHoves-Medium.woff2') format('woff2'),
        url('TTHoves-Medium.woff') format('woff'),
        url('TTHoves-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

