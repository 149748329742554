.product-info-activity {
  display: flex;
  justify-content: space-between;

  &__text-wrap {
    display: flex;
  }

  &__text-icon {
    display: flex;
    flex: 0 0 auto;
    margin-right: 9px;
  }

  &__text {
    flex: 0 1 auto;
    display: flex;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
  }

  &__text-normal {
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .product-info-activity {

  }
}

@include respond-up('medium') {
  .product-info-activity {
    align-items: center;

    &__text {
      align-items: center;
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .product-info-activity {

  }
}

@include respond-down('medium') {
  .product-info-activity {

  }
}

@include respond-down('small') {
  .product-info-activity {
    align-items: flex-start;

    &__text {
      flex-direction: column;
      font-size: 13px;
    }
  }
}

@media print {
  .product-info-activity {
    display: none;
  }
}