.grid-block {
  width: 100%;
  display: grid;
  --grid-gap: 0 var(--grid-column-gap);
  grid-gap: var(--grid-gap);
  grid-template-columns: var(--grid-template-columns);
}

@include respond-up('large') {
  .grid-block {
    --grid-template-columns: repeat(12, 1fr);
  }
}

@include respond('medium') {
  .grid-block {
    --grid-template-columns: repeat(6, 1fr);
  }
}

@include respond-down('small') {
  .grid-block {
    --grid-template-columns: repeat(4, 1fr);
  }
}
