.tabbed-page {
  pointer-events: none;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &._spaceless, &_spaceless {
      margin-bottom: 0;
    }

    .tabs_fourth .tabs__link {
      background-color: #F7F8FA;
    }
  }

  &__tabs, &__fast-activity, &__content {
    pointer-events: auto;
  }
}

@include respond-down('medium') {
  .tabbed-page {
    padding-top: var(--grid-spacer);

    &__head {
      padding: 0 var(--grid-spacer);
    }
  }
}

@include respond-down('medium') {
  .tabbed-page {
    padding: 30px 0 0;
  }
}
