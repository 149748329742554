.seller-pano {
  height: 100vh;
}

@include respond-up('large') {
  .seller-pano {

  }
}

@include respond-up('medium') {
  .seller-pano {

  }
}

@include respond('medium') {
  .seller-pano {

  }
}

@include respond-down('medium') {
  .seller-pano {

  }
}

@include respond-down('small') {
  .seller-pano {

  }
}