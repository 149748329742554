// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.faq-list {
  &_grey {
    background-color: white;
  }
  &__item {
    border-bottom: 1px solid var(--color-border);
    &:last-child {
      border-bottom: none;
    }
  }

  &__answer-wrap {
    height: 0;
    transition: height 0.3s, opacity 0.3s;
    overflow: hidden;
  }

  &__answer{
    color: var(--color-gray-dark);
  }

  &__question {
    font-weight: 500;
    display: block;
    color: var(--color-primary-dark);
    position: relative;

    &:after {
      transform: rotate(-90deg);
    }

    &:before, &:after {
      position: absolute;
      content: '';
      background-color: var(--primary-color);
      width: 2px;
      height: 18px;
      right: 9px;
      top: calc(50% - 9px);
      transition: transform var(--default-transition-duration);
    }
  }

  &__item {
    &._opened {
      .faq-list {
        &__question {
          &:before {
            transform: rotate(-45deg);
          }

          &:after {
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .faq-list {
    &__content {
      padding-bottom: 140px;
    }

    &_grey {
      .faq-list {
        &__item {
          padding: 0 33px;
        }
      }
    }

    &__answer,
    &__question {
      padding-right: 65px;
    }

    &__question {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &__answer {
      padding-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .faq-list {
    &__question {
      line-height: 135%;
      font-size: 19px;
    }
  }
}

@include respond('medium') {
  .faq-list {
    &__question {
      padding: 21px 60px 21px 0;
    }

    &__answer {
      padding-bottom: 30px;
    }

    &_grey {
      .faq-list {
        &__item {
          padding: 0 25px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .faq-list {
    &__question {
      padding: 19px 38px 19px 0;
      font-size: 16px;
      line-height: 140%;
    }

    &_grey {
      .faq-list {
        &__item {
          padding: 0 18px;
        }
      }
    }

    &__answer {
      padding-bottom: 25px;
    }
  }
}
