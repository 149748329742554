.client-service-item {
  background-color: #fff;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
  }

  &__type {
    background-color: var(--color-background);
    padding: 5px 7px;
    color: var(--primary-color);
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
  }

  &__number {
    color: var(--color-gray-dark)
  }

  &__footer {
    border-top: 1px solid var(--color-border);
    padding-top: 12px;
  }

  &__description {

  }

  &__info-items {
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .client-service-item {
    padding: 30px 35px;

    &__description {
      padding-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .client-service-item {
    &__info-items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__info-item {
      margin-right: 35px;
      position: relative;

      &:after {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--color-border);
        position: absolute;
        right: -20px;
        top: 6px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

@include respond('medium') {
  .client-service-item {
    padding: 30px 35px;

    &__description {
      padding-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .client-service-item {
    padding: 18px 20px;

    &__description {
      padding-bottom: 30px;
    }

    &__info-item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}