.date-form {
  &__fields {
    display: flex;
  }
}

@include respond-up('large') {
  .date-form {

  }
}

@include respond-up('medium') {
  .date-form {
    &__fields {
      margin: 0 -12px;
    }

    &__field {
      padding: 0 12px;
    }
  }
}

@include respond('medium') {
  .date-form {

  }
}

@include respond-down('medium') {
  .date-form {

  }
}

@include respond-down('small') {
  .date-form {
    &__fields {
      margin: 0 -7px;
    }

    &__field {
      padding: 0 7px;
    }
  }
}