@use "sass:math";

.product-infra {
  &__top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 86px; //?!
  }

  &__item {
    min-height: 274px; //?!
  }

  &__picture {
    display: flex;
  }

  &__item-title {
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__item-wrap {
    display: flex;
    position: relative;
    justify-content: space-between;

    &:before {
      position: absolute;
      content: '';
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-border);
    }
  }

  &__item-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__item-time {
    position: relative;
    font-weight: normal;
    font-size: 15px;
    line-height: math.div(20, 15)*1em;
    color: var(--color-gray-dark);

  }
}

@include respond-up('large') {
  .product-infra {
    margin-top: 141px;

    &__top-wrapper {
      padding-bottom: 39px;
    }

    &__item-title {
      font-size: 19px;
      line-height: math.div(26, 19)*1em;
      padding-bottom: 13px;
    }

    &__picture {
      margin-bottom: 12px;
    }

    &__items {
      // Чтобы слайдер делал так как хочет дизайнер, приходится в тини делать centre: true,
      // в таком случае выбранный айтем находится по средине экрана,
      // и нужно двигать весь список на левую границу
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 183px);
    }
  }
}

@include respond-up('medium') {
  .product-infra {
    .tns-nav {
      display: none;
    }

    &__item-wrap {
      padding-bottom: 10px;
    }
  }
}

@include respond('medium') {
  .product-infra {
    margin-top: 90px;

    &__top-wrapper {
      padding-bottom: 30px;
    }

    &__picture {
      margin-bottom: 16px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 168px) * -1);
    }
  }
}

@include respond-down('medium') {
  .product-infra {
    &__item-title {
      font-size: 16px;
      line-height: math.div(22, 16)*1em;
      padding-bottom: 10px;
    }
  }
}

@include respond-down('small') {
  .product-infra {
    margin-top: 40px;

    &__item-wrap {
      padding-bottom: 6px;
    }

    &__controls {
      display: none;
    }

    &__top-wrapper {
      padding-bottom: 22px;
    }

    &__picture {
      margin-bottom: 8px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 78px) * -1);
    }

    .tns-nav {
      margin-top: 31px;
    }
  }
}