@media print {
  .seller-apartment-print {
    &__print {
      display: flex;
      background: white;
      flex-direction: column;
    }

    &__print-block {
      width: 100%;
    }

    &__print-manager-info {
      background: var(--color-background);
      padding-top: 2.2mm;
      padding-bottom: 2.2mm;
      display: flex;
      flex-direction: row;
      align-items: center;

      .seller-apartment-print__print-text-dark {
        margin-left: 1mm;
      }
    }

    &__print-manager-phone {
      margin-left: 8.2mm;
    }

    &__print-text {
      font-family: var(--font);
      font-style: normal;
      font-weight: normal;
      font-size: 7pt;
      line-height: 120%;
    }

    &__print-line-wrapper-lower {
      padding-top: 1mm;
    }

    &__print-line-wrapper-second {
      margin-top: 2.7mm;
    }

    &__print-text-large {
      font-family: var(--font);
      font-style: normal;
      font-weight: bold;
      font-size: 13.6pt;
      line-height: 125%;
    }

    &__print-text-head {
      font-family: var(--font);
      font-style: normal;
      font-weight: 700;
      font-size: 13pt;
      line-height: 125%;
    }

    &__print-text-grey {
      color: var(--color-gray-dark);
    }

    &__print-text-dark {
      color: var(--color-primary-dark);
    }

    &__print-top-phone {
      padding-left: 5.4mm;
    }

    &__print-top {
      display: flex;
      padding-top: 5.4mm;
      padding-bottom: 5.4mm;
    }

    &__print-content {
      margin-top: 12.5mm;
    }

    &__print-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 12.5mm;
    }

    &__print-bottom-link-wrapper,
    &__print-bottom-info {
      flex: 1 1 50%;
    }

    &__print-bottom-info {
      padding-top: 1.8mm;
      margin-left: -3.5mm;
      padding-left: 3.5mm;
    }

    &__print-bottom-link-wrapper {
      display: flex;
    }

    &__print-bottom-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 5.4mm;
      padding: 3.4mm 5.3mm;
    }

    &__print-bottom-disclaimer {
      margin-top: 3.3mm;
      padding-top: 3.3mm;
      border-top: 1px solid var(--color-border);
    }

    &__print-bottom-qr {
      width: 20.4mm;
      height: 20.4mm;
    }

    &__print-top-info {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      margin-left: 5.4mm;
      width: 100%;
      padding: 1mm 0;
    }

    &__print-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__print-top-wrapper {
      display: flex;
      flex-direction: row;
    }

    &__print-line-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: baseline;
    }

    &__print-top-name {
      width: 25.8mm;
      height: 2.7mm;
      display: flex;
      align-content: center;

      svg {
        height: 2.7mm;
        width: 25.8mm;
      }
    }

    &__print-top-logo {
      display: flex;
      height: 100%;
      width: fit-content;

      svg {
        height: 8mm;
        width: auto;

        path {
          fill: var(--primary-color);
        }
      }
    }

    &__print-content-info {
      margin-top: 12.2mm;
    }

    &__print-content-info-list {
      border: 1px solid var(--color-border);
      display: flex;
      justify-content: stretch;
    }

    &__print-content-info-item {
      flex: 1 1 auto;
      display: flex;
      padding: 2.4mm;
      align-content: center;
      flex-direction: column;

      .seller-apartment-print__print-text {
        text-align: center;
      }

      .seller-apartment-print__print-text-dark {
        margin-top: 1.8mm;
      }

      &:not(:last-child) {
        border-right: 1px solid var(--color-border);
      }
    }

    &__print-content-layout-images-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__print-content-layout-images-item {
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
      align-items: center;
      padding: 7.2mm;
      border-left: 1px solid var(--color-border);
      border-right: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);

      &:first-child {
        .product-images__picture {
          max-height: 76.9mm;
        }
      }

      &:last-child {
        .product-images__picture {
          max-height: 59.4mm;
        }
      }
    }

    &__renovation-price {
      &._hide {
        display: none;
      }
    }
  }
}
