.customer-compilation {
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--color-border);
  }

  &__label {
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .customer-compilation {
    padding-top: 20px;

    &__content {
      grid-column: 1/12;
    }

    &__description {
      margin-top: 70px;
      background: red;
    }

    &__about-wrapper {
      padding-top: 120px;
    }

    &__about-block {
      margin: 30px calc(#{$spacer-large} * -1px) 0;

      .about-block__body {
        padding: 0 #{$spacer-large}px;
      }
    }

    &__compilation-item {
      padding-top: 120px;
    }

    &__footer {
      margin-top: 90px;
      padding: 12px 0 140px;
    }
  }
}

@include respond-up('medium') {
  .customer-compilation {
  }
}

@include respond('medium') {
  .customer-compilation {
    &__content {
      grid-column: 1/7;
    }
  }
}

@include respond-down('medium') {
  .customer-compilation {

    &__about-wrapper {
      padding-top: 40px;
    }

    &__description {
      margin-top: 30px;
    }

    &__about-block {
      margin: 30px calc(var(--spacer) * -1);
    }

    &__compilation-item {
      padding-top: 40px;
    }

    &__footer {
      margin-top: 40px;
      padding: 12px 0 45px;
      flex-wrap: wrap;
    }

    &__label {
      flex: 0 0 100%;
      text-align: right;

      &:not(:first-child) {
        padding-top: 5px;
      }
    }
  }
}

@include respond-down('small') {
  .customer-compilation {
    &__content {
      grid-column: 1/5;
    }
  }
}

@media print {
  .customer-compilation {
    &__content {
      display: none;
    }
  }
}