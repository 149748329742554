.offices-info-block {
  &__item-address {
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  &__item-address-icon {
    flex: 0 0 auto;
    margin-right: 9px;
  }

  &__item-address-title {
    font-style: normal;
    font-weight: 500;
    flex: 0 1 auto;
  }

  &__item-phone {
    margin-top: 25px;
    font-style: normal;
    font-weight: 500;
    display: flex;
  }

  &__item-worktime {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .offices-info-block {
    &_project {
      padding: 30px 35px 40px;
    }

    &_offices-page {
      .offices-info-block {
        &__item-address {
          margin-top: 30px;
        }
      }
    }

    &__item-request-button-wrap {
      margin-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .offices-info-block {
    &__item-address-title {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__item-phone {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__item-worktime {
      font-size: 15px;
      line-height: 1.35em;
    }
  }
}

@include respond('medium') {
  .offices-info-block {
    &_project {
      padding: 25px 25px 30px;
    }

    &_offices-page {
      .offices-info-block {
        &__item-address {
          margin-top: 25px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .offices-info-block {
    &__item-request-button-wrap {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .offices-info-block {
    &_project {
      padding: 22px #{$spacer-small}px 40px;

      .offices-info-block {
        &__item-request-button {
          width: 100%;
        }
      }
    }

    &__item-address-title {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__item-phone {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__item-worktime {
      font-size: 14px;
      line-height: 1.25em;
    }
  }
}