.product-page-print {
  display: none;
}

@media print {
  .product-page {
    display: none;
  }

  body *:not(.product-page-print):not(.product-page-print *) {
    display: none;
  }

  .product-page-print {
    display: flex;
    width: 210mm;
    height: 297mm;
    padding: 8.2mm;
    box-sizing: inherit;
  }
}
