.seller-banks {
  &__list {
    display: grid;
  }

  &__item {
    border-top: 1px solid var(--color-border);
    display: block;
  }

  &__item-top {
    display: flex;
  }

  &__item-title {
    flex: 0 0 auto;
    font-style: normal;
    font-weight: normal;
    line-height: 1.35em;
    color: var(--color-primary-dark);
  }

  &__picture {
    flex: 0 0 auto;
  }

  &__percent {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &__description-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity .4s;
    pointer-events: none;
    z-index: 1000;

    &._visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__description-bg {
    background-color: var(--color-black-opacity);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
    color: var(--color-gray-dark);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--color-black-opacity);
    }

  }

  &__description-inner {
    background-color: #fff;
    z-index: 15;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--t);
    border: 1px solid var(--color-border);
    border-radius: 100%;
    width: 38px;
    height: 38px;
  }

  &__close-icon {
    display: flex;
  }
}

@include respond-up('large') {
  .seller-banks {
    padding: 120px 0 116px;

    &__list {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 60px;
      margin-top: 40px;
    }

    &__item {
      height: 215px;
      &:not(:nth-child(3n)) {
        border-right: 1px solid var(--color-border);
      }
    }

    &__link {
      padding: 25px 30px 0;
    }

    &__item-title {
      font-size: 18px;
    }

    &__description-inner {
      width: 990px;
      max-height: 680px;
      padding: 70px 73px 70px 71px;
    }

    &__description {
      max-height: 540px;
    }
  }
}

@include respond-up('medium') {
  .seller-banks {
    &__item-top {
      align-items: center;
    }

    &__percent {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__item-title {
      margin-left: 15px;
    }
  }
}

@include respond('medium') {
  .seller-banks {
    padding: 90px 0 70px;

    &__list {
      margin-top: 26px;
      grid-row-gap: 40px;
    }

    &__item {
      height: 144px;
      grid-row-gap: 40px;
      &:nth-child(2n) {
        padding-left: 35px;
      }
    }

    &__item-title {
      font-size: 17px;
      line-height: 1.5em;
    }

    &__description-inner {
      width: calc(100vw - (#{$spacer-medium}px * 2));
      max-height: calc(100vh - (var(--header-height) + (#{$spacer-medium}px * 2)));
      padding: 70px 30px 40px;
    }

    &__description {
      max-height: calc(100vh - (var(--header-height) + (#{$spacer-medium}px * 2) + 110px));
    }
  }
}

@include respond-down('medium') {
  .seller-banks {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:not(:nth-child(2n)) {
        border-right: 1px solid var(--color-border);
      }
    }

    &__link {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .seller-banks {
    padding: 40px 0;

    &__list {
      grid-row-gap: 30px;
      margin-top: 22px;
    }

    &__item-top {
      flex-direction: column;
      align-items: flex-start;
    }

    &__item {
      height: 130px;
      &:nth-child(2n) {
        padding-left: 16px;
      }
    }

    &__item-title {
      margin-top: 20px;
      font-size: 15px;
    }

    &__percent {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__description-inner {
      width: 100vw;
      height: 100%;
      padding: 66px #{$spacer-small}px 0;
    }

    &__description {
      height: 100%;
      padding-bottom: #{$spacer-small}px;
    }

    &__description-wrap {
      align-items: flex-end;
      z-index: 10000;
    }
  }
}