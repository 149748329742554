/*
Все возможные режимы отображения элементов квартир / планировок списком:
- cell - плиточки (Например, в каталоге по-умолчанию)
- list - списком (Например, в каталоге, если выбрать такой режим переключателем)
- slider - внутри слайдера (Например похожие планировки)
- landscape - почти списком (по 1 штуке в ряд), но чуть больше похоже на плитку (Например, в ЛК покупателя - сделки)
- list-seller - списком в ЛК, почти как list, но с небольшими перестановками элементов
*/

._view-mode {
  &_cell, &_slider {
    .layout-card {
      &__price-wrap {
        margin-top: 20px;
      }
    }
  }

  &_list {
  }
  &_list-seller {
  }
}

@include respond-up('large') {
  ._view-mode {
    &_cell, &_slider {
      .layout-card {
        &__inner {
          grid-template-areas: 'header header'
                               'promo buttons'
                               'image-wrap image-wrap'
                               'title title'
                               'info info'
                               'price more'
                               'tags more'
                               'footer footer';
        }

        &__info-list {
          margin-top: 18px;
        }

        &__info-item-title {
          font-size: 14px;
          flex: 0 0 32%;
          margin-right: 21px;
        }

        &__info-item-description {
          font-size: 14px;
        }

        &__tags-wrap {
          margin-top: 13px;
        }

        &__mortgage {
          margin-top: 6px;
        }
      }
    }

    &_landscape {
      .layout-card {
        padding: 25px 0 20px;

        &__inner {
          grid-template-areas: 'header header header'
                               'image-wrap title price'
                               'image-wrap info tags'
                               'image-wrap info more'
                               'footer footer footer';
          grid-template-columns: 217px 1fr 240px;
          grid-column-gap: 50px;
        }


        &__image-wrap {

        }

        &__title {
          margin-top: 0px;
        }

        &__price-wrap {
          text-align: right;
        }

        &__info-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -15px;
          //white-space: nowrap;
          //grid-column-gap: 30px;
          //grid-row-gap: 20px;
          //grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        }

        &__info-item {
          flex-direction: column;
          margin: 0 15px 20px;

          &:nth-child(2) {
            order: 1;
          }

          &:nth-child(4) {
            order: 2;
          }

          &:nth-child(5) {
            order: 3;
          }
        }

        &__price-wrap, &__title {
          margin-bottom: 20px;
        }

        &__info-item-title {
          margin-bottom: 8px;
        }

        &__mortgage {
          margin-top: 10px;
          justify-content: flex-end;
        }

        &__tags-wrap {
          justify-content: flex-end;
        }

        &__more-wrap {
          opacity: 1;
        }
      }
    }

    // слайдером
    &_slider {
      .layout-card {
        &:first-child {
          padding: 22px 30px 0 0;
        }

        &:nth-child(n+2) {
          padding: 22px 30px 0;
        }

        &:last-child {
          padding: 22px 0 0 30px;
        }
      }
    }

    // ячейками
    &_cell {
      .catalog-grid {
        // По 3 штуки на десктопе / планшете
        // Например, в личном кабинете
        &._by-3 {
          grid-template-columns: calc((100% - 30px) / 3) 1fr calc((100% - 30px) / 3);
          grid-row-gap: 60px;

          .layout-card {
            // первый столбик
            &:nth-child(3n+1) {
              padding: 22px 30px 0 0;
              border-right: 1px solid var(--color-border);
            }

            // второй столбик
            &:nth-child(3n+2) {
              padding: 22px 30px 0;
              border-right: 1px solid var(--color-border);
            }

            // третий столбик
            &:nth-child(3n) {
              padding: 22px 0 0 30px;
            }
          }
        }

        // По 4 штуки на десктопе / по 3 на планшете
        // Например, в каталоге сайта
        &._by-4 {
          grid-template-columns: calc((100% - 60px) / 4) 1fr 1fr calc((100% - 60px) / 4);
          grid-row-gap: 60px;

          .layout-card {
            // первый столбик
            &:nth-child(4n+1) {
              padding: 22px 30px 0 0;
              border-right: 1px solid var(--color-border);
            }

            // второй и третий столбик
            &:nth-child(4n+2),
            &:nth-child(4n+3) {
              padding: 22px 30px 0;
              border-right: 1px solid var(--color-border);
            }

            // четвертый столбик
            &:nth-child(4n) {
              padding: 22px 0 0 30px;
            }
          }
        }

      }
    }

    &_list, &_list-seller {
      .layout-card {
        padding: 20px 0;

        &__buttons-wrap {
          margin-top: 8px;
        }

        &__promotion-list {
          flex-direction: column;
          align-items: center;
        }

        &__image {
          width: 130px;
          height: 98px;
        }

        &__promotion-item {
          padding: 2px 0;

          &:nth-child(n + 2) {
            //display: none;
          }
        }

        &__promotion-counter {
          display: block;
          padding: 2px 0;
        }

        &__tags-wrap {
          align-items: flex-end;
          flex-wrap: wrap;
        }

        &__info-item {
          &:nth-child(1) {
            grid-area: project;
          }

          &:nth-child(2) {
            grid-area: finish;
          }

          &:nth-child(3) {
            grid-area: address;
          }

          &:nth-child(4) {
            grid-area: floor;
          }
        }

        &__price-wrap {
          padding-left: 23px;
          margin: 8px 0;
        }

        &__info-item-description {
          margin-top: 8px;
        }

        &__more {
          display: none;

          &_small {
            display: flex;
          }
        }
      }
    }

    &_list-seller {
      .layout-card {
        &__inner {
          grid-template-columns: 32px 149px 1fr auto;
          grid-template-areas: 'promo image-wrap title buttons'
                               'promo image-wrap info price'
                               'promo image-wrap tags more'
        }

        &__image-wrap {
          margin: 0 13px 0 6px
        }


        &__info-list {
          grid-template-areas: 'project address finish floor number';
          grid-template-columns: 1fr 1fr 1fr 59px auto;
          grid-column-gap: 14px;
          margin-top: 8px;
        }

        &__info-item:nth-child(5) {
          grid-area: number;
        }

        &__price-wrap {
          white-space: nowrap;
        }
      }
    }

    &_list {
      .layout-card {
        &__inner {
          grid-template-columns: 32px 156px 152px 532px auto 60px;
          grid-template-areas: 'promo image-wrap title info price buttons'
                               'promo image-wrap tags tags price more'
        }

        &__image-wrap {
          margin: 0 20px 0 6px
        }

        &__title {
          margin-top: 8px;
        }

        &__info-list {
          grid-template-areas: 'project address finish floor';
          grid-template-columns: 130px 130px 130px 59px;
          grid-column-gap: 14px;
          margin: 8px 0 0 26px;
        }

        &__price-wrap {
          border-left: 1px solid var(--color-border);
        }

        &__mortgage {
          margin-top: 10px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  ._view-mode {
    &_cell, &_slider {
      .layout-card {
        &__image-wrap {
          margin-top: 4px;
        }

        &__promotion-list {
          align-items: center;
        }

        &__info-list {
          grid-row-gap: 7px;
        }

        &__info-item {
          align-items: center;
        }

        &__buttons-wrap {
          min-height: 32px;
        }
      }
    }

    &_slider {
      .layout-card {
        &:not(:last-child) {
          border-right: 1px solid var(--color-border);
        }
      }
    }

    &_cell {
    }

    &_list, &_list-seller {
      .layout-card {
        &:last-child {
          border-bottom: 1px solid var(--color-border);
        }

        &__more-wrap {
          justify-content: flex-end;
        }

        &__info-item {
          flex-direction: column;
        }
      }
    }
  }
}

@include respond('medium') {
  ._view-mode {
    &_cell, &_slider {
      .layout-card {
        &__inner {
          grid-template-areas: 'header header'
                               'promo buttons'
                               'image-wrap image-wrap'
                               'title title'
                               'info info'
                               'price price'
                               'tags tags'
                               'footer footer';
        }

        &__image {
          //width: 202px;
          //height: 154px;
          width: 100%;
          height: 100%;
        }

        &__info-list {
          margin-top: 16px;
        }

        &__tags-wrap {
          margin-top: 14px;
        }

        &__more-wrap {
          display: none;
        }

        &__promotion-list {
          margin: 0 -4px;
        }

        &__promotion-item {
          padding: 0 4px;

          &:nth-child(2n+1) {
            display: none;
          }
        }

        &__promotion-counter {
          display: block;
          padding: 3px 4px;
        }

        &__info-item-title {
          flex: 0 0 70px;
          margin-right: 21px;
        }
      }
    }

    &_slider {
      .layout-card {
        padding: 20px 21px 0;

        &:first-child {
          padding: 20px 21px 0 0;
        }

        &:last-child {
          padding: 20px 0 0 21px;
        }
      }
    }


    &_cell {
      .catalog-grid {
        &._by-4, &._by-3 {
          grid-template-columns: calc((100% - 22px) / 3) 1fr calc((100% - 22px) / 3);
          grid-row-gap: 50px;

          .layout-card {
            // первый столбик
            &:nth-child(3n+1) {
              padding: 20px 21px 0 0;
              border-right: 1px solid var(--color-border);
            }

            // второй столбик
            &:nth-child(3n+2) {
              padding: 20px 21px 0;
              border-right: 1px solid var(--color-border);
            }

            // третий столбик
            &:nth-child(3n) {
              padding: 20px 0 0 21px;
            }
          }
        }
      }
    }

    &_landscape {
      .layout-card {
        padding: 20px 0;

        &__inner {
          grid-template-areas: 'header header header'
                               'image-wrap title price'
                               'image-wrap info tags'
                               'image-wrap info more'
                               'footer footer footer';
          grid-template-columns: 170px 1fr 187px;
          grid-column-gap: 30px;
        }


        &__image-wrap {

        }

        &__title {
          margin-top: 0px;
        }

        &__price-wrap {
          text-align: right;
        }

        &__info-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -15px;
          //white-space: nowrap;
          //grid-column-gap: 30px;
          //grid-row-gap: 20px;
          //grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        }

        &__info-item {
          flex-direction: column;
          margin: 0 15px 20px;

          &:nth-child(2) {
            order: 1;
          }

          &:nth-child(4) {
            order: 2;
          }

          &:nth-child(5) {
            order: 3;
          }
        }

        &__price-wrap, &__title {
          margin-bottom: 20px;
        }

        &__info-item-title {
          margin-bottom: 8px;
        }

        &__mortgage {
          margin-top: 10px;
          justify-content: flex-end;
        }

        &__tags-wrap {
          justify-content: flex-end;
          align-content: flex-start;
        }

        &__more-wrap {
          opacity: 1;
        }
      }
    }

    &_list {
      .layout-card {
        &__title {
          margin-top: unset;
        }

        &__inner {
          grid-template-columns: 32px 169px auto auto;
          grid-template-areas: 'promo image-wrap title buttons'
                                     'promo image-wrap info price'
                                     'promo image-wrap tags more';
        }

        &__promotion-list {
          margin: -2 0;
          flex-direction: column;
        }

        &__promotion-item {
          padding: 2px 0;
        }

        &__image-wrap {
          margin: 0 26px 0 6px;
          align-items: flex-start;
        }

        &__image {
          width: 137px;
          height: 103px;
        }

        &__info-list {
          margin-top: 20px;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 15px;
        }

        &__tags-wrap {
          margin-top: 15px;
          align-content: flex-start;
        }

        &__price-wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
        }

        &__more {
          display: none;

          &_small {
            display: flex;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  ._view-mode {

  }
}

@include respond-down('small') {
  ._view-mode {
    &_cell, &_slider, &_list {
      .layout-card {
        &__inner {
          position: relative;
          grid-template-columns: 60px auto 60px;
          grid-template-areas: 'header header header'
                               'promo image-wrap buttons'
                               'title title title'
                               'info info info'
                               'price price more'
                               'tags tags tags'
                               'footer footer footer';
        }

        &__more {
          display: none;

          &_small {
            display: flex;
          }
        }
      }
    }

    &_landscape {
      .layout-card {
        &__inner {
          position: relative;
          grid-template-areas: 'header header'
                               'image-wrap image-wrap'
                               'title title'
                               'info info'
                               'price more'
                               'tags tags'
                               'footer footer';
        }

        &__more {
          display: none;

          &_small {
            display: flex;
          }
        }

        &__info-list {
          margin-bottom: 20px;
        }

        &__more-wrap {
          position: static;
        }
      }
    }
  }
}