.client-actions {
  &__tabs {

  }

  &__actor {
    flex: 0 0 200px;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
    padding-right: 20px;



  }

  &__list {
    padding: 30px 0;
  }

  &__item {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 25px 30px 24px;

    &:not(:first-child) {
      border-top: 1px solid var(--color-border);
    }
  }

  &__message {
    flex: 1 1 auto;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--color-primary-dark);
  }

  &__date {
    flex: 0 0 auto;
    text-align: right;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .client-actions {

  }
}

@include respond-up('medium') {
  .client-actions {

  }
}

@include respond('medium') {
  .client-actions {

  }
}

@include respond-down('medium') {
  .client-actions {

  }
}

@include respond-down('small') {
  .client-actions {

  }
}