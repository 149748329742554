form {
  .checkbox-list, .checkbox-list_as-tab {
    display: flex;
  }

  .checkbox-list {
    &:not(.checkbox-list_as-tab) {
      flex-wrap: wrap;

      label {
        margin: 0;
      }
    }
  }

  .checkbox-list__item {
    &._hidden {
      display: none;
    }
  }

  .checkbox_as_tab, .checkbox-list_as-tab {
    background-color: white;
    height: var(--default-input-height);
    border: 1px solid var(--color-border);

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      font-size: 15px;
      line-height: 135%;
      color: var(--color-primary-dark);
      padding: 0;
      transition: {
        property: color, background-color;
        duration: .3s;
      };

      &::after, &::before {
        display: none;
      }
    }

    input {
      display: none;

      &:checked + label{
        background-color: var(--primary-color);
        color: white;
      }
    }
  }

  .checkbox_as_tab {
    label {
      padding: 0 10px;
    }
    input:checked + label{
      .checkbox_as_tab__hint-icon {
        border-color: white;

        svg path {
          fill: white;
        }
      }
    }

    &__hint {
      margin-left: 6px;

      &:hover {
        .checkbox_as_tab__hint-text {
          opacity: 1;
          transform: translateY(0);
          pointer-events: all;
        }
      }
    }

    &__hint-icon {
      svg path {
        transition: {
          property: fill;
          duration: .3s;
        };
      }
    }
  }

  .checkbox-list_as-tab {
    li {
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .checkbox-list {
    &:not(.checkbox-list_as-tab) {
      display: flex;
      margin: 0 -15px;
      //padding: 10px 0 45px;

      li {
        padding: 11px 15px;
      }
    }
  }
}

@include respond-up('medium') {
  .checkbox_as_tab, .checkbox-list_as-tab {
    padding: 4px 5px;
  }

  .checkbox-list_as-tab {
    li {
      flex: 0 0 42px;
      width: 42px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    label {
      margin-right: 5px;
    }
  }
}

@include respond('medium') {
  .checkbox-list {
    &:not(.checkbox-list_as-tab) {
      margin: 0 -13px;
      padding: 30px 0;

      li {
        padding: 11px 13px;
      }
    }
  }
}

@include respond-down('medium') {

}

@include respond-down('small') {
  .checkbox-list {
    &:not(.checkbox-list_as-tab) {
      flex-direction: column;
      li {
        padding: 8px 0;
      }
    }
  }

  .checkbox_as_tab, .checkbox-list_as-tab {
    padding: 3px 4px;
  }

  .checkbox-list_as-tab {
    li {
      flex: 0 0 34px;
      width: 34px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    label {
      margin-right: 4px;
    }
  }
}