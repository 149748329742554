.customer-compilations {
  border-top: 1px solid var(--color-border);

  &__list {
    padding-top: 20px;
  }

  &__item {
    margin-top: 20px;
  }

  &__compilation {

  }

  &__share-wrap{
    margin-top: 20px;
    display: flex;
    align-content: flex-start;
    justify-content: right;
    &:first-child{
      margin: 0;
    }
  }

  &__share-link{
    display: flex;
  }

  &__share-text{
    margin-left: 10px;
  }

  .ya-share2 {
    position: relative;

    .ya-share2__link_more {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      display: block;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include respond-up('large') {
  .customer-compilations {

  }
}

@include respond-up('medium') {
  .customer-compilations {

  }
}

@include respond('medium') {
  .customer-compilations {

  }
}

@include respond-down('medium') {
  .customer-compilations {

  }
}

@include respond-down('small') {
  .customer-compilations {

  }
}