.seller-submenu {
  &__item {
    &._active, &:hover {
      .seller-submenu {
        &__link {
          background-color: #fff;
        }

        &__name {
          color: var(--primary-color);
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding: 10px var(--menu-padding);
    transition: all 0.3s;
  }

  &__item:last-child {
    .seller-submenu__link {
      border-bottom: none;
    }
  }

  &__icon {
    margin-right: 20px;

    svg {
      path, line {
        transition: stroke 0.3s, fill 0.3s;
      }
    }
  }

  &__name {
    color: var(--color-gray-dark);
    transition: color 0.3s;
  }

  &__counter {
    margin-left: 8px;
    border-radius: 100px;
    background-color: var(--color-gray-blue);
    color: var(--primary-color);
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    padding: 2px 6px 2px;

    &[data-important] {
      color: var(--color-red);
      background-color: rgba(237, 10, 52, 0.15);
      font-weight: bold;
      font-size: 13px;
    }
  }
}