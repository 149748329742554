.product-page {
  &__product-info {
  }

  &__similar-block {
  }

  &__title {
  }

  &__heading-with-state{
    display: flex;
  }

  &__heading-badge {
    color: #fff;
    z-index: 100;
    padding: 5px 20px;

    &._blue {
      background-color: var(--color-blue-dark);
    }

    &._red {
      background-color: var(--alert-color);
    }
  }
}

@include respond-up('large') {
  .product-page {
    &__main-info-container {
      border-bottom: 1px solid var(--color-border);
    }

    &__actions-list {
      padding-top: 40px;
    }

    &__actions, &__about-project {
      padding: 120px 0;
    }

    &__features {
      padding-bottom: 120px;
    }

    &__similar-block {
      padding-top: 140px;
    }

    &__advantages {
      padding-top: 120px;
    }

    &__title {
      margin-bottom: 40px;
    }

    &__renovations {
      padding-bottom: 70px;
    }

    &__heading {
      h1 {
        font-size: 36px;
      }
    }

    &__heading-with-state{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond-up('medium') {
  .product-page {

  }
}

@include respond('medium') {
  .product-page {
    &__actions-list {
      padding-top: 30px;
    }

    &__actions, &__about-project {
      padding: 70px 0 90px;
    }

    &__features {
      padding-bottom: 90px;
    }

    &__similar-block,
    &__advantages {
      padding-top: 90px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__renovations {
      padding-bottom: 50px;
    }

    &__heading {
      h1 {
        font-size: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .product-page {
    &__heading-with-state{
      display: flex;
      flex-direction: column;
    }
    &__heading-badge{
      margin-top: 15px;
      max-width: fit-content;
    }
    &__similar-block {
      .layout-card:last-child {
        display: none;
      }
    }
    .admin-link{
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-page {
    &__actions-list {
      padding-top: 22px;
    }

    &__actions {
      padding: 40px 0;
    }

    &__about-project {
      padding: 30px 0 40px;
    }

    &__features {
      padding-bottom: 40px;
    }

    &__similar-block,
    &__advantages {
      padding-top: 40px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__renovations {
      padding-bottom: 40px;
    }

    &__heading {
      h1 {
        font-size: 22px;
      }
    }
  }
}

@media print {
  .product-page {
    &__actions, .layout-ways-to-buy, &__renovations,
    &__3d, &__similar-block, &__about-project, &__features,
    &__advantages, .product-page-in-future, &__similar-block,
    .admin-link, .show-room {
      display: none;
    }
  }
}