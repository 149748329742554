.payment-steps {
  &__list {
    display: grid;
    grid-row-gap: 15px;
  }

  &__item {
    display: flex;

    &:nth-child(2) {
      .payment-steps__number-circle {
        svg {
          circle {
            &.payment-steps__circle {
              stroke-dasharray: 113.04;
              stroke-dashoffset: 56.52;
            }
          }
        }
      }
    }

    &:nth-child(3) {
      .payment-steps__number-circle {
        svg {
          circle {
            &.payment-steps__circle {
              stroke-dasharray: 169, 56.52;
              stroke-dashoffset: 56.52;
            }
          }
        }
      }
    }

    &:nth-child(4) {
      .payment-steps__number-circle {
        svg {
          circle {
            &.payment-steps__circle {
              stroke-dasharray: 226,08;
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__item-description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__top {
    display: flex;
  }

  &__number-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__number {
    font-style: normal;
    font-weight: 500;
    color: var(--primary-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 10;
  }

  &__number-circle {
    display: flex;
  }

  &__number-line {
    flex: 0 0 auto;
    background-color: var(--color-border);
  }
}

@include respond-up('large') {
  .payment-steps {
    padding-top: 140px;

    &__list {
      grid-template-columns: repeat(4, 1fr);
      margin-top: 50px;
    }

    &__text-wrap {
      margin-top: 32px;
    }

    &__button {
      margin-top: 28px;
    }
  }
}

@include respond-up('medium') {
  .payment-steps {
    &__list {
      grid-column-gap: 15px;
    }

    &__item {
      flex-direction: column;
      &:not(:last-child) {
        .payment-steps__number-line {
          height: 1px;
          width: calc(100% - (15px + 72px));
          margin-left: 15px;
        }
      }
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__item-description {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.5em;
    }

    &__button {
      max-width: 175px;
    }

    &__text-wrap {
      max-width: 241px;
    }

    &__number {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__top {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .payment-steps {
    padding-top: 90px;

    &__list-wrap {
      margin: 0 -#{$spacer-medium}px;
    }

    &__list {
      padding: 0 #{$spacer-medium}px;
      grid-template-columns: repeat(4, 255px);
      margin-top: 30px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__text-wrap {
      margin-top: 28px;
    }

    &__button {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .payment-steps {

  }
}

@include respond-down('small') {
  .payment-steps {
    padding-top: 40px;

    &__list {
      margin-top: 22px;
    }

    &__item {
      &:not(:last-child) {
        .payment-steps {
          &__number-line {
            width: 1px;
            height: calc(100% - (15px + 56px));
            margin-top: 15px;
          }

          &__text-wrap {
            padding-bottom: 15px;
          }
        }
      }
    }

    &__text-wrap {
      margin-left: 32px;
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-description {
      margin-top: 10px;
      font-size: 15px;
      line-height: 1.35em;
    }

    &__button {
      margin-top: 20px;
    }

    &__number {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__number-circle {
      svg {
        width: 56px;
        height: 56px;
      }
    }

    &__top {
      flex-direction: column;
      align-items: center;
    }
  }
}