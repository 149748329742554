@use "sass:math";

.show-room {
  background-color: var(--color-background);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
  }

  &__inner {
    position: relative;
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }

  &__content {
    &._active {
      .show-room {
        &__name {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__slider {
    position: relative;
  }

  &__item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .show-room {
        &__picture {
          transform: translateX(100%);
        }

        &__image {
          transform: translateX(-100%);
        }
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;

      .show-room {
        &__picture {
          transform: translateX(0);
          transition: transform .7s;
        }

        &__image {
          animation: animate-image .7s;
        }
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;

      .show-room {
        &__picture {
          transform: translateX(0);
          transition: transform .7s;
        }

        &__image {
          transform: translateX(0);
          transition: transform .7s;
        }
      }
    }
  }

  &__name {
    position: absolute;
    font-weight: bold;
    line-height: 115%;
    color: white;
    z-index: 20;
    transform: translate3d(0, 100px, 0);
    transition: transform var(--default-transition-duration);
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__arrow {
    bottom: 0;
  }

  &__title {
    flex: 0 0 auto;
  }

  &__tabs-wrap {
    flex: 0 1 auto;
    overflow: hidden;
  }
}

@include respond-up('large') {
  .show-room {
    &__head {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__title {
      margin-right: 20px;
    }

    &__arrow {
      left: 0;
    }

    &__name {
      font-size: 55px;
      bottom: 71px;
      left: -23px;
    }

    &__tabs {

    }

    &__content {

    }

    &__inner {

    }

    &__text-wrapper {
      grid-column: 1/5;
    }

    &__description {
      padding-right: 50px;
      margin-bottom: 35px;
    }

    &__button {

    }

    &__slider {
      grid-column: 5/13;
    }

    &__list {

    }

    &__item {

    }

    &__image-wrapper {

    }

    &__arrow {
      position: absolute;

    }

    &__picture {
      padding-top: math.div(464, 787) * 100%;
    }

    &__tabs-wrap {
      margin: 0 -15px 0 0;
    }
  }
}

@include respond('medium') {
  .show-room {
    &__head {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__text-wrapper {
      grid-row: 2;
      grid-column: 1/7;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;
    }

    &__description {
      max-width: 407px;

    }

    &__name {
      font-size: 36px;
      bottom: 70px;
      left: -25px;
    }

    &__arrow {
      position: relative;
      grid-row: 1;
      grid-column: 1/2;
      align-self: flex-end;
    }

    &__slider {
      grid-row: 1;
      grid-column: 1/7;
      margin-left: 150px;
    }

    &__picture {
      padding-top: math.div(316, 537) * 100%;
    }

    &__tabs-wrap {
      margin: 30px -#{$spacer-medium}px 0;
    }

    &__tabs {
      padding: 0 #{$spacer-medium}px 0 calc(#{$spacer-medium}px - 18px);
    }
  }
}

@include respond-down('medium') {
  .show-room {
    &__head {
      //border-bottom: none;
      flex-direction: column;
      align-items: flex-start;
    }

    &__tabs-wrap {
      width: 100vw;
    }
  }
}

@include respond-down('small') {
  .show-room {
    &__head {
      border-bottom: none;
    //  flex-direction: column;
    //  align-items: flex-start;
    }

    &__tabs-wrap {
      margin: 8px -#{$spacer-small}px 30px;
    }

    &__tabs {
      padding: 0 #{$spacer-small}px 0 calc(#{$spacer-small}px - 12px);
    }

    &__text-wrapper {
      grid-row: 2;
      grid-column: 1/6;
      margin-top: 55px;
    }

    &__description {
      font-size: 15px;
      margin-bottom: 25px;
    }

    &__button {
      width: 100%;
    }

    &__slider {
      grid-row: 1;
      grid-column: 1/6;
      margin-left: 10px;
    }

    &__name {
      font-size: 26px;
      left: -10px;
      bottom: 50px;
    }

    &__arrow {
      grid-row: 1;
      grid-column: 1/6;
      z-index: 20;
      align-self: flex-end;
      justify-self: center;
      margin-bottom: -26px;
    }

    &__picture {
      padding-top: math.div(192, 327) * 100%;
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}