@use "sass:math";

.product-page-news {
  &__form {

  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--color-border);
    &:first-child {
      .product-page-news__wrapper {
        padding-top: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
  }

  &__image-container {
    position: relative;

  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__picture {
    overflow: hidden;
    display: block;
    position: relative;
  }

  &__date-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__date-bg {
    background-color: var(--color-primary-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: var(--color-primary-dark);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      border-color: var(--color-primary-dark) var(--color-primary-dark) transparent;
      border-style: solid;
    }
  }

  &__title {
    border-bottom: 1px solid var(--color-border);
  }

  &__created-at-day {
    line-height: 120%;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__created-at-month {
    color: white;
    line-height: 120%;
  }

  &__text-block {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: 500;
    font-size: 17px;
    line-height: 140%;
    flex-grow: 1;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;
    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }

  &__all-news {

  }
}

@include respond-up('large') {
  .product-page-news {
    &__form {
      grid-column: 1/4;
    }

    &__container {
      position: relative;
      grid-row-gap: 10px;
    }

    &__list {
      grid-column: 5/13;
    }

    &__date-bg {
     padding: 7px 9px;
    }

    &__wrapper {
      padding: 25px 0;
    }

    &__image-container {
      flex: 0 0 240px;
      margin-right: 37px;
    }

    &__picture {
      width: 100%;
      height: 136px;
    }

    &__created-at-day {
      font-size: 20px;
    }

    &__created-at-month {
      font-size: 14px;

    }

    &__title {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__item {
      &:hover {
        .product-page-news {
          &__image {
            transform: scale(1.12);
          }

          &__icon {
            transform: translate3d(18px, -20px, 0);
            &_dub {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }

    &__image {
      transition: transform 2s cubic-bezier(0,0,.2,1);
    }

    &__all-news {
      position: absolute;
      bottom: 0;
      left: 0;
      &_padding {
        bottom: -25%;
      }
    }
  }
}

@include respond-up('medium') {
  .product-page-news {

    &__date-bg {
      padding: 0 8px;
      width: 61px;
      height: 65px;

      &::before {
        bottom: -9px;
        width: 52px;
        height: 9px;
      }

      &::after {
        bottom: -9px;
        border-width: 0 0 9px 9px;
      }
    }

    &__created-at-day {
      padding: 6px 0 4px;
      font-size: 20px;
    }

    &__created-at-month {
      padding: 6px 0 0;
      font-size: 14px;
      line-height: 1.2em;
    }
  }
}
@include respond('medium') {
  .product-page-news {
    &__form {
      grid-column: 1/7;
      padding-bottom: 30px;
      margin-bottom: 25px;
    }

    &__title {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__list {
      grid-column: 1/7;
      margin-bottom: 35px;
    }

    &__item {

    }

    &__wrapper {
      padding: 25px 0;
    }

    &__image-container {
      flex: 0 0 calc((247 / 768) * 100vw);
      margin-right: 37px;
    }



    &__created-at-day {

    }

    &__created-at-month {

    }

    &__text-block {

    }

    &__name {

    }

    &__picture {
      padding-top: math.div(136, 241) * 100%;
    }

    &__arrow {

    }

    &__all-news {
      grid-column: 1/7;
    }
  }
}
@include respond-down('medium') {
  .product-page-news {
    &__form {
      grid-row: 1;
      border-bottom: 1px solid var(--color-border);
    }

    &__list {
      grid-row: 2;
    }

    &__name {
      font-size: 16px;
    }

    &__all-news {
      grid-row: 3;
    }
  }
}
@include respond-down('small') {
  .product-page-news {
    &__form {
      padding-bottom: 30px;
      margin-bottom: 20px;
      grid-column: 1/5;
    }

    &__list {
      grid-column: 1/5;

    }

    &__title {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__wrapper {
      padding: 20px 0;
      flex-direction: column;
    }

    &__image-container {
      max-width: calc((176 / 375) * 100vw);
      margin-bottom: 20px;
    }

    &__date-bg {
      padding: 5px 6px;
      width: 44px;
      &::before {
        bottom: -9px;
        width: 35px;
        height: 9px;
      }

      &::after {
        bottom: -9px;
        border-width: 0 0 9px 9px;
      }
    }

    &__created-at-day {
      font-size: 16px;
      padding-bottom: 4px;
      margin-bottom: 5px;
    }

    &__created-at-month {
      font-size: 13px;
    }

    &__picture {
      padding-top: math.div(100, 176) * 100%;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__all-news {
      display: none;
    }
  }
}