.about-company-in-persons {
  background-color: var(--color-background);

  &__title {
    border-bottom: 1px solid var(--color-border);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 54%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, var(--grid-column-width));
    grid-column-gap: var(--grid-column-gap);
  }

  &__main {
    position: relative;
  }

  &__main-inner {
    z-index: 15;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-bottom: 1px solid var(--color-white-opacity-50);
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: #fff;
  }

  &__item-subtitle {
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
    color: var(--color-white-opacity-50);
  }
}

@include respond-up('large') {
  .about-company-in-persons {
    padding: 120px 0;
    --grid-column-width: 285px;
    --grid-column-gap: 100px;

    &__title {
      padding-bottom: 40px;
    }

    &__picture {
      width: 1196px;
      height: 643px;
    }

    &__main-inner {
      padding: 38px 74px;
    }

    &__item-title {
      font-size: 17px;
    }

    &__main {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .about-company-in-persons {
    &__item-subtitle {
      margin-top: 12px;
    }

    &__item-title {
      line-height: 1.35em;
    }

    &__item-subtitle {
      font-size: 14px;
    }

    &__item {
      padding-bottom: 12px;
    }
  }
}

@include respond('medium') {
  .about-company-in-persons {
    padding: 70px 0;
    --grid-column-width: calc((214 / 768) * 100vw);
    --grid-column-gap: calc((24 / 768) * 100vw);

    &__title {
      padding-bottom: 30px;
    }

    &__picture {
      width: 100vw;
      height: 643px;
    }

    &__main-inner {
      padding: 35px #{$spacer-medium}px;
    }
  }
}

@include respond-down('medium') {
  .about-company-in-persons {
    &__item-title {
      font-size: 16px;
    }

    &__main {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .about-company-in-persons {
    padding: 30px 0;
    --grid-column-width: 162px;
    --grid-column-gap: 10px;

    &__title {
      padding-bottom: 20px;
    }

    &__picture {
      min-width: 554px;
      width: 100%;
      height: 452px;
    }

    &__main {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__main-inner {
      padding: 14px #{$spacer-small}px;
      display: flex;
      justify-content: center;
      width: 100%;
      min-width: 554px;
    }

    &__item-subtitle {
      margin-top: 8px;
    }

    &__item-title {
      line-height: 1.4em;
    }

    &__item-subtitle {
      font-size: 13px;
    }

    &__item {
      padding-bottom: 10px;
    }
  }
}