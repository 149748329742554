.seller-flats-layout {

}

@include respond-up('large') {
  .seller-flats-layout {
    &__bottom {
      margin-top: 80px;
    }

    &__list {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .seller-flats-layout {

  }
}

@include respond('medium') {
  .seller-flats-layout {
    &__bottom {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .seller-flats-layout {
    &__list {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .seller-flats-layout {
    &__bottom {
      margin-top: 60px;
    }
  }
}

@media print {
  .seller-flats-layout {
    &__bottom {
    }
  }
}