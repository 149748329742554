.fb-block {
  font-style: normal;
  border-top: 1px solid var(--color-border);
  padding-top: 21px;
  display: flex;

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-weight: 500;
    line-height: 135%;
    color: var(--color-primary-dark);
    padding-bottom: 7px;
  }

  &__description {
    color: var(--color-gray-dark);
  }

  &__button {
    height: fit-content;
  }
}

@include respond-up('large') {
  .fb-block {
    flex-direction: column;
    position: sticky;
    top: 136px;
    height: fit-content;

    &__description {
      padding-bottom: 25px;
    }
  }
}

@include respond-up('medium') {
  .fb-block {
    &__heading {
      font-size: 22px;
    }
  }
}

@include respond('medium') {
  .fb-block {
    //padding-bottom: 76px;

    &__button {
      .fb-block {
        &__button-link {
          padding: 18px 69px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .fb-block {
    flex-direction: row;
    justify-content: space-between;
  }
}

@include respond-down('small') {
  .fb-block {
    //padding-bottom: 40px;

    &__text-wrapper {
      flex: 1 1 139px;
      padding-right: 37px;
    }

    &__heading {
      font-size: 18px;
    }
  }
}