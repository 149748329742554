.excursions-steps {
  border-top: 1px solid var(--color-border);

  &__inner {
    display: grid;
  }

  &__title {
    grid-area: title;
  }

  &__text-wrap {
    grid-area: text-wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__items-wrap {
    grid-area: items;
  }

  &__item {
    display: flex;
    align-content: flex-start;

    &:last-child {
      .excursions-steps {
        &__number-line {
          display: none;
        }

        &__item-wrap {
          padding-bottom: unset;
        }
      }
    }
  }

  &__item-wrap {
    display: grid;
    flex: 0 1 auto;
  }

  &__number {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
  }

  &__number-title {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.35em;
    color: var(--primary-color);
    flex: 0 0 auto;
  }

  &__number-line {
    width: 1px;
    height: 100%;
    min-height: 70px;
    flex: 0 1 auto;
    background-color: var(--color-border);
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__item {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .excursions-steps {
    padding: 40px 0;
    margin-top: 140px;

    &__inner {
      grid-column-gap: 70px;
      grid-template-columns: 338px 1fr;
      grid-template-areas: 'title items'
                           'text-wrap items';
    }

    &__button {
      margin-top: 35px;
    }

    &__text-wrap {
      justify-content: flex-end;
    }

    &__item-wrap {
      grid-template-columns: 176px 1fr;
      grid-column-gap: 131px;
      padding-bottom: 30px;
    }
  }
}

@include respond-up('medium') {
  .excursions-steps {
    &__number-title {
      width: 62px;
      height: 62px;
    }

    &__number {
      margin-right: 41px;
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__item {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .excursions-steps {
    padding: 30px 0;
    margin-top: 70px;

    &__text-wrap {
      padding-top: 50px;
      max-width: calc((452 / 768) * 100vw);
    }

    &__items-wrap {
      margin-top: 40px;
    }

    &__item-wrap {
      grid-template-columns: calc((176 / 768) * 100vw) 1fr;
      grid-column-gap: 78px;
    }
  }
}

@include respond-down('medium') {
  .excursions-steps {
    &__inner {
      grid-template-areas: 'title'
                           'items'
                           'text-wrap';
    }

    &__button {
      margin-top: 25px;
    }

    &__item-wrap {
      padding-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .excursions-steps {
    padding: 20px 0;
    margin-top: 40px;

    &__text-wrap {
      padding-top: 30px;
    }

    &__items-wrap {
      margin-top: 22px;
    }

    &__number-title {
      width: 46px;
      height: 46px;
    }

    &__number {
      margin-right: 28px;
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item {
      font-size: 15px;
      line-height: 1.35em;
    }
  }
}