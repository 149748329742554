.customer-index{
  &__hello{
    @extend h3;
  }
}

@include respond-up('medium') {
  .customer-index{
    &__hello{
      margin-bottom: 25px;
    }
  }
}

@include respond-down('small') {
  .customer-index{
    &__hello{
      margin-bottom: 20px;
    }
  }
}