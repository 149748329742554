.compilation-card-body {
  &__upper-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__compilation-flag {
    background: var(--color-blue-gray-opacity-40);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    color: var(--primary-color);
    padding: 5px 7px;
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
  }

  &__compilation-body {
    margin-top: 25px;
  }

  &__compilation-name-link {
    padding: 10px;
    margin: -10px;
  }

  &__stripes-list-wrapper {
    margin-top: 35px;
  }

  &__compilation-body {
    .compilation-card-body {
      &__new-name-field {
        input {
          border: none;
          background: var(--color-background);
        }
      }
    }
  }

  &__compilation-body {
    .compilation-card-body {
      &__show-prices-field {
        display: flex;
        align-items: center;
        margin-top: 20px;

        label {
          margin-bottom: 0;
          margin-left: 5px;
        }
      }
    }
  }
}
