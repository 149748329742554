.drop-button {
  --drop-button-height: 38px;
  --drop-link-height: 36px;
  --drop-button-background-color: var(--primary-color);
  --drop-button-color: #fff;
  --drop-button-border-color: transparent;
  --drop-padding: calc(var(--drop-button-height) / 3.15);
  --drop-button-border-color-hover: var(--drop-button-border-color);

  font-weight: 500;
  font-size: 15px;
  position: relative;

  &__handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--drop-padding);
    font-size: inherit;
    height: var(--drop-button-height);
    background-color: var(--drop-button-background-color);
    box-shadow: inset 0 0 0 1px var(--drop-button-border-color);
    color: var(--drop-button-color);
    transition: all 0.3s;
  }

  &__handle-icon {
    svg {
      path {
        fill: var(--drop-button-color);
      }
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    z-index: 1000;
    box-shadow: var(--shadow-card);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;
  }

  &:hover {
    .drop-button__content {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    .drop-button__handle {
      box-shadow: inset 0 0 0 1px var(--drop-button-border-color-hover);
    }
  }

  &__links {
    display: block;
  }

  &__link {
    display: flex;
    font-size: inherit;
    background-color: #fff;
    height: var(--drop-link-height);
    padding: 0 var(--drop-padding);
    align-items: center;
    transition: all 0.3s;
    font-weight: normal;

    &:last-child {
      border-bottom: none;
    }

    &._active, &[data-active="1"] {
      background-color: var(--color-background);
      color: var(--primary-color);
    }

    &:hover {
      background-color: var(--color-gray-blue);
    }
  }

  &_transparent {
    --drop-button-background-color: transparent;
    --drop-button-color: var(--primary-color);
    --drop-button-border-color: var(--color-blue-gray);
    --drop-button-border-color-hover: var(--primary-color);
  }
}

@include respond-down('medium') {
  .drop-button{
    &__link{
      height: unset;
      padding: 10px;
    }
  }
}