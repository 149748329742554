.switchable-accordion {
  &__item {
    border-top: 1px solid var(--color-border);

    &:last-child {
      border-bottom: 1px solid var(--color-border);
    }

    &._opened {
      .switchable-accordion {
        &__icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__title {
    display: block;
    font-style: normal;
    font-weight: bold;
  }

  &__link {
    display: flex;
  }

  &__next {
    display: none;
  }

  &__next-title {
    display: block;
    transform: translate3d(0, 0, 0);
  }

  &__link-main {
    display: flex;
    width: 100%;
  }

  &__icon {
    display: flex;
    transition: transform .4s;
  }

  &__link-main-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__navbar-wrap{
    display: none;
  }

  &__navbar-title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.5em;
    color: var(--color-gray-dark);
  }

  &__navbar-item {
    position: relative;
    padding-left: 19px;

    &._active {
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 5px;
        height: 5px;
        background-color: var(--primary-color);
      }

      .switchable-accordion__navbar-title {
        color: var(--primary-color);
      }
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &_with-nav {
    .switchable-accordion {
      &__title,
      &__icon,
      &__link-main {
        overflow: hidden;
      }
    }

    .switchable-item-content {
      &__title {
        display: none;
      }

      &__description {
        margin-top: unset;
      }
    }
  }
}

@include respond-up('large') {
  .switchable-accordion {
    padding-top: 120px;

    &__items {
      grid-column: 3/13;
    }

    &__title {
      font-size: 26px;
    }

    &__link {
      padding: 30px 0;
    }

    &__item-wrap,
    &__item-content {
      grid-column: 1/13;
    }

    &_with-nav {
      padding-bottom: 72px;
      position: relative;

      .switchable-item-content {
        &__inner {
          grid-template-areas: 'title description'
                               'slider slider'
                               'panorama image-wrap';
        }

        &__text-wrap {
          grid-column: 7/13;
          margin-top: unset;
        }

        &__slider {
          margin-top: 60px;
        }

        &__panoramic-wrap,
        &__image-wrap {
          margin-top: 152px;
        }
      }

      .switchable-accordion {
        &__next {
          display: block;
          overflow: hidden;
        }

        &__navbar-wrap {
          display: flex;
          position: absolute;
          left: 0;
          top: 120px;
          max-width: 176px;
          bottom: 0;
          z-index: 200;
        }

        &__navbar {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: sticky;
          top: 185px;
          height: max-content;
        }

        &__link-main {
          justify-content: unset;
          margin-top: 15px;
        }

        &__title {
          margin-right: 10px;
          flex: 0 0 auto;
        }

        &__icon {
          flex: 0 1 auto;
        }

        &__item-wrap {
          position: relative;
          height: 0;
          grid-column: 7/11;
          overflow: hidden;
          opacity: 1;
          transition: {
            property: opacity;
            duration: .4s;
            //delay: 1.6s;
            delay: .4s;
          };
        }

        &__link {
          width: max-content;
          transition: opacity .4s;
          padding: unset;
          flex-direction: column;
        }

        &__item {
          transition: opacity .4s;
          opacity: 0;
          border-top: unset;
          border-bottom: unset;
          pointer-events: none;

          &._opened {
            opacity: 1;
            pointer-events: auto;

            &:first-child {
              .switchable-item-content {
                &__inner {
                  padding: 0;
                }
              }
            }

            .switchable-item-content {
              &__item-title {
                display: block;
              }
            }

            .switchable-accordion {
              &__item-wrap {
                pointer-events: none;
                opacity: 0;
                transition: {
                  duration: 0s;
                  delay: 0s;
                }
              }

              &__next-title,
              &__link-main-inner {
                transform: translate3d(0, -100%, 0);
              }

              &__icon {
                transform: unset;
              }
            }

            & + .switchable-accordion__item {
              transition-delay: 1.5s;
              opacity: 1;
              pointer-events: auto;

              .switchable-item-content {
                transition-delay: .7s;
                transition-duration: .9s;

                &__inner {
                  opacity: 0;
                  transition: {
                    duration: .3s;
                  };
                }
              }

              .switchable-accordion {
                &__next-title {
                  transition: transform .9s;
                }

                &__link-main-inner {
                  transition: transform .9s;
                }

                &__item-wrap {
                  overflow: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .switchable-accordion {
    &__title {
      line-height: 1.25em;
    }
  }
}

@include respond('medium') {
  .switchable-accordion {
    padding-top: 90px;

    &__title {
      font-size: 23px;
    }

    &__link {
      padding: 25px 0;
    }

    &__item-wrap,
    &__item-content {
      grid-column: 1/7;
    }

    &_with-nav {
      .switchable-item-content {
        &__inner {
          grid-template-areas: 'description description'
                               'slider slider'
                               'panorama image-wrap';
        }

        &__text-wrap {
          grid-column: 1/6;
        }

        &__panoramic-wrap,
        &__image-wrap {
          margin-top: 110px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .switchable-accordion {
    &_with-nav {
      .switchable-item-content {
        &__slider {
          margin-top: 30px;
        }

        &__text-wrap {
          margin-top: unset;
        }
      }
    }
  }
}

@include respond-down('small') {
  .switchable-accordion {
    padding-top: 40px;

    &__title {
      font-size: 20px;
      line-height: 1.35em;
    }

    &__link {
      padding: 18px 0;
    }

    &__item-wrap,
    &__item-content {
      grid-column: 1/5;
    }

    &_with-nav {
      .switchable-item-content {
        &__inner {
          grid-template-areas: 'description'
                               'slider'
                               'panorama'
                               'image-wrap';
        }
      }
    }
  }
}