.flats-page {
  &__content{
    &._hidden{
      display: none;
    }
  }
}

@include respond-up('large') {
  .flats-page {
    &__chess{
      margin-top: -150px;
    // overflow-x: scroll обрезает контент как по горизонтали так и
    // по вертикали. Чтобы обеспечить видимость всплывашек пришлось
    // использовать некоторый костыль
    }
  }
}

@include respond-up('medium') {
  .flats-page {

  }
}

@include respond('medium') {
  .flats-page {

  }
}

@include respond-down('medium') {
  .flats-page {

  }
}

@include respond-down('small') {
  .flats-page {

  }
}