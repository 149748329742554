@use "sass:math";

.partners-present {
  &__text-block {
    flex-grow: 1;
  }

  &__list {
    display: flex;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__val {
    position: absolute;
    font-style: normal;
    text-transform: uppercase;
    color: var(--color-background);
    margin: auto 0;
    font-weight: bold;
    font-size: 45px;
    line-height: 125%;
    z-index: 20;
  }

  &__item {
    position: relative;
  }

  &__wrapper {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    margin-bottom: 15px;
    color: var(--color-primary-dark);
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }


  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;
    z-index: 10;
  }
}

@include respond-up('large') {
  .partners-present {
    &__list {
      justify-content: space-between;
      padding: 0 var(--large-indent);
    }

    &__text-block {
    }

    &__item {
      flex: 0 0 278px;

    }

    &__picture {
      padding-top: math.div(159, 278) * 100%;
    }
  }
}

@include respond-up('medium') {
  .partners-present {
    &__list {
      justify-content: space-between;
    }

    &__text-block {
      padding: 30px;
    }

    &__val {
      bottom: 131px;
      left: 28px;
    }

    &__picture {
      padding-top: math.div(159, 278) * 100%;
    }
  }
}

@include respond('medium') {
  .partners-present {
    &__list {
      padding: 0 var(--medium-indent);
      margin: 0 -14px;
    }

    &__item {
      flex: 0 0 306px;
      padding: 0 14px;
    }
  }
}


@include respond-down('small') {
  .partners-present {
    &__list {
      padding: 0 var(--small-indent);
      margin: 0 -6px;
    }

    &__item {
      flex: 0 0 233px;
      padding: 0 6px;
    }

    &__val {
      bottom: 105px;
      left: 20px;
      font-size: 35px;
    }

    &__text-block {
      padding: 20px;
    }

    &__name {
      margin-bottom: 10px;
      font-size: 16px;
    }

    &__description {
      margin-bottom: 102px;
      font-size: 15px;
    }

    &__picture {
      padding-top: math.div(126, 221) * 100%;
    }
  }
}