.customer-notification {
  border-top: 1px solid var(--color-border);

  &__title {
    position: relative;
    padding-left: 22px;

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      background-color: var(--primary-color);
      width: 7px;
      height: 7px;
    }
  }

  &__description, &__date {
    color: var(--color-gray-dark);
  }
}

@include respond-up('medium') {
  .customer-notification {
    padding: 25px 0;

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 25px;
    }
  }
}

@include respond-down('small') {
  .customer-notification {
    padding: 20px 0;

    &__title {
      margin-bottom: 15px;
    }

    &__description {
      margin-bottom: 20px;
    }
  }
}