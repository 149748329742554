.seller-infrastructure-item {
  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 93%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
    color: var(--color-primary-dark);
    margin-top: 12px;
  }

  &__item-time {
    margin-top: 13px;
  }
}

@include respond-up('large') {
  .seller-infrastructure-item {
  }
}

@include respond-up('medium') {
  .seller-infrastructure-item {

  }
}

@include respond('medium') {
  .seller-infrastructure-item {

  }
}

@include respond-down('medium') {
  .seller-infrastructure-item {
  }
}

@include respond-down('small') {
  .seller-infrastructure-item {

  }
}