.product-info-info-list {
  &__item {
    font-style: normal;
    font-weight: normal;
    display: flex;
  }

  &__title {
    display: flex;
    align-items: baseline;
    color: var(--color-gray-dark);
  }

  &__title-name {
    flex: 0 0 auto;
  }

  &__title-border {
    width: 100%;
    flex: 0 1 auto;
    border-bottom: 1px dashed var(--color-border);
  }

  &__tags {
    display: flex;
  }

  &__tags-items {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }

  &__tags-item {
    padding: 3px;
  }

  &__tags-item-name {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.25em;
    color: var(--color-gray-dark);
    padding: 4px 5px;
    background-color: var(--color-gray-blue);
  }

  &__link {
    color: var(--primary-color);
    transition: {
      duration: var(--default-transition-duration);
      property: color;
    };
    &:hover {
      color: var(--color-blue);
    }
  }
}

@include respond-up('large') {
  .product-info-info-list {
    &__title {
      width: 268px;
    }

    &__tags {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .product-info-info-list {
    &__items {
      margin: -9px 0;
    }

    &__item {
      font-size: 15px;
      line-height: 1.35em;
      padding: 9px 0;
    }

    &__button {
      &_secondary {
        margin-left: 10px;
      }
    }
  }
}

@include respond('medium') {
  .product-info-info-list {
    &__title {
      width: calc((268 / 768) * 100vw)
    }
  }
}

@include respond-down('medium') {
  .product-info-info-list {
    &__tags {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .product-info-info-list {
    &__items {
      margin: -7px 0;
    }

    &__item {
      font-size: 14px;
      line-height: 1.25em;
      padding: 7px 0;
    }

    &__title {
      width: calc((162 / 375) * 100vw);
    }

    &__button {
      &_secondary {
        margin-top: 10px;
      }
    }
  }
}