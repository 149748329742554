// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.payment-calc-professional {
  &__inner {
    display: grid;
  }

  &__input-annotation {
    position: absolute;
    top: 50%;
    right: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
    color: var(--primary-color);
  }

  .slider-input {
    input {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.35em;
    }

    &__line {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 6px;
      height: 2px;
      border: unset;
      background: unset;
      box-shadow: unset;
    }
  }

  .noUi-horizontal {
    .noUi-handle {
      right: -14px;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border: unset;
      box-shadow: unset;

      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 8px;
        height: 8px;
        background-color: var(--primary-color);
      }

      &::after {
        display: none;
      }
    }
  }

  .noUi-touch-area {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -8px;
    left: -8px;
  }

  .noUi-base {
    height: 2px;
    &::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      left: 0;
      bottom: -6px;
      background: #fff;
      z-index: 5;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: var(--primary-color);
      left: 3px;
      bottom: -3px;
      z-index: 10;
      pointer-events: none;
    }
  }

  .noUi-connect {
    background: var(--primary-color);
  }

  &__numbers-title {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__numbers-value {
    margin-top: 4px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4em;
    color: var(--color-primary-dark);

    &_total {
      color: var(--color-primary-dark);
    }
  }

  &__request {
    display: flex;
    align-items: center;
  }

  &__request-title {
    color: #fff;
  }

  &__button {
    flex: 0 0 auto;
  }

  &__values-wrap {
    grid-area: values-wrap;
    //padding-bottom: 6px;
  }

  &__title{
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    flex: auto;
  }

  &__numbers-wrap {
    grid-area: numbers-wrap;
    background: var(--color-gray-light);
    display: flex;
    flex-direction: column;
    .payment-calc-professional{
      &__title{
        color: var(--primary-color);
      }
      &__numbers-item{
        &:last-child{
          border-top: 1px solid var(--color-border);
        }
      }

      &__numbers-plate{
        background: var(--color-blue-gray);
        color: var(--primary-color);
      }
    }
  }

  &__numbers-wrap-add {
    position: relative;
    grid-area: numbers-wrap-add;
    background: var(--primary-color);
    .payment-calc-professional{
      &__numbers-title{
        color: var(--color-white-opacity-70);
      }
      &__numbers-value, &__title{
        color: #FFFFFF;
      }
      &__numbers-item{
        &:last-child{
          border-top: 1px solid var(--color-white-opacity-30);
        }
      }

      &__numbers-plate{
        background: #FFFFFF;
        color: var(--primary-color);
      }
    }
  }

  &__numbers-logo{
    position: absolute;
    svg{
      fill-opacity: 0.2;
    }
  }

  &__request-wrap{
    grid-area: request;
  }

  &__numbers {
    display: flex;
  }

  &__numbers-plate{
    padding: 5px 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__example-price {
    grid-area: example-price;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .payment-calc-professional {
    &__inner {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'values-wrap numbers-wrap numbers-wrap-add'
                           'request numbers-wrap numbers-wrap-add'
                           ' example-price example-price example-price';
      grid-column-gap: 28px;
    }

    .form-field {
      &:not(:first-child) {
        margin-top: 39px;
      }
    }

    &__item {
      padding: 30px 0;
    }

    &__request-wrap, &__values-wrap{
      padding: 0;
    }

    &__numbers-item {
      //flex: 0 0 50%;
      padding: 0 30px;
    }

    &__request-wrap{
      display: flex;
      align-items: flex-end;
    }

    &__button{
      width: 100%;
      height: 54px;
    }

    &__request {
      padding: 29px 30px;
    }

    &__title{
      padding: 0 30px;
    }
    &__numbers {
      flex-direction: column;
    }

    &__example-price {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .payment-calc-professional {
    &__item {

    }

    &__numbers-title {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__numbers-value {
      font-size: 25px;
    }

    &__numbers {
    }

    &__numbers-item {
      padding-top: 20px;
      &:nth-child(3) {
        padding-bottom: 25px;
      }
      &_with-plate{
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: baseline;
      }
    }

    &__numbers-logo{
      position: absolute;
      right: -10px;
      top: 25px;
      svg{
        width: 115px;
        height: 153px;
      }
    }

    &__example-price {
      margin-top: 15px;
    }
  }
}

@include respond('medium') {
  .payment-calc-professional {
    &__inner {
      grid-template-areas: 'values-wrap values-wrap'
                            'numbers-wrap numbers-wrap-add'
                           'example-price example-price'
                           'request request';
      grid-column-gap: 24px;
      grid-template-columns: 1fr 1fr;
    }

    &__numbers-wrap-add, &__numbers-wrap{
      padding: 30px 25px;
    }

    .form-field{
      &:first-child {
        grid-column: 1 / 3;
      }
    }

    &__values-wrap{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 36px;
      grid-column-gap: 24px;
      padding-bottom: 51px;
    }

    &__request-wrap {
      margin-top: 35px;
      display: flex;
    }

    &__button{
      width: 100%;
    }

    &__request {
      padding: 29px 50px;
      justify-content: space-between;
    }

    &__numbers {
      flex-direction: column;
    }
  }
}

@include respond-down('medium') {
  .payment-calc-professional {

  }
}

@include respond-down('small') {
  .payment-calc-professional {
    &__inner {
      grid-template-areas: 'values-wrap values-wrap'
                           'numbers-wrap numbers-wrap-add'
                           'example-price example-price'
                           'request request';
      grid-template-columns: 1fr 1fr;
    }

    &__numbers-wrap-add, &__numbers-wrap{
      padding: 25px 20px;
    }

    &__item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__title{
      font-size: 22px;
      font-style: normal;
      line-height: 120%;
      padding-bottom: 20px;
      word-break: break-all;
      height: max-content;
    }

    .form-field {
      &:not(:first-child) {
        margin-top: 18px;
      }
    }

    &__values-wrap{
      padding-bottom: 41px;
    }

    &__numbers-title {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__numbers-value {
      font-size: 20px;
    }

    &__request {
      padding: 25px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__numbers {
      flex-direction: column;
    }

    &__numbers-item {
      padding-bottom: 20px;
      &:nth-child(4) {
        padding-top: 20px;
      }
      &_with-plate{
      }
    }

    &__request-title {
      max-width: 75%;
    }

    &__numbers-plate{
      width: fit-content;
      margin-bottom: 10px;
    }

    &__button {
      margin-top: 20px;
      width: 100%;
    }

    &__numbers-logo{
      position: absolute;
      right: -5px;
      top: 0;
      svg{
        width: 54px;
        height: 76px;
      }
    }

    &__example-price {
      margin-top: 8px;
    }
  }
}