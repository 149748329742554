/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 30;

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: #{$spacer-small}
  ),
  medium: (
    from: 768,
    to: 1259,
    spacer: #{$spacer-medium}
  ),
  large: (
    from: 1260,
    to: 1920,
    spacer: #{$spacer-large}
  ),
  x-large: (
    from: 1921,
    spacer: #{$spacer-large}
  )
);

@function int-to-px($int) {
  @return $int + 'px';
}

$spacer-medium-px: int-to-px($spacer-medium);
$spacer-small-px: int-to-px($spacer-small);

:root {
  --large-indent: calc((100% - ((var(--max-row-width) * 1px) - (#{$spacer-large} * 2px))) / 2);
  --medium-indent: calc(#{$spacer-medium} * 1px);
  --small-indent: calc(#{$spacer-small} * 1px);

  --spacer: 0px;

  --max-row-width: 1260;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "TT Hoves", sans-serif;
  --h-font: "TT Hoves", sans-serif;

  /* Color styles */
  --t: transparent;

  --primary-color: #113FCC;     // --основные-цвета--синий основной
  --alert-color: #ED0A34;

  --default-transition: all 0.4s;
  --default-transition-duration: 0.4s;

  --primary-color-opacity: rgba(17, 63, 204, .85); // Не из макета

  --color-white-opacity-10: rgba(255, 255, 255, .1);
  --color-white-opacity-20: rgba(255, 255, 255, .2);
  --color-white-opacity-30: rgba(255, 255, 255, .3);
  --color-white-opacity-35: rgba(255, 255, 255, .35);
  --color-white-opacity-50: rgba(255, 255, 255, .5);
  --color-white-opacity-70: rgba(255, 255, 255, 0.70);
  --color-white-opacity-79: rgba(255, 255, 255, 0.79);
  --color-black-opacity: rgba(0, 24, 54, 0.5);
  --color-black-opacity-40: rgba(3, 15, 50, .4);
  --color-blue-gray-opacity: rgba(191, 202, 235, 0.3);
  --color-blue-gray-opacity-35: rgba(191, 202, 235, 0.35);
  --color-blue-gray-opacity-40: rgba(191, 202, 235, 0.4);
  --color-border-40: rgba(199, 203, 214, 0.4);
  --color-border-60: rgba(199, 203, 214, 0.6);

  --color-primary-dark: #030F32;//--основные-цвета--темный основной
  --color-blue-dark: #0D34AA;  //--основные-цвета--темный синий
  --color-background: #EEF0F4;  //--серые--фоновый
  --color-border: #C7CBD6;      //--серые--линии
  --color-blue-gray: #BFCAEB;   //--серые--сине-серый
  --color-gray-dark: #67718F;   //--серые--серый-темный текст
  --color-gray: #ACB4C8;        //--серые--серый-средний
  --color-gray-light: #EEF0F4;  //--серые--светлый-серый
  --color-gray-blue: #EFF6FD;   //--серые--cеро-голубой
  --color-grey-blue-grey: #F2F4FB; // Серые/Сине серый --color-blue-gray opacity 0.2
  --color-blue: #1DA1F2;        //--акцентные--голубой
  --color-red: #ED0A34;         //--акцентные--красный
  --color-red-opacity-15: rgba(237, 10, 52, 0.15);
  --color-green: #0DC268;       //--акцентные--зеленый
  --color-green-opacity-15: rgba(13, 194, 104, 0.15);
  --color-orange: #FD5B37;      //--акцентные--оранжевый
  --color-grey-super-light: #F7F8FA;

  --landing-background: #080E41;
  --landing-background-middle: #091556;
  --landing-form-backgroung: #0D2C96;
  --landing-frame-color: rgba(255, 255, 255, 0.23);
  --landing-frame-dots-color: rgba(255, 255, 255, 0.6);
  --landing-frame-background: #113ECB;


  --color-red-chess: #f87e74;
  --color-orange-chess: rgb(255, 180, 0);
  --color-green-chess: rgb(99, 203, 165);
  --color-gray-chess: #a6a6a6;

  /* Effect styles */
  --shadow-card:  0px 20px 40px rgba(125, 131, 146, 0.21);  // Тень 1 (Карточки)
  --shadow-list:  0px 12px 40px rgba(125, 131, 146, 0.21);  // Тень 2 (Выпадающие списки)
  --shadow-runner:  0px 4px 10px rgba(12, 44, 128, 0.12);   // Бегунки

  --default-bezier: cubic-bezier(.25, .1, .25, 1);
}
