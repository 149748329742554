.product-info-short-info {
  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__value {
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }
}

@include respond-up('medium') {
  .product-info-short-info {
    &__list {
      margin: -15px;
    }

    &__item {
      padding: 15px;
    }

    &__title {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__value {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond-down('small') {
  .product-info-short-info {
    &__list {
      flex-wrap: wrap;
      margin: -8px;
      justify-content: space-between;
    }

    &__item {
      padding: 8px;
    }

    &__title {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__value {
      font-size: 15px;
      line-height: 1.35em;
    }
  }
}

@media print {
  .product-info__apartments {
    .layout-apartment-card__button-small, .layout-card-buttons  {
      display: none;
    }
  }
}
