.seller-return-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 115%;
    color: var(--color-primary-dark);
    flex: 0 0 auto;
    margin-left: 12px;
    transition: color .4s;
  }

  &__icon {
    flex: 0 0 auto;
  }
}

@include respond-up('large') {
  .seller-return-button {
    &:hover {
      .seller-return-button__title {
        color: var(--primary-color);
      }
    }
  }
}