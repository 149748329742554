.bitrix-layout {
  background-color: white;
  padding: 20px;

  &__content {
    min-height: 100vh;
  }
}

@include respond-up('large') {
  .bitrix-layout {

  }
}

@include respond-up('medium') {
  .bitrix-layout {

  }
}

@include respond('medium') {
  .bitrix-layout {

  }
}

@include respond-down('medium') {
  .bitrix-layout {

  }
}

@include respond-down('small') {
  .bitrix-layout {

  }
}