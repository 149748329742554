//important and general stuff
@use "sass:math";

.client-compilation-print {
  display: none;
}

@media print {
  .print-header,
  .print-footer {
    position: fixed;
    width: 210mm !important;
  }

  .print-footer {
    bottom: 0;
    padding-bottom: 6mm;
  }

  .client-compilation-print {
    width: 210mm !important;
    display: block;
    &._share {
      margin-top: -25px;
    }

    &__print {
      display: flex;
      //justify-content: space-between;
      background: white;
      flex-direction: column;
      width: 210mm !important;
      //height: 297mm;
      padding: 19mm 0 0;
      page-break-after: always;
      page-break-before: always;
    }

    &__print-block {
      padding: 0 8.2mm;
      width: 100%;
      margin: 0;
    }

    &__print-wrapper {
      display: flex;
      flex-direction: column;
    }

    //the rest local styles

    .client-compilation-print .about-block__statistic {
      padding-left: 0;
    }

    .product-images__print-description {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 8pt;
      line-height: 120%;
      color: var(--primary-color);
      margin-top: 2mm;
    }

    .product-images {
      &__image-list,
      &__image-slide {
        padding-bottom: math.div(324, 421) * 100%;
      }

      &__picture {
        display: flex;
      }
    }

    .product-info-price-block {
      &__mortgage-wrap {
        flex-direction: row;
      }

      &__mortgage-price {
        font-size: 7.5pt;
      }

      &__mortgage-icon {
        height: 7mm;
        width: 7mm;
        margin-right: 2mm;
      }

      &__price-wrap {
        flex: 0 0 42mm;
        margin-right: 5mm;
        width: auto;
      }

      &__bottom-wrap {
        padding-top: 5mm;
      }

      &__mortgage-title,
      &__price-title {
        font-size: 7.5pt;
      }

      &__price {
        font-size: 13pt;
        margin-top: 1.5mm;
      }

      &__mortgage-text {
        flex-direction: row;
        align-items: baseline;
      }
    }

    .product-info-info-list {
      &__value {
        white-space: nowrap;
      }

      &__title {
        width: 47mm;
      }

      &__items {
        margin: 0;
      }

      &__item {
        font-size: 7.5pt;
        padding: 1mm 0;

        //&:nth-child(6),
        //&:nth-child(8),
        //&:nth-child(9),
        //&:nth-child(10),
        //{
        //  display: none;
        //}
      }
    }

    .customer-compilation-apartments {
      padding-top: 0;

      &__info-list {
        margin-top: 3mm;
      }

      &__name {
        margin-bottom: 0;
        font-size: 13pt;
      }

      &__half {
        grid-column: span 1;
        padding-bottom: 0;

        &:first-child {
          border-right: 1px solid var(--color-border);
          padding: 7mm 6mm 7mm 0;
        }

        &:nth-child(2) {
          padding: 7mm 0 7mm 6mm;
        }
      }

      &__item {
        &:nth-child(3n + 3) {
          page-break-after: always;
          border-bottom: 1px solid var(--color-border);
        }

        &:nth-child(3n + 4) {
          margin-top: 19mm;
        }
      }

      &__apartment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0;
      }

      &__list {
        padding-top: 19mm;
      }

      &__h3 {
        display: none;
      }
    }

    .product-info-short-info {
      &__title {
        font-size: 7.5pt;
      }

      &__value {
        font-size: 8pt;
        margin-top: 1mm;
      }

      &__item {
        padding: 0;
        grid-column: span 1;
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(3, 26mm);
        grid-column-gap: 5mm;
        margin: 5mm 0 0;
        justify-content: flex-start;
      }
    }

    .customer-compilation-project {
      &__genplan {
        margin-top: 5mm;
        padding-top: 5mm;
      }

      &__h3 {
        font-size: 13pt;
      }

      &__genplan-wrapper {
        padding-top: 9mm;
      }

      &__description-wrapper {
        display: grid;
        grid-template-columns: 586fr 480fr;
        grid-template-rows: 12mm auto;
        grid-column-gap: 13mm;
      }

      &__logo {
        grid-column: 1/2;
      }

      &__description {
        margin-top: 4mm;
        grid-column: 1/2;
        font-size: 8.5pt;
        max-height: 50mm;
        overflow: hidden;
      }

      &__picture {
        color: var(--color-gray-dark);
        font-size: 7pt;
      }

      &__image-wrapper {
        grid-column: 2/3;
        grid-row: 1/3;
        margin-top: 0;
      }

      &__info-value {
        font-size: 7pt;
        padding-top: 1mm;
      }

      &__info-key {
        font-size: 9.5pt;
      }

      &__name {
        font-size: 18pt;
        margin-bottom: 7mm;
      }

      &__info-list {
        width: 66%;
        grid-template-rows: repeat(1, auto);
        grid-template-columns: repeat(4, auto);
        grid-gap: 0 3mm;
        padding-bottom: 8mm;
      }

      &__logo-picture {
        width: 37mm;
        height: 12mm;
      }
    }

    .genplan {
      height: 108mm;
    }

    .about-block__advantages-list_column-mobile .about-block__item {
      flex: 0 0 33.33%;
      height: 59mm;
    }

    .about-block {
      &__wrapper {
        padding: 5mm;
      }

      &__advantage-description {
        font-size: 8pt;
      }

      &__advantage-title {
        font-size: 9.5pt;
        margin-bottom: 2mm;
      }

      &__key {
        margin-bottom: 11mm;
        width: 6mm;
        height: 6mm;
        font-size: 8pt;
      }

      &__key-wrapper {
        flex-grow: 0;
      }

      &__stat-description {
        font-size: 8pt;
        margin-bottom: 9mm;
        font-weight: 400;
        padding-right: 7mm;
      }

      &__stat-title {
        font-size: 17.5pt;
        padding-bottom: 2mm;
        margin-bottom: 1mm;
      }

      &__title {
        flex: 0 0 49mm;
        width: 49mm;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          width: 100%;
          max-width: 100%;
        }
      }

      &__head {
        padding-top: 5mm;
        display: flex;
        justify-content: space-between;
      }

      &__description-container {
        margin-bottom: 0;
        margin-top: 0;
        flex: 0 0 118mm;
      }

      &__description {
        margin-bottom: 0;
        font-size: 9pt;
      }

      &__statistic {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-left: 9px;
        margin-bottom: 0;
        margin-top: 11mm;
      }
    }

    .customer-compilation {
      &__about-block {
        margin: 5mm 0;
      }

      &__h3 {
        font-size: 13pt;
      }

      &__about-wrapper {
        padding-top: 21mm;
      }

      &__compilation-item {
        padding-top: 0;
      }

      &__project {
        margin-top: 9mm;
      }
    }

    .customer-compilation-description {
      padding: 5mm 6mm;

      &__bottom-wrapper {
        flex: 0 0 auto;
      }

      &__bottom {
        padding-top: 2mm;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
      }

      &__date {
        flex: 0 0 auto;
        text-align: right;
        margin-top: 0;
      }

      &__date,
      &__price,
      &__count {
        font-size: 7.5pt;
      }

      &__subtitle {
        max-width: 114mm;
        padding-bottom: 13mm;
        font-size: 9pt;
      }

      &__title_big {
        margin-bottom: 4mm;
      }

      &__title {
        font-size: 18pt;
      }

      &__price {
        padding-left: 3mm;
        margin-left: 3mm;

        &:before {
          height: 3mm;
        }
      }
    }

    &__print-manager-info {
      background: var(--color-background);
      padding: 2.2mm 8.2mm 2.2mm 8.2mm;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;

      .client-compilation-print__print-text-dark {
        margin-left: 1mm;
      }
    }

    &__compilation {
      margin-top: 12mm;
    }

    &__contact {
      font-size: 7pt;
      margin-left: 4mm;
    }

    &__logo-wrapper {
      width: 28mm;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 28mm;
      }
    }

    &__contact-item {
      width: 25mm;
    }

    &__header-top,
    &__contacts-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__header-top {
      width: 100%;
      padding: 2mm 0 2mm;
    }

    &__print-manager-phone {
      margin-left: 8.2mm;
    }

    &__print-text {
      font-family: var(--font);
      font-style: normal;
      font-weight: normal;
      font-size: 7pt;
      line-height: 120%;
    }

    &__print-line-wrapper-lower {
      padding-top: 1mm;
    }

    &__print-line-wrapper-second {
      margin-top: 2.7mm;
    }

    &__print-text-large {
      font-family: var(--font);
      font-style: normal;
      font-weight: bold;
      font-size: 13.6pt;
      line-height: 125%;
    }

    &__print-text-grey {
      color: var(--color-gray-dark);
    }

    &__print-text-dark {
      color: var(--color-primary-dark);
    }

    &__print-top-phone {
      padding-left: 5.4mm;
    }

    &__print-content {
      margin-top: 12.5mm;
    }

    &__print-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__print-bottom-date {
      border-top: 1px solid var(--color-border);
      width: 100%;
      padding-top: 2mm;
      text-align: right;
    }
  }
}
