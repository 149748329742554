.type-tag {
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  color: var(--color-gray-dark);
  padding-left: 17px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    width: 7px;
    height: 7px;
  }

  &._red {
    &:before {
      background: var(--color-red);
    }
  }

  &._green {
    &:before {
      background: var(--color-green);
    }
  }

  &._blue {
    &:before {
      background: var(--color-blue);
    }
  }
}