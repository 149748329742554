:root {

}

@include respond-up('large') {
    :root {
        --grid-column-gap: 28px;
    }
}

@include respond-up('medium') {
    :root {

    }
}

@include respond('medium') {
    :root {
        --grid-column-gap: 24px;
    }
}

@include respond-down('medium') {
    :root {

    }
}

@include respond-down('small') {
    :root {
        --grid-column-gap: 14px;
    }
}