.project-office-heading {
  display: flex;
  flex-direction: column;

  &__toggle {
    padding: 3px;
    width: 37px;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .4s;
    margin: 0 15px;
    flex: 0 0 auto;
  }

  &__toggle-inner {
    background-color: #fff;
    border-radius: 100%;
    height: 14px;
    width: 14px;
    transition: transform .4s;
  }

  &__toggle-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2em;
    flex: 0 1 auto;
  }

  &__toggle-wrap {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .project-office-heading {
    padding: 32px 35px 0;

    &__toggle-wrap {
      margin-top: 35px;
      padding-bottom: 30px;
    }
  }
}

@include respond-up('medium') {
  .project-office-heading {
    &__toggle-wrap {
      border-bottom: 1px solid var(--color-border);
    }

    &_mobile {
      display: none;
    }
  }
}

@include respond('medium') {
  .project-office-heading {
    padding: 22px 25px 0;

    &__toggle-wrap {
      padding-bottom: 22px;
    }
  }
}

@include respond-down('medium') {
  .project-office-heading {
    &__toggle-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .project-office-heading {
    z-index: 15;
    position: relative;
    background-color: #fff;
    box-shadow: 0 12px 40px rgba(125, 131, 146, 0.21);
    padding: 20px 25px;
    margin-bottom: -53px;

    &_desktop {
      display: none;
    }
  }
}