.partners-page {
  &__manager-block, &__partners-present {
    background-color: var(--color-background);
  }
}

@include respond-up('large') {
  .partners-page {
    &__you-will-get {
      padding-top: 140px;
    }

    &__manager-block {
     padding-top: 120px;
    }

    &__partners-present {
      padding-top: 116px;
      margin-top: 120px;
    }
  }
}

@include respond('medium') {
  .partners-page {
    &__you-will-get {
      padding-top: 90px;
    }

    &__partners-present {
      padding-top: 70px;
      margin-top: 90px;
    }

    &__manager-block {
      padding-top: 70px;
    }
  }
}

@include respond-down('small') {
  .partners-page {
    &__you-will-get {
      padding-top: 40px;
    }

    &__partners-present {
      padding-top: 30px;
      margin-top: 40px;
    }

    &__manager-block {
      padding-top: 30px;
    }
  }
}