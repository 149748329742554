.layout-apartment-card {
  position: relative;
  display: flex;

  &:nth-child(n+7) {
    display: none;
  }

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__buttons-wrap {
    position: absolute;
  }

  &__number {
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
  }

  &__price-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__price {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.35em;
  }

  &__mortgage {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1em;
  }

  &__mortgage-title {
    display: block;
    color: var(--color-gray-dark);
  }

  &__mortgage-value {
    display: block;
  }

  &__items-wrap {
    margin-top: 20px;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
    display: flex;
  }

  &__item-title {
    color: var(--color-gray-dark);
  }

  &__item-value {
    flex: 0 1 auto;
  }

  &__button,
  &__button-small {
    display: none;
  }

  &__tags {
    display: flex;
  }

  &__tags-items {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }

  &__tags-item {
    padding: 3px;
  }

  &__tags-item-name {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.25em;
    color: var(--color-gray-dark);
    padding: 4px 5px;
    background-color: var(--color-gray-blue);
  }
}

@include respond-up('large') {
  .layout-apartment-card {
    padding: 18px 0 0 30px;

    &:hover {
      .layout-apartment-card__button {
        opacity: 1;
      }
    }

    &:nth-child(2n+1) {
      border-right: 1px solid var(--color-border);
      padding: 18px 30px 0 0;

      .layout-apartment-card {
        &__buttons-wrap {
          right: 30px;
        }

        &__button {
          right: 20px;
        }
      }
    }

    &__buttons-wrap {
      top: 18px;
      right: 0;
    }

    &__button {
      pointer-events: none;
      display: flex;
      position: absolute;
      bottom: 0;
      right: -10px;
      opacity: 0;
      transition: opacity .4s;
      transform: scale(0.65);
    }

    &__number {
      font-size: 17px;
    }

    &__price-wrap {
      margin-top: 25px;
    }

    &__mortgage {
      font-size: 14px;
      line-height: 1em;
      margin-top: 8px;
    }

    &__item {
      font-size: 14px;
    }

    &__item-title {
      margin-right: 21px;
    }

    &__tags{
      padding-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .layout-apartment-card {
    &__items {
      margin: -3px 0;
    }

    &__item {
      padding: 3px 0;
    }

    &__item-title {
      flex: 0 0 80px;
    }
  }
}

@include respond('medium') {
  .layout-apartment-card {
    &:nth-child(3n+1) {
      padding: 20px 21px 0 0;
    }

    &:nth-child(3n+2) {
      padding: 20px 21px 0;
    }

    &:nth-child(3n) {
      padding: 20px 0 0 21px;
    }

    &:not(:nth-child(3n)) {
      border-right: 1px solid var(--color-border);

      .layout-apartment-card__buttons-wrap {
        right: 21px;
      }
    }

    &__buttons-wrap {
      top: 20px;
      right: 0;
    }

    &__item-title {
      margin-right: 2px;
    }

    &__tags{
      padding-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .layout-apartment-card {

    &__number {
      font-size: 16px;
    }

    &__price-wrap {
      margin-top: 20px;
    }

    &__mortgage {
      font-size: 13px;
      line-height: 1.25em;
      margin-top: 4px;
    }

    &__item {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .layout-apartment-card {
    padding: 18px 0;

    &__link{
      display: grid;
      align-items: center;
    }

    &__top{
      grid-row: 1/1;
    }

    &__price-wrap{
      grid-row: 3/3;
    }

    &__tags{
      grid-row: 4/4;
      grid-column: 1/3;
      padding-top: 15px;
    }

    &__buttons-wrap {
      top: 18px;
      right: 0;
    }

    &__items {
      flex-direction: row;
      justify-content: space-between;
      margin: 0 -6px;
    }

    &__item {
      flex-direction: column;
      padding: 0 6px;
      &:nth-child(3) {
        min-width: 58px;
      }
    }

    &__item-value {
      margin-top: 3px;
    }

    &__button-small {
      pointer-events: none;
      display: flex;
      align-items: center;
      max-height: 20px;
      right: 0;
      bottom: 18px;
      grid-row: 3/3;
      justify-self: end;
    }

    &__button-small-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 1.2em;
      color: var(--primary-color);
      flex: 0 0 auto;
      margin-right: 8px;
    }

    &__button-small-icon {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      svg {
        width: 15px;
        height: 15px;
      }
    }

    &__items-wrap {
      grid-row: 2/2;
      grid-column: 1/3;
      &_space-without-price {
        margin-bottom: 62px;
      }
    }
  }
}