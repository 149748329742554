.compare {
  &__block-elements {
    display: grid;
    line-height: 20/15;
  }

  &__block {
    &._hidden {
      display: none;
    }

    &_footer {
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 100;
      box-shadow: 0px 12px 40px rgba(125, 131, 146, 0.21);
      margin: 0 -20px;
      padding: 0 20px;

      .compare__block-element {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &__block-element {
    font-weight: 500;

    &._hidden {
      display: none;
    }
  }

  &__block-head {
    font-size: 14px;
    color: var(--color-gray-dark);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      top: 50%;
      background-color: var(--color-border);
      z-index: 1;
    }
  }

  &__block-title {
    padding-right: 15px;
    background-color: #fff;
    position: relative;
    z-index: 20;
  }

  &__content {
    position: relative;
  }

  &__arrow {
    width: 35px;
    height: 35px;
    position: absolute;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &._inactive {
      opacity: 0.2;
      cursor: default;
    }
  }

}

@include respond-up('large') {
  .compare {
    &__block-elements {
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &__block-element {
      padding: 20px 0 50px;
      font-size: 15px;
    }
  }
}

@include respond-up('medium') {
  .compare {
    &__arrow {
      top: 100px;

      &_prev {
        left: -35px;
      }

      &_next {
        right: -35px;
      }
    }
  }
}

@include respond('medium') {
  .compare {
    &__block-elements {
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__block-element {
      padding: 20px 0 40px;
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .compare {
    &__block-elements {
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }

    &__block-element {
      padding: 12px 0 35px;
      font-size: 14px;
    }

    &__arrow {
      top: 0;

      &_prev {
        left: 32%;
      }

      &_next {
        right: 32%;
      }
    }

    &__blocks {
      padding-top: 35px;
    }
  }
}