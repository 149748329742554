.switchable-slider {
  position: relative;

  &__inner {
    //overflow: hidden;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 50%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__items {
    display: flex;
  }

  &__item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;
      .switchable-slider {
        &__picture {
          transform: translateX(100%);
        }
        &__image {
          transform: translateX(-100%);
        }
      }
    }
    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      .switchable-slider {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image {
          animation: animate-image .9s;
        }
      }
    }
    &.animate_out {
      transform: translateX(0);
      z-index: 1;
      .switchable-slider {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }
}

@include respond-up('large') {
  .switchable-slider {
    &__arrows {
      bottom: -57px;
    }

    &__picture {
      width: 998px;
      height: 498px;
    }
  }
}

@include respond-up('medium') {
  .switchable-slider {
    &__arrows {
      position: absolute;
      right: 0;
    }

    .tns-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .switchable-slider {
    &__arrows {
      bottom: -50px;
    }

    &__picture {
      width: calc(100vw - #{$spacer-medium}px * 2);
    }
  }
}

@include respond-down('medium') {
  .switchable-slider {

  }
}

@include respond-down('small') {
  .switchable-slider {
    &__arrows {
      display: none;
    }

    &__picture {
      width: calc(100vw - #{$spacer-small}px * 2);
    }

    .tns-nav {
      margin-top: 18px;
    }
  }
}