.main-text {
  font-style: normal;
  font-weight: normal;

  &._meduim, &._medium {
    font-weight: 500;
  }
}

@include respond-up('medium') {
  .main-text {
    font-size: 16px;
    line-height: 150%;
  }
}

@include respond-down('small') {
  .main-text {
    font-size: 15px;
    line-height: 135%;
  }
}
