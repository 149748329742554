// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.points {
  display: flex;

  &__item {
    &:nth-child(1) {
      background-color: rgba(191, 202, 235, 0.2);
    }
    &:nth-child(2) {
      background-color: rgba(191, 202, 235, 0.4);

    }
    &:nth-child(3) {
      background-color: rgba(191, 202, 235, 0.6);
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
  }

  &__svg-wrapper {
    width: 52px;
    height: 52px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    overflow: hidden;
  }

  &__svg {
    background-color: white;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .points {
    &__item {
      flex: 0 0 33.333%;
    }

    &__wrapper {
      padding: 30px 40px 40px;
    }

    &__head {
      margin-bottom: 90px;
    }
  }
}
@include respond('medium') {
  .points {
    &__wrapper {
      padding: 30px 35px;
    }

    &__head {
      margin-bottom: 25px;
    }
  }
}
@include respond-down('medium') {
  .points {
    flex-direction: column;

    &__item {
      flex: 0 0 100%;
    }
  }
}
@include respond-down('small') {
  .points {
    &__wrapper {
      padding: 20px;
    }

    &__head {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 16px;
    }

    &__description {
      font-size: 15px;
    }
  }
}