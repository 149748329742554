@use "sass:math";

.manager-block {
  display: flex;
  background-color: white;

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__picture {
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    display: block;
  }

  &__name {
    margin-bottom: 16px;
    font-weight: 500;
    line-height: 135%;
    color: var(--color-primary-dark);
  }

  &__profession {
    line-height: 150%;
    color: var(--color-gray-dark);
  }

  &__contact-wrapper {
    border-left: 2px solid var(--primary-color);
  }

  &__work-number, &__mobile-number {
    display: inline-block;
    font-weight: 500;
    line-height: 135%;
    color: var(--color-primary-dark);
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-color);
    border-bottom: 1px solid transparent;
  }
}

@include respond-up('large') {
  .manager-block {
    padding: 50px 120px 50px 60px;
    align-items: center;
    &__avatar {
      margin-right: 55px;
    }

    &__name-wrapper {
      margin-right: 115px;
    }

    &__name {

    }

    &__profession {

    }

    &__contact-wrapper {

    }

    &__number {
      padding-left: 37px;
    }

    &__work-number, &__mobile-number {
      margin-bottom: 16px;
    }

    &__mobile-number {
      margin-left: 55px;
    }

    &__text {
      transition: border-bottom-color 0.4s ease-in-out;
      &:hover {
        border-bottom: 1px solid var(--primary-color);
      }
    }

    &__email {
      padding-left: 37px;

    }
  }
}
@include respond-up('medium') {
  .manager-block {
    &__avatar {
      width: 146px;
      height: 146px;
    }

    &__picture {
      padding-top: math.div(146, 146) * 100%;
    }

    &__name {
      font-size: 19px;
      margin-bottom: 16px;
    }

    &__profession {
      font-size: 16px;
    }

    &__number {
      display: flex;
      justify-content: space-between;
    }

    &__work-number, &__mobile-number {
      font-size: 19px;
    }
  }
}
@include respond('medium') {
  .manager-block {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-column-gap: 23px;
    padding: 35px 90px 35px 25px;

    &__avatar {
      grid-column: 1/3;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &__name-wrapper {
      grid-column: 3/7;
      grid-row: 1;
      margin-bottom: 35px;
    }

    &__contact-wrapper {
      grid-column: 3/7;
      grid-row: 2;
    }

    &__number {
      padding-left: 37px;
    }

    &__work-number, &__mobile-number {
      margin-bottom: 16px;
    }

    &__email {
      padding-left: 37px;
    }
  }
}
@include respond-down('small') {
  .manager-block {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 14px;
    grid-row-gap: 32px;
    padding: 20px;

    &__avatar {
      width: 100px;
      height: 100px;
      grid-row: 1;
      grid-column: 1/3;
    }

    &__picture {
      padding-top: 100%;
    }

    &__name-wrapper {
      padding: 10px 0 0 40px;
      grid-row: 1;
      grid-column: 2/5;
    }

    &__name {
      font-size: 16px;
    }

    &__profession {
      font-size: 15px;
    }

    &__contact-wrapper {
      grid-row: 2;
      grid-column: 1/5;
    }

    &__number {
      justify-content: space-between;
      display: flex;
    }

    &__work-number, &__mobile-number {
      padding-left: 25px;
      font-size: 16px;
    }

    &__email {
      padding-left: 25px;
    }
  }
}