.project-layouts {
  overflow: hidden;

  &__title {
    grid-area: title;
    grid-row: 1/2;
  }

  &__room-form {
    grid-area: room-form;
  }

  &__mini-form {
    grid-area: mini-form;
  }

  &__slider-wrapper {
    grid-area: slider;
  }
}

@include respond-up('large') {
  .project-layouts {
    &__inner {
      grid-row-gap: 36px;
      grid-template-rows: repeat(2, auto);
    }

    &__title {
      grid-column: 1/6;
    }

    &__room-form {
      grid-column: 6/11;
      grid-row: 1/2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    &__mini-form {
      grid-row: 1/2;
      grid-column: 11/13;
    }

    &__slider-wrapper {
      grid-column: 1/13;
      grid-row: 2/3;
    }
  }
}

@include respond-up('medium') {
  .project-layouts {

    &__mini-form {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

@include respond('medium') {
  .project-layouts {
    &__inner {
      grid-row-gap: 30px;
      grid-template-rows: repeat(3, auto);
    }

    &__title {
      grid-column: 1/7;
    }

    &__room-form {
      grid-column: 1/5;
      grid-row: 2/3;
    }

    &__mini-form {
      grid-row: 2/3;
      grid-column: 5/7;
    }

    &__slider-wrapper {
      grid-column: 1/7;
      grid-row: 3/4;
    }
  }
}

@include respond-down('medium') {
  .project-layouts {

  }
}

@include respond-down('small') {
  .project-layouts {
    &__inner {
      grid-template-columns: 100%;
      grid-row-gap: 22px;
      grid-template-areas:  "title"
                          "room-form"
                          "slider"
                          "mini-form";
    }

    &__slider-wrapper {
    }
  }
}





