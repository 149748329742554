@use "sass:math";

.layout-gallery {
  background-color: var(--color-background);
  &._seller-gallery{
    background: #FFFFFF;
    padding-bottom: unset;
    .layout-gallery{
      &__title{
        padding-top: 80px;
      }
    }
  }
  &__title  {
    border-bottom: 1px solid var(--color-border);
    display: block;
  }

  &__image-block{
    display: none;
    &._active{
      display: block;
    }
  }

  &__links{
    margin-left: -15px;
  }

  &__image-item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .layout-gallery {
        &__picture {
          transform: translateX(100%);
        }

        &__image {
          transform: translateX(-100%);
        }
      }
    }

    &.fade-in {
      transform: translateX(0);
      z-index: 2;

      .layout-gallery {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          animation: animate-image .9s;
        }
      }
    }

    &.fade-out {
      transform: translateX(0);
      z-index: 1;

      .layout-gallery {
        &__picture {
          transform: translateX(0);
          transition: transform 1.3s;
        }

        &__image {
          transform: translateX(0);
          transition: transform 1.3s;
        }
      }
    }
  }

  &__image-wrap{
    position: relative;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .layout-gallery {
    padding-bottom: 120px;
    &__title{
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__links-block{
      padding-bottom: 38px;
    }

    &__picture{
      padding-bottom: math.div(556, 1194) * 100%;
    }

    &__arrows{
      top: -73px;
    }
  }
}

@include respond-up('medium') {
  .layout-gallery {
    .tns-nav{
      display: none;
    }
    &__images-wrapper{
      position: relative;
    }
    &__arrows{
      position: absolute;
      right: 0;
    }
  }
}

@include respond('medium') {
  .layout-gallery {
    padding-bottom: 70px;
    &__title{
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__links-block{
      padding-bottom: 21px;
    }

    &__picture{
      padding-bottom: math.div(321, 690) * 100%;
    }

    &__arrows{
      top: -55px;
    }
  }
}

@include respond-down('medium') {
  .layout-gallery {
    &__title{
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__links-block{
      padding-bottom: 20px;
    }

    &__picture{
      padding-bottom: math.div(195, 338) * 100%;
    }
  }
}

@include respond-down('small') {
  .layout-gallery {
    padding-bottom: 40px;
    &__arrows{
      display: none;
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}