.stripes {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    padding: 4px 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 125%;
    color: var(--color-gray-dark);
    background: var(--color-gray-blue);

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@include respond-up('large') {
  .stripes {
  }
}

@include respond-down('medium') {
  .stripes {
  }
}
