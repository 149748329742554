.hero-project {
  position: relative;
  height: calc((var(--vh, 1vh) * 100) - var(--header-height));
  overflow: hidden;

  &__info-wrap {
    position: relative;
  }

  &__items {
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(0.3deg, rgba(0, 9, 33, 0.68) 3.87%, rgba(3, 13, 44, 0.06) 99.74%);
  }

  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 15;

    &._close {
      .hero-project {
        &__info-item {
          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              .hero-project {
                &__info-value-name {
                  transition-delay: calc(.2s + (#{$i} * .1s));
                }

                &__info-title-name {
                  transition-delay: calc(.4s + (#{$i} * .1s));
                }
              }
            }
          }
        }

        &__type-name,
        &__title-name,
        //&__info-value
        &__info-title-name {
          transform: translate3d(0, 100%, 0);
          transition-delay: 0s;
        }

        &__title-name {
          transition-delay: .2s;
        }

        &__close {
          pointer-events: auto;
          opacity: 1;
          transition-delay: .9s;
        }

        &__close-icon {
          transform: rotate(45deg);
          transition-delay: .9s;
        }

        &__close-title-name {
          display: block;
          transform: translate3d(0, 0, 0);
          transition-delay: 1.1s;
        }
      }
    }
  }

  &__type-name,
  &__title-name {
    transition: transform .4s;
    transition-delay: .7s;
  }

  &__close {
    pointer-events: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: absolute;
    color: #fff;
    opacity: 0;
    transition: opacity .4s;
  }

  &__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-right: 6px;
    transition: transform .4s;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__close-title {
    display: flex;
    flex: 0 1 auto;
    overflow: hidden;
  }

  &__close-title-name {
    display: flex;
    transform: translate3d(0, 100%, 0);
    transition: transform .4s;
  }

  &__type,
  &__title {
    color: #fff;
    overflow: hidden;
  }

  &__controls-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__strip {
    height: 1px;
    width: 160px;
    background-color: var(--color-white-opacity-35);
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: 5px;
  }

  &__strip-filling {
    background-color: #fff;
    height: 100%;
    transition: width .9s;
  }

  &__arrows {
    color: #fff;
    flex: 0 1 auto;

    svg {
      path {
        stroke: #fff;
      }
    }

    .arrows {
      &__current-index,
      &__counter-all {
        width: 18px;
      }
    }
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    flex: 0 0 100vw;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: calc(100vh - var(--header-height));
    transition: width 1.5s ease;
  }

  &__item {
    transition: transform 1.5s ease;

    &._animate-normal-next {
      transform: translateX(50%);

      .hero-project__item-inner {
        width: 100%;
      }
    }

    &._animate-in-next {
      transform: translateX(0);
      z-index: 1;

      .hero-project__item-inner {
        width: 100%;
      }
    }

    &._animate-out-next {
      z-index: 2;
      transform: translateX(-50%);

      .hero-project__item-inner {
        width: 50%;
      }
    }

    &._animate-normal-prev {
      transform: translateX(-50%);

      .hero-project__item-inner {
        width: 50%;
      }
    }

    &._animate-in-prev {
      transform: translateX(0);
      z-index: 2;

      .hero-project__item-inner {
        width: 100%;
      }
    }

    &._animate-out-prev {
      z-index: 1;
      transform: translateX(50%);

      .hero-project__item-inner {
        width: 100%;
      }
    }
  }

  &__info-items {
    display: flex;
    align-items: center;
    //margin: -9px;
  }

  &__info-item {
    align-self: flex-end;
    padding: 9px;
    flex: 0 0 130px;

    &:first-child {
      padding-left: 0;
      flex: 0 0 121px;
    }
  }

  &__info-value-name,
  &__info-title-name {
    transition: transform .4s;
  }

  &__info-item {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        .hero-project {
          &__info-value-name {
            transition-delay: calc(.7s + (#{$i} * .1s));
          }

          &__info-title-name {
            transition-delay: calc(.9s + (#{$i} * .1s));
          }
        }
      }
    }
  }

  &__info-value {
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
  }

  &__info-title {
    overflow: hidden;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.25em;
    color: var(--color-white-opacity-50);
  }
}

@include respond-up('large') {
  .hero-project {
    &__wrapper {
      right: 154px;
      bottom: 80px;
      left: 154px;
      align-items: flex-end;
    }

    &__strip {
      margin-right: 77px;
    }

    &__close {
      bottom: 0;
      left: 0;
    }

    &__info-items-wrap {
      margin-top: 54px;
    }
  }
}

@include respond-up('medium') {
  .hero-project {
    &__info-value {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__info-title {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .hero-project {
    &__wrapper {
      bottom: 56px;
    }

    &__controls-wrap {
      margin-top: 50px;
    }

    &__info-items-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-down('medium') {
  .hero-project {
    &__wrapper {
      right: #{$spacer-small}px;
      left: #{$spacer-small}px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__controls-wrap {
      width: 100%;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .hero-project {
    &__wrapper {
      bottom: 44px;
    }

    &__controls-wrap {
      margin-top: 40px;
    }

    &__info-items-wrap {
      margin: 25px -#{$spacer-small}px 0;
    }

    &__info-value {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__info-title {
      margin-top: 6px;
    }

    &__info-items {
      padding: 0 #{$spacer-small}px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}