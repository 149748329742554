.seller-flat-filter {
  .container_spacer {
    padding: 0;
  }

  &__bottom {
    padding: 50px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bottom-sort {
    width: 200px;
    flex: 0 0 200px;

    label.custom-select__iconable-field {
      margin: 0;
    }
  }

  &__bottom-counter {
    color: var(--primary-color);
    font-weight: 500;
  }

  &__bottom-view {
    width: 200px;
    flex: 0 0 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .filter-fields__field_section {
    display: none;

    .filter-fields__input {
      width: max-content;
    }

    &._visible {
      display: block;
    }
  }
}

@include respond-up('large') {
  .seller-flat-filter {
    &_flats {
      .seller-flat-filter {
        &__fields {
          grid-template-columns: 198px 198px auto 198px;
          grid-template-areas:
            "project address rooms rooms"
            "floor renovation date action"
            "price payment section section"
            "reserved sold . .";
        }
      }


      .filter-form {
        &__tags {
          margin-top: unset;
        }

        &__top-inner {
          position: relative;
        }

        &__buttons {
          padding: 15px 0 15px;
        }
      }
    }
  }
}

@include respond('medium') {
  .seller-flat-filter {
    &__fields {
      grid-template-areas:
        "project project project address address address"
        "rooms rooms rooms rooms rooms rooms"
        "floor floor floor renovation renovation renovation"
        "date date action action price price"
        "payment payment section section section section"
        "reserved reserved sold sold . .";
    }

    .checkbox-list {
      padding-top: unset;
    }
  }
}

@include respond-down('medium') {
  .seller-flat-filter {
    padding: 0 var(--grid-spacer);

    .filter-fields__field {
      &_sold {
        margin-left: unset;
      }
    }

    .filter-fields_catalog {
      .filter-fields__field {
        &_renovation, &_floor {
          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            transition-delay: .2s;
          }
        }
        &_date, &_action, &_price, {
          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            transition-delay: .15s;
          }
        }
        &_payment,
        &_section {
          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            transition-delay: .1s;
          }
        }
      }
    }

    .filter-form__top-inner {
      &._opened {
        .filter-fields_catalog {
          .filter-fields__field {
            &_renovation, &_floor {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .2s;
              }
            }
            &_date, &_action, &_price {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .3s;
              }
            }

            &_payment, &_section {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .4s;
              }
            }

            &_sold, &_reserved {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .5s;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .seller-flat-filter {
    //overflow: hidden;

    &__fields {
      margin-top: 20px;
    }

    &__bottom {
      flex-wrap: wrap;
      padding: 20px 0;
    }

    &__bottom-sort,
    &__bottom-counter,
    &__bottom-view {
      margin: 10px 0;
    }

    &__bottom-view {
      flex: 0 0 auto;
      width: max-content;
    }

    .filter-form {
      &__top {
        display: block;
        position: static;
        overflow: visible;
      }

      &__mobile-header {
        padding-bottom: unset;
      }

      &__closer{
        display: none;
      }
    }

    .filter-fields__field {
      &_reserved,
      &_sold {
        margin: 8px 0 0;
        order: 30;
      }
    }
  }
}