.renovation-plate-modal{
  &__wrap{
    background: white;
    box-shadow: 0 20px 40px rgba(170, 170, 170, 0.25);
    display: flex;
    flex-direction: column;
  }

  &__image-wrap{
    position: relative;
    padding-bottom: 227px;
  }

  &__picture{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__text-wrap{
    padding: 20px 25px 25px;
  }

  &__main-title{
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
    color: var(--color-primary-dark);
    margin-bottom: 8px;
  }

  &__main-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
    mix-blend-mode: normal;
  }

  &__advantages-wrap{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }

  &__advantage-top{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  &__advantage-number{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 125%;
    color: #E3E9FC;
    mix-blend-mode: normal;
    margin-right: 10px;
  }

  &__advantage-title{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-primary-dark);
    mix-blend-mode: normal;
  }

  &__advantage-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
    mix-blend-mode: normal;
  }
}