.drop-menu {
  box-shadow: 0 12px 40px rgba(125, 131, 146, 0.21);
  background: white;
  position: relative;
  height: 0;
  overflow: hidden;

  transition: {
    property: height;
    duration: .5s;
    timing-function: ease-in-out;
  };

  &__wrap {
    z-index: 1000;
  }

  &__wrapper,
  &__wrap {
    right: 0;
    left: 0;
    position: absolute;
    height: max-content;
  }

  &__additional-link {
    color: var(--color-gray-dark);
  }

  &__block,
  &__menu-wrapper {
    display: flex;
  }

  &__inner-link,
  &__additional-link {
    display: block;
    padding: 7px;
    margin: -7px;
  }

  &__main-item {
    &._hovered {
      .drop-menu {
        &__main-item-link {
          color: var(--primary-color);

          &:before {
            width: 100%;
          }
        }

        &__main-item-link-icon {
          display: initial;
        }
      }
    }

    &._shadow {
      .drop-menu {
        &__main-item-link {
          color: var(--color-border);
          border-color: var(--color-border);
        }
      }
    }
  }

  &__main-item-link {
    font-size: var(--h3-size);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    transition: color, border-color var(--default-transition-duration);
    cursor: pointer;
  }

  &__main-item-link-icon {
    height: 20px;
    width: 20px;
    display: none;

    svg {
      path {
        fill: var(--primary-color);
        stroke: var(--primary-color);
      }
    }
  }

  &__additional-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__additional-item {
    margin-bottom: 13px;
  }

  &__anniversary-link{
    padding: 10px 18px;
    background-color: var(--primary-color);
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}

@include respond-up('large') {
  .drop-menu {
    --h3-size: 26px;

    &__block {
      padding: 0 30px 0 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    &__menu-wrapper {
      flex-direction: row;
    }

    &__main {
      border-right: 1px solid var(--color-border);
      padding: 50px 0 70px;
    }

    &__additional {
      padding: 70px 0 70px 60px;
    }

    &__main-list {
      position: relative;
      padding-right: 390px;
    }

    &__main-item {
      &:hover {
        pointer-events: auto;

        .drop-menu {
          &__inner-item {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
            transition: transform .3s, opacity .3s;
          }

          &__inner-list {
            pointer-events: auto;
            opacity: 1;
            transition: opacity 0s;
          }

          &__inner {
            pointer-events: auto;
            z-index: 20;
          }
        }
      }
    }

    &__main-item-link {
      padding: 20px 0;
      border-bottom: 1px solid var(--color-border);
      width: 275px;
      pointer-events: none;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background-color: var(--primary-color);
        position: absolute;
        bottom: -1px;
        left: 0;
        margin: auto;
        transition: width .35s;
      }
    }

    &__inner-list {
      position: absolute;
      top: 0;
      padding-top: 20px;
      left: 275px;
      padding-left: 127px;
      padding-right: 28px;
      pointer-events: none;

      height: 100%;

      z-index: 10;

      &:hover {
        opacity: 1;
        pointer-events: auto;
        z-index: 20;
      }
    }

    &__inner-item {
      width: 233px;
      display: flex;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-10px);
      transition: opacity .2s, transform .2s .25s;
      margin-bottom: 14px;
    }

    &__additional-list {
      grid-column-gap: 64px;
    }

    &__additional-link {
      transition: color var(--default-transition-duration);

      &:hover {
        color: var(--color-primary-dark);
      }
    }

    &__inner-link {
      transition: color var(--default-transition-duration);

      &:hover {
        color: var(--primary-color);
      }
    }

    &__wrap {
      top: 60px;
    }

    &__wrapper {
      bottom: 0;
    }

    &__anniversary-link{
      display: none;
    }
  }
}

@include respond-up('medium') {
  .drop-menu {
    &__main-item-link {
      line-height: 125%;
    }

    &__catalog-button-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .drop-menu {
    --h3-size: 23px;

    &__block {
      padding: 65px 40px 45px 155px;
    }

    &__main {
      margin-bottom: 50px;
    }

    &__main-item-link {
      padding: 15px 0;
    }

    &__inner-list {
      padding: 25px 0 18px;
    }

    &__additional-list {
      grid-column-gap: 111px;
    }

    &__wrap {
      top: 46px;
    }

    &__button-close {
    }

    &__anniversary-link{
      max-width: 124px;
      margin-top: 50px;
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    overflow: auto;

    &__menu-wrapper {
      flex-direction: column;
      width: 100%;
    }

    &__inner {
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: .5s;
        timing-function: ease-in-out;
      };
    }

    &__wrapper {
      top: 0;
      height: 100vh;
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    --h3-size: 20px;

    &__block {
      padding: 8px 20px 40px;
    }

    &__main-item-link {
      line-height: 135%;
      padding: 12px 0;
    }

    &__additional-list {
      grid-column-gap: 15px;
    }

    &__main-list {
      margin-bottom: 40px;
    }

    &__wrapper {
      padding-top: 50px;
    }

    &__inner-list {
      padding-top: 25px;
      padding-bottom: 30px;
    }

    &__anniversary-link{
      margin-top: 40px;
      max-width: 118px;
      font-size: 15px;
    }

    &__catalog-button-wrap {
      margin-top: 30px;
    }

    &__catalog-button {
      width: 100%;
    }

    &__catalog-button-icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
