.seller-hero-slider {
  .hero-project {
    &__strip {
      display: none;
    }
  }
}

@include respond-up('large') {
  .seller-hero-slider {
    .hero-project {
      height: 100vh;

      &__item,
      &__item-inner,
      &__picture {
        height: 100vh;
      }

      &__wrapper {
        margin: 0 auto;
        max-width: var(--max-row-width);
        right: 50px;
      }

      &__info-wrap {
        margin-right: var(--grid-column-gap);
      }
    }
  }
}

@include respond-down('medium') {
  .seller-hero-slider {
    .hero-project {
      margin-top: var(--top-height);
      height: calc(100vh - var(--top-height));

      &__item,
      &__item-inner,
      &__picture {
        height: calc(100vh - var(--top-height));
      }
    }
  }
}