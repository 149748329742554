.share_article {
  .ya-share2 {
    &__item {
      .ya-share2 {
        &__link {
          border-radius: 50%;
          border: 1px solid transparent;
          transition: border-color 0.3s;

          .ya-share2 {
            &__badge {
              background-color: transparent;
            }

            &__icon {
              //filter: invert(1) invert(78%) sepia(6%) saturate(702%) hue-rotate(185deg) brightness(92%) contrast(89%);
            }
          }
        }
      }
    }

    .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
      height: 34px;
      width: 34px;
      //background-size: 23px 23px;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .ya-share2__container_size_m {
      .ya-share2__item_service {
        &_vkontakte {
          .ya-share2__badge {
            .ya-share2__icon {
              background-image: url("../images/svg/icons/icons-vk.svg");
            }
          }
        }

        &_odnoklassniki {
          .ya-share2__badge {
            .ya-share2__icon {
              background-image: url("../images/svg/icons/icons-ok.svg");
            }
          }
        }

        &_whatsapp {
          .ya-share2__badge {
            .ya-share2__icon {
              background-image: url("../images/svg/icons/icons-WhatsApp.svg");
            }
          }
        }

        &_telegram {
          .ya-share2__badge {
            .ya-share2__icon {
              background-image: url("../images/svg/icons/icons-telegram.svg");
            }
          }
        }
      }
    }

    &__list {
      .ya-share2 {
        &__item {
          &:hover {
            opacity: 1;
            fill: var(--color-gray-dark);

            .ya-share2 {
              &__link {
                border-color: var(--color-gray);

                .ya-share2 {
                  &__icon {
                    filter: invert(1) invert(45%) sepia(7%) saturate(1720%) hue-rotate(188deg) brightness(94%) contrast(81%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}