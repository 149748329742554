@use "sass:math";

.customer-compilation-description {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__label {
    display: inline-flex;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    color: var(--primary-color);
    background-color: rgba(191, 202, 235, 0.4);
    padding: 5px 0 5px 8px;
    margin-bottom: 30px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border: 4px solid rgba(191, 202, 235, 0.4);
      right: -8px;
      bottom: 0;
      border-right-color: var(--t);
      border-bottom-color: var(--t);
    }

    &::after {
      content: '';
      position: absolute;
      right: -8px;
      top: 0;
      width: 8px;
      height: calc(100% - 8px);
      background-color: rgba(191, 202, 235, 0.4);
    }
  }

  &__title {
    color: var(--color-primary-dark);

    &_small {
      font-weight: 500;
      line-height: 135%;
    }

    &_big {
      font-weight: bold;
      font-size: 36px;
      line-height: 125%;
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 135%;
    color: var(--color-primary-dark);
    padding-bottom: 100px;
  }

  &__bottom-wrapper,
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bottom {
    border-top: 1px solid var(--color-border);
    width: 100%;
    padding-top: 12px;
  }

  &__count {
    font-size: 15px;
    line-height: 135%;
    color: var(--color-gray-dark);
  }

  &__price {
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    color: var(--color-primary-dark);
    padding-left: 18px;
    margin-left: 18px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50%);
      transform: translate(0, -50%, 0);
      width: 1px;
      height: 15px;
      background-color: var(--color-border);
    }
  }

  &__date {
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }

  &__picture {
    display: block;
    position: relative;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@include respond-up('large') {
  .customer-compilation-description {
    padding: 30px 35px;

    &__subtitle {
      padding-bottom: 100px;
    }

    &__top{
      padding-bottom: 30px;
    }

    &__part {
      flex: 0 0 auto;
      max-width: 550px;
    }

    &__date {
      text-align: right;
    }

    &__picture {
      width: 203px;
      height: 97px;
    }

    &__title {
      &_small {
        font-size: 19px;
        margin-bottom: 55px;
      }
    }
  }
}

@include respond-down('medium') {
  .customer-compilation-description {
    padding: 15px 14px;

    &__subtitle {
      padding-bottom: 70px;
    }

    &__top {
      flex-wrap: wrap;
    }

    &__part {
      flex: 0 0 100%;
    }

    &__bottom {
      flex-wrap: wrap;
    }

    &__date {
      flex: 0 0 100%;
      margin-top: 12px;
    }

    &__picture {
      width: 100%;
      padding-bottom: math.div(97, 203) * 100%;
    }

    &__title {
      &_small {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }
}