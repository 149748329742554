.seller-payment {
  &__calc {
    .payment-calc__item {
      &:nth-child(3) {
        display: none;
      }
    }
  }
  &__project-list {
    //padding: 0;
    .projects-list__items-wrap {
      //margin: 0;
    }
  }
  &__documents {
    .payment-documents {
      &__button-wrap {
        display: none;
      }
      &__item {
        &:nth-child(1) {
          background-color: white;
        }
      }
    }
  }
  &__sizes {
    padding: 0;
    .payment-sizes {
      &__title {
        display: none;
      }
      &__item {
        padding: 30px;
        background-color: white;
        border: none;
        margin-bottom: 6px;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__list {
        margin-top: 0;
      }
    }
  }
}

@include respond-up('large') {
  .seller-payment {
    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 125%;
    }
    &__steps {
      h2 {
        font-weight: bold;
        font-size: 26px;
        line-height: 125%;
      }
    }
    &__calc, &__banks {
      padding-top: 80px;
      padding-bottom: 0;
    }
    &__documents {
      padding-top: 80px;
      grid-template-areas: 'title title'
                         'items items';
      padding-bottom: 0;
      .payment-documents {
        &__title-wrap {
          margin-bottom: 30px;
        }
        &__items-wrap {
          justify-content: flex-start;
        }
      }
    }
    &__calc {
      .payment-calc {
        &__inner {
          grid-template-columns: repeat(3, 1fr);
          grid-template-areas: 'values-wrap values-wrap numbers-wrap'
                           'values-wrap values-wrap numbers-wrap';
        }
        &__numbers-item {
          flex: 0 0 100%;
        }
        &__item {
          &:nth-child(2) {
            padding-left: 53px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .seller-payment {
    &__faq-title {
      padding-top: 90px;
      margin-bottom: 30px;
    }
  }
}

@include respond('medium') {
  .seller-payment {
    &__faq-container {
      padding: 0 var(--grid-spacer) 90px;
    }
  }
}

@include respond-down('medium') {
  .seller-payment {
    &__project-list {
      padding: 90px var(--grid-spacer) 0;
      .projects-list__button {
        margin: 35px var(--grid-spacer);
      }
    }
    &__documents {
      .payment-documents {
        &__title-wrap {
          max-width: 100%;
          padding: 0 var(--grid-spacer);
        }
      }
    }
  }
}

@include respond-down('small') {
  .seller-payment {
    &__faq-title {
      margin-bottom: 20px;
    }
    &__documents {
      overflow: hidden;
    }
    &__faq-container {
      padding: 50px var(--grid-spacer) 50px;
    }
  }
}