:root {
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  $allInputs: 'input[type="text"], input[type="search"], input[type="password"], select, textarea';

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    display: block;
    color: var(--color-gray-dark);
    margin-bottom: 8px;
  }

  ul.errors {
    margin-top: 6px;
    list-style: none;
    font-size: 12px;
  }

  &.fixed-errors-form {
    ul.errors {
      position: absolute;

      li {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }
  }

  .form-field {
    position: relative;
  }

  #{$allInputs} {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    font-size: var(--placeholder-font-size);
    line-height: 150%;
    padding: var(--input-padding);
    background: var(--t);
    border: 1px solid var(--color-border);
    color: var(--color-primary-dark);
    transition: color, border-color var(--default-transition-duration);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  .checkbox-list, .checkbox-single {
    label {
      cursor: pointer;
      font-size: 15px;
      line-height: 135%;
      color: var(--color-primary-dark);
      position: relative;
      padding-left: 28px;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 1px;
        height: 17px;
        width: 17px;
        top: calc(50% - 9px);
        border: 1px solid var(--color-gray);
      }

      &::before {
        background-color: white;
      }

      &::after {
        background: {
          color: var(--primary-color);
          repeat: no-repeat;
          position: center;
          size: 11px;
          image: url("/static/images/svg/check.svg");
        };
        opacity: 0;
        transition: {
          property: opacity;
          duration: .3s;
        };
      }
    }

    input {
      display: none;

      &:checked + label {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .input-wrapper {
    position: relative;

    #{$allInputs} {
      &.invalid {
        border: 1px solid var(--color-red);
      }
    }

    &_grey-border {
      #{$allInputs} {
        background: var(--t);
        border: 1px solid var(--color-border);
        color: var(--color-primary-dark);

        &:focus {
          border-color: var(--color-primary-dark);
        }

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px var(--color-background) inset;
        }
      }
    }

    &_white {
      #{$allInputs} {
        background: white;
        border: none;
        color: var(--color-primary-dark);

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px white inset;
          -webkit-text-fill-color: var(--color-primary-dark);
        }
      }

      input::placeholder {
        color: var(--color-primary-dark);
      }
    }

    &_grey {
      #{$allInputs} {
        background: var(--color-background);
        border: none;
        color: var(--color-primary-dark);

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px white inset;
          -webkit-text-fill-color: var(--color-primary-dark);
        }
      }

      input::placeholder {
        color: var(--color-gray-dark);
      }
    }

    &_opacity {
      #{$allInputs} {
        background: var(--color-white-opacity-30);
        border: none;
        color: white;

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px #5878DB inset;
          -webkit-text-fill-color: white;
        }
      }

      input::placeholder {
        color: white;
      }
    }

    &_white-border {
      #{$allInputs} {
        color: white;
        background: var(--t);
        border: 1px solid var(--color-white-opacity-50);

        &:focus {
          border-color: white;
        }

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px var(--primary-color) inset;
          -webkit-text-fill-color: white;
        }
      }

      input::placeholder {
        color: white;
      }

      label, ul.errors {
        color: var(--color-white-opacity-50);
      }
    }

    &_dark-bg {
      #{$allInputs} {
        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px var(--color-primary-dark) inset;
        }
      }
    }

    &__pwd-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 54px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;

      &_show {
        display: none;
      }

      &_hide {
        display: block;
      }

      &._show {
        .input-wrapper {
          &__pwd-icon {
            &_show {
              display: block;
            }

            &_hide {
              display: none;
            }
          }
        }
      }
    }

    &__submit-button {
      position: absolute;
      right: 0;
      bottom: 0;

      .button {
        border: none;
        background: var(--t);
      }

      svg {
        path {
          fill: var(--color-border);
        }
      }
    }

    &_disabled {
      label, #{$allInputs} {
        border-color: var(--color-border);
        color: var(--color-border);
      }

      .range-input {
        .range-input {
          &__line {
            .noUi-connect, .noUi-handle {
              background-color: var(--color-border);
            }
          }
        }
      }
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-image: url('../images/svg/icons/arrow-down-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 15px;
      width: 10px;
      height: 8px;
    }
  }
}

@include respond-up('large') {
  :root {
    --input-padding: 15px;
    --placeholder-font-size: 16px;
  }
}

@include respond-up('medium') {
  :root {
    --default-input-height: 54px;
  }
}

@include respond-down('medium') {
  :root {
    --input-padding: 12px;
    --placeholder-font-size: 15px;
  }
}

@include respond-down('small') {
  :root {
    --default-input-height: 44px;
  }
}
