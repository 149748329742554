.customer-empty {
  border-top: 1px solid var(--color-border);

  &__main {

  }

  &__text {

  }

  &__title {

  }

  &__description {
    color: var(--color-gray-dark);
  }

  &__main-action {

  }

  &__connect-us {
    color: var(--color-gray-dark);
    margin-bottom: 20px;
  }

  &__connect-us-link {
    font-weight: 500;
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .customer-empty {
    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__connect-us {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .customer-empty {
    padding-top: 40px;

    &__title {
      margin-bottom: 10px;
    }
  }
}

@include respond('medium') {
  .customer-empty {
    &__title {

    }

    &__text {
      margin-bottom: 20px;
    }

    &__connect-us {
      margin-top: 70px;
    }
  }
}

@include respond-down('small') {
  .customer-empty {
    padding-top: 20px;

    &__title {
      margin-bottom: 8px;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__connect-us {
      margin-top: 40px;
    }
  }
}