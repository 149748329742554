.checkbox-switch {
  input {
    display: none;
  }

  label {
    position: relative;
    padding-left: 52px;
    min-height: 20px;
    cursor: pointer;

    &:before, &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 37px;
      height: 20px;
      content: '';
      transition: opacity 0.2s;
    }

    &:before {
      background-image: url('../images/svg/switch-off.svg');
    }

    &:after {
      background-image: url('../images/svg/switch-on.svg');
    }
  }

  label {
    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
    }
  }

  input:checked + label {
    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0;
    }
  }
}