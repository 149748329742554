.payment {
  &__tabs, &__faq-container {
    background-color: var(--color-background);
  }
}

@include respond-up('large') {
  .payment {

    &__points {
      padding-top: 120px;
    }

    &__faq-container {
      padding-top: 100px;
      margin-top: 100px;
    }

    &__tabs {
      padding: 0 var(--large-indent);
      position: relative;
    }
  }
}

@include respond-up('medium') {
  .payment {

  }
}

@include respond('medium') {
  .payment {

    &__points {
      padding-top: 44px;
    }

    &__faq-container {
      padding-top: 70px;
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .payment {
    &__faq-container {
      .faq-page__fb-block {
        display: none;
      }
    }

  }
}

@include respond-down('small') {
  .payment {
    &__points {
      padding-top: 40px;
    }

    &__faq-container {
      padding-top: 30px;
      margin-top: 30px;
    }
  }
}