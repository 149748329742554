h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
}

h1, h2, h3, .h1, .h2, .h3 {
  font-weight: bold;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-weight: 500;
}

h1, .h1 {
  line-height: 115%;
}

h2, .h2 {
  line-height: 125%;
}

h4, .h4 {
  line-height: 135%;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: 55px;
  }

  h2, .h2 {
    --h-size: 36px;
  }

  h3, .h3 {
    --h-size: 26px;
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h6, .h6 {
    --h-size: 17px;
  }
}

@include respond-up('medium') {
  h3, .h3 {
    line-height: 125%;
  }

  h5, h6, .h5, .h6 {
    line-height: 135%;
  }

  h5, .h5 {
    --h-size: 19px;
  }
}

@include respond('medium') {
  h1, .h1 {
    --h-size: 36px;
  }

  h2, .h2 {
    --h-size: 30px;
  }

  h3, .h3 {
    --h-size: 23px;
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down('small') {
  h1, .h1 {
    --h-size: 26px;
  }

  h2, .h2 {
    --h-size: 22px;
  }

  h3, .h3 {
    --h-size: 20px;
    line-height: 135%;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, h6, .h5, .h6 {
    --h-size: 16px;
    line-height: 140%;
  }
}
