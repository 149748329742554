// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.projects-list {
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    .project-card {
      &__picture {
        padding-top: 70%;
      }
    }
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .projects-list {
    padding-top: 120px;

    &__title {
      grid-column: 1/7;
    }

    &__description {
      grid-column: 7/13;
    }

    &__items-wrap {
      margin-top: 50px;
    }

    &__items {
      grid-column-gap: 54px;
    }

    &__item {
      //max-width: 364px;
      //.project-card {
      //  &__picture {
      //    width: 364px;
      //    height: 254px;
      //  }
      //}
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .projects-list {
    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .projects-list {
    padding-top: 90px;

    &__title,
    &__description {
      grid-column: 1/7;
    }

    &__description {
      margin-top: 16px;
    }

    &__items-wrap {
      margin: 30px -#{$spacer-medium}px 0;
    }

    &__items {
      padding: 0 #{$spacer-medium}px;
      grid-column-gap: 30px;
      grid-template-columns: repeat(3, 316px) calc(#{$spacer-medium}px - 30px);
    }

    //&__item {
    //  .project-card {
    //    &__picture {
    //      width: 316px;
    //      height: 220px;
    //    }
    //  }
    //
    //  &:last-child {
    //    padding-right: #{$spacer-medium}px;
    //  }
    //}

    &__button {
      margin-top: 35px;
    }
  }
}

@include respond-down('medium') {
  .projects-list {
    &__items {
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      &::after {
        content: '';
        position: relative;
      }
    }
  }
}

@include respond-down('small') {
  .projects-list {
    padding-top: 40px;

    &__title,
    &__description {
      grid-column: 1/5;
    }

    &__items-wrap {
      margin: 22px -#{$spacer-small}px 0;
    }

    &__items {
      grid-column-gap: 25px;
      padding: 0 #{$spacer-small}px;
      grid-template-columns: repeat(3, 306px) 1px;

      .project-card__picture {
        width: 100%;
      }

    }




    //&__item {
    //  .project-card {
    //    &__picture {
    //      width: 306px;
    //      height: 213px;
    //    }
    //  }
    //
    //  &:last-child {
    //    padding-right: #{$spacer-small}px;
    //  }
    //}

    &__button {
      margin-top: 30px;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 12px;
    }
  }
}