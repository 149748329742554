@use "sass:math";

.article-part {
  &__article {

  }

  &__images-wrapper {
    overflow: hidden;
  }

  &__image-list {
    overflow: hidden;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;

    &_slider {
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &__static-images-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__static-item {
    position: relative;
    height: fit-content;
  }

  &__static-picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  &__static-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .article-part {
        &__picture {
          transform: translateX(100%);
        }

        &__image {
          transform: translateX(-100%);
        }
      }
    }

    &.fade-in {
      transform: translateX(0);
      z-index: 2;

      .article-part {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          animation: animate-image .9s;
        }
      }
    }

    &.fade-out {
      transform: translateX(0);
      z-index: 1;

      .article-part {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }

  &__arrows {
    display: flex;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .article-part {
    &__arrows {
      margin: 33px 0 60px;
    }

    &__picture {
      padding-top: math.div(464, 816) * 100%;
      margin-bottom: 60px;

      &_slider {
        margin-top: 45px;
        margin-bottom: 0;
      }
    }

    &__static-images-wrapper {
      padding-top: 60px;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 56px;
    }

    &__static-item {
      &_first {
        padding-top: math.div(464, 380) * 100%;
      }

      &_second {
        padding-top: math.div(364, 380) * 100%;

        .article-part {
          &__static-picture {
            //height: calc(var(--grid-column4) * 364 / 380);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .article-part {

  }
}

@include respond('medium') {
  .article-part {
    &__picture {
      padding-top: math.div(392, 690) * 100%;
      margin-bottom: 50px;

      &_slider {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    &__arrows {
      margin: 33px 0 54px;
    }

    &__static-images-wrapper {
      padding-top: 30px;
      grid-column-gap: 24px;
    }

    &__static-item {
      &_first {
        padding-top: math.div(407, 333) * 100%;
      }

      &_second {
        padding-top: math.div(318, 333) * 100%;

        .article-part {
          &__static-picture {
            //height: calc(var(--grid-column3) * 318 / 333);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .article-part {

  }
}

@include respond-down('small') {
  .article-part {
    &__picture {
      padding-top: math.div(192, 338) * 100%;
      margin-bottom: 40px;

      &_slider {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    &__arrows {
      margin: 23px 0 44px;
    }

    &__static-images-wrapper {
      padding-top: 20px;
      grid-column-gap: 14px;
    }

    &__static-item {
      &_first {
        padding-top: math.div(198, 162) * 100%;
      }

      &_second {
        padding-top: math.div(154, 162) * 100%;

        .article-part {
          &__static-picture {
            //height: calc(var(--grid-column2) * 154 / 162);
          }
        }
      }
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}