.project-page {
  overflow: hidden;

  &__hero-wrap {
    position: relative;
  }

  &__breadcrumbs-wrap {
    position: absolute;
    z-index: 15;

    .breadcrumbs {
      &__item {
        color: #fff;
      }

      &__item_delimiter {
        color: var(--color-border);
      }
    }
  }

  &__about-project {
  }
}

@include respond-up('large') {
  .project-page {
    &__menu {
      height: 100px;
    }

    &__breadcrumbs-wrap {
      top: 86px;
      left: 154px;
    }

    &__about-project, &__advantages, &__layouts, &__genplan, &__progress {
      padding-top: 140px;
    }

    &__reviews {
      padding-top: 144px;
    }

    &__circles {
      padding-top: 100px;
    }

    &__news {
      padding-top: 137px;
    }

    &__renovations {
      &_simple {
        padding: 219px 0 100px;
      }

      &_hard {
        padding: 199px 0 70px;
      }
    }
  }
}

@include respond-up('medium') {
  .project-page {

  }
}

@include respond('medium') {
  .project-page {
    &__circles {
      padding-top: 53px;
    }

    &__menu {
      height: 90px;
    }

    &__breadcrumbs-wrap {
      top: 50px;
      left: #{$spacer-medium}px;
    }

    &__about-project, &__circles, &__advantages, &__layouts, &__genplan, &__progress {
      padding-top: 90px;
    }

    &__news {
      padding-top: 87px;
    }

    &__reviews {
      padding-top: 62px;
    }

    &__renovations {
      &_simple {
        padding: 146px 0 60px;
      }

      &_hard {
        padding: 146px 0 50px;
      }
    }
  }
}

@include respond-down('medium') {
  .project-page {

  }
}

@include respond-down('small') {
  .project-page {
    &__circles {
      padding-top: 40px;
    }

    &__menu {
      height: 59px;
    }

    &__breadcrumbs-wrap {
      top: 26px;
      left: #{$spacer-small}px;
    }

    &__news {
      padding-top: 40px;
    }

    &__about-project, &__circles, &__advantages, &__layouts, &__genplan, &__progress {
      padding-top: 40px;
    }

    &__reviews {
      padding-top: 30px;
    }

    &__renovations {
      &_simple {
        padding: 62px 0 25px;
      }

      &_hard {
        padding: 62px 0 40px;
      }
    }
  }
}