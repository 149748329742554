.tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  //position: absolute;
  //bottom: 15px;
  width: 100%;
  flex-wrap: wrap;

  button {
    background-color: var(--color-blue-gray);
    width: 9px;
    height: 9px;
    border-radius: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    transition: {
      property: background-color;
      duration: .3s;
    };

    &:not(:last-child) {
      margin-right: 15px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 21px;
      height: 21px;
      border-radius: 100%;
      z-index: 1;
      border: 1px solid var(--color-blue-gray);
      transform: scale(0);
      transition: {
        property:transform;
        duration: .3s;
      };
    }


    &.tns-nav-active {
      position: relative;
      background-color: var(--primary-color);

      &::before {
        transform: scale(1);
      }
    }
  }
}