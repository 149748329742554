.seller-auth {
  background: {
    color: var(--color-primary-dark);
    position: 50% 50%;
    size: cover;
    image: url('../images/auth/auth-background.jpg');
  }
  min-height: 100vh;

  &__phone {

  }

  &__form {
    background-color: #fff;
  }

  &__title {
    text-align: center;
    margin-bottom: 25px;
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tabs + &__description {
    margin-top: 25px;
  }

  &__description {
    color: var(--color-gray-dark);
    text-align: center;
  }

  &__input {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__policy {
    margin-top: 20px;
    font-size: 12px;
    color: var(--color-gray-dark);
    max-width: 380px;
  }

  &__policy-link {
    color: var(--color-primary-dark);
  }

  &__phone {
    text-align: center;
  }

  &__phone-description {
    color: var(--color-gray-dark);
    margin-bottom: 8px;
  }

  &__phone-value {
    color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 19px;
  }
}

@include respond-up('large') {
  .seller-auth {
    padding: 125px 0 160px;

    &__form {
      margin: 0 auto;
      max-width: 767px;
      padding: 70px 102px 70px;
    }

    &__actions{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .seller-auth {
    padding: 90px 0 200px;

    &__form {
      padding: 70px 95px 140px;
    }
  }
}

@include respond-up('medium') {
  .seller-auth {
    &__actions {
      margin-top: 35px;
    }

    &__top {
      margin-bottom: 40px;
    }

    &__actions {
      &_justify-space {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &>* {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include respond-down('small') {
  .seller-auth {
    padding: 17px 0 28px;

    &__form {
      padding: 30px 15px 160px;
    }

    &__top {
      margin-bottom: 25px;
    }

    &__actions {
      margin-top: 25px;
    }

    &__description {
      padding: 0 20px;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>* {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .button_filter-wrapper {
        width: 100%;
      }
    }

    &__phone-value {
      font-size: 16px;
    }
  }
}