.about-what-made-of {
  &__list {
    display: grid;
  }
}

@include respond-up('large') {
  .about-what-made-of {
    &__list {
      grid-column-gap: 28px;
    }
  }
}

@include respond-up('medium') {
  .about-what-made-of {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
    }
  }
}

@include respond('medium') {
  .about-what-made-of {
    &__list {
      grid-column-gap: 24px;
    }
  }
}

@include respond-down('medium') {
  .about-what-made-of {

  }
}

@include respond-down('small') {
  .about-what-made-of {
    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }
}