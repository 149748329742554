.customer-deals {
  &__tab-content {

  }
}

@include respond-up('medium') {
  .customer-deals {
    &__tabs {
      margin: 0 -18px;
    }

    &__tabs {
      padding-bottom: 30px;
    }
  }

}

@include respond-down('small') {
  .customer-deals {
    &__tabs {
      margin: 0 -12px;
    }

    &__tabs {
      padding-bottom: 20px;
    }
  }
}