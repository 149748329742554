:root {
  --modal-bg: rgba(3, 15, 50, 0.5);
  --modal-content-radius: 0;
  --modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27);
  --modal-content-padding: 100px;
  --modal-content-background: #fff;
  --modal-closer-color: rgba(3, 15, 50, 0.5);
  --modal-closer-size: 38px;
  --modal-preloader-size: 0;
}

.modal__bg.tour {
  --modal-content-padding: 30px;

  .modal__layout {
    padding: var(--modal-content-padding) 0;

    .modal__closer {
      top: 10px;
      right: 10px;
    }
  }
}

@include respond-down('medium') {
  .modal__bg.tour {
    .modal__container {
      padding: var(--modal-content-padding);
    }
  }
}

@include respond-down('small') {
  .modal__bg.tour {
    --modal-content-padding: 0px;

    .modal__content {
      padding: 0 35px;
    }
  }
}



.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  padding: var(--modal-content-padding);
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;

  &.popup {
    padding: 0;
  }
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  top: 36px;
  right: 36px;

  font-size: var(--modal-closer-size);
  color: var(--modal-closer-color);

  text-decoration: none;
  cursor: pointer;
  background: {
    image: url('../images/svg/modal-close.svg');
    position: 50% 50%;
    repeat: no-repeat;
  };
  width: 38px;
  height: 38px;
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: var(--color-primary-dark);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size) / -2);
      margin-top: calc(var(--preloader-size) / -2);
      background-color: #000;
      border-radius: calc(var(--preloader-size) / 2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--primary-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond('large') {
  .modal {
    &__container {
      &.popup {
        .ajax-form {
          width: auto;

          &__success {
            padding: 100px 140px 230px;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .modal {
    &__container {
      padding-left: 95px;
      padding-right: 95px;

      &.popup {
        .ajax-form {
          width: auto;

          &__success {
            padding: 100px 130px 225px;
          }
        }
      }
    }

    &__bg {
      &.popup {
        .modal__layout {
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .modal {
    &__layout {
      padding: 0;

      &.opened {
        display: block;
      }
    }

    &__container {
      padding: 110px 18px 30px;
      min-height: 100vh;

      &.popup {
        .ajax-form {
          width: auto;

          &__success {
            padding: 100px 60px 50px;
          }
        }
      }
    }

    &__closer {
      right: 18px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
