.blog-article {
  display: flex;
  flex-direction: column;

  &__picture {
    display: flex;
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    position: relative;
    display: block;
  }

  &__date-wrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__date {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary-dark);
    color: #fff;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: var(--color-primary-dark);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      border-color: var(--color-primary-dark) var(--color-primary-dark) transparent;
      border-style: solid;
    }
  }

  &__date-day {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--color-white-opacity-30);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2em;
  }

  &__date-month {
    font-style: normal;
    font-weight: normal;
    display: block;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2em;
    color: var(--color-gray-dark);
    flex: 0 0 auto;
  }

  &__title {
    display: block;
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;
    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }
}

@include respond-up('large') {
  .blog-article {
    &:hover {
      .blog-article {
        &__image {
          transform: scale(1.12);
        }

        &__icon {
          transform: translate3d(18px, -20px, 0);
          &_dub {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &__picture {
      width: 100%;
      height: 202px;
      overflow: hidden;
    }

    &__image {
      transition: transform 2s cubic-bezier(0,0,.2,1);
    }

    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }

    &__text-wrap {
      margin-top: 35px;
    }

    &__title {
      margin-top: 22px;
      font-size: 19px;
      line-height: 1.35em;
    }

    &__icon-wrap {
      margin-top: 38px;
    }
  }
}

@include respond-up('medium') {
  .blog-article {
    &__date {
      padding: 0 8px;
      width: 53px;
      height: 58px;

      &::before {
        bottom: -9px;
        width: 44px;
        height: 9px;
      }

      &::after {
        bottom: -9px;
        border-width: 0 0 9px 9px;
      }
    }

    &__date-day {
      padding: 6px 0 4px;
      font-size: 20px;
    }

    &__date-month {
      padding: 6px 0 0;
      font-size: 14px;
      line-height: 1.2em;
    }

    &__tags {
      margin: -6px;
    }

    &__tag {
      padding: 6px;
      //&:first-child {
      //  margin-right: 12px;
      //}
    }
  }
}

@include respond('medium') {
  .blog-article {
    max-width: calc((310 / 768) * 100vw);

    &__picture {
      width: calc((310 / 768) * 100vw);
      height: calc((175 / 768) * 100vw);
    }

    &__text-wrap {
      margin-top: 25px;
      font-size: 16px;
      line-height: 1.4em;
    }

    &__icon-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-down('medium') {
  .blog-article {
    &__title {
      margin-top: 15px;
    }
  }
}

@include respond-down('small') {
  .blog-article {
    max-width: calc((153 / 375) * 100vw);

    &__picture {
      width: calc((153 / 375) * 100vw);
      height: calc((87 / 375) * 100vw);
    }

    &__text-wrap {
      margin-top: 20px;
    }

    &__date {
      padding: 0 6px;
      width: 44px;
      height: 49px;

      &::before {
        bottom: -7px;
        width: 37px;
        height: 7px;
      }

      &::after {
        border-width: 0 0 7px 7px;
        bottom: -7px;
      }
    }

    &__date-day {
      padding: 5px 0 4px;
      font-size: 16px;
    }

    &__date-month {
      padding: 5px 0 0;
      font-size: 13px;
      line-height: 1.25em;
    }

    &__tags {
      flex-direction: column;
    }

    &__tag {
      &:first-child {
        margin-bottom: 4px;
       }
    }

    &__icon-wrap {
      margin-top: 20px;
    }
  }
}