.seller-project-reviews {
  &__list {

  }

  &__item {
    background-color: white;
    margin-bottom: 6px;
    padding: 30px;

    .review {
      &__before-content {
        margin-right: 56px;
      }

      &__content {
        flex: 1 1 auto;
      }

      &__content-wrap {
        justify-content: space-between;
        column-gap: unset;
      }
    }
  }
}

@include respond-up('large') {
  .seller-project-reviews {

  }
}

@include respond-up('medium') {
  .seller-project-reviews {

  }
}

@include respond('medium') {
  .seller-project-reviews {

  }
}

@include respond-down('medium') {
  .seller-project-reviews {
    padding: 49px var(--spacer);
  }
}

@include respond-down('small') {
  .seller-project-reviews {

  }
}