.actions-block {
  &__heading {
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
    padding-bottom: 8px;
  }

  &__list {

  }


  &__tags-wrapper {
    display: flex;
    margin: 0 -6px;
    color: var(--color-gray-dark);
    margin-bottom: 10px;
  }

  &__tags {
    padding: 0 6px;
  }

  &__item {
    padding: 22px 0;
    border-bottom: 1px solid var(--color-border);

    &:last-child {
      border-bottom: none;
    }
  }

  &__created-at {
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
    padding-bottom: 10px;
  }


  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-primary-dark);
  }
}
