.filter-form {
  &__top {
    background-color: var(--color-background);
  }

  &__label {
    font-size: 14px;
    line-height: 120%;
    color: var(--color-gray-dark);
    margin-bottom: 8px;
  }

  &__buttons {
    display: flex;
  }

  &__bottom-fields {
    display: flex;
    align-items: center;
  }

  &__bottom-field {
    &_sort {
      flex: 0 0 auto;
    }
  }
}

@include respond-up('large') {
  .filter-form {

    &__buttons {
      padding: 45px 0 55px;
    }

    &__accordion-button-wrapper {
      display: none;
    }

    &__bottom-fields {
      padding: 35px 0 59px;
    }

    &__view-icon-wrapper {
      margin-left: 120px;
    }

    &__tags {
      margin-top: 10px;
    }
  }
}

@include respond-up('medium') {
  .filter-form {

    &__button, &__mobile-header, &__opener {
      display: none;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;

      &_restore {
        position: relative;
        margin-left: 40px;

        &::before{
          content: '';
          position: absolute;
          left: -20px;
          background-color: var(--color-border);
          width: 1px;
          top: 0;
          bottom: 0;
        }
      }
    }

    &__link-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }

    &__link-text {
      font-size: 15px;
      line-height: 120%;
      color: var(--color-gray-dark);
    }


    &__middle-inner {
      position: relative;
    }

    &__total {
      background-color: var(--primary-color);
      width: 175px;
      height: 112px;
      position: absolute;
      top: -56px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    &__total-number {
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: white;
    }

    &__total-text {
      font-size: 16px;
      line-height: 150%;
      color: white;
    }
  }
}

@include respond('medium') {
  .filter-form {

    &__buttons {
      padding: 0 0 35px;
    }

    &__button {
      display: none;
    }

    &__accordionable {
      //overflow: hidden;
      height: 198px;
      transition: {
        property: height;
        duration: .5s;
        timing-function: ease-in-out;
      };
    }

    &__accordion-button-wrapper {
      flex: 0 0 100%;
      display: flex;
      padding-bottom: 32px;
    }

    &__tags {
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px);
      transition: {
        property: opacity, transform;
        duration: .3s;
      };
    }

    &__bottom-fields {
      justify-content: space-between;
      padding: 34px 0 50px;
    }

    &__view-icon-wrapper {
      margin-right: 221px;
    }
  }
}

@include respond-down('medium') {
  .filter-form {
    &__buttons {
      flex-wrap: wrap;
    }

    .filter-fields_catalog {
      .filter-fields__field {
        &_renovation, &_date, &_action, &_price, &_payment, &_floor, &_section, &_sold, &_reserved {
          pointer-events: none;

          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            opacity: 0;
            transform: translateY(20px);
            transition: opacity .3s, transform .3s;
          }
        }
        &_renovation, &_date, &_floor {
          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            transition-delay: .15s;
          }
        }
        &_action, &_price, &_payment {
          .filter-fields__label,
          .filter-fields__input,
          .custom-select,
          .range-input {
            transition-delay: .1s;
          }
        }
      }
    }

    &__top-inner {
      &._opened {
        .filter-form__tags {
          opacity: 1;
          pointer-events: all;
          transition-delay: .4s;
          transform: translateY(0);
        }
        .filter-fields_catalog {
          .filter-fields__field {
            &_renovation, &_date, &_floor, &_action, &_price, &_payment, &_section, &_sold, &_reserved {
              pointer-events: all;

              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                opacity: 1;
                transform: translateY(0);
              }
            }
            &_renovation, &_date, &_floor {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .2s;
              }
            }
            &_action, &_price, &_payment {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .3s;
              }
            }

            &_sold, &_reserved {
              .filter-fields__label,
              .filter-fields__input,
              .custom-select,
              .range-input {
                transition-delay: .4s;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .filter-form {

    &._open {
      .filter-form {
        &__top {
          display: block;
        }
      }
    }
    &__top {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding: 32px 0 23px;
      display: none;
      height: calc(var(--vh, 1vh) * 100);
    }

    &__buttons {
      width: 100%;
      padding-top: 30px;
    }

    &__link {
      &_restore {
        display: none;
      }

      &_reset {
        flex: 0 0 100%;
        margin-bottom: 8px;
        border: 1px solid var(--color-blue-gray);
        padding: 13px 0;
        justify-content: center;
        text-align: center;
      }
    }

    &__link-text {
      color: var(--primary-color);
      font-weight: 500;
    }


    &__button {
      flex: 0 0 100%;

      .button__link {
        align-items: center;
        flex: 0 0 100%;
        justify-content: center;
      }
    }

    &__count {
      margin: 0 3px;
    }

    &__mobile-header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 33px;
    }

    &__title {
      font-size: 22px;
      line-height: 125%;
      font-weight: bold;
      color: var(--color-primary-dark)
    }

    &__opener {
      background-color: var(--primary-color);
      border-radius: 100px;
      position: fixed;
      width: 65px;
      height: 65px;
      bottom: 20px;
      right: 20px;
      top: calc(50% - 33px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 400;
    }

    &__closer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 38px;
      width: 38px;
      height: 38px;
      background-color: white;
      border-radius: 100px;
      box-shadow: 0 4px 10px rgba(12, 44, 128, 0.12);

      svg {
        flex: 0 0 14px;
        width: 14px;
        height: 14px;
      }
    }

    &__bottom-fields {
      padding: 20px 0 30px;
    }

    &__accordion-button-wrapper {
      margin-bottom: 30px;
    }

    &__link-icon, &__view-icon-wrapper, &__total {
      display: none;
    }

    &__accordionable {
      height: 334px;
      transition: {
        property: height;
        duration: .5s;
        timing-function: ease-in-out;
      };
    }

    &__top-inner {
      overflow: hidden;
      &._opened {
        .filter-fields {
          &__field {
            &_area, &_floor, &_renovation, &_date, &_action, &_price, &_payment {
              pointer-events: auto;
              opacity: 1;
            }
          }
        }
      }
    }

    &__tags {
      pointer-events: none;
      opacity: 0;
      transition: opacity .4s;
    }

    .filter-fields {
      &__field {
        &_area, &_floor, &_renovation, &_date, &_action, &_price, &_payment {
          pointer-events: none;
          opacity: 0;
          transition: opacity .4s;
        }
      }
    }
  }
}