.cite {
   font-style: italic;
   font-size: 20px;
   line-height: 140%;
 }

@include respond-up('large') {
  .cite {
    font-weight: normal;
  }
}

@include respond-up('medium') {
  .cite {
    font-size: 20px;
  }
}

@include respond-down('medium') {
  .cite {
    font-weight: 500;
  }
}

@include respond-down('small') {
  .cite {
    font-size: 17px;
  }
}