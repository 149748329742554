@use "sass:math";

.seller-article-page {
  &__content {
    margin-top: 43px;
  }

  &__date {
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
    margin: 40px 0 20px;
  }

  &__image-wrapper {
    width: 100%;
  }

  &__picture {
    margin-bottom: 50px;
    width: 100%;
    position: relative;
    padding-bottom: math.div(444, 892) * 100%;
    display: block;
  }

  &__image {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__bottom {
    padding-top: 35px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      background-color: var(--color-border);
    }
  }
}

@include respond-up('large') {
  .seller-article-page {
    &__bottom {
      &:before {
        left: -30px;
        right: -30px;
      }
    }
  }
}

@include respond-up('medium') {
  .seller-article-page {

  }
}

@include respond('medium') {
  .seller-article-page {

  }
}

@include respond-down('medium') {
  .seller-article-page {

    &__return {
      padding: 59px 0 7px 40px;
    }
    &__bottom {
      &:before {
        left: calc(-1 * var(--grid-spacer));
        right: calc(-1 * var(--grid-spacer));
      }
    }
  }
}

@include respond-down('small') {
  .seller-article-page {

  }
}