.product-info-buttons {
  display: flex;

  &__icon {
    svg {
      path {
        transition: fill .4s;
        fill: var(--color-border);
      }
    }
  }

  &__items {
    display: flex;
    margin: 0 -8px;
  }

  &__item {
    padding: 0 8px;
  }

  &__link {
    display: block;
  }

  .ya-share2 {
    position: relative;

    .ya-share2__link_more {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      display: block;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }


  .ya-share2__list,
  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
    display: block;
  }

  &__link {
    &[data-compare="1"], &[data-favorites="1"] {
      svg path {
        fill: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .product-info-buttons {
    &__item {
      &:hover {
        .product-info-buttons {
          &__icon {
            svg {
              path {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product-info-buttons {

  }
}

@include respond('medium') {
  .product-info-buttons {

  }
}

@include respond-down('medium') {
  .product-info-buttons {

  }
}

@include respond-down('small') {
  .product-info-buttons {

  }
}


@media print {
  .product-info-buttons {
    display: none;
  }
}