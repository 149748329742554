.seller-layout {
  width: 100%;
  min-height: 100vh;
  position: relative;

  &__background {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
  }

  &__substrate {
    pointer-events: none;
    opacity: 0;
    z-index: 110;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    transition: opacity 0.3s;
  }

  &._menu-opened {
    .seller-layout__substrate {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &__menu-opener {
    display: none;
  }

  &__bar {
    padding-right: 20px;

    .seller-menu {
      position: relative;
      z-index: 110;
    }
  }

  // Для шаблонов с основного сайта
  .container,
  .container_spacer {
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

@include respond-up('large') {
  .seller-layout {
    &__container {
      margin: 0 auto;
      max-width: var(--max-row-width);
      padding: 50px 40px;
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: var(--grid-column12);
    }

    &__bar {
      display: none;
    }

    &__content-wrapper {
      position: relative;
      z-index: 100;
    }

    &__menu-opener {
      display: block;
      pointer-events: auto;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: all 0.3s;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 1000;
    }

    &._menu-opened {
      .seller-layout__container {
        grid-template-columns: var(--grid-column3) var(--grid-column9);
      }

      .seller-layout__bar {
        display: block;
      }

      .seller-layout__menu-opener {
        pointer-events: none;
        opacity: 0;
        transform: translate3d(-20px, 0, 0);
      }
    }

    &__substrate {
      display: none;
    }

    &__tablet-title {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .seller-layout {
    &__content {
      margin: 0 auto;
      max-width: 1480px;
      padding: var(--top-height) 0 0;
    }

    &__content-wrapper {
      position: relative;
      z-index: 100;
    }

    &__tablet-title {
      padding: 50px var(--grid-spacer) 40px;

      h1 {
        --h-size: 26px;
      }

      &:empty {
        display: none;
      }
    }

    .tabbed-page {
      &__head {
        overflow-x: scroll;
        
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

@media print {
  .seller-layout {
    &__menu-opener, &__bar, &__substrate {
      display: none;
    }

    &__container {
      padding: 0;
      grid-template-columns: var(--grid-column12);
    }
  }
}