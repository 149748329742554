.circles {
  &__text {
    color: var(--color-primary-dark);
  }

  &__list {
    grid-row-gap: var(--grid-column-gap);
  }

  &__item {
    display: flex;
    background: var(--color-grey-blue-grey);
  }

  &__image {
    width: 46px;
    height: 46px;

    svg {
      //width: 100%;
      //height: 100%;
    }
  }
}

@include respond-up('large') {
  .circles {
    &__item {
      padding: 38px 25px;
    }
  }
}

@include respond-up('medium') {
  .circles {
    &__item {
      grid-column: span 3;
      align-items: center;
    }

    &__text {
      padding-left: 20px;
    }
  }
}

@include respond('medium') {
  .circles {
    &__item {
      padding: 25px;
    }
  }
}

@include respond-down('small') {
  .circles {
    &__item {
      grid-column: span 2;
      flex-direction: column;
      padding: 15px;
    }

    &__text {
      margin-top: 12px;
    }
  }
}