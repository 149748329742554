.vacancies {
  &__list {
    border-top: 1px solid var(--color-border);
  }

  &__item {
    border-bottom: 1px solid var(--color-border);
  }

  &__salary {
    color: var(--primary-color);
  }

  &__description {
    color: var(--color-gray-dark);
  }

  &__bottom {
    color: var(--color-primary-dark);
    display: flex;
  }

  &__occupation {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: var(--color-primary-dark);
    }
  }

  &__date {
    color: var(--color-gray-dark);
  }
}

@include respond-up("large") {
  .vacancies {
    padding-bottom: 120px;

    &__list {
      margin-top: 80px;
    }

    &__link {
      padding: 40px 0;
    }

    &__name,
    &__description {
      grid-column: 1/10;
    }

    &__salary {
      grid-column: 10/13;
      text-align: right;
    }

    &__description {
      margin-top: 30px;
    }

    &__bottom {
      grid-column: 1/13;
      margin-top: 50px;
    }
  }
}

@include respond-up("medium") {
  .vacancies {
    &__bottom {
      flex-direction: row;
      justify-content: space-between;
    }

    &__occupation {
      padding-left: 20px;
      margin-left: 20px;
    }
  }
}

@include respond("medium") {
  .vacancies {
    padding-bottom: 90px;

    &__list {
      margin-top: 40px;
    }

    &__link {
      padding: 30px 0;
    }

    &__name,
    &__salary,
    &__description,
    &__bottom {
      grid-column: 1/7;
    }

    &__salary {
      margin-top: 20px;
    }

    &__description {
      margin-top: 30px;
    }

    &__bottom {
      margin-top: 40px;
    }
  }
}

@include respond-down("medium") {
  .vacancies {
  }
}

@include respond-down("small") {
  .vacancies {
    padding-bottom: 40px;

    &__list {
      margin-top: 30px;
    }

    &__link {
      padding: 20px 0;
    }

    &__name,
    &__salary,
    &__description,
    &__bottom {
      grid-column: 1/5;
    }

    &__salary {
      margin-top: 20px;
    }

    &__description {
      margin-top: 30px;
    }

    &__bottom {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 40px;
    }

    &__occupation {
      padding-left: 10px;
      margin-left: 10px;
    }

    &__date {
      margin-top: 12px;
    }
  }
}
