.requestcall-form {
  &__data,
  &__form-wrapper,
  &__button-wrapper {
    display: flex;
  }

  &__form-wrapper {
    flex-direction: column;

    .input-wrapper {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__policy {
    color: var(--color-white-opacity-50);
  }
}

@include respond-up('large') {
  .requestcall-form {
    &__description {
      flex: 1 0 30%;
      padding-right: 140px;
    }

    &__policy {
      padding-right: 75px;
    }
  }
}

@include respond-up('medium') {
  .requestcall-form {
    &__data,
    &__button-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }

    &__button-wrapper {
      align-items: flex-start;
    }
  }
}

@include respond('medium') {
  .requestcall-form {
    &__description {
      flex: 1 0 35%;
      padding-right: 60px;
    }

    &__policy {
      padding-right: 25px;
    }
  }
}

@include respond-down('small') {
  .requestcall-form {
    &__data,
    &__button-wrapper {
      flex-direction: column;
    }

    &__description {
      margin-bottom: 25px;
    }

    &__policy {
      margin-bottom: 20px;
    }

    &__button {
      display: flex;
      width: fit-content;
      align-self: flex-end;
    }
  }
}
