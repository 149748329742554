@use "sass:math";

.documents-page {
  overflow: hidden;
  position: relative;

  &__all-item {
    border-right: 1px solid var(--color-border);
    display: flex;
    &:nth-child(3n+1) {
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-border);
      }
    }
    &:nth-child(3n+3) {
      border-right: none;
    }
  }


  &__all-head {
    display: flex;
    align-items: center;
  }

  &__all-path {
    position: absolute;
    bottom: 6px;
    left: 11px;
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
  }

  &__all-image {
    margin-right: 20px;
    position: relative;
    width: 48px;
    height: 56px;
    background: {
      image: url('../images/svg/icons/file.svg');
      size: contain;
      repeat: no-repeat;
    };
  }

  &__name {
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    flex-grow: 1;
  }

  &__all-description {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }


  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__projects-description {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    padding-top: math.div(99, 102) * 100%;
    display: block;
    position: relative;
  }

  &__projects-item {
    border: 1px solid var(--color-border);
  }


  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__projects-wrapper {
    display: flex;
  }

  &__projects-image {
    margin-right: 20px;
  }

  &__icon {
    display: block;
    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }
}

@include respond-up('large') {
  .documents-page {
    &__all-documents {
      padding: 70px 0 140px;
    }

    &__all-list {
      grid-row-gap: 60px;
      grid-column-gap: 23px;
    }

    &__all-item {
      &:nth-child(3n+1) {
        grid-column: 1/5;
      }
      &:nth-child(3n+2) {
        grid-column: 5/9;
      }
      &:nth-child(3n+3) {
        grid-column: 9/13;
        margin-left: 10px;
      }
    }

    &__all-head {
      margin: 25px 0 40px;
    }

    &__all-description {
      max-width: 345px;
    }

    &__title {
      margin-bottom: 40px;
    }

    &__projects-list {
      grid-row-gap: 28px;
    }

    &__projects-item {
      &:nth-child(3n+1) {
        grid-column: 1/5;
      }
      &:nth-child(3n+2) {
        grid-column: 5/9;
      }
      &:nth-child(3n+3) {
        grid-column: 9/13;
      }
    }

    &__projects-wrapper {
      padding: 20px;
      display: flex;

      &:hover {
        .documents-page {
          &__image {
            transform: scale(1.12);
          }

          &__icon {
            transform: translate3d(18px, -20px, 0);
            &_dub {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    &__projects-image {
      margin-right: 20px;
      width: 102px;
    }

    &__image {
      transition: transform 2s cubic-bezier(0,0,.2,1);
    }

    &__picture {
      overflow: hidden;
      padding-top: math.div(99, 102) * 100%;
    }



    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }
  }
}
@include respond('medium') {
  .documents-page {

    &__all-list {
      grid-row-gap: 40px;
      padding: 40px 0 90px;
      overflow: hidden;

    }

    &__all-item {
      &:nth-child(2n+1) {
        grid-column: 1/4;
      }
      &:nth-child(2n+2) {
        grid-column: 4/7;
        border-right: none;
      }
      &:nth-child(2n+3) {
        border-right: 1px solid var(--color-border);
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-border);
        }
      }
    }

    &__all-head {
      margin: 20px 0 40px;

    }

    &__all-description {
      font-size: 15px;
      max-width: calc((290 / 768) * 100vw);
    }

    &__title {
      margin-bottom: 30px;
    }

    &__projects-list {
      grid-row-gap: 24px;
    }

    &__projects-item {
      &:nth-child(2n+1) {
        grid-column: 1/4;
      }
      &:nth-child(2n+2) {
        grid-column: 4/7;
      }
    }

    &__projects-wrapper {
      display: flex;
      padding: 18px;
    }

    &__projects-image {
      width: calc((102 / 768) * 100vw);
      margin-right: 25px;
    }
  }
}
@include respond-down('medium') {
  .documents-page {
    &__all-description {
      font-size: 15px;
    }
  }
}
@include respond-down('small') {
  .documents-page {
    &__all-documents {
      padding: 30px 0 40px;
    }

    &__all-item {
      grid-column: 1/5;
      border-right: none;
      padding: 20px 0;
      border-bottom: 1px solid var(--color-border);

      &:first-child {
        border-top: 1px solid var(--color-border);
      }

      &:before {
        display: none;
      }
    }

    &__all-head {
      margin-bottom: 20px;
    }

    &__name {
      font-size: 16px;
    }

    &__all-description {
      font-size: 15px;
    }

    &__projects-documents {
      padding-bottom: 40px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__projects-list {
      grid-row-gap: 10px;
    }

    &__projects-item {
      grid-column: 1/5;

    }

    &__projects-wrapper {
      padding: 12px;
    }

    &__projects-image {
      width: calc((102 / 375) * 100vw);
    }

  }
}