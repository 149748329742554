.renovation-slider {
  background-color: var(--color-background);

  &__item-top {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image-wrap {
    position: relative;
  }

  &__number {
    z-index: 10;
    display: flex;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    line-height: 1.25em;
    color: var(--color-gray-light);
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__item-description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__picture {
    display: flex;
    position: relative;
    padding-top: 59%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__items {
    display: flex;
  }

  &__item {
    display: flex;
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@include respond-up('large') {
  .renovation-slider {
    padding: 120px 0 137px;

    &__items {
      // Чтобы слайдер делал так как хочет дизайнер, приходится в тини делать centre: true,
      // в таком случае выбранный айтем находится по средине экрана,
      // и нужно двигать весь список на левую границу
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 198px);
    }

    &__item {
      //&:not(:last-child) {
      //  margin-right: 28px;
      //}
    }

    &__item-top {
      padding: 35px 30px 60px;
    }

    &__number {
      top: -28px;
      left: 30px;
    }

    &__item-description {
      //min-height: 72px;
    }

    &__picture {
      width: 380px;
      height: 224px;
    }
  }
}

@include respond-up('medium') {
  .renovation-slider {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__items-wrap {
      margin-top: 39px;
    }

    &__item-title {
      font-size: 17px;
      line-height: 1.35em;
    }

    &__item-description {
      font-size: 16px;
      line-height: 1.5em;
      margin-top: 15px;
    }

    &__number {
      font-size: 45px;
    }

    .tns-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .renovation-slider {
    padding: 37px 0 70px;

    &__title {
      max-width: calc((387 / 768) * 100vw);
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 175px) * -1);
    }

    &__item {
      //&:not(:last-child) {
      //  margin-right: 24px;
      //}
    }

    &__item-top {
      padding: 35px 25px 40px;
    }

    &__number {
      top: -25px;
      left: 25px;
    }

    &__item-description {
      //min-height: 96px;
    }

    &__picture {
      width: 333px;
      height: 196px;
    }
  }
}

@include respond-down('medium') {
  .renovation-slider {

  }
}

@include respond-down('small') {
  .renovation-slider {
    padding: 30px 0;

    &__items-wrap {
      margin-top: 22px;
    }

    &__items {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 119px) * -1);
    }

    &__item {
      //&:not(:last-child) {
      //  margin-right: 12px;
      //}
    }

    &__item-top {
      padding: 20px 20px 42px;
    }

    &__number {
      top: -22px;
      left: 20px;
      font-size: 35px;
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-description {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 10px;
      //min-height: 100px;
    }

    &__arrows {
      display: none;
    }

    &__picture {
      width: 221px;
      height: 130px;
    }

    .tns-nav {
      margin-top: 25px;
    }
  }
}