.project-info-block {
  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
  }

  &__logo {
    grid-area: logo;
  }

  &__description-wrap {
    grid-area: description;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__video-wrap {
    grid-area: video;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__video-wrap-inner {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &__video-link {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    transform: translate3d(-50%, -50%, 0);
    z-index: 10;
  }

  &__video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 54px;
    height: 54px;

    svg {
      z-index: 5;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 54px;
      height: 54px;
      background-color: var(--color-blue-gray);
      opacity: 0.4;
      border-radius: 100%;
    }
  }

  &__info {
    grid-area: info;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__image-wrap {
    grid-area: image-wrap;
  }

  &__preview-picture,
  &__logo-picture,
  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__logo-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left bottom;
  }

  &__picture {
    padding-top: 56%;
  }

  &__preview-picture {
    padding-top: 56%;
    width: 100%;
  }

  &__preview-image,
  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-title {
    font-style: normal;
    font-weight: 500;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
  }

  &__info-item-value {
    border-bottom: 1px solid var(--color-border);
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
  }

  &__info-item-title {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .project-info-block {
    padding-top: 98px;
    grid-template-rows: 100px 1fr;
    grid-template-areas: 'logo video'
                         'description video';

    &__logo-picture {
      width: 208px;
      height: 70px;
    }

    &__preview-picture {
      height: 271px;
    }

    &__logo,
    &__description-wrap {
      grid-column: 1/7;
    }

    &__video-wrap {
      grid-column: 8/13;
    }

    &__video-wrap-inner {
      height: 271px;
    }

    &__button {
      margin-top: 35px;
    }

    &_image {
      grid-template-areas: 'logo info'
                           'description info'
                           'video image-wrap';

      .project-info-block {
        &__preview-picture {
          height: 251px;
        }

        &__logo,
        &__description-wrap {
          grid-column: 1/5;
        }

        &__info {
          grid-column: 6/13;
          padding: 9px 0 0 36px;
          grid-row-gap: 89px;
          grid-column-gap: 95px;
        }

        &__image-wrap {
          grid-column: 6/13;
          padding: 140px 0 0 36px;
        }

        &__video-wrap {
          grid-column: 1/6;
          padding: 140px 36px 0 0;
        }

        &__picture {
          height: 364px;
          width: 100%;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-info-block {
    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__video-title {
      font-size: 16px;
      line-height: 1.5em;
      margin-top: 15px;
    }

    &__info-item-value {
      font-size: 22px;
      padding-bottom: 20px;
    }

    &__info-item-title {
      font-size: 16px;
      line-height: 1.5em;
      padding-top: 18px;
    }
  }
}

@include respond('medium') {
  .project-info-block {
    padding-top: 50px;
    grid-row-gap: 50px;
    grid-template-areas: 'logo description'
                         'logo video';

    &__button {
      margin-top: 28px;
    }

    &__logo {
      grid-column: 1/3;
    }

    &__description-wrap,
    &__video-wrap {
      grid-column: 3/7;
    }

    &__video-wrap-inner {
      height: 254px;
    }

    &__logo-picture {
      width: 170px;
      height: 57px;
    }

    &__preview-picture {
      height: 254px;
    }

    &_image {
      grid-template-areas: 'logo description'
                           'info info'
                           'video image-wrap';

      .project-info-block {
        &__preview-picture {
          height: 145px;
        }

        &__logo {
          grid-column: 1/3;
        }

        &__description-wrap {
          grid-column: 3/7;
        }

        &__info {
          grid-column: 1/7;
          padding-bottom: 20px;
          grid-row-gap: 81px;
          grid-column-gap: 60px;
        }

        &__video-wrap {
          grid-column: 1/4;
          padding-right: 75px;
        }

        &__image-wrap {
          grid-column: 4/7;
          margin-left: -41px;
        }

        &__picture {
          width: 100%;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .project-info-block {

  }
}

@include respond-down('small') {
  .project-info-block {
    padding-top: 30px;
    grid-row-gap: 20px;
    grid-template-areas: 'logo'
                         'description'
                         'video';

    &__description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__button {
      margin-top: 20px;
    }

    &__logo,
    &__video-wrap {
      grid-column: 1/5;
    }

    &__video-wrap-inner {
      height: 190px;
    }

    &__description-wrap {
      padding-bottom: 15px;
      grid-column: 1/5;
    }

    &__logo-picture {
      width: 126px;
      height: 41px;
    }

    &__preview-picture {
      height: 190px;
    }

    &__video-title {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 10px;
    }

    &__info-item-value {
      font-size: 18px;
      padding-bottom: 18px;
    }

    &__info-item-title {
      font-size: 15px;
      line-height: 1.35em;
      padding-top: 16px;
    }

    &_image {
      grid-template-areas: 'logo'
                           'description'
                           'info'
                           'video'
                           'image-wrap';

      .project-info-block {
        &__preview-picture {
          height: 141px;
        }

        &__logo {
          grid-column: 1/5;
        }

        &__description-wrap {
          grid-column: 1/5;
          padding-bottom: unset;
        }

        &__info {
          grid-column: 1/5;
          grid-column-gap: 27px;
          grid-row-gap: 30px;
          padding-top: 10px;
        }

        &__video-wrap {
          grid-column: 1/4;
          padding-top: 15px;
        }

        &__image-wrap {
          grid-column: 1/5;
          padding: 10px 0 0 37px;
        }

        &__picture {
          width: 100%;
        }
      }
    }
  }
}