.anniversary {
  position: relative;
  &_dark{
    background-color: var(--color-primary-dark);
  }
  &_light{
    background-color: var(--color-blue-dark);
    .anniversary{
      &__logo{
        display: none;
      }
    }
  }

  &__title{
    font-weight: 700;
    color: #fff;
  }

  &__description{
    font-style: normal;
    font-weight: 400;
    color: #fff;
    opacity: .7;
  }

  &__logo{
    position: absolute;
    right: -10px;
    bottom: -10px;
  }
}

@include respond-up('large') {
  .anniversary {

    &_dark{
      padding: 100px 0 140px 0;
    }
    &_light{
      padding: 120px 0 140px 0;
    }

    &__video-wrap{
      grid-column: 1 / 8;
    }

    &__text-wrap{
      grid-column: 8 / 13;
      margin-left: calc(96px - var(--grid-column-gap));
      padding-top: 30px;
    }

    &__title{
      font-size: 26px;
      padding-bottom: 30px;
    }

    &__description{
      max-width: 380px;
    }
  }
}

@include respond-up('medium') {
  .anniversary {
    &__title{
      line-height: 125%;
    }

    &__description{
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include respond('medium') {
  .anniversary {
    &_dark{
      padding: 50px 0 90px 0;
    }
    &_light{
      padding: 70px 0 90px 0;
    }

    &__video-wrap{
      grid-column: 1/ 5;
      padding-bottom: 45px;
    }

    &__text-wrap{
      grid-column: 1/ 5;
    }

    &__title{
      font-size: 23px;
      padding-bottom: 18px;
    }

    &__logo{
      svg{
        width: 222px;
        height: 229px;
      }
    }
  }
}

@include respond-down('medium') {
  .anniversary {

  }
}

@include respond-down('small') {
  .anniversary {
    &_dark{
      padding: 30px 0 90px 0;
    }
    &_light{
      padding: 40px 0 60px 0;
    }

    &__video-wrap{
      grid-column: 1/ 5;
      padding-bottom: 30px;
    }

    &__text-wrap{
      grid-column: 1/ 5;
    }

    &__title{
      font-size: 20px;
      line-height: 135%;
      padding-bottom: 17px;
    }

    &__description{
      font-size: 15px;
      line-height: 135%;
    }

    &__logo{
      svg{
        width: 114px;
        height: 115px;
      }
    }
  }
}