.seller-empty {
  padding-top: 100px;
  text-align: center;

  &__title {
    color: var(--color-gray);
    font-weight: 500;
    font-size: 45px;
  }

  &__description {
    margin-top: 30px;
    font-size: 20px;
  }
}