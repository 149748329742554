.content-header {
  background: var(--color-background);

  &__created-at {
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }

  &__tags-wrapper {
    display: flex;
    margin: 0 -6px;
    color: var(--color-gray-dark);
  }

  &__tags {
    padding: 0 6px;
  }

}

@include respond-up('large') {
  .content-header {

    padding-top: 100px;

    &__title {
      font-size: 36px;
    }

    h1 {
      padding-bottom: 80px;
    }

    h2 {
      padding-bottom: 50px;
    }

    &__created-at, &__tags-wrapper {
      padding-bottom: 35px;
    }
  }
}

@include respond('medium') {
  .content-header {
    padding-top: 50px;

    &__title {
      font-size: 30px;
    }

    h1 {
      padding-bottom: 45px;
    }

    h2 {
      padding-bottom: 40px;
    }

    &__created-at {
      padding-bottom: 45px;
    }


    &__tags-wrapper, &__tags-wrapper {
      padding-bottom: 45px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    padding-top: 25px;

    &__title {
      font-size: 22px;
    }

    h1 {
      padding-bottom: 30px;

      &.content-header__no-padding {
        padding-bottom: 0;
      }
    }

    h2 {
      padding-bottom: 30px;
    }

    &__created-at, &__tags-wrapper {
      padding-bottom: 30px;
    }
  }
}

@media print {
  .content-header {
    &_no-print {
      display: none;
    }
  }
}