.social-icon {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  margin-right: 4px;

  &:last-child{
    margin-right: 0;}

  &__icon {
    position: absolute;
    left: 0;
    width: 100%;
    transition: fill 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__ring {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-out, opacity 0.5s;
    opacity: 0.5;

    circle {
      fill: none;
      stroke: var(--color-gray);
      stroke-width: 7;
      stroke-dasharray: 565.486677646;
      stroke-dashoffset: 565.486677646;
      transition: stroke-dashoffset 0.3s linear;
    }

    &:hover {
      transform: rotate(-90deg) scale(1.1);
      opacity: 1;

      circle {
        stroke-width: 7;
        stroke-dashoffset: 0;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    svg{
      path{
        fill: var(--color-gray-dark);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    &._white{
      .social-icon{
        &:hover {
          svg{
            path{
              fill: white;
            }
          }
        }
      }
    }
  }
}
