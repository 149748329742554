.client-deals {
  &__list {
    background: white;
    padding: 30px;
  }

  &__deal-list {
    display: flex;
    flex-direction: column;
  }

  &__deal-item {
    background: white;
    padding: 0 30px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__deal-info {
    padding: 25px 0;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: row;
    grid-row-gap: 23px;
  }

  &__apartments {
    padding: 25px 0;
  }

  &__deal-item-top-wrapper,
  &__deal-item-top-left-wrapper,
  &__deal-item-link {
    display: flex;
    align-items: center;
  }

  &__deal-item-top-wrapper,
  &__deal-item-top-left-wrapper{
    justify-content: space-between;
  }

  &__deal-item-top-wrapper {
    grid-row: 1/2;
    grid-column: 1/3;
  }

  &__deal-item-type {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--color-gray-dark);
    position: relative;
    padding-right: 15px;
    margin-right: 15px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: var(--color-border);
    }
  }

  &__deal-item-date {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 135%;
    color: var(--color-gray-dark);
  }

  &__deal-item-status {
    background: var(--color-blue-gray-opacity-40);
    padding: 5px 7px;
    color: var(--color-gray-dark);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
  }

  &__deal-name-link {
    padding: 10px;
    margin: -10px;
    display: block;
  }

  &__deal-item-price {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 135%;
    color: var(--color-primary-dark);
    grid-column: 1/2;
  }

  &__deal-item-link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--primary-color);
    padding: 10px;
    margin: -10px;
    justify-content: flex-end;
  }

  &__deal-item-link-arrow {
    margin-left: 10px;
  }

  &__show-profile {
    //margin-top: 20px;
    padding-bottom: 50px;
    //border-top: 1px solid var(--color-border);
  }

  &__show-profile-row {
    display: flex;
    padding-top: 15px;
  }

  &__show-profile-text {
    color: var(--color-gray-dark);
    font-size: 15px;
    line-height: 135%;
    margin-right: 4px;
  }

  &__show-profile-value {
    color: var(--color-primary-dark);
    font-size: 15px;
    line-height: 135%;
  }
}

@include respond-up('medium') {
  .client-deals {
    &__tabs {
      padding-bottom: 37px;
    }
  }
}

@include respond-down('medium') {
  .client-deals {
    padding: 0 var(--grid-spacer);
    padding-top: 30px;
  }
}

@include respond-down('small') {
  .client-deals {
    &__tabs {
      padding-bottom: 20px;
    }
  }
}
