.about-buildings {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__fields {
    display: flex;
  }
}

@include respond-up('large') {
  .about-buildings {
    &__list {
      grid-row-gap: 37px;
    }
  }
}

@include respond-up('medium') {
  .about-buildings {
    &__fields {
      align-items: center;
      justify-content: space-between;
    }

    &__list {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .about-buildings {
    &__list {
      grid-row-gap: 25px;
    }
  }
}

@include respond-down('small') {
  .about-buildings {
    &__list {
      grid-row-gap: 20px;
    }

    &__fields {
      flex-direction: column-reverse;
    }

    &__list {
      margin-top: 30px;
    }

    &__field_sort {
      margin-top: 25px;
    }
  }
}