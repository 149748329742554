// друг, помни, этот блок есть в личном кабинете, а так же на основном сайте
// меняй вдумчиво, или спроси Антона О. что, где, почем, да прибудет с тобой сила!
.project-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  position: relative;

  &__link {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-border);
    z-index: 5;
    transition: opacity cubic-bezier(0,0,.2,1);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--color-primary-dark);
    z-index: 10;
    transition: width cubic-bezier(0,0,.2,1);
  }

  &__picture {
    display: flex;
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__text-wrap-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text-wrap-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__flats-count,
  &__flats-price {
    flex: 0 0 auto;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__type-icon {
    width: 7px;
    height: 7px;
    margin-right: 10px;
    flex: 0 0 auto;

    &_blue {
      background-color: var(--color-blue);
    }

    &_red {
      background-color: var(--color-orange);
    }

    &_green {
      background-color: var(--color-green);
    }
  }

  &__type-name {
    flex: 0 0 auto;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
    color: var(--color-gray-dark);
  }

  &__flats-count {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    padding-right: 19px;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
      width: 1px;
      height: 15px;
      background-color: var(--color-border);
    }
  }

  &__flats-price {
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
    padding-left: 18px;
  }

  &__top {
    position: relative;
  }

  &__tags-list {
    position: absolute;
    display: flex;
    align-items: center;
    margin: -2px;
  }

  &__tags-item {
    padding: 2px;
    display: flex;

    &_blue {
      .project-card {
        &__tags-item-name {
          background-color: var(--primary-color);
          color: #fff;
        }

        &__tags-item-corner {
          &::before {
            background-color: var(--primary-color);
          }
          &::after {
            border-color: var(--primary-color) var(--primary-color) transparent;
          }
        }
      }
    }
  }

  &__tags-item-name {
    padding: 5px 0 5px 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25em;
    background-color: #fff;
    color: var(--primary-color);
  }

  &__tags-item-corner {
    display: block;
    width: 7px;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      width: 7px;
      height: 19px;
      background-color: #fff;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 2px;
      border-color: #fff #fff transparent;
      border-style: solid;
      border-width: 0 0 7px 7px;
    }
  }
}

@include respond-up('large') {
  .project-card {
    &:hover {
      &::before {
        opacity: 0;
        transition-duration: .2s;
        transition-delay: .2s;
      }

      &::after {
        width: 100%;
        transition-duration: .5s;
        transition-delay: .7s;
      }

      .project-card {
        &__image {
          transform: scale(1.12);
        }
      }
    }

    &__picture {
      overflow: hidden;
    }

    &__image {
      transition: transform 2s cubic-bezier(0,0,.2,1);
    }

    &__text-wrap {
      margin-top: 19px;
    }

    &__text-wrap-bottom {
      margin-top: 19px;
    }

    &__title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__type-name {
      font-size: 14px;
    }

    &__tags-list {
      position: absolute;
      display: flex;
      align-items: center;
    }
  }
}

@include respond-up('medium') {
  .project-card {
    &__flats-count,
    &__flats-price {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__tags-list {
      left: 10px;
      top: 10px;
    }
  }
}

@include respond('medium') {
  .project-card {
    &__text-wrap-bottom {
      margin-top: 14px;
    }

    &__text-wrap {
      margin-top: 10px;
    }
  }
}

@include respond-down('medium') {
  .project-card {
    &__title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__type-name {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .project-card {
    &__text-wrap-bottom {
      margin-top: 12px;
    }

    &__text-wrap {
      margin-top: 8px;
    }

    &__flats-count,
    &__flats-price {
      font-size: 14px;
      line-height: 1.25em;
    }

    &__picture {
      padding-top: 70%;
      width: calc(100vw - (#{$spacer-small}px * 2));
    }

    &__tags-list {
      left: 8px;
      top: 8px;
    }
  }
}