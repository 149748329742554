.review-empty {
  &__content-wrap{
    display: flex;
  }

  &__rounds-container{
    display: flex;
  }

  &__round {
    border-radius: 100px;
    overflow: hidden;

    &_quotes{
      background-color: var(--color-primary-dark);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__empty-image-container{
    background-color: var(--color-blue-gray);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content{
    width: 100%;
  }

  &__content-head{
    border-bottom: 1px solid var(--color-border);
  }

  &__content-name{
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark)
  }
}

@include respond-up('large') {
  .review-empty {
    padding-top: 53px;
    &__content-wrap{
      column-gap: 254px;
    }

    &__before-content{
      flex: 0 0 154px;
    }

    &__content{
      flex: 0 0 788px;
    }

    &__content-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .review-empty {
    &__round{
      width: 77px;
      height: 77px;
    }

    &__content-name{
      font-size: 19px;
      line-height: 135%;
    }
  }
}

@include respond('medium') {
  .review-empty {
    padding-top: 30px;
    &__content-wrap{
      column-gap: 84px;
    }

    &__content-head{
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
}


@include respond-down('small') {
  .review-empty {
    padding-top: 22px;
    &__content-wrap{
      flex-direction: column;
    }

    &__rounds-container{
      padding-bottom: 20px;
    }

    &__round{
      width: 54px;
      height: 54px;
    }

    &__content-head{
      padding-bottom: 18px;
      margin-bottom: 20px;
    }

    &__content-name{
      font-size: 16px;
      line-height: 140%;
    }
  }
}