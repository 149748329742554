.background-slider {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: absolute;

  &__slides {

  }

  &__slide {

  }

  &__image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}