.projects {
  position: relative;

  &_page {
    .projects {
      &__item {
        grid-column: span 1;
      }
    }
  }

  &__list {
    position: relative;
  }

  &__item {
    display: flex;
  }
}

@include respond-up('large') {
  .projects {
    &_page {
      .projects {
        &__list {
          grid-template-columns: repeat(3, 1fr);
        }

        &__item {
          .project-card__picture {
            padding-top: 70%;
            width: 364px;
            height: 254px;
          }

          &:nth-child(3n+1) {
            padding-right: 16px;
          }

          &:nth-child(3n+2) {
            padding: 0 8px;
          }

          &:nth-child(3n) {
            padding-left: 16px;
          }
        }
      }
    }

    &_index {
      .projects {
        &__item {
          .project-card__picture {
            padding-top: 70%;
            width: 364px;
            height: 254px;
          }

          &:nth-child(10n+2),
          &:nth-child(10n+6) {
            padding-top: 43%;
          }

          &:nth-child(10n+1) {
            grid-column: 1/9;
            padding-right: 8px;

            .project-card__picture {
              padding-top: 54%;
              width: 780px;
              height: 420px;
            }
          }

          &:nth-child(10n+7) {
            grid-column: 5/13;
            padding-left: 8px;

            .project-card__picture {
              padding-top: 54%;
              width: 780px;
              height: 420px;
            }
          }

          &:nth-child(10n+2),
          &:nth-child(10n+5),
          &:nth-child(10n) {
            grid-column: 9/13;
            padding-left: 16px;
          }

          &:nth-child(10n+3),
          &:nth-child(10n+6),
          &:nth-child(10n+8) {
            grid-column: 1/5;
            padding-right: 16px;
          }

          &:nth-child(10n+4),
          &:nth-child(10n+9) {
            grid-column: 5/9;
            padding: 0 8px;
          }
        }
      }
    }

    &__list {
      grid-row-gap: 85px;
    }
  }
}

@include respond('medium') {
  .projects {
    &_page {
      .projects {
        &__list {
          grid-template-columns: repeat(2, 1fr);
        }

        &__item {
          &:nth-child(2n) {
            justify-content: flex-end;
          }
        }
      }
    }

    &_index {
      .projects {
        &__item {
          &:nth-child(12n+1),
          &:nth-child(12n+3),
          &:nth-child(12n+5),
          &:nth-child(12n+8),
          &:nth-child(12n+10) {
            grid-column: 1/4;
          }

          &:nth-child(12n+2),
          &:nth-child(12n+4),
          &:nth-child(12n+6),
          &:nth-child(12n+9),
          &:nth-child(12n+11) {
            grid-column: 4/7;
            justify-content: flex-end;
          }

          &:nth-child(12n+7),
          &:nth-child(12n) {
            grid-column: 1/7;

            .project-card__picture {
              padding-top: 54%;
              width: calc(100vw - calc(#{$spacer-medium}px * 2));
            }
          }
        }
      }
    }

    &__list {
      grid-row-gap: 60px;
    }

    &__item {
      .project-card__picture {
        padding-top: 70%;
        width: calc(50vw - #{$spacer-medium}px - 29px);
      }
    }
  }
}

@include respond-down('small') {
  .projects {
    &__list {
      grid-row-gap: 43px;
      grid-template-columns: 1fr;
    }
  }
}