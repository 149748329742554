.switchable-item-content {
  transition: height .4s;
  overflow: hidden;
  height: 0;

  &__item-title {
    display: none;
    grid-area: title;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 75%;
  }

  &__picture-panoramic {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 56%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__panoramic-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-area: panorama;
  }

  &__panoramic-wrap-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__panoramic-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__panoramic-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    position: relative;

    svg {
      z-index: 10;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 54px;
      height: 54px;
      background-color: var(--color-blue-gray);
      opacity: 0.35;
      border-radius: 100%;
      z-index: 5;
    }
  }

  &__panoramic-title {
    font-style: normal;
    font-weight: 500;
  }

  &__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: image-wrap;
  }

  &__text-wrap {
    grid-area: description;
  }

  &__slider {
    grid-area: slider;
  }
}

@include respond-up('large') {
  .switchable-item-content {
    &__item-title {
      grid-column: 3/6;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
    }

    &__inner {
      padding: 0 0 140px;
    }

    &__slider {
      grid-column: 3/13;
    }

    &__text-wrap {
      grid-column: 3/9;
      margin-top: 40px;
    }

    &__panoramic-wrap {
      grid-column: 3/7;
      margin-top: 100px;
    }

    &__panoramic-wrap-inner {
      width: 382px;
      height: 212px;
    }

    &__image-wrap {
      grid-column: 7/13;
      padding-left: 74px;
      margin-top: 100px;
    }

    &__picture {
      width: 513px;
      height: 386px;
    }

    &__picture-panoramic {
      width: 382px;
      height: 212px;
    }

    &__title {
      font-size: 19px;
      line-height: 1.25em;
    }

    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__panoramic-title {
      margin-top: 12px;
    }
  }
}

@include respond-up('medium') {
  .switchable-item-content {
    &__inner {
      grid-template-areas: 'slider slider'
                           'description description'
                           'panorama image-wrap';
    }

    &__description {
      margin-top: 18px;
    }

    &__panoramic-title {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .switchable-item-content {
    &__inner {
      padding: 0 0 90px;
    }

    &__text-wrap {
      margin-top: 30px;
    }

    &__slider {
      grid-column: 1/7;
    }

    &__panoramic-wrap {
      grid-column: 1/4;
      margin-top: 60px;
      padding-right: 39px;
    }

    &__panoramic-wrap-inner {
      width: calc((294 / 768) * 100vw);
      height: calc((165 / 768) * 100vw);
    }

    &__image-wrap {
      grid-column: 4/7;
      margin-top: 60px;
    }

    &__picture-panoramic {
      width: calc((294 / 768) * 100vw);
      height: calc((165 / 768) * 100vw);
    }

    &__picture {
      width: calc((333 / 768) * 100vw);
      height: calc((253 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .switchable-item-content {
    &__text-wrap {
      grid-column: 1/5;
    }

    &__title {
      font-size: 18px;
      line-height: 1.35em;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__panoramic-title {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .switchable-item-content {
    &__inner {
      padding: 0 0 40px;
      grid-template-areas: 'slider'
                           'description'
                           'panorama'
                           'image-wrap';
    }

    &__slider {
      grid-column: 1/5;
    }

    &__image-wrap {
      grid-column: 1/5;
      padding-left: 37px;
      margin-top: 30px;
    }

    &__panoramic-wrap {
      grid-column: 1/4;
      margin-top: 40px;
    }

    &__panoramic-wrap-inner {
      width: calc((250 / 375) * 100vw);
      height: calc((140 / 375) * 100vw);
    }

    &__text-wrap {
      margin-top: 25px;
    }

    &__description {
      margin-top: 15px;
    }

    &__panoramic-title {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__picture-panoramic {
      width: calc((250 / 375) * 100vw);
      height: calc((140 / 375) * 100vw);
    }

    &__picture {
      width: calc((301 / 375) * 100vw);
      height: calc((229 / 375) * 100vw);
    }
  }
}