body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  color: var(--color-primary-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &._hidden {
    overflow: hidden;
    height: 100%;
  }
}

input, button, form {
  font-family: var(--font);
}