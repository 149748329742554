.cross {
  width: 50px;
  height: 60px;
  position: relative;
  cursor: pointer;

  &:after,
  &:before {
    position: absolute;
    content: "";
    background: var(--color-border);
    transition: transform var(--default-transition-duration);
    height: 2px;
    right: 0;
    top: 28px;
  }

  &:after {
    transform: rotate(
        -45deg
    );
  }

  &:before {
    transform: rotate(
        45deg
    );
  }

  &__inner {
    &:after,
    &:before {
      position: absolute;
      content: "";
      background: var(--color-border);
      transition: transform var(--default-transition-duration);
      height: 2px;
      right: 0;
      top: 28px;
    }

    &:after {
      transform: rotate(
          45deg
      );
    }

    &:before {
      transform: rotate(
          -45deg
      );
    }
  }

  &:hover {
    &:after {
      transform: translate(-2px, 2px) rotate(
          -45deg
      );
    }

    &:before {
      transform: translate(2px, 2px) rotate(
          45deg
      );
    }

    .cross {

      &__inner {
        &:after {
          transform: translate(-2px, -2px) rotate(
              45deg
          );
        }

        &:before {
          transform: translate(2px, -2px) rotate(
              -45deg
          );
        }
      }
    }
  }
}

@include respond-up('medium') {
  .cross {
    &:after,
    &:before {
      width: 26px;
      left: 11px;
    }

    &__inner {
      &:after,
      &:before {
        width: 26px;
        left: 11px;
      }
    }
  }
}

@include respond-down('small') {
  .cross {
    &:after,
    &:before {
      width: 22px;
      left: 14px;
    }

    &__inner {
      &:after,
      &:before {
        width: 22px;
        left: 14px;
      }
    }
  }
}