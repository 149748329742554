.index-filter-form {
  background-color: var(--color-background);

  &__button {
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &__button-square {
    background-color: var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@include respond-up('large') {
  .index-filter-form {
    display: grid;
    grid-template-columns: auto 153px;

    &__fields {
      display: grid;
      grid-template-columns: auto auto;
      padding: 40px 28px 40px 35px;
    }
  }
}

@include respond-up('medium') {
  .index-filter-form {
    &__fields {
      margin: 0 -8px;
    }

    &__field-part {
      display: grid;

      &_first {
        grid-template-columns: auto 1fr;
      }

      &_second {
        grid-template-columns: 198px 198px;
      }
    }

    &__field {
      margin: 0 8px;

      &_rooms {
        display: grid;
        grid-template-columns: auto auto;
      }
    }

    &__euro-block {
      margin-left: 8px;
      .index-filter-form__label{
        opacity: 0;
      }
    }

    &__button-text {
      display: none;
    }

    &__button-square {
      height: 100%;
      width: 100%;
    }

    &__button-icon {
      flex: 0 0 54px;
      height: 54px;
      width: 54px;
    }
  }
}

@include respond('medium') {
  .index-filter-form {
    position: relative;

    &__fields {
      padding: 35px;
    }

    &__field-part {
      &_first {
        margin-bottom: 41px;
      }
    }

    &__button {
      position: absolute;
      height: 140px;
      width: 140px;
      right: 35px;
      bottom: -27px;
    }
  }
}

@include respond-down('small') {
  .index-filter-form {
    &__button {
      height: 64px;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: var(--color-background);
    }

    &__button-square {
      flex: 0 0 74px;
    }

    &__button-text {
      flex: 1 1 auto;
      padding-left: 20px;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: var(--color-primary-dark);
    }

    &__fields {
      display: none;
    }

    &__button-icon {
      flex: 0 0 44px;
      height: 44px;
      width: 44px;
    }
  }
}
