.common-form {
  color: white;

  &__heading {
    border-bottom: 1px solid white;
    font-style: normal;
    font-weight: bold;
    line-height: 125%;
  }

  &__form-wrapper {
    flex: 1 0 40%;
  }
}

@include respond-up('large') {
  .common-form {
    padding: 60px 75px 70px;

    &__heading {
      font-size: 36px;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__inputs-wrapper {
      margin-bottom: 35px;
    }
  }
}

@include respond('medium') {
  .common-form {
    padding: 35px 30px 55px;
    &__heading {
      font-size: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__inputs-wrapper {
      margin-bottom: 25px;
    }
  }
}

@include respond-down('small') {
  .common-form {
    padding: 30px 25px 40px;

    &__heading {
      font-size: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__inputs-wrapper {
      margin-bottom: 25px;
    }
  }
}
