.about-page {

  &__tabs-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__about-block {
    .about-block__head {
      border-top: none;
    }
  }

  &__press {
    background-color: var(--color-background);
  }

  &__tabs {
    border-top: 1px solid var(--color-border);
  }

  &__content-item{
    &:nth-child(2){
      border-top: 1px solid var(--color-border);
    }
  }
}

@include respond-up('large') {
  .about-page {
    padding-top: 40px;

    &__content-item {
      padding: 90px 0 120px;
    }

    &__press {
      padding: 113px 0 120px;
      margin-top: 127px;
    }

    &__tabs {
      padding-top: 40px;
      margin-top: 40px;
    }

    &__our-values-slider-block {
      margin-top: 120px;
    }

    &__about-awards, &__press {
      padding-bottom: 120px;
    }

    &__content {
      margin-top: 80px;

      &_buildings,
      &_what-made-of {
        padding-bottom: 120px;
      }
    }
  }
}

@include respond('medium') {
  .about-page {
    padding-top: 40px;

    &__content-item {
      padding-top: 50px;
      &:first-child{
        padding-bottom: 30px;
      }
    }

    &__press {
      padding: 68px 0 70px;
      margin-top: 72px;
    }

    &__tabs {
      padding-top: 30px;
      margin-top: 40px;
    }

    &__develop-new-territory {
      //margin-top: 120px;
    }

    &__our-values-slider-block {
      margin-top: 101px;
    }

    &__about-awards, &__press {
      padding-bottom: 90px;
    }

    &__content {
      margin-top: 50px;

      &_buildings,
      &_what-made-of {
        padding-bottom: 90px;
      }
    }
  }
}

@include respond-down('small') {
  .about-page {
    padding-top: 30px;

    &__content-list{
      padding-top: 40px;
    }

    &__content-item {
      //padding-top: 10px;
      &:first-child{
        padding-bottom: 20px;
      }
    }

    &__press {
      padding: 30px 0 40px;
      margin-top: 30px;
    }

    &__tabs {
      padding-top: 20px;
      margin-top: 30px;
    }

    &__our-values-slider-block {
      margin-top: 30px;
    }

    &__about-awards, &__press {
      padding-bottom: 40px;
    }

    &__content {
      margin-top: 35px;

      &_buildings,
      &_what-made-of {
        padding-bottom: 50px;
      }
    }
  }
}