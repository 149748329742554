.date-badge {
  display: block;
  &__date-bg {
    background-color: var(--color-primary-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: var(--color-primary-dark);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      border-color: var(--color-primary-dark) var(--color-primary-dark) transparent;
      border-style: solid;
    }
  }

  &__created-at-day {
    line-height: 120%;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  &__created-at-month {
    color: white;
    line-height: 120%;
  }
}

@include respond-up('large') {
  .date-badge {
    &__date-bg {
      padding: 7px 9px;
    }
    &__created-at-day {
      font-size: 20px;
    }
    &__created-at-month {
      font-size: 14px;
    }
  }
}

@include respond-up('medium') {
  .date-badge {
    &__date-bg {
      padding: 0 8px;
      width: 61px;
      height: 65px;

      &::before {
        bottom: -9px;
        width: 52px;
        height: 9px;
      }

      &::after {
        bottom: -9px;
        border-width: 0 0 9px 9px;
      }
    }
    &__created-at-day {
      padding: 6px 0 4px;
      font-size: 20px;
    }
    &__created-at-month {
      padding: 6px 0 0;
      font-size: 14px;
      line-height: 1.2em;
    }
  }
}


@include respond-down('small') {
  .date-badge {
    &__date-bg {
      padding: 5px 6px;
      width: 44px;
      &::before {
        bottom: -9px;
        width: 35px;
        height: 9px;
      }

      &::after {
        bottom: -9px;
        border-width: 0 0 9px 9px;
      }
    }
    &__created-at-day {
      font-size: 16px;
      padding-bottom: 4px;
      margin-bottom: 5px;
    }
    &__created-at-month {
      font-size: 13px;
    }
  }
}