.seller-progress {
  .project-construction-progress {
    padding-top: unset;

    &__slider {
      display: grid;
      grid-gap: var(--grid-column-gap);
      grid-auto-flow: row;
      margin-left: unset;
    }

    &__title,
    &__controls-wrap {
      display: none;
    }

    &__picture,
    &__item-text-inner {
      width: 100%;
    }
  }
}

@include respond-up('large') {
  .seller-progress {
    .project-construction-progress {
      &__slider {
        grid-template-columns: repeat(2, auto);
      }
    }
  }
}

@include respond('medium') {
  .seller-progress {
    .project-construction-progress {
      padding: 0 #{$spacer-medium}px;

      &__slider {
        grid-template-columns: repeat(auto-fill, var(--grid-column3));
        grid-gap: 20px;
      }

      &__item {
        width: var(--grid-column3);
      }
    }
  }
}

@include respond-down('medium') {
  .seller-progress {
    margin-top: 35px;
  }
}

@include respond-down('small') {
  .seller-progress {
    .project-construction-progress {
      padding: 0 #{$spacer-small}px;

      &__item {
        width: 100%;
      }

      &__top {
        flex-wrap: wrap;
        margin: -7px;
      }

      &__filters-wrap,
      &__cam {
        margin: 7px;
      }

      &__cam {
        display: flex;
        height: 54px;

        &_small {
          display: none;
        }
      }
    }
  }
}