.seller-news-page {
  &__content {
  }

  &__list {
    display: grid;
  }

  &__item {
    margin-bottom: 50px;
    border-top: 1px solid var(--color-border);
  }
}

@include respond-up('large') {
  .seller-news-page {

    &__title {
      margin-bottom: 40px;
      display: block;
    }

    &__list {
      grid-template-columns: 1fr 30px 30px 1fr 30px 30px 1fr;
    }

    &__item {
      &:nth-child(3n - 2) {
        grid-column: 1/3;
        border-right: 1px solid var(--color-border);

        .seller-news-card {
          padding-right: 30px;
        }
      }

      &:nth-child(3n - 1) {
        grid-column: 3/6;
        border-right: 1px solid var(--color-border);

        .seller-news-card {
          padding-right: 30px;
          padding-left: 30px;
        }
      }

      &:nth-child(3n) {
        grid-column: 6/8;

        .seller-news-card {
          padding-left: 30px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .seller-news-page {

  }
}

@include respond('medium') {
  .seller-news-page {

  }
}

@include respond-down('medium') {
  .seller-news-page {
    &__title {
      display: none;
    }
    &__list {
      grid-template-columns: 1fr 1fr;
    }
    &__item {
      &:nth-child(2n - 1) {
        border-right: 1px solid var(--color-border);

        .seller-news-card {
          padding-right: 30px;
        }
      }

      &:nth-child(2n) {
        .seller-news-card {
          padding-left: 30px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .seller-news-page {

  }
}