@use "sass:math";

.project-slider {
  border-top: 1px solid var(--color-border);
  position: relative;

  &__list {
    overflow: hidden;
  }

  &__item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .project-slider {
        &__title, &__subtitle {
          transform: translateY(20px);
          opacity: 0;
        }

        &__picture {
          transform: translateX(100%);
        }

        &__image {
          transform: translateX(-100%);
        }
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;

      .project-slider {
        &__title, &__subtitle {
          transform: translateY(0);
          opacity: 1;
          transition-delay: .3s;
        }

        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          animation: animate-image .9s;
        }
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;

      .project-slider {
        &__title, &__subtitle {
          transform: translateY(20px);
          opacity: 0;
        }

        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: block;
    position: relative;
    object-fit: cover;
    overflow: hidden;
  }


  &__title {
    font-weight: 500;
    line-height: 135%;
  }

  &__subtitle {
    line-height: 150%;
    color: var(--color-gray-dark);
  }

  &__title, &__subtitle {
    transition: transform .5s, opacity .5s;
  }

  &__arrows {
    position: absolute;
    z-index: 10;
  }
}

@include respond-up('large') {
  .project-slider {
    padding-top: 40px;
    margin-top: 40px;

    &__img-wrapper {
      grid-column: 1/7;
    }

    &__picture {
      padding-top: math.div(549, 584) * 100%;
    }

    &__description-wrapper {
      grid-column: 8/13;
      padding-top: 88px;
    }

    &__title {
      font-size: 19px;
      margin-bottom: 18px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__arrows {
      bottom: 50px;
      left: 720px;
    }
  }
}

@include respond('medium') {
  .project-slider {
    padding-top: 30px;
    margin-top: 30px;


    &__img-wrapper {
      grid-column: 1/4;
      max-width: calc((307 / 768) * 100vw);
    }


    &__picture {
      padding-top: math.div(326, 307) *100%;
    }

    &__description-wrapper {
      grid-column: 4/7;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 15px;
    }

    &__arrows {
      bottom: 0;
      left: calc((357 / 768) * 100vw);
    }
  }
}

@include respond-down('small') {
  .project-slider {
    padding-top: 20px;
    margin-top: 20px;

    &__wrapper {
      grid-row-gap: 25px;
    }

    &__img-wrapper {
      grid-column: 1/4;
    }

    &__picture {
      padding-top: math.div(235, 250) * 100%;
    }

    &__description-wrapper {
      grid-column: 1/5;
      padding-left: 40px;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__arrows {
      padding-left: 40px;
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}