.compilation-page {
  &__top-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  &__back-link {
    padding: 10px;
    margin: -10px;
  }

  &__back-link-icon {
    margin-right: 8px;
  }

  &__form {
    margin-top: 20px;
  }

  .form-field {
  }

  &__apartments {
    margin-top: 25px;
    margin-bottom: 50px;
  }

  &__apartments-empty {
    color: var(--color-gray-dark);
    border-top: 1px solid var(--color-border);
    padding-bottom: 70px;
    padding-top: 20px;
  }
}