.agency-agents {
  &__activity {
    padding: var(--menu-padding);
  }

  &__notify-all {
    width: 100%;
  }

  &__top {
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: 35px;
    margin-bottom: 30px;

    .button {
      width: 100%;

      .button__link {
        padding: 0;
        height: var(--default-input-height);
      }

      &.agency-agents__new-customer-button {
        .button__link {
          height: calc(var(--default-input-height) - 2px);
        }
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 110px;
  }

  &__list-head, &__item {
    display: grid;
    grid-template-columns: 1fr 160px 80px 180px;
    padding: 20px 0;
    grid-gap: 28px;
    border-bottom: 1px solid var(--color-border-60);
  }

  &__list-head {
    color: var(--color-gray-dark);
  }

  &__customer-name {
    display: block;
    transition: color 0.4s;
    margin-bottom: 10px;

    &:hover {
      color: var(--primary-color);
    }
  }

  &__customer-phone {
    color: var(--color-gray-dark);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    display: block;
    padding: 0 20px;
    border-right: 1px solid var(--color-border-40);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  &__activity {
    display: inline-block;
    box-shadow: none;
    padding: 0;
    border: none;
    appearance: none;
    background-color: transparent;
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}