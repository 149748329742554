article {
  &, p, li, a {
    color: var(--color-gray-dark);
    font-size: 16px;
    line-height: 150%;
  }

  h1, h2, h3, h4, strong {
    color: var(--color-primary-dark);
  }

  em {
    font-style: italic;
  }

  p {
    img {
      object-fit: cover;
    }
  }

  strong {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  ul, ol {
    margin-top: 20px;
  }

  ul {
    li {
      position: relative;
      padding: 5px 0 5px 19px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        background-color: var(--primary-color);
        width: 7px;
        height: 7px;
      }
    }
  }

  ol {
    counter-reset: heading;

    li {
      padding: 15px 0;
      display: flex;
      position: relative;

      p {
        border-bottom: 1px solid var(--color-border);
      }

      &::before {
        margin-right: 22px;
        counter-increment: heading;
        color: var(--color-primary-dark);
        content: "" counter(heading) ". ";
      }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 30px;
        background-color: var(--color-border);
        width: 750px;
        height: 1px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  blockquote {
    min-height: 112px;
    position: relative;
    padding-left: 84px;

    p {
      color: var(--color-primary-dark);
      font-style: italic;
      font-size: 20px;
      line-height: 140%;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 59px;
      height: 59px;
      background: {
        image: url('../images/svg/icons/blockquote.svg');
        size: contain;
        repeat: no-repeat;
      };
    }
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include respond-up('large') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
      margin-top: 60px;
    }
  }
}

@include respond('medium') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
      margin-top: 50px;
    }

    ol {
      counter-reset: heading;

      li {
        &::after {
          left: 30px;
          width: calc((658 / 768) * 100vw);
        }
      }
    }
  }
}

@include respond-down('small') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
      margin-top: 40px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    &, p, li {
      font-size: 15px;
    }

    blockquote {
      padding-left: 58px;

      &::before {
        width: 42px;
        height: 42px;
      }

      p {
        font-size: 16px;
      }
    }

    ol {
      counter-reset: heading;

      li {
        &::before {
          margin-right: 18px;
        }

        &::after {
          left: 25px;
          width: calc((290 / 375) * 100vw);
        }
      }
    }

    strong {
      font-size: 17px;
    }
  }
}