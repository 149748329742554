@use "sass:math";

.pagination-endless {
  &__nav {
    display: flex;
    justify-content: center;
  }

  &__button {
    display: block;
  }

  &__on-mobile {
    display: none;
  }
}

@include respond-up('large') {
  .pagination-endless {
    &__nav {
      margin-top: 100px;
    }
  }
}

@include respond('medium') {
  .pagination-endless {
    &__nav {
      margin-top: 70px;
    }
  }
}

@include respond-up('medium') {
  .pagination-endless {
    &__button {
      height: 38px;
      flex: 0 0 42px;
      display: flex;
      background: {
        image: url("/static/images/svg/preloader.svg");
        position: center;
        size: contain;
        repeat: no-repeat;
      };
      animation: {
        name: rotate;
        duration: 1s;
        iteration-count: infinite;
        timing-function: linear;
      };
    }
  }
}

@include respond-down('small') {
  .pagination-endless {
    &__nav {
      margin-top: 30px;
    }

    &__button {
      width: 100%;
      font-weight: 500;
      font-size: 15px;
      line-height: math.div(18, 15)*1em;
      color: var(--primary-color);
      border: 1px solid var(--color-blue-gray);
    }

    &__on-mobile {
      display: block;
      padding: 13px 0;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}