.seller-projects-page {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 60px;
  }

  &__item {
    grid-column: span 1;

    .project-card__picture {
      padding-top: 57%;
    }
  }
}