.blog-articles {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    display: flex;
    border-top: 1px solid var(--color-border);
    &:nth-child(2n) {
      border-left: 1px solid var(--color-border);
    }
  }

  &__link {
    width: 100%;
    flex: 0 0 100%;
  }
}

@include respond-up('large') {
  .blog-articles {
    &__list {
      grid-row-gap: 60px;
    }

    &__item {
      padding: 40px 0 0;

      &:nth-child(2n+1) {
        padding-right: 50px;
      }

      &:nth-child(2n) {
        padding-left: 50px;
      }
    }
  }
}

@include respond-up('medium') {
  .blog-articles {

  }
}

@include respond('medium') {
  .blog-articles {
    &__list {
      grid-row-gap: 40px;
    }
  }
}

@include respond-down('medium') {
  .blog-articles {
    &__item {
      padding: 20px 0 0;

      &:nth-child(2n) {
        justify-content: flex-end;
      }
    }
  }
}

@include respond-down('small') {
  .blog-articles {
    &__list {
      grid-row-gap: 30px;
    }
  }
}