.about-building-card {
  background-color: var(--color-background);

  &__image-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0.3deg, rgba(0, 9, 33, 0.5) 0.89%, rgba(3, 13, 44, 0.15) 99.74%);
      z-index: 5;
    }
  }

  &__image-wrap-top {
    display: flex;
  }

  &__volume {
    margin-left: 10px;
  }

  &__image-wrap-top,
  &__name {
    position: relative;
    z-index: 10;
    color: #fff;
  }

  &__text-wrap {
    //background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__description {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    color: var(--color-gray-dark);
  }

  &__year-title,
  &__volume-title {
    font-style: normal;
    font-weight: 400;
    color: #fff;
  }

  &__year-value,
  &__volume-value {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #fff;
  }

  &__year-value {
    margin-top: 2px;
  }

  &__volume {
    margin-left: 10px;
  }
}

@include respond-up('large') {
  .about-building-card {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 28px;

    &__image-wrap {
      padding: 35px 45px;
      flex: 0 0 auto;
      grid-column: 1/10;
      margin-right: 4px;
      height: calc((340 / 1500) * 100vw);
      max-height: 340px;
    }

    &__text-wrap {
      padding: 35px 32px 35px 0;
      flex: 0 1 auto;
      grid-column: 10/13;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .about-building-card {
    &__description {
      font-size: 16px;
      line-height: 150%;
    }

    &__year,
    &__volume {
      width: 162px;
    }

    &__year-title,
    &__volume-title {
      font-size: 16px;
      line-height: 150%;
    }

    &__year-value,
    &__volume-value {
      font-size: 22px;
    }
  }
}

@include respond('medium') {
  .about-building-card {
    &__image-wrap {
      padding: 35px 40px;
      height: calc((266 / 768) * 100vw);
    }

    &__text-wrap {
      padding: 35px 40px;
    }
  }
}

@include respond-down('medium') {
  .about-building-card {
    display: flex;
    flex-direction: column;
  }
}

@include respond-down('small') {
  .about-building-card {
    &__image-wrap {
      padding: 20px;
      height: calc((185 / 375) * 100vw);
    }

    &__text-wrap {
      padding: 20px;
    }

    &__description {
      font-size: 15px;
      line-height: 135%;
    }

    &__year,
    &__volume {
      width: 122px;
    }

    &__year-title,
    &__volume-title {
      font-size: 15px;
      line-height: 135%;
    }

    &__year-value,
    &__volume-value {
      font-size: 18px;
    }
  }
}