.faq-page {
  &__content {
    display: grid;
    position: relative;
  }
}

@include respond-up('large') {
  .faq-page {
    //padding-bottom: 121px;

    &__content {
      padding-top: 70px;
      grid-template-columns: auto 241px;
      grid-column-gap: 102px;
    }

    &__tabs{
      padding: 0 var(--large-indent);
      background: var(--color-background);
      position: relative;
      margin-left: -35px;
    }
  }
}

@include respond('medium') {
  .faq-page {
    &__content {
      grid-row-gap: 70px;
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .faq-page {
    &__content {
      grid-row-gap: 40px;
      padding-top: 10px;
    }
  }
}


