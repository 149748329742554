.customer-service {
  &__list {
    border-top: 1px solid var(--color-border);
  }

  &__list-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .customer-service {
    &__list {
      padding-top: 40px;
    }
  }
}

@include respond('medium') {
  .customer-service {
    &__list {
      padding-top: 30px;
    }
  }
}

@include respond-down('small') {
  .customer-service {
    &__list {
      padding-top: 20px;
    }
  }
}