@use "sass:math";

.article-page {
  &__subheading {
    color: var(--color-gray-dark);
  }

  &__part-image-list {
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    object-fit: cover;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__bottom {
    display: flex;
  }

  &__image-container {
    position: relative;
  }

  &__badge {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__more {
    strong {
      color: var(--primary-color);
    }
  }
}

@include respond-up('large') {
  .article-page {

    &__container {
      border-bottom: 1px solid var(--color-border);
    }

    &__picture {
      padding-top: math.div(464, 816) * 100%;
      margin-bottom: 60px;
    }

    &__wrap, &__actions-block {
      padding-top: 70px;
      padding-bottom: 60px;
    }

    &__wrap {
      grid-column: 1/10;
      padding-right: 50px;
      border-right: 1px solid var(--color-border);
      margin-right: 20px;
    }

    &__actions-block {
      grid-column: 10/13;
    }

    &__content {
      margin-bottom: 60px;
    }

    &__more {
      margin-bottom: 35px;
    }

    &__subheading {
      padding-bottom: 40px;
    }

    &__bottom {
      padding-top: 35px;
      padding-bottom: 120px;
    }

    &__bottom-form {
      padding-bottom: 120px;
    }
  }
}

@include respond-up('medium') {
  .article-page {
    &__heading {
      padding-bottom: 20px;
    }

    &__bottom {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .article-page {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 50px;
      grid-column: 1/7;
      border-bottom: 1px solid var(--color-border);
    }

    &__content {
      margin-bottom: 50px;
    }

    &__more {
      margin-bottom: 28px;
    }

    &__subheading {
      padding-bottom: 30px;
    }

    &__picture {
      padding-top: math.div(392, 690) * 100%;
      margin-bottom: 50px;
    }

    &__bottom {
      padding-top: 30px;
      padding-bottom: 70px;
    }

    &__bottom-form {
      padding-bottom: 70px;
    }
  }
}

@include respond-down('medium') {
  .article-page {
    &__actions-block {
      display: none;
    }
  }
}

@include respond-down('small') {
  .article-page {
    &__wrap {
      padding-top: 30px;
      grid-column: 1/5;
    }

    &__content {
      margin-top: 40px;
    }

    &__more {
      margin-bottom: 20px;
    }

    &__heading {
      padding-bottom: 12px;
    }

    &__subheading {
      padding-bottom: 20px;
    }

    &__picture {
      padding-top: math.div(192, 338) * 100%;
      margin-bottom: 40px;
    }

    &__bottom {
      flex-direction: column-reverse;
      padding-top: 25px;
      padding-bottom: 40px;

      .share {
        margin-bottom: 20px;
      }
    }

    &__bottom-form {
      padding-bottom: 40px;
    }
  }
}
