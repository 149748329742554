.reviews{
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: top;
  }

  &__wrap{
    position: relative;
    display: grid;
  }

  &__arrows{
    position: absolute;
  }

  &__slider-container {
    grid-area: slider;
    position: relative;
  }

  &__empty{
    grid-area: slider;
  }

  &__slider {
    overflow: hidden;
  }

  &__button {
    white-space: nowrap;
  }

  &__button-wrapper {
    grid-area: button;
  }

  &__item{
    &.normal{
      transition-duration: 0s !important;
      animation-duration: 0s !important;
      .review{
        &__content-name,  &__content-date{
          transform: translateY(20px);
          opacity: 0;
        }

        &__content-text{
          opacity: 0;
        }

        &__image-container,
        &__round_noimage,
        &__content-button-wrap {
          opacity: 0;
        }
      }
    }

    &.fade-in{
      .review{
        &__content-name,  &__content-date{
          transform: translateY(0);
          opacity: 1;
          transition: {
            property: opacity, transform;
            duration: .3s;
            timing-function: ease-in;
          };
        }

        &__content-text{
          opacity: 1;
          transform: translateY(0);
          transition: {
            property: opacity;
            duration: .3s;
            timing-function: ease-in;
          };
        }

        &__image-container,
        &__round_noimage,
        &__content-button-wrap {
          opacity: 1;
          transition: opacity ease-in .3s;
        }
      }
    }

    &.fade-out{
      .review{
        &__content-name, &__content-date{
          transform: translateY(-15px);
          opacity: 0;
          transition: opacity ease-out .3s, transform ease-out .5s;
        }
        &__content-text{
          opacity: 0;
          transform: translateY(-20px);
          transition: {
            property: opacity,transform;
            duration: .3s;
            timing-function: ease-out;
          };
        }

        &__image-container,
        &__round_noimage,
        &__content-button-wrap {
          opacity: 0;
          transition: opacity ease-out .3s;
        }
      }
    }
  }
}

@include respond-up('large'){
  .reviews{
    &__top{
    }

    &__slider-container {
      padding-top: 53px;
    }

    &__button{
    }

    &__arrows{
      top: 89px;
      left: 0;
    }
  }
}

@include respond-up('medium'){
  .reviews{


    &__wrap {
      grid-template-columns: 1fr auto;
      grid-template-areas:  "top button"
                          "slider slider";
    }

    &__button{
    }
  }
}

@include respond('medium'){
  .reviews{
    &__top {
    }

    &__slider-container {
      padding-top: 30px;
    }

    &__button {
    }

    &__arrows{
      top: 77px;
      left: 0;
    }
  }
}

@include respond-down('medium'){
  .reviews{
  }
}

@include respond-down('small'){
  .reviews{
    &__top{
      padding-bottom: 22px;
    }

    &__arrows{
      top: 9px;
      right: 0;
    }


    &__button{
      margin-top: 25px;
      width: 100%;
    }

    &__wrap {
      grid-template-columns: 1fr auto;
      grid-template-areas:  "top"
                            "slider"
                            "button";
    }
  }
}