.youtube-video-block {
  &__youtube-framed{
    width: 100%;
    height: 100%;
  }
}

@include respond-up('large') {
  .youtube-video-block {
    &__link{
      height: 365px;
    }
  }
}

@include respond-up('medium') {
  .youtube-video-block {

  }
}

@include respond('medium') {
  .youtube-video-block {

  }
}

@include respond-down('medium') {
  .youtube-video-block {

  }
}

@include respond-down('small') {
  .youtube-video-block {

  }
}