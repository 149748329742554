.seller-infrastructure-items {
  display: grid;
  grid-gap: var(--grid-column-gap);
  grid-auto-flow: row;
}

@include respond-up('large') {
  .seller-infrastructure-items {
    grid-template-columns: repeat(auto-fill, var(--grid-column4));

    &__item {
      width: var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .seller-infrastructure-items {

  }
}

@include respond('medium') {
  .seller-infrastructure-items {
    grid-gap: 20px;
  }
}

@include respond-down('medium') {
  .seller-infrastructure-items {
    padding: 0 #{$spacer-medium}px;
    grid-template-columns: repeat(auto-fill, var(--grid-column3));

    &__item {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .seller-infrastructure-items {

  }
}