.seller-awards {
  &__content-item-wrap {
    display: grid;
    grid-row-gap: 40px;
  }
}

@include respond-up('large') {
  .seller-awards {
    &__content-item-wrap {
      grid-template-columns: repeat(4, 1fr);
    }

    .about-awards {
      &__award-item {
        padding: 22px 30px 0 30px;


        &:nth-child(4n+1) {
          padding: 22px 30px 0 0;
        }

        &:nth-child(4n) {
          padding: 22px 0 0 30px;
        }

        &:not(:nth-child(4n)) {
          border-right: 1px solid var(--color-border);
        }

        &:nth-child(n+5) {
          border-top: 1px solid var(--color-border);
        }
      }
    }
  }
}
@include respond-down('medium') {
  .seller-awards {
    &__content-item-wrap {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}