.paragraph {
  font-weight: 500;
  line-height: 140%;
}

@include respond-up('medium') {
   .paragraph {
     font-size: 20px;
   }
 }

@include respond-down('small') {
  .paragraph {
    font-size: 17px;
  }
}
