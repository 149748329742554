.customer-compilation-header {
  background-color: white;
  z-index: 1000;

  &__top, &__main-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-wrapper {
    display: flex
  }

  &__main-link {
    flex: 0 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    color: black;

    &:last-child {
      margin-left: 20px;
    }
  }

  &__bottom {
    background-color: var(--color-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  &__contact {
    display: flex;
    align-items: center;
  }

  &__contact-item {
    display: inline-flex;
    align-items: center;
  }

  &__contact-key {
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
  }

  &__contact-value {
    font-size: 14px;
    line-height: 135%;
    color: var(--color-primary-dark);
    margin-left: 6px;
  }

  &__print-link {
    padding: 5px 0;
  }

  &__print-icon {

  }
}

@include respond-up('large') {
  .customer-compilation-header {
    top: 66px;
    position: sticky;

    &__top {
      padding: 30px 0 20px;
    }

    &__logo-wrapper {
      flex: 0 0 120px;
    }

    &__contact-item {
      &:nth-child(n + 2) {
        margin-left: 70px;
      }
    }

    &__contact-value {
      padding: 12px 0;
    }
  }
}

@include respond-up('medium') {
  .customer-compilation-header {
  }
}

@include respond('medium') {
  .customer-compilation-header {
  }
}

@include respond-down('medium') {
  .customer-compilation-header {
    &__top {
      flex-wrap: wrap;
      min-height: 66px;
    }

    &__logo-wrapper {
      flex: 0 0 276px;
    }

    &__bottom {
      margin: 0 calc(var(--spacer) * -1);
      padding: 0 var(--spacer);
    }

    &__contact {
      flex-wrap: wrap;
      padding: 9px 0;
    }

    &__contact-item {
      flex: 0 0 100%;
    }

    &__contact-value {
      padding: 6px 0;
    }
  }
}

@include respond-down('small') {
  .customer-compilation-header {
    &__main-link-wrapper {
      padding: 15px 0;
    }
  }
}

@media print {
  .customer-compilation-header {

    &__print-link {
      display: none;
    }
  }
}