.customer-apartment-card {
  &__status {
    display: flex;
    align-items: center;
  }

  &__status-icon {
    width: 42px;
    height: 42px;
    margin-right: 11px;
  }

  &__status-data {
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__status-item {
    display: flex;
    align-items: center;
  }

  &__status-description {
    color: var(--color-gray-dark);
  }

  &__status-value {
    margin-left: 5px;
    color: var(--primary-color);
  }
}

@include respond-up('medium') {
  .customer-apartment-card {
    &__footer {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .customer-apartment-card {
    padding: 20px 0 20px;

    &__footer {
      padding-top: 15px;
    }
  }
}