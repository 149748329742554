.seller-customer-search {
  background-color: #fff;
  box-shadow: 0 12px 40px rgba(125, 131, 146, 0.21);
  transition: all 0.3s;

  &__item {
    display: block;
    padding: 8px 22px;
    font-size: 15px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      color: var(--primary-color);
    }

    &:hover {
      background-color: var(--color-gray-light);
    }
  }

  &__item-value {
    padding-left: 10px;

    &:first-child {
      padding-left: 0;
    }
  }

  &__empty {
    padding: 22px;
    color: var(--color-gray-dark);
  }

  &._unfocus {
    opacity: 0;
    pointer-events: none;
  }
}