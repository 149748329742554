.input-wrapper {
  ul.errors {
    color: var(--color-gray-dark);
    opacity: .8;
  }

 label {
   color: rgba(255, 255, 255, 0.5);
   font-size: 14px;
   line-height: 120%;
 }

  &_white-errors {
    ul.errors {
      color: var(--color-white-opacuty-50);
    }
  }

  &__pwd-icon {
    &_show {

    }

    &_hide {

    }
  }

  &__submit-button {

  }
}

@include respond-down('small') {
  .input-wrapper {
    &_select {
      &::before {
        top: 45px;
        right: 12px;
      }
    }
  }
}