.contacts-modal {
  &__button {
    border: 1px solid var(--color-border);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border var(--default-transition-duration);

    &:hover {
      border: 1px solid black;
    }
  }

  &__subtitle {
    margin-top: 12px;
    color: var(--color-gray-dark);
  }
  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__button-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--color-primary-dark);
  }
  &__buttons-wrap {
    display: flex;
    flex-direction: column;
    &_top {
      display: flex;
      justify-content: space-between;
    }
    &_bottom {
      display: flex;
    }
  }
  &__button {
    &_left,
    &_right {
      flex: 0 0 47%;
    }

    &_bottom {
      flex: 0 0 100%;
    }
  }

  &__button-icon {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .contacts-modal {
    width: 584px;
  }
}


@include respond-up('medium') {
  .contacts-modal {
    &__buttons-wrap {
      margin-top: 40px;
      &_bottom {
        margin-top: 28px;
      }
    }
    &__button {
      height: 54px;
    }
    &__button-icon {
      margin-right: 13px;
    }
  }
}


@include respond('medium') {
  .contacts-modal {
    width: 500px;
  }
}


@include respond-down('small') {
  .contacts-modal {
    &__button {
      height: 54px;
    }
    &__button {
      &_bottom {
        margin-top: 18px;
      }
    }
    &__buttons-wrap {
      margin-top: 25px;
    }
    &__button-icon {
      margin-right: 9px;
    }
    &__subtitle {
      text-align: center;
    }
  }
}
