.client-service {
  &__list-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include respond-down('medium') {
  .client-service {
    padding: 0 var(--grid-spacer);
  }
}