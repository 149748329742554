@use "sass:math";

.news-item {
  display: flex;
  border-bottom: 1px solid var(--color-border);

  &:nth-child(1) {
    border-top: 1px solid var(--color-border);
  }

  &__image-container {
    display: block;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__picture {
    overflow: hidden;
    display: block;
    position: relative;
  }

  &__date-badge {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__description-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 500;
    line-height: 135%;
  }

  &__short-text {
    line-height: 150%;
    color: var(--color-gray-dark);
    flex-grow: 1;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;

    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }
}

@include respond-up('large') {
  .news-item {
    padding: 30px 0;

    &:hover {
      .news-item {
        &__image {
          transform: scale(1.12);
        }

        &__icon {
          transform: translate3d(18px, -20px, 0);

          &_dub {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &__image-container {
      margin-right: 42px;
      flex: 0 0 365px;
    }

    &__image {
      transition: transform 2s cubic-bezier(0, 0, .2, 1);
    }

    &__picture {
      padding-top: math.div(206, 365) * 100%;
    }

    &__title {
      font-size: 19px;
      margin-bottom: 15px;
    }

    &__short-text {
      font-size: 16px;
    }

    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }
  }
}

@include respond-up('medium') {
  .news-item {
    padding: 26px 0;


  }
}

@include respond('medium') {
  .news-item {

    &__image-container {
      flex: 0 0 286px;
      margin-right: 25px;
    }

    &__picture {
      padding-top: math.div(164, 286) * 100%;
    }

    &__title {
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .news-item {

    &__title {
      margin-bottom: 15px;
    }

    &__short-text {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .news-item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 0;

    &__picture {
      padding-top: math.div(100, 176) * 100%;
    }

    &__image-container {
      grid-row: 1;
      grid-column: 1/4;
      margin-bottom: 20px;
      max-width: calc((176 / 375) * 100vw);
    }

    &__description-container {
      grid-row: 2;
      grid-column: 1/5;
    }

    &__short-text {
      margin-bottom: 20px;
    }
  }
}