.product-info {
  &__half {
    display: flex;
    flex-direction: column;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
  }

  &__slider-wrapper {
    grid-area: slider;
  }
  &__activity {
  }

  &__apartments {
  }
}

@include respond-up('large') {
  .product-info {
    padding-top: 70px;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding-bottom: 80px;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: var(--color-border);
      left: 50%;
      top: 0;
    }

    &__info-list {
      margin-top: 50px;
    }

    &__price-block {
      margin-top: 50px;
    }

    &__half {
      &:nth-child(2) {
        padding-left: 20px;
      }
    }

    &__slider-wrapper {
      padding-right: 38px;
    }
  }
}

@include respond-up('medium') {
  .product-info {
    &__slider {
      margin-top: 20px;
    }

    &__apartments {
      margin-top: 70px;
    }


    &__price-block-cca {
      .product-info-price-block {
        &__bottom-wrap {
          padding-top: 30px;
        }
      }
    }

    &__price-block-layout {
      padding-top: 30px;

      .product-info-price-block {
        &__bottom-wrap {
          padding-top: 20px;
        }
      }
    }
  }
}

@include respond('medium') {
  .product-info {
    padding: 40px 0 31px;

    &__slider-wrapper {
      margin-bottom: 50px;
    }

    &__price-block {
      margin-top: 40px;
      padding-bottom: 30px;
    }

    &__info-list {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .product-info {
    grid-template-columns: 100%;

    &__info-block {
      border-top: var(--color-border);
      padding-top: 30px;
    }
  }
}

@include respond-down('small') {
  .product-info {
    padding: 20px 0;

    &__info-list {
      margin-top: 25px;
    }

    &__slider-wrapper {
      margin-bottom: 30px;
    }

    &__apartments {
      margin-top: 40px;
    }

    &__price-block {
      margin-top: 35px;
    }

    &__slider {
      margin-top: 24px;
    }

    &__price-block-cca{
      .product-info-price-block {
        &__bottom-wrap {
          padding-top: 20px;
        }
      }
    }
  }
}

@media print {
  .product-info-short-info {
    &__list {
      max-width: 500px;
    }
  }
}
