.hint {
  position: relative;
  display: inline-block;

  &:hover {
    .hint__text {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 17px;
    width: 17px;
    height: 17px;
    border-radius: 100px;
    border: 1px solid var(--color-border);
    transition: {
      property: border-color;
      duration: .3s;
    };

    svg path {
      transition: {
        property: fill;
        duration: .3s;
      };
    }
  }

  &__text {
    position: absolute;
    background-color: white;
    padding: 15px;
    color: var(--color-primary-dark);
    z-index: 1000;
    box-shadow: 0 0 15px 14px rgba(125, 131, 146, 0.09);
    left: -10px;
    top: calc(100% + 10px);
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .4s;
    };

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: -20px;
      border: {
        style: solid;
        color: transparent;
        bottom-color: white;
        width: 10px 7px;
      }
    }
  }
}