.link-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  padding-bottom: 3px;
  border-bottom: 1px dashed var(--color-border);
  transition: border-color var(--default-transition-duration);

  &:hover {
    border-color: var(--color-primary-dark);
  }
}