.error-page {
  background-color: var(--color-background);

  &__inner {
    display: flex;
  }

  &__code-wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__code {
    color: var(--color-blue-dark);
    font-weight: bold;
    line-height: .8;
  }

  &__code-image {
    display: flex;
    svg {
      height: auto;
      width: 100%;
    }
  }
}

@include respond-up('large') {
  .error-page {
    padding: 157px 0 266px;

    &__inner {
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__subtitle {
      margin-bottom: 35px;
      color: #787A7D;
      max-width: 331px;
    }

    &__code {
      font-size: 227px;
    }

    &__code-wrapper {
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .error-page {

  }
}

@include respond('medium') {
  .error-page {
    padding: 90px 0 200px;

    &__code-wrapper {
      margin-bottom: 80px;
    }

    &__code {
      font-size: 160px;
    }

    &__code-image {
      width: 441px;
      flex: 0 0 441px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    &__inner {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__subtitle {
      margin-bottom: 28px;
    }

    &__code-wrapper {
      order: 3;
      justify-content: center;
    }

    &__content {
      order: 7;
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding: 70px 0 150px;

    &__code-wrapper {
    }

    &__content {
    }

    &__code-wrapper {
      margin-bottom: 60px;
    }

    &__code {
      font-size: 117px;
    }

    &__code-image {
      max-width: 298px;
      flex: 0 1 298px;
    }
  }
}