.seller-whiteboard {
  background-color: #fff;

  &_spacer {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .seller-whiteboard {
    &_spacer {
      padding-right: var(--grid-spacer);
      padding-left: var(--grid-spacer);
    }
  }
}