.seller-people {
  &__company-in-persons {
    padding: 0;

    .container_spacer-large-up {
      padding: 0;
    }

    .about-company-in-persons {
      &__picture {
        width: 100%;
      }

      &__list{
        grid-column-gap: 28px;
      }

      &__main-inner{
        padding: 0 0 38px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__company-in-persons + &__employees {
    margin-top: 80px;
  }

  &__employees{

  }

  &__employees-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: unset;

    li {
      width: 278px;
      flex: 0 0 278px;
      margin-right: 28px;
      margin-bottom: 70px;
    }
  }
}

@include respond-up('large') {
  .seller-people {
    &__employees-list {
      .about-personal__picture {
        padding-top: 0;
      }
    }
  }
}