.contacts-requisites {
  &__content {
    border-top: 1px solid var(--color-border);
  }

  &__content-title {
    font-style: normal;
    font-weight: 500;
  }

  &__content-address {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    margin-top: 12px;
  }

  &__content {
    display: flex;
  }

  &__content-title-wrap {
    flex: 0 0 auto;
  }

  &__content-info {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    flex: 0 1 auto;
  }
}

@include respond-up('large') {
  .contacts-requisites {
    margin-top: 140px;

    &__content {
      margin-top: 40px;
      padding-top: 40px;
    }

    &__content-title-wrap {
      margin-right: 234px;
      max-width: 480px;
    }
  }
}

@include respond-up('medium') {
  .contacts-requisites {
    &__content-title {
      font-size: 17px;
      line-height: 1.35em;
    }

    &__content-address,
    &__content-info  {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .contacts-requisites {
    margin-top: 90px;

    &__content {
      margin-top: 30px;
      padding-top: 30px;
    }

    &__content-title-wrap {
      margin-right: 143px;
      max-width: calc((332 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .contacts-requisites {

  }
}

@include respond-down('small') {
  .contacts-requisites {
    margin-top: 40px;

    &__content {
      margin-top: 20px;
      padding-top: 20px;
    }

    &__content-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__content-address {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__content-info  {
      font-size: 15px;
      line-height: 1.35em;
      margin-top: 30px;
    }

    &__content {
      flex-direction: column;
    }
  }
}