.customer-lk {
  &__menu {

  }

  &__content {

  }

  .customer-lk:empty {
    display: none;
  }
}

@include respond-up('large') {
  .customer-lk {
    display: grid;
    grid-template-columns: 275px 1fr;

    &__menu, &__content {
      padding-top: 70px;
      padding-bottom: 120px;
    }

    &__content {
      padding-left: 68px;
    }

    &__menu {
      border-right: 1px solid var(--color-border);
    }

    &__hr {
      border-top: 1px solid var(--color-border);
      padding-bottom: 142px;
    }
  }
}

@include respond-up('medium') {
  .customer-lk {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .customer-lk {
    &__menu {
      margin: 0 calc(-1 * var(--spacer));
      border-bottom: 1px solid var(--color-border);
    }
  }
}

@include respond('medium') {
  .customer-lk {
    &__content {
      padding: 40px 0 120px;
    }
  }
}

@include respond-down('small') {
  .customer-lk {
    &__content {
      padding: 30px 0 40px;
    }

    &__head {
      margin-bottom: 30px;

      h2 + * {
        margin-top: 15px;
      }
    }
  }
}