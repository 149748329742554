.renovations-slider-complicated {
  background-color: var(--color-background);

  &__link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    svg {
      transition: stroke .4s;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 100%;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s cubic-bezier(.55,0,.1,1) .4s;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-color: #fff;
      border-radius: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 0;
      height: 0;
      background-color: var(--color-blue-dark);
      transition: width .4s, height .4s;
      border-radius: 100%;
    }
  }

  &__items-wrap {
    display: flex;
  }

  &__items {
    display: flex;
    width: 100%;
  }

  &__item {
    display: flex;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: flex .8s cubic-bezier(.55,0,.1,1);

    &._active {
      flex: 1 1 100%;
      cursor: unset;

      .renovations-slider-complicated {
        &__item-title,
        &__item-bg {
          opacity: 0;
        }

        &__item-title-open {
          opacity: 1;
        }

        &__item-number {
          color: #fff;
        }

        &__arrows,
        &__link {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &._close {
      .renovations-slider-complicated {
        &__item-title-open,
        &__arrows,
        &__link {
          transition-delay: 0s;
        }
      }
    }

    &:not(._active) {
      &:hover {
        .renovations-slider-complicated__item-bg {
          &::before {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  &__item-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    transition: opacity .4s cubic-bezier(.55,0,.1,1);
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #E5E5E5;
      transition: transform .4s ease;
      will-change: transform;
      transform: translate3d(-100%, 0, 0);
    }
  }

  &__item-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &__item-title {
    display: flex;
    position: absolute;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    z-index: 10;
    transition: opacity .4s cubic-bezier(.55,0,.1,1);
  }

  &__item-title-open {
    font-style: normal;
    font-weight: bold;
    position: absolute;
    width: max-content;
    color: #fff;
    opacity: 0;
    transition: opacity .4s cubic-bezier(.55,0,.1,1) .4s;
    pointer-events: none;
    z-index: 10;
  }

  &__item-number {
    font-style: normal;
    font-weight: bold;
    position: absolute;
    z-index: 10;
    transition: color .4s cubic-bezier(.55,0,.1,1);
  }

  &__slider-wrap {
    position: absolute;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 47%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__arrows {
    position: absolute;
    z-index: 10;
    color: #fff;
    opacity: 0;
    transition: opacity .4s cubic-bezier(.55,0,.1,1) .4s;
    pointer-events: none;

    .arrows__link {
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  &__slider-item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .renovations-slider-complicated {
        &__picture {
          transform: translateX(100%);
        }

        &__image {
          transform: translateX(-100%);
        }
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;

      .renovations-slider-complicated {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          animation: animate-image .9s;
        }
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;

      .renovations-slider-complicated {
        &__picture {
          transform: translateX(0);
          transition: transform .9s;
        }

        &__image {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }

  &__slider-item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(359.93deg, rgba(0, 9, 33, 0.14) 1.44%, rgba(3, 13, 44, 0) 99.94%);
  }
}

@include respond-up('large') {
  .renovations-slider-complicated {
    //padding: 120px 0 70px;

    &__link {
      top: 108px;
      left: 50px;

      &:hover {
        &:after {
          width: 55px;
          height: 55px;
        }

        .renovations-slider-complicated {
          &__link-icon {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    &__items-wrap {
      margin-top: 40px;
    }

    &__items {
      height: 559px;
    }

    &__item {
      flex: 1 0 74px;
    }

    &__item-bg {
      max-width: 74px;
    }

    &__item-inner {
      padding: 35px 24px;
      max-width: 74px;
    }

    &__item-title {
      font-size: 19px;
      line-height: 1.35em;
      width: 418px;
      top: 35px;
    }

    &__item-title-open {
      font-size: 26px;
      top: 50px;
      left: 50px;
    }

    &__item-number {
      font-size: 26px;
    }

    &__item-number {
      bottom: 35px;
      right: 30px;
    }

    &__picture {
      width: 1196px;
      height: 559px;
    }

    &__arrows {
      bottom: 40px;
      left: 50px;
    }
  }
}

@include respond-up('medium') {
  .renovations-slider-complicated {
    &__item {
      &._bordered {
        &:not(:first-child) {
          border-left: 1px solid var(--color-border);
        }

        &._active {
          border-left: unset;
        }
      }
    }

    &__item-title {
      transform: rotate(-90deg);
      right: 50%;
      transform-origin: 100% 50%;
      justify-content: flex-end;
    }

    &__item-title-open {
      line-height: 1.25em;
    }

    &__item-number {
      line-height: 1.25em;
    }

    &__link {
      width: 54px;
      height: 54px;

      &::before {
        width: 54px;
        height: 54px;
      }
    }

    &__slider-wrap {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: max-content;
    }
  }
}

@include respond('medium') {
  .renovations-slider-complicated {
    //padding: 70px 0 50px;

    &__items-wrap {
      margin-top: 30px;
    }

    &__items {
      height: calc((344 / 768) * 100vw);
    }

    &__item {
      flex: 1 0 48px;
    }

    &__item-bg {
      max-width: 48px;
    }

    &__item-inner {
      padding: 25px 13px;
      max-width: 48px;
    }

    &__item-number {
      font-size: 23px;
      right: 17px;
      bottom: 25px;
    }

    &__item-title {
      width: calc((343604 / 768) * 100vw);
      top: 25px;
    }

    &__item-title-open {
      font-size: 23px;
      top: 35px;
      left: 35px;
    }

    &__picture {
      width: calc(100vw - #{$spacer-medium}px * 2);
      height: calc((344 / 768) * 100vw);
    }

    &__link {
      top: 84px;
      left: 35px;
    }

    &__arrows {
      left: 35px;
    }
  }
}

@include respond-down('medium') {
  .renovations-slider-complicated {
    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__arrows {
      bottom: 25px;
      .arrows {
        &__current-index,
        &__delimiter,
        &__counter-all {
          display: none;
        }

        &__left {
          padding-right: 20px;
        }

        &__right {
          padding-left: 20px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .renovations-slider-complicated {
    //padding: 40px 0;

    &__items {
      width: 100vw;
      height: max-content;
      flex-direction: column;
    }

    &__item {
      flex: 1 0 61px;

      &._active {
        flex: 1 1 calc((300 / 375) * 100vw);
      }

      &._bordered {
        &:not(:first-child) {
          border-top: 1px solid var(--color-border);
        }

        &._active {
          border-top: unset;
        }
      }
    }

    &__item-bg {
      max-height: 61px;
      top: unset;
      bottom: 0;
    }

    &__item-inner {
      max-height: 61px;
    }

    &__items-wrap {
      margin-top: 22px;
    }

    &__item-inner {
      padding: 13px 19px;
      top: unset;
      bottom: 0;
    }

    &__item-number {
      font-size: 20px;
      line-height: 1.35em;
      right: 18px;
      bottom: 17px;
    }

    &__item-title {
      left: 19px;
    }

    &__item-title-open {
      font-size: 20px;
      line-height: 1.35em;
      top: 20px;
      left: 19px;
    }

    &__link {
      top: 59px;
      left: 19px;
      width: 36px;
      height: 36px;

      &::before {
        width: 36px;
        height: 36px;
      }
    }

    &__picture {
      height: calc((300 / 375) * 100vw);
      width: 100vw;
    }

    &__arrows {
      left: 19px;
      bottom: 22px;
    }

    &__slider-wrap {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: max-content;
      width: 100vw;
    }
  }
}