.layout-card-buttons {
  display: flex;

  &__item {
    &:first-child {
      margin-right: 16px;
    }
  }

  &__icon {
    svg {
      path {
        transition: fill .4s;
        fill: var(--color-border);
      }
    }
  }

  &__link {
    &[data-compare="1"], &[data-favorites="1"] {
      svg path {
        fill: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .layout-card-buttons {
    &__item {
      &:hover {
        .layout-card-buttons {
          &__icon {
            svg {
              path {
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .layout-card-buttons {

  }
}

@include respond('medium') {
  .layout-card-buttons {

  }
}

@include respond-down('medium') {
  .layout-card-buttons {

  }
}

@include respond-down('small') {
  .layout-card-buttons {

  }
}