.whitebox-slider {
  position: absolute;
  bottom: 50px;
  right: 50px;
  max-width: 584px;
  background-color: #fff;
  z-index: 100;
  padding: 40px;

  &__container {
    overflow: hidden;
    margin-bottom: 30px;
  }

  &__slide {

  }

  &__slide-content {

  }

  &__slide-title {
    margin-bottom: 18px;
  }

  &__slide-description {
    color: var(--color-gray-dark);
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-pager {
    display: flex;
    align-items: center;
    text-align: center;

    svg {
      display: block;
    }
  }

  &__nav-prev, &__nav-next {
    margin: -10px;
    padding: 10px;
    display: block;
  }

  &__nav-info {
    padding: 0 10px;
    width: 90px;
    text-align: center;
  }

  &__nav-next {

  }

  &__nav-toggle {
    .vertical-line {
      transition: all 0.3s;
      opacity: 0;
    }
  }


  &._hide {
    .whitebox-slider {
      &__container {
        position: fixed;
        top: -9990px;
        left: -9990px;
      }

      &__nav-pager {
        position: fixed;
        top: -9990px;
        left: -9990px;
      }

      &__nav-toggle {
        .vertical-line {
          opacity: 1;
        }
      }
    }
  }
}