.blog {
  &__filter-form {
    grid-area: tags;
    margin: -3px;

    .checkbox-list {
      padding: 0;
      margin: 0;

      li {
        padding: 3px;
        max-width: max-content;
      }

      input {
        &:checked + label {
          background-color: var(--primary-color);
          color: #fff;
        }
      }

      label {
        padding: 9px 12px;
        border: 1px solid var(--color-border);

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  &__articles {
    grid-area: items;
  }
}

@include respond-up('large') {
  .blog {
    &__tabs {
      padding: 0 var(--large-indent);
      position: relative;
    }

    &__articles {
      grid-column: 1/10;
      padding-right: 74px;
    }

    &__filter-form {
      grid-column: 10/13;
    }

    &__bottom {
      padding: 70px 0 176px;
    }
  }
}

@include respond-up('medium') {
  .blog {

  }
}

@include respond('medium') {
  .blog {
    &__bottom {
      padding: 40px 0 90px;
    }

    &__articles {
      grid-column: 1/7;
      margin-top: 40px;
    }

    &__filter-form {
      grid-column: 1/7;
    }
  }
}

@include respond-down('medium') {
  .blog {
    &__bottom {
      grid-template-areas: 'tags'
                           'items';
    }
  }
}

@include respond-down('small') {
  .blog {
    &__bottom {
      padding: 30px 0 50px;
    }

    &__articles {
      grid-column: 1/5;
      margin-top: 30px;
    }

    &__filter-form {
      grid-column: 1/5;
    }
  }
}