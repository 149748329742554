.temp-class {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(3, 1fr);
}
.custom-select {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;

  &__link{
    flex: 1 1 100%;
    padding: 15px;
  }

  &._disable {
    pointer-events: none;
    .custom-select {
      &__field {
        color: var(--color-border);
      }
    }
  }

  &._open {
    z-index: 500;

    .custom-select {
      &__list_openable {
        max-height: 216px;
        overflow: auto;
      }

      &__arrow-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .custom-select {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      position: relative;
      flex: 0 0 100%;
    }

    &_type {
      &_single {
      }

      &_multi {
      }

      &_field-iconable {
      }
      &_grouped {
        .custom-select {
          &__list {
            filter: none;
          }
        }
      }
    }

    &__iconable-field {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      cursor: pointer;
    }

    &__field-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 22px;
      height: 22px;
      width: 22px;
      margin-right: 11px;
    }

    &__field-name {
      font-size: 15px;
      line-height: 133%;
      color: var(--color-primary-dark);
    }

    &__field-wrapper {
      width: 100%;
      position: relative;
      z-index: 30;
    }

    &__arrow-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10px;
      height: 7px;
      pointer-events: none;
      transition: {
        property: transform;
        duration: .2s;
      };

      &_absolute {
        position: absolute;
        top: calc(50% - 3px);
        right: 15px;
      }

      &_relative {
        margin-left: 6px;
      }
    }

    &__field {
      background-color: white;
      height: 54px;
      padding-left: 15px;
      padding-right: 40px;
      //height: var(--default-input-height);
      border: 1px solid var(--color-border);
      width: 100%;
      font-size: 15px;
      line-height: 135%;
      &:read-only {
        cursor: pointer;
      }
    }

    &_type_multi, &_iconable {
      .custom-select__label {
        padding-left: 10px;
      }
    }

    &__groups {
      list-style-type: none;
    }

    &__group {
      &:not(:first-child) {
        .custom-select__group-name {
          border-top: 1px solid var(--color-border);
        }
      }
    }

    &__group-name {
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: var(--color-gray-dark);
      background-color: white;
      padding: 10px 22px;
      border-bottom: 1px solid var(--color-border);
      position: relative;
      z-index: 100;

    }

    &__list {
      display: block;
      background-color: white;
      filter: drop-shadow(0px 12px 40px rgba(125, 131, 146, 0.21));

      &_openable {
        max-height: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        min-width: 100%;
        top: 100%;
        z-index: 30;
      }
    }



    &__item {
      width: 100%;
      display: block;

      &._hidden {
        display: none;
      }
    }


    &__input {
      display: none;

      &:checked + .custom-select__label {
        background-color: var(--color-background);

        .custom-select {
          &__choice-icon {
            svg {
              path {
                fill: var(--primary-color);
              }
            }

            &_small {
              opacity: 1;
            }
          }

          &__choice-name {
            color: var(--primary-color);
          }
        }
      }
    }

    &__label {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 22px 0 5px;
      margin: 0;
      min-width: 240px;
      transition: {
        property: background-color;
        duration: .3s;
      };

      &_type-url{
        padding: 0;
      }

      &:hover {
        background-color: var(--color-background);
      }
    }

    &__choice-name {
      color: var(--color-primary-dark);
      font-size: 15px;
      line-height: 135%;
      padding: 8px 0;
      transition: {
        property: color;
        duration: .3s;
      };

      &_without-icon {
        margin-left: 17px;
      }
    }

    &__choice-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: 100%;
        path {
          transition: {
            property: fill;
            duration: .3s;
          };
        }
      }

      &:not(.custom-select__choice-icon_small) {
        path {
          fill: var(--color-border);
        }
      }

      &_small {
        margin-left: 5px;
        flex: 0 0 11px;
        width: 11px;
        height: 11px;
        opacity: 0;
        transition: {
          property: opacity;
          duration: .3s;
        };
      }
    }
  }
}

@include respond-up('large') {
  .custom-select {

  }
}

@include respond-up('medium') {
  .custom-select {

  }
}

@include respond('medium') {
  .custom-select {

  }
}

@include respond-down('medium') {
  .custom-select {

  }
}

@include respond-down('small') {
  .custom-select {

  }
}