.compilation-request-fields {

}

@include respond-up('large') {
  .compilation-request-fields {

  }
}

@include respond-up('medium') {
  .compilation-request-fields {
    display: grid;
    grid-column-gap: 12px;
    grid-template-areas:
          "area price"
          "district district"
          "project rooms";

    &__field.form-field {
      &.area {
        grid-area: area;
      }
      &.price {
        grid-area: price;
      }
      &.district {
        grid-area: district;
      }
      &.project {
        grid-area: project;
      }
      &.rooms {
        grid-area: rooms;
      }
    }
  }
}

@include respond('medium') {
  .compilation-request-fields {

  }
}

@include respond-down('medium') {
  .compilation-request-fields {

  }
}

@include respond-down('small') {
  .compilation-request-fields {

  }
}