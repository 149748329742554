.recall-modal {
  &__subtitle {
    color: var(--color-gray-dark);
  }

  &__image-wrapper {
    position: relative;
    grid-area: picture;
  }

  &__info {
    grid-area: form;
  }

  &__picture {
    display: flex;
    position: relative;
  }

  &__image {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__subtitle {
    margin-top: 12px;
  }
}

@include respond-up("large") {
  .recall-modal {
    &__image {
      width: 551px;
      height: 702px;
    }

    &__image-wrapper {
      grid-column: 1/7;
    }

    &__picture {
      width: 509px;
    }

    &__info {
      grid-column: 7/12;
      padding-top: 100px;
      height: 100%;
      padding-bottom: 80px;
    }

    &__info {
      width: 486px;
    }

    &__form {
      width: 486px;
    }

    .ajax-form__agreement {
      width: auto;
      margin-right: 70px;
    }
  }
}

@include respond-up("medium") {
  .recall-modal {
    &__form {
      margin-top: 40px;
    }
  }
}

@include respond("medium") {
  .recall-modal {
    padding: 100px 95px 38px;

    &__image-wrapper,
    &__info {
      grid-column: span 6;
      width: 500px;
    }

    &__image-wrapper {
      margin-top: 50px;
    }
  }
}

@include respond-down("medium") {
  .recall-modal {
    &__wrap_popup {
      grid-template-areas:
        'form'
        'picture';
    }
  }
}

@include respond-down("small") {
  .recall-modal {
    padding: 100px 19px 0;

    &__image-wrapper,
    &__info {
      grid-column: span 4;
    }

    &__image-wrapper {
      margin-top: 35px;
    }

    &__form {
      margin-top: 25px;
    }
  }
}
