.about-block {
  &__description {
    line-height: 135%;
    color: var(--color-primary-dark);
  }

  &__statistic {
    display: flex;
  }

  &__stat-title {
    font-size: 35px;
    line-height: 135%;
    color: var(--color-primary-dark);
    border-bottom: 1px solid var(--color-border);
  }

  &__stat-description {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
  }

  &__advantages-list {
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    overflow: hidden;

    &:hover {
      .about-block {
        &__advantage-description {
          height: 100%;
          opacity: 1;
        }
      }
    }

    &:nth-child(1) {
      color: white;
      background-color: var(--primary-color);

      .about-block__key {
        border: 1px solid white;
      }
    }

    &:nth-child(2) {
      background-color: var(--color-primary-dark);

      .about-block {
        &__advantage-title {
          color: white;
        }

        &__key {
          border: 1px solid rgba(255, 255, 255, 0.4);
          color: white;
        }
      }
    }

    &:nth-child(3) {
      background-color: white;
      color: var(--color-primary-dark);

      .about-block {
        &__key {
          border: 1px solid var(--color-primary-dark);
        }

        &__advantage-description {
          color: var(--color-primary-dark);
        }
      }
    }

    &_grey {
      &:nth-child(3) {
        background-color: var(--color-background);
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__key-wrapper {
    flex-grow: 1;
  }

  &__key {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 16px;
    line-height: 150%;
  }

  &__advantage-title {
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
  }

  &__advantage-description {
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.5);
  }

  &__head {
    border-top: 1px solid var(--color-border);
  }
}

@include respond-up('large') {
  .about-block {
    &__head {
      padding-top: 40px;
      margin-bottom: 120px;
    }

    &__title {
      grid-column: 1/4;
    }

    &__description-container {
      grid-column: 6/13;
    }

    &__description {
      font-size: 18px;
      margin-bottom: 60px;
    }

    &__statistic {
      justify-content: space-between;
    }

    &__stat-item {
      flex: 0 0 196px;
    }

    &__stat-title {
      padding-bottom: 15px;
      margin-bottom: 8px;
    }

    &__body {
      padding: 0 var(--large-indent);
    }

    &__item {
      height: 350px;
      flex: 1 1 33%;
    }

    &__wrapper {
      padding: 40px;
    }

    &__key {
      flex-grow: 1;
      margin-bottom: 75px;
    }

    &__advantage-title {
      margin-bottom: 12px;
    }

    &__advantage-description {
      opacity: 0;
      height: 0;
      transition: height 0.6s ease-in-out, opacity 0.6s ease-in-out;
    }
  }
}

@include respond('medium') {
  .about-block {
    &__title {
      grid-column: 1/3;

      svg {
        width: 200px;
      }
    }

    &__description-container {
      grid-column: 3/7;
      margin-bottom: 71px;
    }

    &__description {
      margin-bottom: 50px;
    }

    &__statistic {
      justify-content: space-between;
    }

    &__stat-title {
      padding-bottom: 15px;
      margin-bottom: 8px;
    }

    &__advantages-list {
      padding-left: 42px;
      overflow: scroll;
    }

    &__item {
      flex: 0 0 333px;
    }

    &__advantage-title {
      margin-bottom: 12px;
    }
  }
}

@include respond-down('medium') {
  .about-block {
    &__head {
      padding-top: 30px;
    }

    &__wrapper {
      padding: 30px 25px;
    }

    &__key {
      margin-bottom: 90px;
    }

    &__advantages-list {
      overflow: scroll;

      &_column-mobile {
        flex-wrap: wrap;
        padding-left: 0 !important;

        .about-block__item {
          flex: 0 0 100%;
        }
      }
    }
  }
}

@include respond-down('small') {
  .about-block {
    &__title {
      grid-column: 1/5;

      svg {
        max-width: 50%;
      }
    }

    &__description-container {
      grid-column: 1/5;
      margin-top: 35px;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__statistic {
      flex-direction: column;
      padding-left: calc((107 / 375) * 100vw);
      margin-bottom: 40px;
    }

    &__stat-item {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__stat-title {
      font-size: 30px;
      padding-bottom: 14px;
      margin-bottom: 8px;
    }

    &__stat-description {
      font-size: 15px;
    }

    &__advantages-list {
      padding-left: 20px;
    }

    &__item {
      flex: 0 0 288px;
    }

    &__advantage-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__advantage-description {
      font-size: 15px;
    }
  }
}