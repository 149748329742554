.large-text {
   font-style: normal;
   font-weight: normal;

   &._meduim {
     font-weight: 500;
   }
 }

@include respond-up('large') {
  .large-text {
    font-size: 18px;
    line-height: 135%;
  }
}

@include respond('medium') {
  .large-text {
    font-size: 17px;
    line-height: 150%;
  }
}

@include respond-down('small') {
  .large-text {
    font-size: 15px;
    line-height: 135%;
  }
}
