@media print {
  @page {
    size: A4; /* auto is the initial value */
    margin: 0 !important;
    padding: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .container_spacer {
    padding: 0;
  }
}
