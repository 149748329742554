.video-block {
  &__link, &__add{
    position: relative;
    display: block;
  }

  &__link-wrap{
    position: relative;
  }

  &__link{
    z-index: 1;
    &:before{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(3, 15, 50, .35);
      z-index: 2;
    }
  }

  &__preview-picture, &__add-picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__preview-image, &__add-image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__button{
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
    border-radius: 100%;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(191, 202, 235, 0.4);
    z-index: 2;
    svg{
      margin-left: 3px;
    }
  }

  &__video-subtitle{
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark);
  }
}

@include respond-up('large') {
  .video-block {
    padding-bottom: 120px;
    &__video-wrap{
      grid-column: 1 / 6;
    }

    &__add{
      grid-column: 7 / 13;
    }

    &__link{
      margin-bottom: 15px;
    }

    &__link-wrap{
      padding-bottom: calc(271 / 482 * 100%);
    }

    &__add{
      padding-bottom: calc(461 / 584 * 100%);
    }
  }
}

@include respond-up('medium') {
  .video-block {
    &__video-subtitle{
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include respond('medium') {
  .video-block {
    padding-bottom: 70px;
    &__video-wrap{
      grid-column: 1 / 4;
    }

    &__add{
      grid-column: 4 / 7;
    }

    &__link-wrap{
      padding-bottom: calc(187 / 333 * 100%);
    }

    &__add{
      padding-bottom: calc(263 / 333 * 100%);
    }
  }
}

@include respond-down('medium') {
  .video-block {
    &__link{
      margin-bottom: 10px;
    }
  }
}

@include respond-down('small') {
  .video-block {
    padding-bottom: 30px;
    row-gap: 30px;
    &__video-wrap{
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }

    &__add{
      grid-column: 2 / 5;
      grid-row: 2 / 3;
    }

    &__link-wrap{
      padding-bottom: calc(187 / 333 * 100%);
    }

    &__add{
      padding-bottom: calc(263 / 333 * 100%);
    }
    &__video-subtitle{
      font-size: 15px;
      line-height: 135%;
    }
  }
}