.renovation-plate{
  margin-bottom: 8px;
  &:last-child{
    margin-bottom: 0;
  }
  &__wrap{
    position: relative;
    display: flex;
    padding: 12px 18px;
    border: 1px solid var(--color-blue-gray);
    align-items: center;
    justify-content: left;
    cursor: pointer;
    &:hover{
      .renovation-plate{
        &__modal{
          opacity: 1;
          pointer-events: all;
          z-index: 10000;
        }
      }
    }
  }

  &__price{
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
    color: var(--color-primary-dark);
    margin-right: 25px;
    white-space: nowrap;
  }

  &__modal{
    background: transparent;
    max-height: 625px;
    width: 509px;
    position: absolute;
    left: -510px;
    bottom: -50px;
    transition: opacity .3s;
    pointer-events: none;
    padding-right: 25px;
    opacity: 0;
  }

  &__text{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;
    color: var(--primary-color);
  }
}