.notification-message {
  &__text {
    font-weight: 500;
  }

  &_success, &_error {
    .notification-message {
      &__text {
        display: flex;
        align-items: center;
      }
    }
  }

  &_success {
    .notification-message__text {
      color: var(--color-green);
    }
  }

  &_error {
    .notification-message__text {
      color: var(--color-red);
    }
  }
}

@include respond-up('medium') {
  .notification-message {
    &__text {
      font-size: 17px;
      line-height: 22.95 / 17;
    }

    &_success, &_error {
      .notification-message {
        &__text {
          padding-left: 62px;
          min-height: 42px;
          background: {
            size: 42px 42px;
            position: top left;
            repeat: no-repeat;
          };
        }
      }
    }

    &_success {
      .notification-message__text {
        background: {
          image: url('../images/svg/notification-success-large.svg');
        };
      }
    }

    &_error {
      .notification-message__text {
        background: {
          image: url('../images/svg/notification-error-large.svg');
        };
      }
    }

    &_button {
      .notification-message__button {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .notification-message {
    &__text {
      font-size: 16px;
      line-height: 21.6 / 16;
    }

    &_success, &_error {
      .notification-message {
        &__text {
          padding-left: 38px;
          min-height: 28px;
          background: {
            size: 28px 28px;
            position: top left;
            repeat: no-repeat;
          };
        }
      }
    }

    &_success {
      .notification-message__text {
        background: {
          image: url('../images/svg/notification-success-mobile.svg');
        };
      }
    }

    &_error {
      .notification-message__text {
        background: {
          image: url('../images/svg/notification-error-mobile.svg');
        };
      }
    }

    &_button {
      .notification-message__button {
        margin-top: 15px;
      }
    }
  }
}
