.seller-customers {
  &__activity {
    padding: var(--menu-padding);
  }

  &__notify-all {
    width: 100%;
  }

  &__top {
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: 35px;
    margin-bottom: 30px;

    .button {
      width: 100%;

      .button__link {
        padding: 0;
        height: var(--default-input-height);
      }

      &.seller-customers__new-customer-button {
        .button__link {
          height: calc(var(--default-input-height) - 2px);
        }
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 110px;
    grid-template-areas: 'input button'
                         'filter filter'
                         'filter-second filter-second';
  }

  &__form-input-wrapper {
    grid-area: input;
  }

  &__form-button-wrapper {
    grid-area: button;
  }

  &__form-filter {
    grid-area: filter;
    margin-top: 15px;
    font-size: 15px;
  }

  &__form-filter-second {
    grid-area: filter-second;
    margin-top: 15px;
    font-size: 15px;
  }

  &__list-head, &__item {
    display: grid;
    grid-template-columns: 1fr 160px 160px 160px;
    padding: 20px 0;
    grid-gap: 28px;
    border-bottom: 1px solid var(--color-border-60);
  }

  &__list-head {
    color: var(--color-gray-dark);
  }

  &__customer-name {
    display: block;
    transition: color 0.4s;
    margin-bottom: 10px;

    &:hover {
      color: var(--primary-color);
    }
  }

  &__customer-phone {
    color: var(--color-gray-dark);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    display: block;
    padding: 0 10px;
    border-right: 1px solid var(--color-border-40);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  &__new-action{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: var(--primary-color);
    opacity: 0.3;
    display: flex;
    margin-top: 1px;
    margin-right: 10px;
  }

  &__form-checkbox-wrapper{
    //margin-top: 15px;

    label {
      margin: 0;
    }
  }

  &__table {
    width: 100%;
    text-align: left;

    .column-name {

    }

    .column-leads {

    }

    .column-deals {

    }

    .column-actions {
      width: 160px;
    }

    td, th {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 10px;
      border-bottom: 1px solid var(--color-border-60);

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__customer-last-activity {
    color: var(--color-gray-dark);
  }

  &__table-head {
    color: var(--color-gray-dark);
  }

  &__data {
    position: relative;
  }

  &__switcher {
    position: absolute;
    right: 0;
    top: 20px;
    color: var(--color-gray-dark);
    z-index: 100;
  }

  &__switcher-toggle {
    text-align: right;
    display: block;
  }

  .table-switcher {
    background-color: #fff;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    white-space: nowrap;
    display: none;
  }

  &__switcher {
    &._opened {
      .table-switcher {
        display: block;
      }
    }
  }

  &__form-filter {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
  }

  &__form-complex {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    white-space: nowrap;

    input {
      width: 110px;
      padding: 2px 5px;
      appearance: none;
      border-radius: 0;
      border: 1px solid var(--color-border);

      &::placeholder {
        color: var(--color-gray-dark);
      }
    }

    label {
      margin: 0;
      color: var(--color-primary-dark);
      font-size: 16px;
    }

    select {
      padding: 3px 40px 3px 10px;
      height: auto;
      width: 200px;
    }
  }
}