.customer-compilation-apartments {
  padding-top: 120px;

  &__list {
    padding-top: 30px;
  }

  &__item {
    position: relative;
    border-top: 1px solid var(--color-border);

    &:last-child {
      border-bottom: 1px solid var(--color-border);
    }

    &_inactive {
      .customer-compilation-apartments__apartment {
        opacity: .5;
      }
    }
  }

  &__apartment {
  }

  &__half {
    position: relative;
  }

  &__fav-link{
    z-index: 100;
    position: absolute;

    &[data-favorites="1"] {
      svg path {
        fill: var(--primary-color);
      }
    }
  }

  &__fav-icon{
    svg{
      path{
        fill: var(--color-border);
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 26px;
    line-height: 125%;
    color: var(--color-primary-dark);
    margin-bottom: 31px;
  }

  &__info-block {

  }

  &__short-info {

  }

  &__info-list {
    margin-top: 20px;
  }

  &__inactive-badge {
    color: #fff;
    background-color: var(--alert-color);
    position: absolute;
    right: 0;
    z-index: 100;
    padding: 5px 20px;

    &_blue {
      background-color: var(--color-blue-dark);
    }
  }
}

@include respond-up('large') {
  .customer-compilation-apartments {
    &__apartment {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      grid-column-gap: var(--grid-column-gap);
    }

    &__half {
      padding: 40px 30px;

      &:first-child {
        border-right: 1px solid var(--color-border);
        grid-column: 1/6;
      }

      &:last-child {
        grid-column: 6/12;
      }
    }

    &__inactive-badge {
      top: 40px;
    }
  }
}

@include respond-down('medium') {
  .customer-compilation-apartments {
    &__half {
      padding-bottom: 40px;

      &:first-child {
        padding-top: 30px;
      }
    }

    &__inactive-badge {
      top: 30px;
    }
  }
}