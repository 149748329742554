.projects-page {
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    background-color: var(--color-gray-light);
    width: 100%;
  }

  &__inner {
    position: relative;
  }

  &__heading {
    display: flex;

    h1 {
      padding-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .projects-page {
    &__background {
      height: 86px;
    }

    &__description {
      flex: 0 1 386px;
    }

    &__heading {
      padding-bottom: 157px;
    }
  }
}

@include respond-up('medium') {
  .projects-page {
    &__heading {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .projects-page {
    &__background {
      height: 50px;
    }

    &__heading {
      padding-bottom: 120px;
    }

    &__description {
      flex: 0 1 316px;
    }
  }
}

@include respond-down('medium') {
  .projects-page {

  }
}

@include respond-down('small') {
  .projects-page {
    &__background {
      height: 61px;
    }

    &__heading {
      flex-direction: column;
      padding-bottom: 91px;
    }

    &__description {
      padding-top: 20px;
    }
  }
}