.footer {
  background: var(--color-primary-dark);
  overflow: hidden;

  .point, .small-label {
    color: var(--color-white-opacity-50);
  }

  &__developer-link {
    color: white;
  }

  &__lower-info {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 10px;
  }

  &__info {
    color: white;
  }

  &__phone {
    font-weight: 500;
    display: block;
  }

  &__email {
    font-weight: normal;
    display: block;
  }

  &__logo {
    display: block;

    svg {
      height: 59px;
      width: 49px;

      path {
        fill: var(--primary-color);
      }
    }
  }

  &__menu {
    color: white;
  }

  &__menu-list {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__menu-item-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-white-opacity-50);
    margin-bottom: 16px;
  }

  &__menu-item, &__menu-sub-list {
    display: flex;
    flex-direction: column;
  }

  &__subscribe-slogan {
    font-style: normal;
    font-weight: 500;
    color: white;
    grid-column: 1/5;
  }

  &__menu-link {
    display: block;
    font-weight: normal;
    font-size: 15px;
    line-height: 135%;
    padding-bottom: 9px;
  }

  &__menu-sub-item {
    &:last-child {
      margin-bottom: -9px;
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
  }

  &__input-wrap {
    width: 100%;
  }

  &__submit-button {
    position: absolute;
    --button-size: calc(var(--default-input-height) - 10px);
    right: 5px;
    bottom: calc(50% - var(--button-size) / 2);

    .button__link-arrow {
      width: var(--button-size);
      height: var(--button-size);
    }

    svg {
      path {
        fill: var(--color-border);
      }
    }
  }

  &__form {
    position: relative;
  }

  &__form-success {
    color: white;
    background-color: var(--primary-color);
  }

  &__policy{
    margin-top: 26px;
  }
}

@include respond-up('large') {
  .footer {
    &__lower {
      padding: 50px 0 75px;
    }

    &_lower {
      border-top: 1px solid var(--color-white-opacity-20);
    }

    &__lower-info {
      grid-column: 1/5;
    }

    &__lower-disclaimer {
      grid-column: 5/13;
    }

    &__phone {
      font-size: 20px;
      line-height: 140%;
      padding-bottom: 9px;
    }

    &__email {
      font-size: 16px;
      line-height: 150%;
    }

    &__info {
      padding-top: 80px;
    }

    &__logo {
      padding-bottom: 36px;
    }

    &__social, &__subscribe-block {
      height: 154px;
      display: grid;
      align-items: center;
      align-self: end;
    }

    &__subscribe-block {
      grid-template-columns: repeat(8, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__menu {
      padding-top: 80px;
      padding-bottom: 112px;
    }

    &__form {
      grid-column: 5/9;
      padding-right: 60px;
    }

    &__social_mobile {
      display: none;
    }

    &__plug {
      grid-column: 4/5;
      position: relative;
      align-self: stretch;
      border-left: 1px solid var(--color-white-opacity-20);;

      &:before {
        position: absolute;
        content: "";
        bottom: 154px;
        left: 0;
        height: 1px;
        background: var(--color-white-opacity-20);
        width: 2560px;
      }
    }

    &__left-wrapper {
      display: grid;
      grid-column: 1/4;
    }

    &__right-wrapper {
      grid-column: 5/13;
    }

    &__menu-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include respond-up('medium') {
  .footer {
    --default-input-height: 64px;

    &__subscribe-slogan {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include respond('medium') {
  .footer {
    &__phone {
      font-size: 16px;
      line-height: 135%;
    }

    &__logo {
      padding-bottom: 30px;
    }

    &__lower {
      padding: 35px 0 50px;
      grid-template-areas:
        "info social"
        "disclaimer disclaimer";
      grid-row-gap: 50px;
    }

    &__social_mobile {
      grid-area: social;
      grid-column: 5/7;
      justify-self: end;
      margin-top: -9px;
      padding-right: 10px;
    }

    &__lower-info {
      grid-area: info;
      grid-column: 1/5;
    }

    &__lower-disclaimer {
      grid-area: disclaimer;
      grid-column: 1/7;
    }

    &__info {
    }

    &__left-wrapper {
      padding-top: 50px;
      grid-column: 1/3;
      position: relative;
      padding-right: calc(25% + 11px);

      &:before {
        bottom: 202px;
        left: calc(75% + 9px);
        height: 1px;
        width: 2560px;
      }

      &:after {
        width: 1px;
        height: 100%;
        right: calc(25% - 9px);
        top: 0;
        bottom: 0;
      }

      &:before, &:after {
        background: var(--color-white-opacity-20);
        position: absolute;
        content: "";
      }
    }

    &__menu {
      padding-top: 50px;
      padding-bottom: 70px;
    }

    &__subscribe-block {
      height: 202px;
      flex-direction: column;
      align-items: center;
      align-self: end;
      align-content: center;
      display: grid;
      grid-row-gap: 20px;
    }

    &__right-wrapper {
      grid-column: 3/7;
    }

    &__plug {
      display: none;
    }

    &__subscribe-slogan, &__form {
      grid-column: 1/5;
    }

    &__phone{
      padding-bottom: 9px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__email {
      font-size: 15px;
      line-height: 135%;
    }

    &__input-wrapper {
      input[type="text"] {
        padding-right: 50px;
      }
    }

    &__social_desktop {
      display: none;
    }

    &_lower {
      border-top: 1px solid var(--color-white-opacity-20);
    }

    &__menu-list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 50px;
    }
  }
}

@include respond-down('small') {
  .footer {
    --default-input-height: 54px;

    &__phone {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 8px;
    }

    &__lower {
      padding: 30px 0;
    }

    &__social_mobile {
      grid-column: 1/5;
      padding-bottom: 40px;
    }

    &__lower-info {
      grid-column: 1/5;
      padding-bottom: 30px;
    }

    &__lower-disclaimer {
      grid-column: 1/5;
    }

    &__subscribe-slogan {
      font-size: 15px;
      line-height: 135%;
      padding-bottom: 20px;
    }

    &__left-wrapper {
      grid-column: 1/5;
    }

    &__right-wrapper {
      grid-column: 1/5;
    }

    &__info {
      padding: 25px 0;
      display: flex;
      align-items: flex-end;
    }

    &__mobile-border {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: -50%;
        height: 1px;
        background: var(--color-white-opacity-20);
        width: 2560px;
      }
    }

    &__logo {
      margin-right: 25px;
    }

    &__menu-list {

    }

    &__menu {
      padding: 30px 0 40px;
    }

    &__subscribe-block {
      padding: 30px 0;
    }
  }
}


@media print {
  .footer {
    background: #fff;

    &__info {
      color: #000;
    }

    &__right-wrapper {
      display: none;
    }

    &__social {
      display: none;
    }

    &__lower-info {
      display: none;
    }

    &__lower-disclaimer .small-label {
      color: #868686;
    }
    &__logo {
      svg {
        path {
          fill: #000;
        }
      }
    }
  }
}
