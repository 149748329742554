.client-apartment-card {
  background: white;

  &__status {
    display: flex;
    align-items: center;
  }

  &__status-icon {
    width: 42px;
    height: 42px;
    margin-right: 11px;
  }

  &__status-data {
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__status-item {
    display: flex;
    align-items: center;
  }

  &__status-description {
    color: var(--color-gray-dark);
  }

  &__status-value {
    margin-left: 5px;
    color: var(--primary-color);
  }

  &__paid-tag-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__paid-tag {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    padding: 5px 7px;
    height: fit-content;

    &_paid {
      background: var(--color-green-opacity-15);
      color: var(--color-green);
    }

    &_not-paid {
      background: var(--color-red-opacity-15);
      color: var(--color-red);
    }
  }
}

@include respond-up('medium') {
  .client-apartment-card {
    &__footer {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .client-apartment-card {
    padding: 20px 0 20px;

    &__footer {
      padding-top: 15px;
    }
  }
}