.actions {
  &__list {
    position: relative;

  }
  &__item {
    border-right: 1px solid var(--color-border);
    display: flex;
    &:nth-child(3n+1) {
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-border);
      }
    }
    &:nth-child(3n+3) {
      border-right: none;
    }
  }
  &__svg {
    width: 48px;
    height: 48px;
    border: 1px solid var(--color-blue-gray);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__created-at {
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }

  &__description {
    font-weight: 500;
    line-height: 135%;
  }

  &__wrapper {
    display: block;
    flex: 0 0 100%;
  }
}

@include respond-up('large') {
  .actions {
    padding: 70px 0 170px;
    &__list {
      grid-row-gap: 60px;
    }

    &__item {
      &:nth-child(3n+1) {
        grid-column: 1/5;
      }
      &:nth-child(3n+2) {
        grid-column: 5/9;
        margin-left: 10px;
      }
      &:nth-child(3n+3) {
        grid-column: 9/13;
        margin-left: 10px;
      }
    }

    &__wrapper {

    }

    &__svg {
      margin-top: 25px;
        margin-bottom: 40px;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__description {
      font-size: 19px;
      margin-bottom: 38px;
      max-width: 345px;

    }
  }
}
@include respond('medium') {
  .actions {
    padding: 40px 0 90px;
    &__list {
      grid-row-gap: 42px;
    }

    &__item {
      &:nth-child(2n+1) {
        grid-column: 1/4;
      }
      &:nth-child(2n+2) {
        grid-column: 4/7;
        border-right: none;
      }
    }

    &__wrapper {

    }

    &__svg {
      margin: 20px 0 40px;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__description {
      margin-bottom: 35px;
      max-width: 310px;
    }
  }
}
@include respond-down('medium') {
  .actions {
    &__list {
      overflow: hidden;
    }

    &__item {
      &:nth-child(2n+3) {
        border-right: 1px solid var(--color-border);
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-border);
        }
      }
    }

    &__wrapper {

    }

    &__svg {

    }

    &__created-at {

    }

    &__description {
      font-size: 16px;
    }
  }
}
@include respond-down('small') {
  .actions {
    padding: 30px 0 50px;
    &__list {
      grid-row-gap: 30px;
    }

    &__item {
      &:nth-child(2n+1) {
        grid-column: 1/3;
      }
      &:nth-child(2n+2) {
        grid-column: 3/5;
        border-right: none;
      }

    }

    &__wrapper {

    }

    &__svg {
      margin: 20px 0;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__description {
      margin-bottom: 20px;
      max-width: 153px;

    }
  }
}