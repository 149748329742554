.button-text {
  font-style: normal;
  font-size: 15px;
  line-height: 120%;
  color: var(--color-primary-dark);

  &._medium {
    font-weight: 500;
  }

  &._mini {
    font-weight: normal;
    font-size: 14px;
  }

   &._white{
     color: white;
   }
}