.uikit-page {
  padding-top: 100px;

  &__grid-example {
    background-color: var(--primary-color);
    color: white;
    height: 150px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__element-desc {
    padding: 20px;
    background: var(--color-primary-dark);
    color: white;
    margin: 10px 0 30px;
  }
}

@include respond-up('large') {
  .uikit-page {
    &__grid-example {
      &:nth-child(1) {
        grid-column: 1/17;
      }

      &:nth-child(2) {
        grid-column: 1/6;
      }

      &:nth-child(3) {
        grid-column: 8/17;
      }
    }

    &__element {
      flex: 0 0 48%;
      margin-bottom: 50px;
    }
  }
}

@include respond-up('medium') {
  .uikit-page {

  }
}

@include respond('medium') {
  .uikit-page {
    &__grid-example {
      &:nth-child(1) {
        grid-column: 1/7;
      }

      &:nth-child(2) {
        grid-column: 1/3;
      }

      &:nth-child(3) {
        grid-column: 4/7;
      }
    }
  }
}

@include respond-down('medium') {
  .uikit-page {
    &__element {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .uikit-page {
    &__grid-example {
      &:nth-child(1) {
        grid-column: 1/5;
      }

      &:nth-child(2) {
        grid-column: 1/3;
      }

      &:nth-child(3) {
        grid-column: 4/5;
      }
    }
  }
}
