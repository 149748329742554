.offices {
  &__list-item {
    &:not(:last-child) {
      .offices__list-link {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          height: 1px;
          background-color: var(--color-border);
        }
      }
    }
  }

  &__list-link {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__list-item-image {
    flex: 0 0 auto;
    margin-right: 18px;
    border-radius: 100%;
    overflow: hidden;
  }

  &__list-content {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__list-item-name {
    font-style: normal;
    font-weight: 500;
  }

  &__list-item-address {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
    color: var(--color-gray-dark);
    margin-top: 6px;
  }

  &__list-item-picture,
  &__item-picture {
    display: flex;
  }

  &__list {
    overflow: hidden;
  }

  &__item-back-wrap {
    margin: -10px;
  }

  &__item-back {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 10px;
  }

  &__item-back-icon {
    margin-right: 10px;
    flex: 0 0 auto;
  }

  &__item-back-title {
    flex: 0 1 auto;
  }

  &__item {
    background-color: #fff;
  }

  &__bg {
    display: none;
  }

  &__heading {
    display: flex;
  }
}

@include respond-up('large') {
  .offices {
    &__map {
      height: 830px;
    }

    &__items {
      top: 95px;
    }

    &__list {
      max-width: 380px;
      height: 534px;
      top: 95px;
    }

    &__list-elements {
      max-height: 534px;
    }

    &__list-link {
      padding: 20px 35px;

      &::after {
        width: calc(100% - 70px);
      }
    }

    &__item {
      padding: 32px 35px 40px;
    }

    &__list-content {
      min-width: 230px;
    }

    &__item-image {
      margin-top: 25px;
    }

    &__item-picture {
      width: 310px;
      height: 165px;
    }

    &__button {
      margin-top: 5px;
    }
  }
}

@include respond-up('medium') {
  .offices {
    &__list-item-name {
      font-size: 16px;
      line-height: 1.5em;
    }

    &__show-map {
      display: none;
    }

    &__show-list {
      display: none;
    }

    &__container {
      position: relative;
    }

    &__list, &__items {
      position: absolute;
      z-index: 100;
      left: 0;
    }

    &__list {
      background-color: #fff;
      transform: translate3d(-20px, 0, 0);
      opacity: 0;
      transition: all 0.4s;
      visibility: hidden;
    }

    &__items {
      pointer-events: none;
    }

    &__item {
      //width: 300px;
      transform: translate3d(20px, 0, 0);
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.4s;
    }

    &._list-screen {
      .offices {
        &__list {
          opacity: 1;
          transform: translate3d(0px, 0, 0);
          pointer-events: auto;
          visibility: visible;
        }
      }
    }

    &._item-screen {
      .offices {
        &__item._show {
          transform: translate3d(0px, 0, 0);
          opacity: 1;
          pointer-events: auto;
          visibility: visible;
        }
      }
    }

    &__item-back-title {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.2em;
      color: var(--color-gray-dark);
    }

    &__excursion {
      display: none;
    }

    &__list-elements {
      overflow-y: scroll;
      height: 100%;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px var(--t);
        border: solid 3px var(--t);
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px var(--color-background);
        border: solid 3px var(--t);
      }
    }

    &__heading {
      flex-direction: row;
      justify-content: space-between;
    }

    &__button {
      height: 54px;
    }
  }
}

@include respond('medium') {
  .offices {
    &__map {
      height: 700px;
    }

    &__list {
      max-width: 300px;
      height: 514px;
      top: 37px;
    }

    &__list-item {
      &:first-child {
        .offices__list-link {
          padding: 20px 25px;
        }
      }
    }

    &__items {
      top: 37px;
    }

    &__list-link {
      padding: 12px 25px 20px;

      &::after {
        width: calc(100% - 50px);
      }
    }

    &__item-picture {
      width: 250px;
      height: 133px;
    }

    &__item-image {
      margin-top: 22px;
    }

    &__item {
      padding: 22px 25px;
    }

    &__list-elements {
      max-height: 514px;
    }

    &__button {
      margin-top: -7px;
    }
  }
}

@include respond-down('medium') {
  .offices {
  }
}

@include respond-down('small') {
  .offices {
    &__items {
      position: absolute;
      bottom: 40px;
      left: #{$spacer-small}px;
      right: #{$spacer-small}px;
    }

    &__item-image {
      display: none;
    }

    &__list-item-name {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__list-item {
      margin: 0 -#{$spacer-small}px;
    }

    &__list-link {
      padding: 20px #{$spacer-small}px;

      &::after {
        width: calc(100vw - #{$spacer-small}px * 2);
      }
    }

    &__map {
      display: none;
      height: 500px;
    }

    &__list {
      display: none;
    }

    &__item {
      display: none;
      padding: 20px 25px;
      box-shadow: 0 12px 40px rgba(125, 131, 146, 0.21);
    }

    &._list-screen {
      .offices {
        &__show-list {
          display: none;
        }

        &__list {
          display: block;
          padding-top: 20px;
        }
      }
    }

    &__bg {
      width: 100vw;
      height: 159px;
      background-color: #fff;

      &._visible {
        display: block;
      }
    }

    &._item-screen {
      position: relative;

      .offices {
        &__map {
          display: block;
        }

        &__item._show {
          display: block;
          position: relative;
          z-index: 10;
        }
      }
    }

    &__show-map {
      width: 100%;
    }

    &__excursion {
      width: 100%;
      margin-top: 8px;
    }

    &__list-elements {
      margin-top: 10px;
    }

    &__item-back-wrap {
      display: none;
    }

    &__show-list {
      display: flex;
      position: absolute;
      right: #{$spacer-small}px;
      top: 10px;
      z-index: 10;
    }

    &__show-list-link {
      display: flex;
      align-items: center;
    }

    &__show-list-icon {
      display: flex;
      align-items: baseline;
      flex: 0 0 auto;
      margin-right: 10px;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &__show-list-title {
      display: flex;
      flex: 0 1 auto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.2em;
      padding-top: 1px;
    }

    &__button {
      display: none;
    }
  }
}