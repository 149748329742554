.button {
  padding: 0;
  margin: 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  transition: border-color var(--default-transition-duration);

  svg {
    path {
      transition: fill var(--default-transition-duration);
    }
  }

  &_normal {
    font-weight: normal;
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_primary {
    background: var(--primary-color);
    color: white;

    .button__link {
      color: white;
    }

    &._white {
      background: white;
      color: var(--color-primary-dark);

      .button__link {
        color: var(--color-primary-dark);
      }

      svg {
        path {
          fill: var(--color-primary-dark);
        }
      }

      &:hover {
        color: var(--color-gray-dark);

        .button__link {
          color: var(--color-gray-dark);
        }
      }
    }
  }

  &__link {
    z-index: 200;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--t);
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    transition: color var(--default-transition-duration);
  }

  &_filter-wrapper {
    position: relative;
    display: inline-flex;

    &:hover {
      .button {
        &__filter {
          height: 100%;
        }
      }
    }
  }

  &__filter {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-blue-dark);
    height: 0;
    width: 100%;
    transform-origin: bottom;
    transition: height 0.3s ease-in-out;
    z-index: 100;
  }

  &_secondary {
    color: var(--primary-color);
    border: 1px solid var(--color-blue-gray);

    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }

  &_alert {
    color: var(--alert-color);
    border: 1px solid var(--alert-color);

    svg {
      path {
        fill: var(--alert-color);
      }
    }
  }

  &_secondary-black {
    color: var(--color-primary-dark);
    border: 1px solid var(--color-border);

    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }

  &_tetriary {
    padding-bottom: 7px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      background: var(--color-primary-dark);
      bottom: 0;
      width: 100%;
      transform-origin: right;
      transition: transform 0.4s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      background: var(--color-primary-dark);
      bottom: 0;
      width: 100%;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.4s ease-out;
    }

    &:hover {
      &::after {
        transition: transform .3s var(--default-bezier) .33s;
        transform: scaleX(1);
      }

      &::before {
        transition: transform .3s var(--default-bezier);
        transform: scaleX(0);
      }
    }

    &_primary {
      color: var(--primary-color);

      &:before, &:after {
        background: var(--primary-color);
      }
    }
  }

  &_action {
    padding: 7px;
    margin: -7px;
    color: var(--color-gray-dark);
    transition: color var(--default-transition-duration);

    &:hover {
      color: var(--primary-color);

      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }

  &._border {
    border: 1px solid var(--color-border);
  }

  &._small {
    .button {
      &__link {
        padding: 10px 28px;
      }
    }
  }

  &__icon {
    display: inline-flex;
  }

  &__link-arrow {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link-arrow-right {
    border: 1px solid var(--color-border);

    &:hover {
      border-color: var(--color-primary-dark);
    }
  }
}

@include respond-up('large') {
  .button {
    &_secondary {
      &:hover {
        border-color: var(--primary-color);
      }
    }

    &_secondary-black {
      &:hover {
        border-color: var(--color-primary-dark);
      }
    }

    &__icon {
      margin-right: 12px;
    }

    &_action {
      &__icon {
        margin-right: 6px;
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    &_primary, &_secondary-black, &_alert {
      .button {
        &__link {
          padding: 18px 30px;
        }
      }
    }

    &_secondary {
      .button {
        &__link {
          padding: 17px 30px;
        }
      }
    }

    &__link-arrow {
      width: 54px;
      height: 54px;
    }
  }
}

@include respond-down('medium') {
  .button {

    &__icon {
      margin-right: 10px;
    }
  }
}

@include respond-down('small') {
  .button {
    &_primary, &_secondary-black, &_alert {
      .button {
        &__link {
          padding: 13px 20px;
        }
      }
    }

    &_secondary {
      .button {
        &__link {
          padding: 12px 20px;
        }
      }
    }

    &__link-arrow {
      width: 44px;
      height: 44px;
    }
  }
}
