.layout-apartments {
  &__title {
    font-style: normal;
    font-weight: 500;
  }

  &__items {
    display: grid;
    margin-top: 20px;
  }

  &__item {
    border-top: 1px solid var(--color-border);
  }
}

@include respond-up('large') {
  .layout-apartments {
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }

    &__button {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .layout-apartments {
    &__title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__items {
      grid-row-gap: 40px;
    }
  }
}

@include respond('medium') {
  .layout-apartments {
    &__items {
      grid-template-columns: repeat(3, 1fr);
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .layout-apartments {

  }
}

@include respond-down('small') {
  .layout-apartments {
    &__title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__items {
      grid-template-columns: repeat(1, 1fr);
    }

    &__button {
      margin-top: 30px;
    }
  }
}