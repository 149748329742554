.project-construction-progress {
  .tns-nav {
    display: none;
  }

  &__image-link {
    display: flex;
    position: relative;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 66%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__filters {
    grid-area: filters;
  }

  &__slider-wrap {

  }

  &__strip {
    height: 1px;
    background-color: var(--color-border);
    overflow: hidden;
    width: 160px;
    flex: 0 0 auto;
    margin-right: 50px;
  }

  &__strip-filling {
    background-color: var(--color-primary-dark);
    height: 100%;
    transition: width .9s;
  }

  &__controls {
    display: flex;
    max-width: 350px;
  }

  &__controls-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__slider {
    display: flex;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;
  }

  &__item-text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: flex-end;
    z-index: 15;
  }

  &__item-text-inner {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-title {
    font-style: normal;
    font-weight: 500;
    color: #fff;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(0.71deg, rgba(0, 9, 33, 0.4) 4.19%, rgba(3, 13, 44, 0) 99.39%);
  }

  &__item-count {
    display: flex;
    align-items: center;
  }

  &__item-count-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--color-blue-gray-opacity-35);
    flex: 0 0 auto;
    margin-right: 8px;
  }

  &__item-count-title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
    color: #fff;
    flex: 0 1 auto;
  }

  &__hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background-color: var(--color-blue-gray-opacity-35);
    border-radius: 100%;

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &__image-wrap {
    &:nth-child(n+2) {
      display: none;
    }
  }
}

@include respond-up('large') {
  .project-construction-progress {
    &__item-text-inner {
      width: calc(100% - 28px);
    }

    &__title {
      margin-bottom: 40px;
    }

    &__picture {
      width: 380px;
      height: 252px;
    }

    &__slider-wrap {
      margin-top: 40px;
    }

    &__slider {
      margin-left: calc((var(--max-row-width) / 2 * -1px) + #{$spacer-large}px + 190px);
    }

    &__controls-wrap {
      margin-top: 60px;
    }

    &__hover {
      opacity: 0;
      transition: opacity .4s;
    }

    &__image-link {
      &:hover {
        .project-construction-progress__hover {
          opacity: 1;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-construction-progress {
    &__controls {
      align-items: flex-end;
    }

    &__cam {
      max-width: 208px;

      &_small {
        display: none;
      }
    }

    &__item-text {
      padding: 20px;
    }

    &__item-title {
      font-size: 17px;
      line-height: 1.35em;
    }
  }
}

@include respond('medium') {
  .project-construction-progress {
    &__title {
      margin-bottom: 30px;
    }

    &__item-text-inner {
      width: calc(100% - 24px);
    }

    &__slider-wrap {
      margin-top: 30px;
    }

    &__slider {
      margin-left: calc((100vw / 2 - #{$spacer-medium}px - 167px) * -1);
    }

    &__controls-wrap {
      margin-top: 35px;
    }

    &__picture {
      width: 333px;
      height: 220px;
    }
  }
}

@include respond-down('medium') {
  .project-construction-progress {

  }
}

@include respond-down('small') {
  .project-construction-progress {
    &__title {
      margin-bottom: 20px;
    }

    &__cam {
      display: none;

      &_small {
        display: flex;
        width: 100%;
        margin-top: 20px;
      }
    }

    &__picture {
      width: 304px;
      height: 201px;
    }

    &__item-text-inner {
      width: calc(100% - 12px);
    }

    &__item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__item-text {
      padding: 15px;
    }

    &__controls-wrap {
      display: none;
    }

    &__slider-wrap {
      margin-top: 20px;
    }

    &__slider {
      margin-left: calc((100vw / 2 - #{$spacer-small}px - 152px) * -1);
    }

    .tns-nav {
      margin-top: 25px;
    }
  }
}