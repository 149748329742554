.circle {
  border-radius: 100%;

  &:nth-child(1) {
    background-color: var(--color-gray-light);
  }

  &:nth-child(2) {
    background-color: var(--primary-color);
  }

  &:nth-child(3) {
    background-color: var(--color-primary-dark);
  }
}

@include respond-up('large') {
  .circle {
    width: 262px;
    height: 262px;
  }
}

@include respond-up('medium') {
  .circle {

  }
}

@include respond('medium') {
  .circle {
    width: 230px;
    height: 230px;
  }
}

@include respond-down('medium') {
  .circle {

  }
}

@include respond-down('small') {
  .circle {
    width: 194px;
    height: 194px;
  }
}