@use "sass:math";

.page-slider {

  &__item {
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
  }

  &__picture {
    display: block;
    position: relative;
    overflow: hidden;
  }

  &__feature-item {
    position: absolute;

    &:hover {
      z-index: 30;

      .page-slider__feature-content {
        opacity: 1;
        transform: translateY(0);

      }
    }
  }

  &__feature-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
  }

  &__feature-sizeble {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(34, 44, 57, 0.33);
    opacity: 0;
    border-radius: 100px;
    animation-name: pulsar;
    animation-iteration-count: infinite;
    animation-duration: 3.6s;
  }

  &__feature-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;

    svg {
      z-index: 50;
    }

    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-primary-dark);
      opacity: 0.6;
      width: 28px;
      height: 28px;
      border-radius: 100%;
    }
  }

  &__feature-content {
    position: absolute;
    width: 268px;
    background-color: white;
    padding: 21px;
    font-size: 13px;
    line-height: 125%;
    pointer-events: none;
    opacity: 0;
    transition: {
      duration: .3s;
      timing-function: ease-in-out;
      property: opacity, transform;
    };

    &._left {
      right: 0;
    }

    &._right {
      left: 0;
    }

    &._top {
      bottom: 54px;
      transform: translateY(-7px);
    }

    &._bottom {
      top: 54px;
      transform: translateY(7px);
    }
  }

  &__feature-image {
    display: block;
    margin-bottom: 13px;
  }

  &__arrows {
    display: flex;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .page-slider {
    &__image-container {
      margin-bottom: 38px;
    }

    &__picture {
      padding-top: math.div(648, 1202) * 100%;
    }


    &__feature-content {
      &._top {
        bottom: 54px;
      }

      &._bottom {
        top: 54px;
      }
    }
  }
}

@include respond('medium') {
  .page-slider {
    &__image-container {
      margin-bottom: 30px;
    }

    &__picture {
      padding-top: math.div(372, 690) * 100%;
    }

    &__feature-content {
      &._top {
        bottom: 44px;
      }

      &._bottom {
        top: 44px;
      }
    }
  }
}

@include respond-down('small') {
  .page-slider {
    &__image-container {
      margin-bottom: 20px;
    }

    &__picture {
      padding-top: math.div(183, 338) * 100%;
    }

    &__feature-item {
      display: none;
    }
  }
}

@keyframes pulsar {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  16% {
    opacity: 1;
  }

  33% {
    transform: scale(1.6);
    opacity: 0;
  }

  34% {
    transform: scale(1);
  }
  36% {
    opacity: 1;
  }

  53% {
    opacity: 1;
  }

  70% {
    transform: scale(1.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}