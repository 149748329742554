.about-what-made-of-article {
  background-color: #fff;

  &__to-list {
    display: flex;
  }

  &__to-list-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(180deg);

      path {
        fill: var(--color-border);
      }
    }
  }

  &__to-list-title {
    margin-left: 10px;
  }

  &__main {

  }

  &__sticky {
    position: sticky;
    top: 140px;
  }

  &__bottom-inner {
    display: flex;
  }
}

@include respond-up('large') {
  .about-what-made-of-article {
    &__content-header {
      padding-top: 85px;
    }

    &__title {
      margin-top: 15px;
      padding-bottom: 50px;
    }

    &__top {
      border-bottom: 1px solid var(--color-border);
    }

    &__main {

    }

    &__main-left {
      grid-column: 1/10;
      padding: 70px 74px 60px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        right: 23px;
        background-color: var(--color-border);
      }
    }

    &__main-right {
      grid-column: 10/13;
      padding: 70px 0 60px;
    }

    &__see-also-list {
      margin-top: 8px;
    }

    &__see-also-item {
      &:not(:last-child) {
        .about-what-made-of-article__see-also-link {
          border-bottom: 1px solid var(--color-border);
        }
      }
    }

    &__see-also-link {
      display: flex;
      padding: 22px 0;

      &:hover {
        .about-what-made-of-article__see-also-link-title {
          color: var(--color-gray-dark);
        }
      }
    }

    &__see-also-link-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      transition: color .4s;
    }

    &__bottom {
      padding: 35px 0 120px;
    }
  }
}

@include respond-up('medium') {
  .about-what-made-of-article {
    &__to-list-icon {
      background-color: #fff;
      border-radius: 100%;
      width: 42px;
      height: 42px;
    }

    &__to-list-title {
      padding-top: 9px;
    }

    &__bottom-inner {
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .about-what-made-of-article {
    &__content-header {
      padding-top: 32px;
    }

    &__title {
      padding-bottom: 45px;
    }

    &__bottom {
      padding: 30px 0 90px;
    }

    &__main-left {
      grid-column: 1/7;
      padding: 40px 0 50px;
    }
  }
}

@include respond-down('medium') {
  .about-what-made-of-article {
    &__title {
      margin-top: 10px;
    }

    &__main {

    }

    &__main-left {
      border-bottom: 1px solid var(--color-border);
    }

    &__main-right {
      display: none;
    }
  }
}

@include respond-down('small') {
  .about-what-made-of-article {
    &__content-header {
      padding-top: 30px;
    }

    &__to-list {
      align-items: center;
    }

    &__to-list-icon {
      padding-top: 4px;
    }

    &__title {
      padding-bottom: 30px;
    }

    &__bottom {
      padding: 25px 0 50px;
    }

    &__bottom-inner {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__bottom-button {
      margin-top: 18px;
      width: 100%;
    }

    &__main-left {
      grid-column: 1/5;
      padding: 30px 0 40px;
    }
  }
}