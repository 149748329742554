.seller-map {
  .project-map {
    &__bg {
      display: none;
    }

    &__filter {
      right: 0;
      transform: unset;
      flex-direction: row-reverse;
    }

    .container,
    .container_spacer {
      margin: 0 auto;
      padding: 0 var(--grid-spacer);
    }
  }
}

@include respond-up('large') {
  .seller-map {
    .project-map {
      margin-top: unset;

      &__filter {
        bottom: 45px;
      }

      .container,
      .container_spacer {
        width: var(--max-row-width);
      }
    }
  }
}

@include respond-up('medium') {
  .seller-map {
    .project-map__canvas {
      height: 100vh;
    }
  }
}

@include respond('medium') {
  .seller-map {
    .project-map {
      margin-top: 189px;
    }
  }
}

@include respond-down('medium') {
  .seller-map {
    .project-map {
      &__filter {
        bottom: 40px;
      }
    }
  }
}

@include respond-down('small') {
  .seller-map {
    .project-map {
      margin-top: 152px;

      &__canvas {
        height: calc(100vh - 152px);
      }
    }
  }
}