.menu-project {
  border-bottom: 1px solid var(--color-border);
  border-top: 1px solid var(--t);
  transition: border-top-color .4s;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &._active {
      .menu-project__link {
        color: var(--color-primary-dark);
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2em;
    color: var(--color-gray-dark);
    white-space: nowrap;
  }

  &__more {
    transition: opacity .4s;

    &._hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__more-title {
    flex: 0 0 auto;
    margin-right: 8px;
    display: flex;
  }

  &__more-icon {
    display: flex;
    svg {
      transform: rotate(-90deg);
      path {
        fill: var(--color-primary-dark)
      }
    }
  }

  &__inner-wrap {
    display: flex;
    align-items: center;
  }

  &._sticky {
    position: fixed;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 3000;
    border-top-color: var(--color-border);
  }
}

@include respond-up('large') {
  .menu-project {
    &._sticky {
      top: var(--menu-translate);
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inner-wrap {
    }

    &__list {
      max-width: 917px;
    }

    &__link {
      padding: 40px 15px;

      &._drop {
        min-width: 209px;
        padding: 12px 25px;

        &:first-child {
          padding: 25px 25px 12px;
        }

        &:last-child {
          padding: 12px 25px 25px;
        }
      }
    }

    &__drop-menu-wrap {
      position: relative;

      &:hover {
        .menu-project__drop-menu-container {
          opacity: 1;
          pointer-events: auto;
          background-color: #fff;
          z-index: 100;
        }
      }
    }

    &__drop-menu-container {
      position: absolute;
      opacity: 0;
      box-shadow: 0 8px 10px rgba(125, 131, 146, 0.08);
      pointer-events: none;
      transition: opacity .4s;
    }
  }
}

@include respond-up('medium') {
  .menu-project {
    &__link {
      font-size: 17px;
    }
  }
}

@include respond('medium') {
  .menu-project {
    padding-left: calc(#{$spacer-medium}px - 15px);

    &._sticky {
      top: var(--menu-translate);
    }

    &__link {
      padding: 35px 15px;
    }
  }
}

@include respond-down('medium') {
  .menu-project {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__excursion {
      display: none;
    }

    &__drop-menu-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .menu-project {
    padding-left: calc(#{$spacer-small}px - 10px);

    &._sticky {
      top: var(--menu-translate);
    }

    &__link {
      font-size: 16px;
      padding: 20px 10px;
    }
  }
}