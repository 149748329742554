.ajax-form {

  &__header {

  }

  &__title {
    text-align: center;
  }

  &__description {
    color: var(--color-gray-dark);
    text-align: center;
  }

  &__form {
    label {
      display: none;
    }

    .form-field {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      input, textarea, select {
        background: var(--color-background);
        border: none;
      }
    }

    .errors {
      color: var(--color-red);
    }
  }

  &__agreement {
    color: var(--color-gray-dark);

    input {
      &:checked + label {
        &:before {
          background-image: url("../images/svg/checkbox-dark-checked.svg");
        }
      }

      & + label:before {
        background-image: url("../images/svg/checkbox-dark-unchecked.svg");
      }
    }
  }

  &__agreement-link {
    color: var(--color-primary-dark);
  }

  &__success-icon {
    height: 64px;
    margin-bottom: 60px;
    background: {
      image: url('../images/svg/success-form.svg');
      position: 50% 50%;
      repeat: no-repeat;
      size: contain;
    };
  }
}

.ajax-form.ajax-form_loading {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include respond-up('large') {
  .ajax-form {
    width: 586px;
  }
}

@include respond('medium') {
  .ajax-form {
    width: 500px;
  }
}


@include respond-up('medium') {
  .ajax-form {
    &__title {
      margin-bottom: 12px;
    }

    &__header {
      margin-bottom: 40px;
    }

    &__form {
      margin-bottom: 45px;
    }

    &__agreement {
      width: 330px;
    }

    &__footer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .ajax-form {
    &__title {
      margin-bottom: 12px;
    }

    &__header {
      margin-bottom: 25px;
    }

    &__form {
      margin-bottom: 20px;
    }

    &__agreement {
      width: 288px;
      margin-bottom: 20px;
    }

    &__submit {
      display: flex;
      justify-content: flex-end;
    }

    &__success-icon {
      margin-bottom: 50px;
    }
  }
}