.parking {

  &__list {
    display: flex;
    background-color: var(--color-background);
  }

  &__counter {
    margin-right: 10px;
    &.tabs__counter {
      margin-left: 0;
    }
  }

  &__head {
    border-top: 1px solid var(--color-blue-gray);
  }

  &__layout-title-wrapper {
    background-color: var(--color-background);
  }
}

@include respond-up('large') {
  .parking {
    padding-bottom: 120px;

    &__list {
      padding: 0 var(--large-indent);
      margin-bottom: 70px;
      position: relative;

      &._before {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: var(--large-indent);
          background-color: white;
        }
      }
    }

    &__slider {
      margin-bottom: 120px;
    }

    &__head {
      padding-top: 40px;
      margin-bottom: 120px;
    }

    &__layout-title-wrapper {
      padding: 132px 0 60px;
    }
  }
}
@include respond('medium') {
  .parking {
    padding-bottom: 90px;

    &__list {
      margin-bottom: 40px;
    }

    &__slider {
      margin-bottom: 30px;
    }

    &__head {
      padding: 30px 0 70px;
    }

    &__layout-title-wrapper {
      padding: 90px 0 32px;
    }
  }
}
@include respond-down('small') {
  .parking {
    padding-bottom: 40px;

    &__list {
      margin-bottom: 30px;
    }

    &__slider {
      margin-bottom: 30px;
    }

    &__head {
      padding: 20px 0 40px;
    }

    &__layout-title-wrapper {
      padding: 40px 0 30px;
    }
  }
}