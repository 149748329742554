.product-page-in-future {
  &__wrapper {
    border-top: 1px solid var(--color-border);
    display: flex;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }
}

@include respond-up('large') {
  .product-page-in-future {
    padding: 70px 0;

    &__wrapper {
      margin-top: 40px;
      padding-top: 40px;
    }

    &__text-wrap {
      flex: 0 0 auto;
      max-width: 321px;
      margin-right: 87px;
    }

    &__items {
      flex: 0 1 auto;
    }

    &__item {
      &:last-child {
        .product-page-in-future-link {
          margin-top: 30px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product-page-in-future {
    &__button {
      max-width: 213px;
    }

    &__description {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

@include respond('medium') {
  .product-page-in-future {
    padding: 45px 0;

    &__wrapper {
      margin-top: 30px;
      padding-top: 30px;
    }

    &__text-wrap {
      flex-direction: row;
      align-items: flex-start;
    }

    &__description {
      max-width: calc((452 / 768) * 100vw);
      flex: 0 0 auto;
      margin-right: 25px;
    }

    &__button {
      flex: 0 1 auto;
    }

    &__items {
      margin-top: 54px;
    }
  }
}

@include respond-down('medium') {
  .product-page-in-future {
    &__wrapper {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .product-page-in-future {
    padding: 20px 0;

    &__wrapper {
      margin-top: 20px;
      padding-top: 20px;
    }

    &__button {
      max-width: 193px;
      margin-top: 25px;
    }

    &__description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__items {
      margin-top: 30px;
    }
  }
}