.landing {
  position: relative;
  overflow: hidden;
  z-index: 500;

  &__block, &__block-four, &__block-fifth, &__block-sixth {
    &.animate {
      .landing {
        &__icon-stat,
        &__icon-heart,
        &__icon-lightning,
        &__icon-key,
        &__icon-lock,
        &__icon-stat_mobile,
        &__icon-bell,
        &__icon-plus {
          transform: translateX(0) rotate(0) translateY(0);
        }
        &__block-heading {
          opacity: 1;
        }
        &__counter {
          transform: translateX(0);
          opacity: 1;
        }
        &__block-description {
          transform: translateY(0);
          opacity: .72;
        }
        &__message {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &__wrapper {
    position: absolute;
  }

  &__top,
  &__middle,
  &__bottom {
    overflow: hidden;
  }

  &__icon-stat {
    position: absolute;
    display: flex;
    transition: {
      property: transform;
      duration: 1.1s;
      delay: .4s;
    };
  }

  &__icon-heart {
    position: absolute;
    display: flex;
    z-index: 100;
    transition: {
      property: transform;
      duration: 1.1s;
      delay: .4s;
    };
  }

  &__icon-lightning {
    position: absolute;
    display: flex;
    transition: {
      property: transform;
      duration: 1.1s;
      delay: .4s;
    };
  }

  &__icon-key {
    backface-visibility: hidden;
    position: absolute;
    display: flex;
    transition: {
      property: transform;
      duration: 1.1s;
      delay: .4s;
    };
  }

  &__icon-lock {
    position: absolute;
    display: flex;
    transition: {
      property: transform;
      duration: 1.1s;
      delay: .4s;
    };
  }

  &__icon-bell {
    position: absolute;
    display: flex;
    transition: transform 1.2s;
  }

  &__icon-plus {
    position: absolute;
    display: flex;
    transition: transform 1.2s;
  }

  &__policy,
  &__policy-link {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }

  &__policy {
    color: var(--color-white-opacity-50);
    margin-top: 20px;
  }

  &__policy-link {
    color: #fff;
  }

  &__input-wrapper {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__form-logo {
    position: absolute;
    right: 0;
    bottom: -5px;
    z-index: 500;
  }

  &__block-form {
    position: relative;
    background-color: var(--landing-form-backgroung);
    border-radius: 10px 0 0 0;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      background-color: var(--landing-form-backgroung);
      width: calc((100vw - var(--max-row-width-px)) / 2 + var(--grid-spacer));
      right: calc(((-100vw + var(--max-row-width-px)) / 2 - var(--grid-spacer)));;
    }
  }

  &__bottom {
    overflow: hidden;
    background-color: var(--landing-background-middle);
    position: relative;
  }

  &__form-text {
    color: #fff;
    background-color: #3A3A3A;
  }

  &__ksm-logo-message {
    width: 20px;
    height: 20px;
  }

  &__message-title,
  &__message-description {
    font-family: var(--font);
    color: var(--color-gray-dark);
    font-weight: 400;
    line-height: 150%;
  }

  &__message-name {
    font-family: var(--font);
    color: var(--color-primary-dark);
    font-weight: 500;
    line-height: 135%;
  }

  &__block-messages {
    position: relative;
  }

  &__message {
    margin-top: 6px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    z-index: 500;
    position: relative;
    transform: translateY(40px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: 1.2s;
    }
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition-delay: calc(#{$i} * .4s);
      }
    }
  }

  &__frame {
    backface-visibility: hidden;
    background-color: var(--landing-frame-color);
    border-radius: 10px;
    width: 100%;
  }

  &__frame-block-dots {
    display: flex;
    position: absolute;
    top: 13px;
    left: 9px;
    width: 33px;
    height: 7px;
  }

  &__dots {
    &:not(:first-child) {
      margin-left: 6px;
    }

    width: 7px;
    background-color: var(--landing-frame-dots-color);
    border-radius: 100%;
  }

  &__pictures-container {
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 500;
  }

  &__picture-wrapper {
    position: relative;
    padding: 0 6px;

    .landing {
      &__picture {
        position: relative;
      }
    }
  }

  &__picture-second-wrapper {
    position: absolute;
    right: 0;
    z-index: 600;

    .landing {
      &__frame {
        background-color: var(--landing-frame-background);
      }
    }
  }

  &__middle {
    background-color: var(--landing-background-middle);
  }

  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
  }

  &__picture {
    display: flex;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }

  &__frame {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    top: -33px;
    display: flex;

    //svg {
    //  width: 100%;
    //  height: auto;
    //}
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }

  &__block-slider {
    position: relative;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.22);
      opacity: 1;
      width: 6px;
      height: 6px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .swiper-pagination-bullet-active {
      background: rgba(255, 255, 255, 0.9);
    }
  }

  &__top {
    background-color: var(--landing-background);
  }

  &__block-heading {
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 1.2s;
    transition-delay: .67s;
  }

  &__block-description {
    color: white;
    opacity: .72;
    transition: {
      property: opacity, transform;
      duration: 1.3s;
      delay: .81s;
    };

  }

  &__block-text {
    display: flex;
    position: relative;
  }

  &__counter {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    font-weight: 700;
    background: linear-gradient(179.44deg, #FFFFFF -162.15%, rgba(255, 255, 255, 0) 82.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 87.5px;
    transition: {
      property: opacity, transform;
      duration: 1.2s;
      delay: .7s;
    };
  }

  &__slider {
    padding: 0 6px;

    position: relative;

    .swiper {
      border-radius: 0px 0px 10px 10px;
      overflow: hidden;
    }
  }

  &__slider-left,
  &__slider-right {
    position: absolute;
    padding: 7px;
    margin: -7px;
    display: flex;
    cursor: pointer;
  }
}

@include respond-up('large') {
  .landing {

    &__block-description {
      transform: translateY(35px);
      opacity: 0;
    }

    &__wrapper {
      top: -125px;
      width: var(--max-row-width-px);
      left: 50%;
      transform: translateX(-50%);
    }

    &__top {
      //height: 1658px;
      height: calc(3 * ( 389 / 572 * (var(--grid-column6) - 12px) + 39px) + 227px * 2);
    }

    &__slider {
      //width: var(--grid-column6);

      .landing {
        &__picture {
          //width: calc(var(--grid-column6) - 12px);
          height: calc(389 / 572 * (var(--grid-column6) - 12px));
        }

        &__frame {
          //width: calc(var(--grid-column6));
          height: calc(389 / 572 * (var(--grid-column6) - 12px) + 39px);
        }
      }
    }

    //&__frame-block-dots {
    //  top: calc((((428 / 584 * (var(--grid-column6)))) - (389 / 572 * (var(--grid-column6) - 12px)) - 13px) / 2);
    //}

    &__icon-stat {
      left: -118px;
      top: -39px;
      transform: translateX(155px) rotate(45deg) translateY(40px);
    }

    &__icon-heart {
      bottom: -62px;
      right: -177px;
      transform: translateX(-137px) rotate(-60deg) translateY(-25px);
    }

    &__icon-lightning {
      bottom: 83px;
      left: -118px;
      transform: translateX(122px) translateY(-10px) rotate(-55deg);
    }

    &__icon-key {
      bottom: -71px;
      left: -176px;
      transform: translateX(30px) translateY(-10px);
    }

    &__icon-lock {
      top: 21px;
      right: -80px;
      transform: translateX(-78px) translateY(10px) rotate(-55deg);
    }

    &__icon-bell {
      top: -86px;
      left: -67px;
      transform: translateX(76px) translateY(95px) rotate(50deg);
    }

    &__icon-plus {
      bottom: -58px;
      right: -109px;
      transform: translateX(-110px) translateY(-60px) rotate(-50deg);

      svg {
        width: 159px;
        height: 159px;
      }
    }

    &__form-text {
      width: 510px;
      height: 120px;
    }

    &__form-logo {
      svg {
        width: 234px;
        height: 265px;
      }
    }

    &__block-sixth {
      .landing {
        &__block-description {
          margin-top: 24px;
        }

        &__form-block-text {
          grid-column: 1/5;
          margin-top: 120px;
        }

        &__block-form {
          grid-column: 7/13;
          padding-top: 120px;
          padding-left: 74px;
          padding-bottom: 215px;
        }
      }
    }

    &__ksm-logo-message {
      margin-right: 7px;
    }

    &__message-title {
      font-size: 15px;
      display: flex;
    }

    &__message-description {
      font-size: 15px;
      margin-top: 5px;
    }

    &__message-name {
      font-size: 18px;
      margin-top: 12px;
    }

    &__message {
      flex-direction: column;
      padding: 15px 19px 15px 22px;

    }

    &__block-messages {
      grid-column: 1/7;
    }

    &__block-fifth {
      margin-top: 291px;

      .landing__block-text {
        grid-column: 8/12;
        padding-right: 0;
      }
    }

    &__picture-second-wrapper {
      bottom: -30px;
    }

    &__pictures-container {
      margin-top: 109px;
      grid-column: 1/13;
    }

    &__picture-wrapper_one {
      width: var(--grid-column7);

      .landing {
        &__picture {
          width: calc(var(--grid-column7) - 12px);
          height: calc(456 / 674 * (var(--grid-column7) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column7));
          height: calc(456 / 674 * (var(--grid-column7) - 12px) + 39px);
        }
      }
    }

    &__picture-wrapper_two {
      width: var(--grid-column6);

      .landing {
        &__picture {
          width: calc(var(--grid-column6) - 12px);
          height: calc(377 / 572 * (var(--grid-column6) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column6));
          height: calc(377 / 572 * (var(--grid-column6) - 12px) + 39px);
        }
      }
    }

    &__middle {
      padding-top: 130px;
      padding-bottom: 200px;
    }

    &__block {
      &:not(:first-child) {
        margin-top: 227px;
      }
    }

    &__counter {
      top: -69px;
      transform: translateX(-19px);
      opacity: 0;
    }

    &__block-text {
      grid-column: 1/6;
      flex-direction: column;
      margin-top: 70px;
      padding-left: calc(33 * 100vw / 1500);
      //padding-right: calc(161 * 100vw / 1500);
      padding-right: calc(var(--grid-column1) / 2);

      &:not(:first-child) {
        margin-top: 97px;
      }

      &_four {
        grid-column: 1/13;
        flex-direction: row;
        padding-right: calc(58 * 100vw / 1500);
        justify-content: space-between;

        .landing {
          &__block-heading {
            flex: 0 0 33%;
          }

          &__block-description {
            flex: 0 0 33%;
            margin-top: 0;
          }
        }
      }
    }

    &__block-description {
      margin-top: 24px;
    }

    &__block-slider {
      grid-column: 7/13;
    }
  }
}

@include respond-up('medium') {
  .landing {

    &__icon-stat_mobile {
      display: none;
    }

    &__icon-stat {
      svg {
        width: 94px;
        height: 94px;
      }
    }

    &__icon-heart {
      svg {
        width: 113px;
        height: 113px;
      }
    }

    &__icon-lightning {
      svg {
        width: 86px;
        height: 86px;
      }
    }

    &__icon-key {
      svg {
        width: 124px;
        height: 124px;
      }
    }

    &__icon-lock {
      svg {
        width: 112px;
        height: 112px;
      }
    }

    &__icon-bell {
      svg {
        width: 78px;
        height: 78px;
      }
    }

    .swiper-pagination {
      bottom: -38px;
    }

    &__slider-left {
      left: -24px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__slider-right {
      right: -24px;
      transform: rotate(180deg) translateY(50%);
      top: 50%;
    }
  }
}

@include respond('medium') {
  .landing {

    &__block-description {
      transform: translateY(35px);
      opacity: 0;
    }

    &__wrapper {
      top: -87px;
    }

    &__top {
      //height: 2161px;
      height: calc(3 * ( 389 / 572 * (var(--grid-column5) - 12px) + 39px + 240px - (60/768 * 100vw)) + 194px * 2);
    }

    //&__frame {
    //  height: calc(428 / 584 * (var(--grid-column5) - 12px) + 39px);
    //}

    //&__frame-block-dots {
    //  top: calc((((428 / 584 * (var(--grid-column5)))) - (389 / 572 * (var(--grid-column5) - 12px)) - 13px) / 2);
    //}

    &__block-text,
    &__block-slider {
      grid-column: 1/7;
    }

    &__slider {
      width: var(--grid-column5);

      .landing {
        &__picture {
          width: calc(var(--grid-column5) - 12px);
          height: calc(389 / 572 * (var(--grid-column5) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column5));
          height: calc(389 / 572 * (var(--grid-column5) - 12px) + 39px);
        }
      }
    }

    &__icon-stat {
      left: -112px;
      top: -92px;
      transform: translateX(250px) rotate(54deg) translateY(150px);
    }

    &__icon-heart {
      bottom: -80px;
      right: -125px;
      transform: translateX(-130px) rotate(-50deg) translateY(-80px);
    }

    &__icon-lightning {
      bottom: 54px;
      left: -115px;
      transform: translateX(120px) translateY(-25px) rotate(-40deg);
    }

    &__icon-key {
      bottom: -78px;
      left: -144px;
      transform: translateX(20px) translateY(-15px);
    }

    &__icon-lock {
      top: -6px;
      right: -78px;
      transform: translateX(-78px) translateY(-3px) rotate(-55deg);
    }

    &__icon-bell {
      top: -93px;
      left: -46px;
      transform: translateX(55px) translateY(100px) rotate(55deg);
    }

    &__icon-plus {
      bottom: -46px;
      right: -85px;
      transform: translateX(-90px) translateY(-50px) rotate(-55deg);

      svg {
        width: 119px;
        height: 119px;
      }
    }

    &__form-text {
      width: 333px;
      height: 120px;
    }

    &__form-logo {
      svg {
        width: 175px;
        height: 198px;
      }
    }

    &__block-sixth {
      .landing__block-description {
        margin-top: 14px;
      }

      .landing__form-block-text {
        grid-column: 1/4;
        margin-top: 60px;
        padding-right: calc(var(--grid-column1) / 2);
      }

      .landing__block-form {
        grid-column: 4/7;
        padding-top: 60px;
        padding-left: 24px;
        padding-bottom: 217px;
        margin-left: calc(0px - var(--grid-column-gap));
      }
    }

    &__message-title {
      font-size: 12px;
      display: flex;
    }

    &__ksm-logo-message {
      margin-right: 6px;
    }

    &__message-description {
      font-size: 12px;
      margin-top: 4px;
    }

    &__message-name {
      font-size: 14px;
      margin-top: 8px;
    }

    &__message {
      flex-direction: column;
      padding: 12px;
    }

    &__block-messages {
      grid-column: 1/4;
    }

    &__block-fifth {
      margin-top: 349px;

      .landing__block-text {
        grid-column: 4/7;
        flex-direction: column;
        margin-top: 74px;
      }
    }

    &__picture-wrapper {
      width: var(--grid-column4);
    }

    &__picture-second-wrapper {
      bottom: -223px;
      width: var(--grid-column4);
    }

    &__picture-wrapper_one {
      .landing {
        &__picture {
          width: calc(var(--grid-column4) - 12px);
          height: calc(299 / 440 * (var(--grid-column4) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column4));
          height: calc(299 / 440 * (var(--grid-column4) - 12px) + 39px);
        }
      }
    }

    &__picture-wrapper_two {
      .landing {
        &__picture {
          width: calc(var(--grid-column4) - 12px);
          height: calc(291 / 440 * (var(--grid-column4) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column4));
          height: calc(291 / 440 * (var(--grid-column4) - 12px) + 39px);
        }
      }
    }

    &__pictures-container {
      grid-column: 1/7;
      margin-top: 74px;
    }

    &__middle {
      padding-top: 145px;
      padding-bottom: 132px;
    }

    &__block {
      &:not(:first-child) {
        margin-top: 194px;
      }
    }

    &__block-slider {
      width: var(--grid-column5);
      margin: 93px auto 0;
    }

    &__counter {
      top: -60px;
      transform: translateX(-19px);
      opacity: 0;
    }

    &__block-text {
      padding-left: calc(38 * 100vw / 768);
    }

    &__block-heading {
      flex: 0 0 calc(50% - 38 * 100vw / 768);
      margin-right: 43px;
    }

    &__block-description {
      flex: 0 0 calc(50% - 43px);
    }
  }
}

@include respond-down('medium') {
  .landing {
    &__wrapper {
      width: calc(100vw - 2 * var(--grid-spacer));
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .landing {

    &__block-description {
      transform: translateY(35px);
      opacity: 0;
    }

    &__wrapper {
      top: -40px;
    }

    &__top {
      //height: 1734px;
      height: calc(3 * ( 222 / 326 * (var(--grid-column4) - 12px) - (135/786 * 100vw)) + 1300px);
    }

    &__slider {
      .landing {
        &__picture {
          width: calc(var(--grid-column4) - 12px);
          height: calc(222 / 326 * (var(--grid-column4) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column4));
          height: calc(222 / 326 * (var(--grid-column4) - 12px) + 39px);
        }
      }
    }

    //&__frame {
    //  height: calc(261 / 338 * (var(--grid-column4)) - 6px);
    //}

    //&__frame-block-dots {
    //  top: calc((((261 / 338 * (var(--grid-column4)))) - (222 / 326 * (var(--grid-column4) - 12px)) - 13px) / 2);
    //}

    &__icon-stat_mobile {
      position: absolute;
      display: flex;
      top: -87px;
      left: -43px;
      transition: {
        property: transform;
        duration: 1.1s;
        delay: .4s;
      };
      transform: translateX(41px) translateY(73px) rotate(55deg);

      svg {
        width: 67px;
        height: 67px;
      }
    }

    &__icon-stat {
      display: none;
    }

    &__icon-heart {
      bottom: -114px;
      right: -32px;
      z-index: 100;
      transform: translateX(-36px) translateY(-115px) rotate(-55deg);

      svg {
        width: 86px;
        height: 86px;
      }
    }

    &__icon-lightning {
      bottom: -60px;
      left: -40px;
      transform: translateX(45px) translateY(-100px) rotate(-45deg);

      svg {
        width: 60px;
        height: 60px;
      }
    }

    &__icon-key {
      bottom: -119px;
      left: -49px;
      transform: translateX(15px) translateY(-15px);

      svg {
        width: 90px;
        height: 90px;
      }
    }

    &__icon-lock {
      top: -83px;
      right: -71px;
      transform: translateX(-80px) translateY(80px) rotate(-65deg);

      svg {
        width: 85px;
        height: 85px;
      }
    }

    &__icon-bell {
      top: -36px;
      left: -32px;
      transform: translateX(40px) translateY(40px) rotate(55deg);

      svg {
        width: 53px;
        height: 53px;
      }
    }

    &__icon-plus {
      bottom: -58px;
      right: -49px;
      transform: translateX(-50px) translateY(-60px) rotate(-50deg);

      svg {
        width: 93px;
        height: 93px;
      }
    }

    &__button {
      width: 100%;
    }

    &__form-text {
      width: 308px;
      height: 44px;
    }

    &__form-logo {
      svg {
        width: 184px;
        height: 209px;
      }
    }

    &__block-sixth {
      .landing {
        &__block-description {
          margin-top: 20px;
        }

        &__form-block-text {
          grid-column: 1/5;
        }

        &__block-form {
          grid-column: 1/5;
          margin-top: 30px;
          padding-top: 40px;
          padding-left: 15px;
          padding-bottom: 218px;
        }
      }
    }

    &__message-title {
      font-size: 12px;
      display: flex;
    }

    &__ksm-logo-message {
      margin-right: 6px;
    }

    &__message-description {
      font-size: 12px;
      margin-top: 4px;
    }

    &__message-name {
      font-size: 14px;
      margin-top: 8px;
    }

    &__message {
      flex-direction: column;
      padding: 12px;
    }

    &__block-messages {
      grid-column: 1/5;
      grid-row: 2/2;
      margin-top: 40px;
    }

    &__block-fifth {
      margin-top: 279px;

      .landing__block-text {
        grid-column: 1/5;
        grid-row: 1/2;
        flex-direction: column;
      }
    }

    &__picture-second-wrapper {
      bottom: -162px;
    }

    &__picture-wrapper_one {
      width: calc(var(--grid-column3) + 2 * var(--grid-column-gap));

      .landing {
        &__picture {
          width: calc(var(--grid-column3) + 2 * var(--grid-column-gap-gap) - 12px);
          height: calc(185.23 / 272 * (var(--grid-column3) + 2 * var(--grid-column-gap) - 12px));
        }

        &__frame {
          width: calc(var(--grid-column3) + 2 * var(--grid-column-gap));
          height: calc(185.23 / 272 * (var(--grid-column3) + 2 * var(--grid-column-gap) - 12px) + 39px);
        }
      }
    }

    &__picture-wrapper_two {
      width: var(--grid-column3);

      .landing {
        &__picture {
          width: calc(var(--grid-column3) - 12px);
          height: calc(157 / 239 * (var(--grid-column3) - 12px));
        }

        &__frame {
          width: var(--grid-column3);
          height: calc(157 / 239 * (var(--grid-column3) - 12px) + 39px);
        }
      }
    }

    &__pictures-container {
      margin-top: 73px;
    }

    &__middle {
      padding-top: 105px;
      padding-bottom: 100px;
    }

    .swiper-pagination {
      bottom: -36px;
    }

    &__pictures-container,
    &__block-text,
    &__block-slider {
      grid-column: 1/5;
    }

    &__block-text {
      padding-left: calc(38 * 100vw / 375);
      flex-direction: column;
    }

    &__block {
      &:not(:first-child) {
        margin-top: 149px;
      }
    }

    &__block-slider {
      margin-top: 83px;
    }

    &__block-description {
      margin-top: 18px;
    }

    &__counter {
      top: -59px;
      transform: translateX(-19px);
      opacity: 0;
    }

    &__slider-left,
    &__slider-right {
      display: none;
    }
  }
}