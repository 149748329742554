.title {
  &_border {
    border-bottom: 1px solid var(--color-border);
  }
}

@include respond-up('large') {
  .title {
    &_border {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
}

@include respond('medium') {
  .title {
    &_border {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
}

@include respond-down('small') {
  .title {
    &_border {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
}