.bg-corner {
  position: relative;
  background: var(--primary-color);

  &:before {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
    border-width: 0 0 20px 20px;
  }
}

@include respond-up('large') {
  .bg-corner {
    &_big {
      &:before {
        border-width: 0 0 60px 60px;
      }
    }
  }
}

@include respond('medium') {
  .bg-corner {
    &_big {
      &:before {
        border-width: 0 0 50px 50px;
      }
    }
  }
}

@include respond-down('small') {
  .bg-corner {
    &_big {
      &:before {
        border-width: 0 0 30px 30px;
      }
    }
  }
}
