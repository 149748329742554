.about-history {
  &__slider-wrap {
    border-top: 1px solid var(--color-border);
    position: relative;
    overflow: hidden;
  }

  &__slider-main-item-title {
    font-style: normal;
    font-weight: normal;
    line-height: 1em;
    color: var(--color-gray-light);
    position: absolute;
  }

  &__top {
    .arrows__link {
      padding: 10px 15px;
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &__bottom {
    .arrows__link {
      padding: 10px 15px;
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__slider-main-item-inner {
    display: flex;
    position: relative;
  }

  &__slider-secondary-item-inner {
    display: flex;
  }

  &__picture-big,
  &__picture-little {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__image-little-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__image-big,
  &__image-little {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__images-wrap {
    flex: 0 0 auto;
    display: flex;
    position: relative;
  }

  &__text-wrap {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__slider-secondary-arrows {
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }

  &__slider-secondary-item-description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
    transition: transform .5s, opacity .5s;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: var(--primary-color);
      border-radius: 100%;
    }
  }

  &__slider-secondary-item-title {
    font-style: normal;
    font-weight: 500;
    transition: transform .5s, opacity .5s;
  }

  &__slider-main-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
  }

  &__counter {
    overflow: hidden;
    margin: 20px 0;
  }

  &__counter-item-title {
    font-style: normal;
    font-weight: normal;
    line-height: 1.35em;
    color: var(--color-border);
    transition: font-size .4s, color .4s;
  }

  &__counter-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &.swiper-slide-active {
      .about-history__counter-item-title {
        color: var(--color-primary-dark);
        font-style: normal;
        font-weight: normal;
        line-height: 1.35em;
      }
    }
  }

  &__slider-secondary-item {
    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;
      .about-history {
        &__slider-secondary-item-title,
        &__slider-secondary-item-description {
          transform: translateY(20px);
          opacity: 0;
        }
        &__picture-big,
        &__picture-little {
          transform: translateX(100%);
        }
        &__image-big,
        &__image-little {
          transform: translateX(-100%);
        }
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      .about-history {
        &__slider-secondary-item-title,
        &__slider-secondary-item-description {
          transform: translateY(0);
          opacity: 1;
          transition-delay: .3s;
        }
        &__picture-big,
        &__picture-little {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image-big,
        &__image-little {
          animation: animate-image .9s;
        }
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;
      .about-history {
        &__slider-secondary-item-title,
        &__slider-secondary-item-description {
          transform: translateY(20px);
          opacity: 0;
        }
        &__picture-big,
        &__picture-little {
          transform: translateX(0);
          transition: transform .9s;
        }
        &__image-big,
        &__image-little {
          transform: translateX(0);
          transition: transform .9s;
        }
      }
    }
  }
}

@include respond-up('large') {
  .about-history {
    &__slider-wrap {
      padding-top: 70px;
      margin-top: 40px;
    }

    &__picture-big {
      width: 445px;
      height: 393px;
    }

    &__picture-little {
      width: 199px;
      height: 179px;
    }

    &__images-wrap {
      width: 505px;
      height: 496px;
      margin-right: 44px;
    }

    &__text-wrap {
      width: 468px;
      padding-top: 157px;
      padding-bottom: 40px;
    }

    &__slider-secondary-arrows {
      right: 523px;
    }

    &__slider-secondary-item-description {
      margin-top: 34px;
    }


    &__slider-main-item-title {
      left: 549px;
      top: -15px;
    }

    &__slider-main-controls {
      top: 70px;
      width: 70px;
    }

    &__counter {
      max-height: 218px;
    }

    &__slider-secondary-item-inner {
      max-width: 1017px;
    }

    &__counter-item-title {
      font-size: 14px;
    }

    &__counter-item {
      &.swiper-slide-active {
        .about-history__counter-item-title {
          font-size: 28px;
        }
      }

      &.swiper-slide-prev,
      &.swiper-slide-next {
        .about-history__counter-item-title {
          font-size: 20px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .about-history {
    &__slider-secondary-item-description {
      font-size: 16px;
      line-height: 1.5em;
      padding-left: 32px;
    }

    &__slider-secondary-item-title {
      font-size: 19px;
      line-height: 1.35em;
    }

    &__slider-main-item-title {
      font-size: 95px;
    }
  }
}

@include respond('medium') {
  .about-history {
    &__slider-wrap {
      padding-top: 30px;
      margin-top: 30px;
    }

    &__slider-secondary-item-description {
      margin-top: 30px;
    }

    &__images-wrap {
      width: calc((570 / 768) * 100vw);
      height: calc((460 / 690) * (100vw - 2 * #{$spacer-medium}px));
    }

    &__slider-main-controls {
      top: calc((460 / 690) * (100vw - 2 * #{$spacer-medium}px) + 80px);
    }

    &__picture-big {
      width: calc((445 / 768) * 100vw);
      height: calc((393 / 768) * 100vw);
    }

    &__picture-little {
      width: calc((199 / 768) * 100vw);
      height: calc((179 / 768) * 100vw);
    }

    &__slider-main-item-title {
      right: 0;
      top: 135px;
    }

    &__text-wrap {
      max-width: calc((524 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .about-history {
    &__slider-secondary-item-inner {
      flex-direction: column;
    }

    &__counter-item {
      &.swiper-slide-active {
        .about-history__counter-item-title {
          font-size: 20px;
        }
      }

      &.swiper-slide-prev,
      &.swiper-slide-next {
        .about-history__counter-item-title {
          font-size: 15px;
        }
      }
    }

    &__counter-item-title {
      font-size: 13px;
    }

    &__counter {
      max-height: 191px;
    }

    &__text-wrap {
      margin-top: 50px;
      padding-bottom: 60px;
      min-height: 300px;
    }

    &__slider-main-controls {
      width: 50px;
    }
  }
}

@include respond-down('small') {
  .about-history {
    &__slider-wrap {
      padding-top: 20px;
      margin-top: 20px;
    }

    &__slider-secondary-item-description {
      margin-top: 18px;
      padding-left: 22px;
    }

    &__slider-secondary-item-description {
      font-size: 15px;
      line-height: 1.35em;
    }

    &__slider-secondary-item-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__slider-main-item-title {
      font-size: 75px;
      right: calc((146 / 375) * 100vw);
      top: calc((270 / 375) * 100vw);
    }

    &__picture-big {
      width: calc((297 / 375) * 100vw);
      height: calc((263 / 375) * 100vw);
    }

    &__picture-little {
      width: calc((133 / 375) * 100vw);
      height: calc((120 / 375) * 100vw);
    }

    &__images-wrap {
      width: calc(100vw - #{$spacer-small}px * 2);
      height: calc((332 / 338) * (100vw - 2 * #{$spacer-small}px));
    }

    &__text-wrap {
      max-width: calc((250 / 375) * 100vw);
    }

    &__slider-main-controls {
      top: calc((332 / 338) * (100vw - 2 * #{$spacer-small}px) + 72px);
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}