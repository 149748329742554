@use "sass:math";

.about-press {
  &__arrows {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__head {
    position: relative;
  }
  &__title {
    border-bottom: 1px solid var(--color-border);
  }

  &__list {
    display: flex;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
  }

  &__logo {
    margin-bottom: 50px;
    width: 105px;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: math.div(30, 105) * 100%;
  }

  &__created-at {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 125%;
    color: var(--color-gray-dark);
  }

  &__description {
    font-weight: 500;
    line-height: 135%;
    margin-bottom: 39px;
    flex-grow: 1;

  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;
    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: none;
    }
  }
}

@include respond-up('large') {
  .about-press {
    &__title {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__arrows {
      top: 0;
      right: 0;
    }

    &__item {
      &:hover {
        .about-press {
          &__icon {
            transform: translate3d(18px, -20px, 0);
            &_dub {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    &__wrapper {
      padding: 35px;
    }

    &__icon {
      transition: transform .5s;
      transform: translate3d(0, 0, 0);

      &_dub {
        display: block;
        position: absolute;
        transform: translate3d(-18px, 20px, 0);
      }
    }
  }
}
@include respond('medium') {
  .about-press {
    &__title {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }

    &__wrapper {
      padding: 30px;
    }
  }
}
@include respond-down('small') {
  .about-press {
    &__title {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__arrows {
      display: none;
    }

    &__list {
      margin-bottom: 25px;
    }


    &__wrapper {
      padding: 25px 20px;
    }

    &__logo {
      margin-bottom: 40px;
    }

    &__created-at {
      font-size: 14px;
    }

    &__description {
      font-size: 16px;
    }
  }
}