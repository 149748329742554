.about-develop-new-territory {
  &__item-title {
    font-style: normal;
    font-weight: normal;
  }

  &__item-subtitle,
  &__description {
    font-style: normal;
    font-weight: normal;
    color: var(--color-gray-dark);
  }

  &__item {
    &:not(:last-child) {
      .about-develop-new-territory {
        &__description {
          border-bottom: 1px solid var(--color-border);
        }
      }
    }
  }
}

@include respond-up('large') {
  .about-develop-new-territory {
    padding-top: 105px;

    &__items {
      margin-top: 80px;
    }

    &__title-wrap {
      grid-column: 1/5;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &__description {
      grid-column: 6/13;
      padding-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .about-develop-new-territory {
    &__item-title {
      font-size: 35px;
      line-height: 1.35em;
    }

    &__item-subtitle,
    &__description  {
      font-size: 16px;
      line-height: 1.5;
    }

    &__item-subtitle {
      margin-top: 6px;
    }
  }
}

@include respond('medium') {
  .about-develop-new-territory {
    padding-top: 70px;

    &__items {
      margin-top: 30px;
      padding-top: 40px;
    }

    &__description {
      padding-bottom: 30px;
      margin-left: 46px;
      grid-column: 3/7;
    }

    &__title-wrap {
      grid-column: 1/3;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .about-develop-new-territory {
    &__items {
      border-top: 1px solid var(--color-border);
    }
  }
}

@include respond-down('small') {
  .about-develop-new-territory {
    padding-top: 30px;

    &__items {
      margin-top: 20px;
      padding-top: 30px;
    }

    &__item-title {
      font-size: 30px;
      line-height: 1.25em;
    }

    &__item-subtitle,
    &__description  {
      font-size: 15px;
      line-height: 1.35;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    &__item-subtitle {
      margin-top: 12px;
    }

    &__description {
      padding: 25px 0 25px 35px;
      grid-column: 1/5;
    }

    &__title-wrap {
      grid-column: 1/5;
    }
  }
}