.solo-arrow {
  background-color: white;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__right {

  }

  &__link {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: flex;
  }

  &__ring {
    transition: transform 0.2s ease-out, opacity 0.5s;
    opacity: 0.5;
    position: absolute;
    transform: rotate(-90deg);

    circle {
      fill: none;
      stroke: var(--color-gray);
      stroke-width: 7;
      stroke-dasharray: 564.486677646;
      stroke-dashoffset: 564.486677646;
      transition: {
        duration: 0s;
        property: stroke-dashoffset;
      };
    }

    &._active {
      transform: rotate(-90deg) scale(1.1);
      opacity: 1;

      circle {
        transition: {
          duration: 5s;
        };
        stroke-dashoffset: 0;
      }
    }
  }
}

@include respond-down('small') {
  .solo-arrow {
    width: 52px;
    height: 52px;

    &__right {

    }

    &__link {
      width: 44px;
      height: 44px;
    }
  }
}