.contacts-page {

}

@include respond-up('large') {
  .contacts-page {
    padding: 80px 0 0;
  }
}

@include respond-up('medium') {
  .contacts-page {

  }
}

@include respond('medium') {
  .contacts-page {
    padding: 40px 0 0;
  }
}

@include respond-down('medium') {
  .contacts-page {

  }
}

@include respond-down('small') {
  .contacts-page {
    padding: 30px 0 0;
  }
}