.payment-description {
  border-top: 1px solid var(--color-blue-gray);

  &__text-wrap-buttons {
    display: flex;
  }

  &__title-percent {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .payment-description {
    margin-top: 60px;
    padding-top: 40px;

    &__title {
      grid-column: 1/7;
    }

    &__text-wrap {
      grid-column: 7/13;
    }

    &__application {
      flex: 0 0 auto;
      width: 255px;
      &_calc {
        width: unset;
      }
    }

    &__calc {
      margin-left: 10px;
      flex: 0 0 auto;
    }
  }
}

@include respond-up('medium') {
  .payment-description {
    &__text-wrap-buttons {
      margin-top: 28px;
    }
  }
}

@include respond('medium') {
  .payment-description {
    margin-top: 40px;
    padding-top: 30px;

    &__title {
      grid-column: 1/4;
    }

    &__text-wrap {
      grid-column: 4/7;
    }

    &__calc {
      margin-top: 10px;
    }
  }
}

@include respond-down('medium') {
  .payment-description {
    &__text-wrap-buttons {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .payment-description {
    margin-top: 30px;
    padding-top: 20px;

    &__text-wrap-buttons {
      margin-top: 20px;
    }

    &__calc {
      margin-top: 8px;
    }

    &__title {
      grid-column: 1/5;
    }

    &__text-wrap {
      margin-top: 12px;
      grid-column: 1/5;
    }
  }
}