.excursions-page {
  overflow: hidden;

  &__title {

  }

  &__faq {
    background-color: var(--color-background);
  }

  &__show-room {
    .show-room {
      &__button {
        display: none;
      }
    }
  }
}

@include respond-up('large') {
  .excursions-page {
    &__title {
      margin-bottom: 40px;
    }

    &__slider {
      padding-top: 120px;
    }

    &__faq {
      padding: 120px 0;
    }

    &__bottom-form {
      padding: 140px 0 120px;
    }

    &__show-room {
      .show-room {
        padding: 81px 0 120px;
      }
    }
  }
}

@include respond-up('medium') {
  .excursions-page {

  }
}

@include respond('medium') {
  .excursions-page {
    &__title {
      margin-bottom: 30px;
    }

    &__slider {
      padding-top: 70px;
    }

    &__bottom-form {
      padding: 70px 0;
    }

    &__show-room {
      .show-room {
        padding: 70px 0 94px;
      }
    }

    &__faq {
      padding: 70px 0;
    }
  }
}

@include respond-down('medium') {
  .excursions-page {
    &__faq {
      .faq-page__fb-block {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .excursions-page {
    &__title {
      margin-bottom: 20px;
    }

    &__slider {
      padding-top: 30px;
    }

    &__faq {
      padding: 30px 0;
    }

    &__bottom-form {
      padding: 40px 0;
    }

    &__show-room {
      .show-room {
        padding: 30px 0;
      }
    }
  }
}
