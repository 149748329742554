.b-form {
  &__block {
    border-top: 1px solid var(--color-border);

    &_submit {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__block-info {

  }

  &__block-rows {

  }

  &__block-title {
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  &__block-description {
    color: var(--color-gray-dark);
    line-height: 1.4;
  }

  &__row {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {

  }

  &__image {
    .current-image {
      display: block;
      max-width: 100px;
      margin: 10px 0;

      img {
        display: block;
      }
    }

    input + label.clear-file {
      display: inline-block;
    }

    .hint {
      display: block;
      color: var(--color-gray-dark);
    }
  }
}

@include respond-up('large') {
  .b-form {
    &__block {
      padding: 40px 0 60px;
      grid-template-columns: 203px 1fr;
    }
  }
}

@include respond-up('medium') {
  .b-form {
    &__block-title {
      font-size: 19px;
    }

    &__block-description {
      font-size: 15px;
    }

    &__block {
      display: grid;
      grid-gap: 63px;
      grid-template-columns: 170px 1fr;

      &_submit {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}


@include respond('medium') {
  .b-form {
    &__block {
      padding: 30px 0 50px;
    }
  }
}

@include respond-down('small') {
  .b-form {
    &__block {
      padding: 20px 0 30px;
    }

    &__block-title {
      font-size: 16px;
    }

    &__block-description {
      font-size: 14px;
    }

    &__block-info {
      margin-bottom: 20px;
    }
  }
}