.point {
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
}

@include respond-up('medium') {
  .point {
    font-size: 14px;
  }
}

@include respond-down('small') {
  .point {
    font-size: 13px;
  }
}