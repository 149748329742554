.chess-table {
  padding-top: 150px;
  overflow-x: scroll;
  &__info{
    display: flex;
    padding-bottom: 20px;
  }

  &__content{
    display: flex;
  }

  &__section{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
  }

  &__section-name{
    color: var(--color-gray-dark);
    padding-bottom: 10px;
  }

  &__floor{
    display: flex;
    align-self: flex-start;
    min-height: 32px;
    align-items: center;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    transition: border-color .2s;
    &:hover{
      border-bottom: 1px solid var(--color-gray-chess);
      border-top: 1px solid var(--color-gray-chess);
    }
  }

  &__apartments{
    display: flex;
  }

  &__info-item{
    display: flex;
    margin-right: 10px;
  }

  &__example {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    // background-color: var(--color-gray-chess);
    background-color: var(--color-red-chess);
    margin-right: 3px;
    &._active{
      background-color: var(--color-green-chess);
    }

    &._reserved{
      background-color: var(--color-orange-chess);
    }

    &._sold{
      background-color: var(--color-red-chess);
    }
    &._hasNotLayout{
      background-color: var(--color-gray-chess);
    }
    &._hasLayout{
      background-color: var(--color-blue);
    }

    &._isForAgent{
      &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 0;
        border-color: transparent var(--color-red-chess) transparent transparent;
      }
    }
  }

  &__manage-form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-button{
    margin-bottom: 20px;
  }

  &__cell-wrap{
    position: relative;
  }

  &__cell{
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    position: relative;
    // background-color: var(--color-gray-chess);
    background-color: var(--color-red-chess);
    margin: 2px 0 2px 4px;
    transition: outline-color .2s;
    outline: solid transparent;
    &._active{
      background-color: var(--color-green-chess);
    }

    &._reserved{
      background-color: var(--color-orange-chess);
    }

    &._sold{
      background-color: var(--color-red-chess);
    }

    &:hover{
      outline: solid var(--color-red);
      .chess-table__admin-wrap{
        opacity: 1;
        z-index: 30;
        pointer-events: all;
      }
    }
  }

  &__floor-number{
    text-align: center;
    min-width: 32px;
  }

  &__link{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      .chess-table{
        &__cell-info{
          opacity: 1;
          z-index: 30;
        }
      }
    }
  }

  &__link-text{
    font-weight: 500;
  }

  &__cell-info{
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    bottom: 35px;
    left: 0;
    opacity: 0;
    transition: opacity .2s;
  }

  &._with-checkboxes{
    padding: 70px 100px;
    .chess-table__link-text{
      font-weight: 300;
      font-size: 10px;
    }
    .cell-info__with-checkbox{
      pointer-events: none;
      background-color: #fff;
      position: absolute;
      bottom: 35px;
      left: 0;
      opacity: 0;
      transition: opacity .2s;
    }
      input{
        display: none;
        &:checked + label{
          outline: solid var(--color-red);
        }
      }

      .chess-table__label{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        color: #FFFFFF;
        margin: 2px 0 2px 4px;
        background-color: var(--color-blue);
        transition: outline-color .2s;
        outline: solid transparent;
        &._hasNotLayout{
          background-color: var(--color-gray-chess);
        }

        &._changeRenovation{
          background-color: var(--color-red-chess);
        }

        &._active{
          background-color: var(--color-green-chess);
        }

        &:hover{
          outline: solid var(--color-gray);
          .cell-info{
            &__with-checkbox{
              opacity: 1;
              z-index: 250;
            }
          }
          .chess-table{
            &__admin-wrap{
              &_with-checkbox{
                opacity: 1;
                z-index: 30;
                pointer-events: all;
              }
            }
          }
        }
      }
    }

  &._manage-flats-for-agent{
    .chess-table{
      &__form-button{
        align-self: center;
      }

      &__form-control{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
      }

      &__sections{
        display: flex;
      }

      &__date-input{
        display: block;
      }

      &__label{
        background-color: var(--color-green-chess);
        position: relative;
        &._isForAgent{
          &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 0;
            border-color: transparent var(--color-red-chess) transparent transparent;
          }
        }
      }
    }
  }

  &__admin-wrap{
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity .2s;
    padding: 10px;
    box-shadow: 0 10px 15px 5px rgba(125, 131, 146, 0.25);

    &_with-checkbox{
      top: 28px;
    }
  }

  &__admin-link{
    display: block;
    color: black;
    border-bottom: 1px solid transparent;
    transition: border .3s;
    &:hover{
      border-bottom: 1px solid black;
    }
  }
}