.promo-link {
  border: 1px solid var(--color-blue-gray);
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 34px;
  height: 34px;

  svg {
    width: 32px;
    height: 32px;
  }
}

@include respond-up('medium') {
  .promo-link {
    &:hover {
      .promo-link__show-name {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
      }
    }

    &__show-name {
      position: absolute;
      display: inline-block;
      background-color: white;
      padding: 15px;
      color: var(--color-primary-dark);
      z-index: 300;
      box-shadow: 0 0 15px 14px rgba(125, 131, 146, 0.09);
      left: -10px;
      top: calc(100% + 10px);
      opacity: 0;
      pointer-events: none;
      transform: translateY(10px);
      min-width: 200px;
      transition: {
        property: opacity, transform;
        duration: .4s;
      };
      &::before {
        content: '';
        position: absolute;
        border: {
          style: solid;
          color: transparent;
          bottom-color: white;
          width: 10px 7px;
        }
        left: 20px;
        top: -19px;
      }
    }
  }
}

@include respond-down('small') {
  .promo-link {
    &__show-name {
      display: none;
    }
  }
}