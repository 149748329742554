.customer-menu {
  &__item {
    &._active, &:hover {
      .customer-menu {
        &__icon {
          svg {
            path {
              fill: var(--primary-color);
            }

            line {
              stroke: var(--primary-color);
            }
          }
        }

        &__name {
          color: var(--primary-color);
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 20px;
    display: flex;
    align-items: center;

    svg {
      path, line {
        transition: stroke 0.3s, fill 0.3s;
      }
    }
  }

  &__name {
    color: var(--color-gray-dark);
    transition: color 0.3s;
  }

  &__counter {
    margin-left: 8px;
    border-radius: 100px;
    background-color: var(--color-gray-blue);
    color: var(--primary-color);
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    padding: 2px 6px 2px;

    &[data-important] {
      color: var(--color-red);
      background-color: rgba(237, 10, 52, 0.15);
      font-weight: bold;
      font-size: 13px;
    }
  }
}

@include respond-up('large') {
  .customer-menu {
    &__link {
      padding: 10px 0;
    }

    &__item {
      &:last-child .customer-menu__link {
        padding-bottom: 0;
      }

      &:first-child .customer-menu__link {
        padding-top: 0;
      }
    }
  }
}

@include respond-down('medium') {
  .customer-menu {
    overflow-x: auto;
    overflow-y: hidden;
    --link-padding-y: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__icon {
      display: none;
    }

    &__list {
      display: flex;
      white-space: nowrap;
      padding: 0 var(--spacer);
    }

    &__item {
      &:last-child .customer-menu__link {
        padding-right: 0;
      }

      &:first-child .customer-menu__link {
        padding-left: 0;
      }
    }

    &__link {
      padding: var(--link-padding-y) 10px;
    }

    &__item:last-child {
      padding-right: var(--spacer);
    }
  }
}

@include respond-down('small') {
  .customer-menu {
    --link-padding-y: 20px;
  }
}