.project-hero-info {
  &__type {
    color: #fff;
    overflow: hidden;
  }

  &__type-name {
    transform: translate3d(0, 100%, 0);
    transition-delay: 0s;
  }
}

@include respond-up('large') {
  .project-hero-info {

  }
}

@include respond-up('medium') {
  .project-hero-info {

  }
}

@include respond('medium') {
  .project-hero-info {

  }
}

@include respond-down('medium') {
  .project-hero-info {

  }
}

@include respond-down('small') {
  .project-hero-info {

  }
}