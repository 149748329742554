.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;

  &__bottom-form {
    &_bg {
      background-color: var(--color-background);

      .bg-corner {
        &::before {
          border-color: transparent transparent var(--color-background) transparent;
        }
      }
    }
  }
}

@include respond-up('large') {
  .layout {
    --header-height: 120px;
    --menu-translate: 66px;

    &__wrapper {
      //padding-bottom: 140px;

      &_short {
        padding-bottom: 0;
      }
    }

    &__bottom-form {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
}

@include respond-up('medium') {
  .layout {

  }
}

@include respond('medium') {
  .layout {
    --header-height: 96px;
    --menu-translate: 58px;

    &__wrapper {
      //padding-bottom: 90px;

      &_short {
        padding-bottom: 0;
      }
    }

    &__bottom-form {
      padding-top: 90px;
      padding-bottom: 70px;
    }
  }
}

@include respond-down('medium') {
  .layout {

  }
}

@include respond-down('small') {
  .layout {
    --header-height: 50px;
    --menu-translate: 50px;

    //&__wrapper,
    &__bottom-form {
      padding-top: 40px;
      padding-bottom: 40px;

      &_short {
        padding-bottom: 0;
      }
    }
  }
}

@media print {
  .layout {
    &__no-print {
      display: none;
    }

    &__bottom-form {
      display: none;
    }
  }
}