.employee {
  &__qr,
  &__qr_mobile{
    width: 85px;
    height: 85px;
  }

  &__button-arrow-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg{
      transform: rotate(180deg) translateY(-1px);
      path{
        transition: fill var(--default-transition-duration);
        fill: var(--color-border);
      }
    }
  }

  &__button {
    &:hover {
      .employee {
        &__button-arrow-wrap {
          svg {
            path {
              fill: black;
            }
          }
        }
      }
    }
  }

  &__button-link {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--color-primary-dark);
  }

  &__item-title {
    flex: 0 0 35%;
  }

  &__info-item {
    display: flex;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-border);
    align-items: flex-end;
  }

  &__info-top {
    border-bottom: 1px solid var(--color-border);
  }

  &__image-wrap {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
  &__working-position-wrap,
  &__item-title {
    color: var(--color-gray-dark);
  }
  &__info-item {
    color: var(--color-primary-dark);
  }
  &__item-messengers {
    display: flex;
  }
  &__item-messenger {
    display: flex;
    align-items: end;
    &:not(:first-child) {
      margin-left: 16px;
    }
    svg {
      height: 19px;
      width: 19px;
      margin-right: 6px;
    }
  }

  &__item-viber {
    svg {
      path{
        fill: #665CAC;
      }
    }
  }

  &__item-whatsApp {
    svg {
      path{
        fill: #67C15E;
      }
    }
  }

  &__item-telegram {
    svg {
      path{
        fill: #37AEE2;
      }
    }
  }
}

@include respond-up('large') {
  .employee {
    padding: 70px 0 120px;

    &__head {
      padding-bottom: 45px;
    }

    &__company-info-wrap {
      margin-top: 50px;
    }

    &__qr-wrap_mobile,
    &__company-info-wrap-mobile {
      display: none;
    }

    &__image-block-wrap {
      grid-column: 1/7;
    }

    &__block-info-wrap {
      grid-column: 7/13;
      padding-left: 80px;
    }

    &__info-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 35px;
    }

    &__working-position-wrap {
      margin-top: 16px;
    }

    &__info-contact-button {
      margin-top: 30px;
    }
  }
}
@include respond-up('medium') {
  .employee {

    &__button-arrow-wrap {
      width: 42px;
      height: 42px;
      background-color: #fff;
      border-radius: 50%;
    }

    &__button-wrap {
      display: flex;
      align-items: center;
      max-width: 100px;
    }

    &__company-bottom-social {
      display: flex;
    }

    &__company-info-bottom-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    &__company-bottom-text {
      color: var(--color-gray-dark);
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &__company-subtitle {
      color: var(--color-gray-dark);
      margin-top: 4px;
    }

    &__company-icon {
      margin-right: 40px;
    }

    &__company-info-top-wrap {
      display: flex;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--color-border);
    }

    &__company-info-wrap,
    &__company-info-wrap-mobile {
      padding: 25px 32px;
      background-color: var(--color-background);
    }
  }
}
@include respond('medium') {
  .employee {
    padding: 40px 0 90px;

    &__head {
      padding-bottom: 35px;
    }

    &__info-top {
      padding-bottom: 20px;
    }

    &__company-info-wrap-mobile {
      grid-column: 1/7;
    }

    &__qr-wrap_mobile {
      grid-column: 4/7;
    }

    &__qr-wrap_mobile {
      margin-top: 22px;
    }

    &__company-info-wrap-mobile {
      margin-top: 35px;
    }
    &__image-block-wrap {
      grid-column: 1/4;
    }

    &__block-info-wrap {
      grid-column: 4/7;
    }

    &__image-block-wrap{
      margin-right: 10px;
    }
  }
}
@include respond-down('medium') {
  .employee {
    &__company-info-wrap {
      display: none;
    }

    &__working-position-wrap {
      margin-top: 10px;
    }

    &__info-contact-button {
      margin-top: 25px;
      .button {
        width: 100%;
      }
    }

    &__info-messengers-wrap {
      flex-wrap: wrap;
    }

    &__item-messengers {
      margin-top: 12px;
    }

    &__qr-wrap_mobile {
      border: 1px solid var(--color-border);
      display: flex;
      padding: 0 18px;
      align-items: center;
      justify-content: space-between;
    }
    &__qr-text {
      max-width: 184px;
      display: flex;
      flex-wrap: wrap;
      color: var(--color-gray-dark);
    }
    &__qr-wrap {
      display: none;
    }
  }
}
@include respond-down('small') {
  .employee {
    padding: 30px 0 50px;

    &__head {
      padding-bottom: 30px;
    }

    &__info-top {
      padding-bottom: 25px;
    }

    &__company-bottom-social {
      margin-top: 10px;
      margin-left: -7px;
    }

    &__company-info-wrap-mobile {
      padding: 25px;
      background-color: var(--color-background);
    }

    &__company-bottom-social {
      display: flex;
    }

    &__company-info-bottom-wrap {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    &__company-bottom-text {
      color: var(--color-gray-dark);
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &__company-subtitle {
      color: var(--color-gray-dark);
      margin-top: 4px;
    }

    &__company-icon {
      margin-right: 25px;
    }

    &__company-info-top-wrap {
      display: flex;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--color-border);
    }

    &__image-block-wrap {
      grid-column: 1/5;
    }

    &__block-info-wrap {
      grid-column: 1/5;
      margin-top: 25px;
    }

    &__qr-wrap_mobile {
      grid-column: 1/5;
      margin-top: 16px;
    }

    &__company-info-wrap-mobile {
      grid-column: 1/5;
      margin-top: 40px;
    }
  }
}