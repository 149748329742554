.about-hero {
  position: relative;
  &__title {
    color: white;
    position: absolute;
    z-index: 50;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(3.08deg, #030F32 8.19%, rgba(3, 15, 50, 0) 75.54%);
    //background: linear-gradient(0.3deg, rgba(0, 9, 33, 0.68) 3.87%, rgba(3, 13, 44, 0.06) 99.74%);
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100vw;
  }
}

@include respond-up('large') {
  .about-hero {
    &__title {
      bottom: 80px;
      left: 154px;
    }

    &__picture {
      height: calc((564 / 1500) * 100vw);
    }
  }
}
@include respond('medium') {
  .about-hero {
    &__title {
      bottom: 50px;
      left: 39px;
    }

    &__picture {
      height: calc((294 / 768) * 100vw);
    }
  }
}
@include respond-down('small') {
  .about-hero {
    &__title {
      left: 19px;
      bottom: 40px;
    }

    &__picture {
      height: calc((194 / 375) * 100vw);
    }
  }
}