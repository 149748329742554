@use "sass:math";

.seller-index {
  &__top {
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: 35px;
    margin-bottom: 30px;

    .button {
      width: 100%;

      .button__link {
        padding: 0;
        height: var(--default-input-height);
      }

      &.seller-index__new-customer-button {
        .button__link {
          height: calc(var(--default-input-height) - 2px);
        }
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 110px;
    position: relative;
  }

  &__search-result {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1000;
  }

  &__value {
    font-weight: 500;
    font-size: 30px;
    line-height: 135%;
    text-align: center;
    color: var(--primary-color);
    position: relative;
    padding-right: 90px;
    margin-right: 45px;
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: var(--color-border);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    flex-grow: 1;
  }

  &__item {
    border-bottom: 1px solid var(--color-border);
    &:first-child {
      border-top: 1px solid var(--color-border);
    }
  }

  &__link-wrapper {
    padding: 22px 0;
    display: flex;
    align-items: center;
  }

  &__menu-list {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }

  &__menu-link {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-top: math.div(180, 295) * 100%;
    &::before {
      background: linear-gradient(359.43deg, rgba(0, 9, 33, 0.64) 1.6%, rgba(3, 13, 44, 0) 99.52%);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &_mans {
      background: {
        image: url('/static/images/seller/mans.jpg');
        size: cover;
        repeat: no-repeat;
      };
    }
    &_project {
      background: {
        image: url('/static/images/seller/project.jpg');
        size: cover;
        repeat: no-repeat;
      };
    }
    &_home {
      background: {
        image: url('/static/images/seller/home.jpg');
        size: cover;
        repeat: no-repeat;
      };
    }
  }

  &__menu-text {
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
  }

  &__picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@include respond-down('small') {
  .seller-index {
    &__menu-text {
      font-size: 10px;
    }
  }
}