.seller-flats-apartment {
  &__buttons {
    margin-top: 30px;

    .seller-apartment-buttons {
      display: flex;
      justify-content: flex-start;

      &__item {
        &:last-child {
          width: 100%;
        }
      }
    }

    .drop-button__handle {
      height: 50px;
    }
  }

  .product-page__actions {
    padding: 0;
  }
}

@include respond-up('large') {
  .seller-flats-apartment {
    .product-page__actions {
      margin-top: 80px;
    }
  }
}

@include respond-up('medium') {
  .seller-flats-apartment {
    &__buttons {
      margin-top: 30px;

      .seller-apartment-buttons {
        &__item {
          &:first-child {
            .drop-button__handle {
              min-width: 191px;
            }
          }

          &:last-child {
            max-width: 210px;
            margin-left: 10px;
          }
        }
      }
    }

    &__plates{
      padding-top: 20px;
    }
  }
}

@include respond('medium') {
  .seller-flats-apartment {
    .product-page__actions {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .seller-flats-apartment {

  }
}

@include respond-down('small') {
  .seller-flats-apartment {
    .product-page__actions {
      margin-top: 60px;
    }

    &__buttons {
      .seller-apartment-buttons {
        flex-direction: column;
      }
    }

    &__plates{
      display: none;
    }
  }
}

@media print {
  .seller-flats-apartment {
    &__buttons {
      display: none;
    }

    &__plates{
      display: none;
    }
  }
}