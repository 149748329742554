.seller-about {
  &__content {

  }
}

@include respond-up('large') {
  .seller-about {
    &__content {
      .about-block {
        &__head {
          border-top: none;
          padding-top: 0;
        }

        &__body {
          padding: 0;
        }

        &__title {
          svg {
            //width: 240px;
            width: 206px;
            //height: 30px;

            path {
              fill: var(--primary-color);
            }
          }
        }

        &__item {
          height: 370px;
        }

        &__advantage-description {
          opacity: 1;
          height: 100%;
        }

        &__wrapper {
          padding: 30px 25px;
        }

        &__description-container {
          grid-column: 5/13;
        }

        &__statistic {
          margin: 0 -15px;
        }

        &__stat-item {
          flex: 0 0 33.333%;
          padding: 0 15px;
        }
      }
    }
  }
}

@include respond('medium') {
  .seller-about {
    &__content {
      .about-block {
        &__body {
          margin: 0 -40px;
        }
      }
    }
  }
}