.client-compare {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__content {
    padding: 0 30px;
  }

  &__image-wrap {
    display: block;
    position: relative;
    padding-bottom: calc(195 / 254 * 100%);
  }

  &__picture, &__no-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__no-image {
    background: {
      position: center;
      size: contain;
      repeat: no-repeat;
      image: url("/static/images/svg/no-image.svg");
    };
  }

  &__image {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }

  &__item-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__price {
    font-weight: 700;
    color: var(--color-primary-dark);
  }

  &__button {
    width: 100%;
  }

  &__action-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2px;
  }

  &__action-item {
    padding: 0 2px 4px;
  }

  .compare__arrow_prev {
    left: -10px;
  }

  .compare__arrow_next {
    right: -10px;
  }
}

@include respond-up('large') {
  .client-compare {
    &__head {
      padding-bottom: 10px;
    }
  }
}
@include respond-up('medium') {
  .client-compare {
    &__image-wrap {
      margin-bottom: 30px;
    }

    &__price {
      font-size: 19px;
    }
  }
}

@include respond-down('medium') {
  .client-compare {
    &__head {
      padding-left: var(--grid-spacer);
      padding-right: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .client-compare {
    &__head {
      padding-bottom: 20px;
    }

    &__image-wrap {
      margin-bottom: 20px;
    }

    &__price {
      font-size: 17px;
    }

    &__button._small .button__link {
      padding-left: 0;
      padding-right: 0;
    }
  }
}