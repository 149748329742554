.seller-menu {
  --menu-width: 374px;
  --logo-width: 80px;
  --menu-padding: 22px;

  svg {
    display: block;
  }

  li._active, a:hover, a._active {
    svg {
      [stroke="#C7CBD6"] {
        stroke: var(--primary-color);
      }
      [fill="#C7CBD6"] {
        fill: var(--primary-color);
      }
    }
  }

  a {
    svg {
      [stroke="#C7CBD6"] {
        transition: stroke 0.4s;
      }
      [fill="#C7CBD6"] {
        transition: fill 0.4s;
      }
    }
  }

  &__wrapper {
    .seller-menu {
      &__on-opened {
        display: none;
      }
    }

    &._menu-opened {
      .seller-menu {
        &__on-closed {
          display: none;
        }

        &__on-opened {
          display: inherit;
        }
      }
    }
  }

  &__top {
    background-color: #fff;
    border-bottom: 1px solid var(--color-border-40);
  }

  &__top-actions {
    display: grid;
    grid-template-columns: var(--logo-width) var(--menu-width);
  }

  &__menu-link {
    width: 140px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    background-color: var(--primary-color);
    justify-content: center;
  }

  &__logo {
    width: var(--logo-width);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
  }

  &__current-project {
    display: grid;
    grid-template-columns: 1fr 55px;
  }

  &__current-project-link {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  &__current-project-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    background-color: #fff;
  }

  &__projects {
    display: none;
  }

  &._projects-opened {
    .seller-menu {
      &__projects {
        display: block;
      }

      &__current-project-menu {
        svg {
          [stroke="#C7CBD6"] {
            stroke: var(--primary-color);
          }
          [fill="#C7CBD6"] {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

  &__projects-list {
    border-bottom: 1px solid var(--color-border-40);
  }

  &__project-link {
    display: block;
    padding: 9px var(--menu-padding);
    background-color: #fff;
    transition: all 0.3s;
    font-size: 15px;

    &:hover {
      background-color: var(--color-background);
      color: var(--primary-color);
    }
  }

  &__customer-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__customer {
    padding: 26px var(--menu-padding) 13px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__customer-closer {
    padding: 26px var(--menu-padding) 0 0;
  }

  &__customer-icon {
    margin-right: 13px;
  }

  &__nav {

  }

  &__nav-items {
    display: flex;
    padding: 0 calc(var(--menu-padding) - 10px);
  }

  &__nav-item {
    flex: 1 1 0px;
  }

  &__nav-link {
    display: flex;
    justify-content: center;
    padding: 13px 0 26px;
  }

  &__main {
    box-shadow: 0 8px 20px rgba(125, 131, 146, 0.15);
    position: relative;
  }

  &__activity {
    background-color: #F7F8FA;
  }

  &__section-activity {

  }

  &__settings-activity {
    padding: 30px var(--menu-padding);
    position: relative;
    display: none;
  }

  &._settings-opened {
    .seller-menu {
      &__settings-activity {
        display: block;
      }

      &__section-activity {
        display: none;
      }

      &__manager-action_settings {
        svg {
          [stroke="#C7CBD6"] {
            stroke: var(--primary-color);
          }
          [fill="#C7CBD6"] {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

  &__settings-activity-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__section-activity:empty {
    display: none;
  }

  &__manager {
    padding: 25px var(--menu-padding);
    color: var(--color-gray-dark);

    &._agent {
      padding-top: 0;
    }
  }

  &__agent {
    padding: 25px var(--menu-padding) 20px;
    display: flex;
    align-items: center;

    &._has-logo {
      padding: 0 0;
      margin-bottom: 10px;

      .seller-menu__agent-name {
        padding-left: 15px;
      }
    }
  }

  &__agent-logo-img {
    width: 80px;
    height: 70px;
    object-fit: contain;
    display: block;
  }

  &__agent-name {
    color: var(--color-gray-dark);
    font-size: 14px;
  }

  &__manager-info {
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__manager-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__manager-actions-main{
    display: flex;
    align-items: center;
  }

  &__manager-action{
    &_link{
      margin-left: 25px;
    }
  }

  &__manager-logout {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
  }

  &__manager-logout-icon {
    margin-right: 6px;
  }

  &__settings-line {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__settings-input {
    &_radio-choices {
      background-color: #fff;
      padding: 6px;

      .radio-list {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
      }


      li {
        flex: 1 1 0;
      }

      input {
        display: none;
      }

      label {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        cursor: pointer;
        color: var(--color-primary-dark);
      }

      input:checked + label {
        color: #fff;
        background-color: var(--color-primary-dark);
      }
    }
  }

  &__settings-button {
    background-color: #fff;
    padding: 6px;

    .button {
      width: 100%;
    }
  }
}

@include respond-up('large') {
  .seller-menu {
    --menu-width: 1fr;
    --logo-width: 80px;

    &__top {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__logo-icon_tablet {
      display: none;
    }

    &__top {
      height: 70px;
      position: relative;
    }

    &__top-additional {
      display: none;
    }

    &__closer {
      display: none;
    }

    &__hide {
      position: absolute;
      left: 100%;
      top: 0;
    }

    &__wrapper {
      &._menu-opened {
        .hero-project {
          &__wrapper {
            justify-content: flex-end;
          }

          &__info-wrap {
            width: var(--grid-column8);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  :root {
    --top-height: 58px;
  }

  .seller-menu {
    --menu-width: 374px;
    --logo-width: 63px;

    &__hide {
      display: none;
    }

    &__top {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1000;
      height: var(--top-height);
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      background-color: #fff;
      box-shadow: 0 6px 8px rgba(125, 131, 146, 0.04);
    }

    &__top-actions {
      display: grid;
      grid-template-columns: var(--logo-width) calc(var(--menu-width) - var(--logo-width));
    }

    &__top-additional {
      display: flex;
      align-items: stretch;
    }

    &__current-project {
      box-shadow: 1px 0 0 0 var(--color-border-40);
    }

    &__logo-icon_desktop {
      display: none;
    }

    &__body {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      position: fixed;
      top: var(--top-height);
      z-index: 1000;
      width: var(--menu-width);
      transition: all 0.2s;
      padding-top: 20px;
    }

    &__closer {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 200;
    }

    &__wrapper._menu-opened {
      .seller-menu__body {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }

  }
}