.mini-redirect-form {

  &__fields {
    display: none;
  }
}

@include respond-up('large') {
  .mini-redirect-form {

  }
}

@include respond-up('medium') {
  .mini-redirect-form {

  }
}

@include respond('medium') {
  .mini-redirect-form {

  }
}

@include respond-down('medium') {
  .mini-redirect-form {

  }
}

@include respond-down('small') {
  .mini-redirect-form {
      &__button {
        width: 100%;
      }
  }
}