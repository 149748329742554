/*
 Друг мой, когда залезаешь в этот файл - знай, он используется дохренища где
 (включая личные кабинеты и даже приложение для битрикса)

 Поэтому будь осторожен и аккуратен. А если тебе страшно или ты до конца не понимаешь что делаешь, то
 Создай свой шаблон, унаследуй его от _layout-card.tpl или _apartment-card.tpl, перекрой что тебе нужно
 и сверстай для своей локальной задачи.

 Самый лучший вариант - прокосультироваться с Антоном О. или Романом З.
 */

.layout-card {
  border-top: 1px solid var(--color-border);

  &__inner {
    display: grid;
  }

  &__promotion-list {
    grid-area: promo;
    display: flex;
  }

  &__promotion-item {
    display: flex;
  }

  &__promotion-counter {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2em;
    color: var(--color-blue-gray);
    display: none;
  }

  &__buttons-wrap {
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
  }

  &__image-wrap {
    grid-area: image-wrap;
    display: block;
    position: relative;
    padding-bottom: calc(195 / 254 * 100%);

  }

  &__picture, &__no-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__no-image {
    background: {
      position: center;
      size: contain;
      repeat: no-repeat;
      image: url("/static/images/svg/no-image.svg");
    };
  }

  &__image {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }

  &__title {
    grid-area: title;
    margin-top: 20px;
  }

  &__title-text {
    font-weight: 500;
    line-height: 1.35em;
    color: var(--color-primary-dark);
  }

  &__info-list {
    grid-area: info;
    display: grid;
  }

  &__info-item {
    display: flex;
  }

  &__info-item-title,
  &__info-item-description {
    font-weight: normal;
    line-height: 1.25em;
  }

  &__info-item-title {
    color: var(--color-gray-dark);
  }

  &__info-item-description {
    color: var(--color-primary-dark);
  }

  &__price-wrap {
    grid-area: price;
  }

  &__price {
    font-weight: bold;
    line-height: 1.35em;
    color: var(--color-primary-dark);
  }

  &__tags-wrap {
    grid-area: tags;
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }

  &__more-wrap {
    grid-area: more;
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }

  &__header {
    grid-area: header;
  }

  &__footer {
    grid-area: footer;
  }

  &__more {
    &_small {
      display: none;
      align-items: center;
      padding: 10px;
      margin: -10px;
    }
  }

  &__more-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2em;
    color: var(--primary-color);
    flex: 0 0 auto;
    margin-right: 8px;
  }

  &__more-icon {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__tag-wrap {
    padding: 3px;
  }

  &__tag {
    padding: 4px 5px;
    font-weight: normal;
    font-size: 13px;
    line-height: 125%;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-blue);
  }

  &__mortgage {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.25em;
    flex-wrap: wrap;
  }

  &__mortgage-title {
    color: var(--color-gray-dark);
    margin-right: 1px;
  }

  &__mortgage-value {
    color: var(--color-primary-dark);
  }

  &_inactive {
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  &_badge-blue {
    opacity: 0.8;
  }

  &__inactive-badge {
    color: #fff;
    position: absolute;
    z-index: 100;
    padding: 5px 20px;

    &._blue {
      background-color: var(--color-blue-dark);
    }

    &._red {
      background-color: var(--alert-color);
    }
  }

  &__in-fav{
    color: var(--color-blue-dark);
  }
}

@include respond-up('large') {
  .layout-card {
    &:hover {
      .layout-card {
        &__more-wrap {
          opacity: 1;
        }
      }
    }

    &__promotion-list {
      margin: -4px;
    }

    &__promotion-item {
      padding: 4px;
    }

    &__price {
      font-size: 19px;
    }

    &__title-text {
      font-size: 17px;
    }

    &__more-wrap {
      opacity: 0;
      transition: opacity .4s;
      margin-top: 20px;

      .button__link-arrow {
        width: 45px;
        height: 45px;

        .button__link {
          svg {
            width: 16px;
            height: auto;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .layout-card {
    &__info-item-description {
      flex: 0 1 auto;
    }

    &__more_large {
      display: flex;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .layout-card {

  }
}

@include respond-down('medium') {
  .layout-card {
    padding: 20px 0;

    &__info-item-title {
      font-size: 13px;
    }

    &__info-item-description {
      margin-top: 3px;
      font-size: 13px;
    }

    &__price {
      font-size: 17px;
    }

    &__title-text {
      font-size: 16px;
    }

    &__mortgage {
      margin-top: 4px;
    }

    &__more-title {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .layout-card {
    &__promotion-list {
      flex-direction: column;
      margin: -3px 0;
    }

    &__promotion-item {
      padding: 3px 0;
    }

    &__image-wrap {
      width: 100%;
    }

    //&__image {
    //  width: calc((177 / 375) * 100vw);
    //  height: calc((135 / 375) * 100vw);
    //}

    &__info-list {
      margin-top: 15px;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 12px;
      grid-column-gap: 14px;
    }

    &__info-item {
      flex-direction: column;
    }

    &__tags-wrap {
      margin-top: 15px;
      padding-right: 105px;
    }
  }
}

@media print {
  .layout-card {
    &__image-wrap, &__promotion-list, &__buttons-wrap.layout-card-buttons, &__more-wrap {
      display: none;
    }
  }
}