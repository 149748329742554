.rooms-filter-form {
  display: flex;

  &__field {
    &_euro {
      margin-left: 8px;
    }
  }

  &__label {

  }

  &__input {

  }
}

@include respond-up('large') {
  .rooms-filter-form {

  }
}

@include respond-up('medium') {
  .rooms-filter-form {

  }
}

@include respond('medium') {
  .rooms-filter-form {

  }
}

@include respond-down('medium') {
  .rooms-filter-form {

  }
}

@include respond-down('small') {
  .rooms-filter-form {

  }
}