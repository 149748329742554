.subscribe-mini-form {
  &__form {
    position: relative;
    &.success {
      .subscribe-mini-form {
        &__title, &__wrapper{
          opacity: 0;
          pointer-events: none;
          transition-delay: 0s;
        }

        &__success {
        }

        &__success-title {
          opacity: 1;
          transform: translateY(0);
          transition-delay: .1s;
        }

        &__success-icon-wrapper {
          opacity: 1;
          transform: translateY(0);
          transition-delay: .2s;
        }
      }
    }
  }

  &__container {
  }

  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    transition: {
      property: opacity;
      duration: .3s;
      delay: .3s;
    };
  }

  &__wrapper {
    transition: {
      property: opacity;
      duration: .3s;
      delay: .3s;
    };
  }

  &__fields {
  }

  &__field {
    input::placeholder {
      color: black;
    }
  }

  &__button {
    width: 100%;
  }

  &__success {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    transition: {
      property: opacity, transform;
      duration: .4s;
    };
  }

  &__success-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 140%;
    color: var(--color-primary-dark);
    transform: translateY(10px);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .4s;
    };
  }

  &__success-icon-wrapper {
    display: flex;
    padding: 8px 0;
    background-color: var(--primary-color);
    justify-content: center;
    margin-top: 22px;
    transform: translateY(10px);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .4s;
    };
  }

  &__success-icon {
    height: 28px;
    width: 32px;
    flex: 0 0 32px;
    background: {
      image: url('../images/svg/ok.svg');
      position: center;
      repeat: no-repeat;
      size: contain;
    };
  }
}

@include respond-up('large') {
  .subscribe-mini-form {
    &__title {
      margin-bottom: 20px;
    }
  }
}
@include respond('medium') {
  .subscribe-mini-form {
    &__container {
      display: grid;
      grid-column-gap: 80px;
      grid-template-columns: 1fr 1fr;
    }

    &__title {
      max-width: 278px;
    }
  }
}
@include respond-down('small') {
  .subscribe-mini-form {
    &__title {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
}