@use "sass:math";

.product-images {
  &__print-description {
    display: none;
  }

  &__images-block {
    position: relative;
  }

  &__image-slide, &__image-list {
    position: relative;
    padding-bottom: 100%;
  }

  &__image-slide-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
  }

  &__image-item {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    transition: {
      property: opacity;
      duration: .4s;
    };

    &._active {
      opacity: 1;
      z-index: 20;
    }
  }

  &__image-link {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &__image-slider-arrow {
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    margin-top: -30px;
    z-index: 100;
    transition: all 0.3s;

    &_prev {
      left: -50px;
    }

    &_next {
      right: -50px;
    }

    &._arrow-inactive {
      opacity: 0.2;
    }
  }

  &__arrows-block {

  }

  &__arrows {

  }

  &__links-block {
    position: relative;
    z-index: 450;
  }

  &__links {
    display: flex;
    justify-content: center;
  }

  &__link-item {
  }

  &__link {

  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@include respond-up('large') {
  .product-images {
    &__arrows-block {
      display: none;
    }

    &__images-block {
    }

    &__links-block {
      margin-top: 30px;
      width: 100%;
    }

    &__links {
    }

    &__image-empty {
      width: 100%;
      padding-bottom: math.div(420, 546) * 100%;
      background: {
        position: center;
        size: contain;
        repeat: no-repeat;
        image: url("/static/images/svg/no-image.svg");
      };
    }

    &__genplan-link{
      position: absolute;
      z-index: 500;
      width: 54px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: var(--color-background);
    }

    &__genplan-resize-icon{
      display: flex;
    }
  }
}

@include respond-up('medium') {
  .product-images {

    &__links {
    }
  }
}

@include respond('medium') {
  .product-images {
    &__images-block {
      width: math.div(546, 688)*100%;
    }

    &__links-block {
      margin: 30px -#{$spacer-medium}px 0;
    }
  }
}

@include respond-down('medium') {
  .product-images {
    &._empty {
      display: none;
    }

    &__links-block {
      overflow: hidden;
    }

    &__images-block {
      margin: auto;
    }

    &__genplan-link{
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-images {
    &__links-block {
      margin: 30px -#{$spacer-small}px 0;
    }

    &__images-block {
      width: math.div(301, 338)*100%;
    }

    &__link {

    }

    &__image-slider-arrow {
      width: 30px;
      z-index: 100;
      transition: all 0.3s;

      &_prev {
        left: -25px;
      }

      &_next {
        right: -25px;
      }

      &._arrow-inactive {
        opacity: 0.2;
      }
    }
  }
}

@media print {
  .product-images {
    &__links-block {
      display: none;
    }

    &__image-slider-arrows {
      display: none;
    }
  }
}