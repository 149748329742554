.filter-fields {
  &_catalog {
    .filter-fields {
      &__rooms-block, &__euro-block {
        flex: 0 0 auto;
      }

      &__rooms-block {
        margin-right: 8px;
      }

      &__field {
        &_rooms {
          display: flex;
        }
      }
    }
  }

  &_parking {
  }

  &__field_reserved, &__field_sold{
    margin-top: 23px;
    .filter-fields__label{
      display: none;
    }
  }

  //&__field_sold{
  //  margin-left: -225px;
  //}
}

@include respond-up('large') {
  .filter-fields {
    //margin: 0 -8px;

    &_catalog {
      display: grid;
      grid-gap: 36px 17px;
      grid-template-columns: 198px 198px 198px auto 50px auto;
      grid-template-areas:
              "project address rooms rooms rooms floor"
              "renovation date action price payment payment"
    ;
      .filter-fields {
        &__euro-block {
          margin-right: 16px;
        }
      }
    }

    &_chess{
      grid-template-areas:
              "address address";
      padding-bottom: 36px;
    }

    &_floors{
      grid-template-areas: "address address sections floors";
      padding-bottom: 36px;
    }

    &_parking, &_pantries {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 17px;
    }

    &_parking {
      grid-template-areas: "project address price type";
    }

    &_pantries {
      grid-template-areas: "project address price area";
    }
  }
}

@include respond-up('medium') {
  .filter-fields {
    &_catalog {
      display: grid;

      .filter-fields {
        &__area-block {
          flex: 1 1 auto;
        }
      }
    }

    &_parking, &_pantries {
      display: grid;
    }
  }
}

@include respond('medium') {
  .filter-fields {
    &_catalog {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: var(--grid-column-gap);
      grid-row-gap: 20px;
      grid-template-areas:
              "project project project address address address"
              "rooms rooms rooms rooms rooms rooms"
              "floor floor renovation renovation date date"
              "action action price price payment payment";

      .filter-fields {
        &__euro-block {
          margin-right: var(--grid-column-gap);
        }
      }
    }

    &_parking, &_pantries {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-column-gap);
    }

    &_parking {
      grid-template-areas:  "project address"
                            "price type";
    }

    &_pantries {
      grid-template-areas:  "project address"
                            "price area";
    }
  }
}

@include respond-down('medium') {
  .filter-fields {
  }
}

@include respond-down('small') {
  .filter-fields {
    flex-direction: column;
    display: flex;

    &_catalog {
      .filter-fields {
        &__field, &__rooms-block, &__euro-block,  {
          margin-bottom: 18px;
        }

        &__area-block {
          flex: 0 0 100%;
        }

        &__field {
          &_rooms {
            order: 20;
            flex-wrap: wrap;
          }
          &_project, &_address {
            order: 10;
          }

          &_floor, &_renovation, &_date, &_action, &_price, &_payment {
            order: 30;
          }
        }
      }
    }

    &_parking, &_pantries {
      .filter-fields {
        &__field {
          margin-bottom: 18px;
        }
      }
    }
  }
}