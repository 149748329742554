.about-what-made-of-card {
  &__image-wrap {
    position: relative;
  }

  &__text-wrap {
    background-color: var(--color-background);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0.3deg, rgba(0, 9, 33, 0.6) 0.89%, rgba(3, 13, 44, 0) 99.74%);
      z-index: 5;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    color: #fff;
    z-index: 10;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    color: var(--color-gray-dark);
  }

  &__icon {
    display: flex;

    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .about-what-made-of-card {
    &__image-wrap {
      height: calc((355 / 1500) * 100vw);
      max-height: 355px;
    }

    &__title {
      bottom: 35px;
      left: 40px;
      right: 40px;
    }

    &__text-wrap {
      padding: 35px 40px;
    }

    &__icon {
      margin-top: 38px;
    }

    &__image {
      transition: transform .4s;
    }

    &:hover {
      .about-what-made-of-card__image {
        transform: scale(1.05);
      }
    }
  }
}

@include respond-up('medium') {
  .about-what-made-of-card {
    &__description {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include respond('medium') {
  .about-what-made-of-card {
    &__image-wrap {
      height: calc((204 / 768) * 100vw);
    }

    &__title {
      bottom: 22px;
      left: 25px;
      right: 25px;
    }

    &__text-wrap {
      padding: 25px;
    }

    &__icon {
      margin-top: 36px;
    }
  }
}

@include respond-down('medium') {
  .about-what-made-of-card {

  }
}

@include respond-down('small') {
  .about-what-made-of-card {
    &__image-wrap {
      height: calc((206 / 375) * 100vw);
    }

    &__title {
      bottom: 20px;
      left: 20px;
      right: 20px;
    }

    &__text-wrap {
      padding: 20px;
    }

    &__description {
      font-size: 15px;
      line-height: 135%;
    }

    &__icon {
      margin-top: 21px;
    }
  }
}