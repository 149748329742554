.project-office {
  &__content {
    background-color: #fff;
  }

  ._office-main, ._office-project {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    height: 0;
    transition: all 0.4s;
    transition-delay: 0s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  ._office-main {
    transform: translate3d(20px, 0, 0);
  }

  ._office-project {
    transform: translate3d(-20px, 0, 0);
  }

  &__items {
    position: relative;
  }

  &._active-project {
    .project-office-heading__toggle-inner {
      transform: translate3d(-17px, 0 ,0);
    }
  }

  &._active-main {

  }

  &._active-project ._office-project,
  &._active-main ._office-main {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    height: auto;
    transition-delay: 0.1s;
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  &__toggle {

  }

  &__heading {

  }

  &__bg-small {
    display: none;
  }
}

@include respond-up('large') {
  .project-office {
    margin-top: 140px;

    &__map {
      height: 830px;
    }

    &__content {
      width: 380px;
      top: 95px;
    }
  }
}

@include respond-up('medium') {
  .project-office {
    &__container {
      position: relative;
    }

    &__content {
      position: absolute;
      left: 0;
      z-index: 100;
    }
  }
}

@include respond('medium') {
  .project-office {
    margin-top: 90px;

    &__map {
      height: 700px;
    }

    &__content {
      width: 300px;
      top: 50px;
    }
  }
}

@include respond-down('small') {
  .project-office {
    position: relative;
    margin-top: 40px;


    &__map {
      height: 418px;
    }

    &__items {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }

    &__bg-small {
      display: block;
      height: 266px;
      background-color: #fff;
    }
  }
}