.floor-genplan {
  height: 100vh;
  padding-top: 150px;
  padding-bottom: 50px;
  margin-top: -120px;

  &._genplan-product{
    height: unset;
    padding: unset;
    margin: unset;
    .floor-genplan{
      &__plan-container{
        width: 1000px;
        height: 600px;
      }
    }
  }

  &._product-slider{
    height: unset;
    padding: unset;
    margin: unset;
    .floor-genplan{
      &__plan-container{
        width: 100%;
        height: 100%;
      }
    }
  }

  &__container {
    height: 100%;
  }

  &__row {
    display: grid;
    grid-template-rows: 100px auto 120px;
    height: 100%;
  }

  &__plan {
    height: 100%;

    .genplan__background {
      display: none;
    }

    .genplan__layer-polygon svg path {
      opacity: 0.2;

      &._hover {
        opacity: 0.4;
      }
    }
  }

  &__legend{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info-container{
    display: flex;
    align-items: center;
  }

  &__back{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-primary-dark);
    border-radius: 100%;
    margin-right: 50px;
      svg{
        path{
          fill: var(--color-primary-dark);
        }
      }
  }

  &__side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &._seller{
    padding-top: 180px;
    margin-top: 0;
    padding-bottom: 0;
    .floor-genplan{
      &__legend{
        padding: 0 300px;
      }
    }
  }

  &._seller-with-filter{

  }
}

@include respond-down('small'){
  .floor-genplan{
    &__legend{
      flex-direction: column;
    }

    &__info-container{
      padding-bottom: 20px;
    }

    &__plan-container{
      margin-top: 50px;
    }
  }
}