.subscribe-form {
  &__inputs-wrapper {
    display: flex;
  }

  &__data {
    display: flex;
  }

  &__input-wrapper {
    flex: 1 1;
  }

  &__policy {
    color: var(--color-white-opacity-50);
  }

  &__plane {
    position: absolute;
  }
}

@include respond-up('large') {
  .subscribe-form {
    &__form-wrapper {
      flex: 0 1 640px;
    }

    &__plane {
      bottom: 10px;
      right: 60px;

      svg {
        width: 253px;
        height: 168px;
      }
    }
  }
}

@include respond('medium') {
  .subscribe-form {
    &__form-wrapper {
      flex: 0 1 70%;
    }

    &__plane {
      bottom: 38px;
      right: 7px;

      svg {
        width: 166px;
        height: 109px;
      }
    }
  }
}

@include respond-down('small') {
  .subscribe-form {
    &__inputs-wrapper {
      flex-direction: column;
    }

    &__plane {
      bottom: 25px;
      right: 0px;

      svg {
        width: 144px;
        height: 95px;
      }
    }

    &__data{
      margin-bottom: 100px;
    }
  }
}
