.c-genplan {
  &__gen {
    height: 100vh;
    width: 100%
  }

  &__gen-interface {
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000;
    &._full-screen{
      height: 100vh;
    }
  }

  &__interface-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: 0 100px 50px;
  }

  &__interface-action {
    pointer-events: auto;
    cursor: pointer;
  }

  &__interface-button-text {
    color: #fff;
  }

  &__placemark {
    svg {
      display: block;
    }

    &_icon {
      width: 44px;
      height: 44px;
    }
  }

  &__placemark-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    background-color: var(--orange-color);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background-color: var(--orange-color);
      border-radius: 100px;
      width: 50px;
      height: 50px;
      animation-duration: 1.4s;
      animation-name: point-pulse;
      animation-iteration-count: infinite;
      z-index: 10;
      left: 0;
      top: 0;
    }
  }

  &__placemark-icon {
    display: block;
  }

  &__placemark-number-text {
    position: relative;
    z-index: 100;
  }

  &__floor-placemark {
    width: 2px;
    height: 30px;
    background-color: transparent;
    display: block;
  }

  &__back-button-wrapper{
    display: flex;
    align-items: center;
  }

  &__back-button-icon{
    width: 52px;
    height: 52px;
    background-color: #FFFFFF;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    svg{
      path{
        stroke: var(--primary-color);
      }
    }
  }

  &__back-button-text{
    color: #FFFFFF;
    font-weight: 700;
    line-height: 125%;
    text-shadow: 0px 6px 20px rgba(3, 15, 50, 0.72);
  }
}

@include respond-up('large') {
  .c-genplan {
    &__interface-button {
      padding-left: 40px;
      padding-right: 40px;

      .button__text {
        margin-left: 15px;
        color: #fff;
      }
    }

    &__back-button-icon{
      margin-right: 18px;
    }

    &__back-button-text{
      font-size: 26px;
    }
  }
}

@include respond-down('medium') {
  .c-genplan{
    &__back-button-icon{
      margin-right: 12px;
    }

    &__back-button-text{
      font-size: 23px;
    }
  }
}

@include respond-down('small') {
  .c-genplan{
    &__interface-bottom {
      padding: 0 50px 50px;
    }
  }
}



@keyframes point-pulse {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  30% {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(1.7);
  }
}