.cell-info {
  color: black;
  position: relative;
  box-shadow: 0 10px 15px 5px rgba(125, 131, 146, 0.25);
  min-width: 250px;
  padding: 10px;
  &:before{
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    border-width: 0 15px 10px 0;
    bottom: -10px;
    left: 0;
  }

  &._seller{
    .cell-info__layout-status{
      display: none;
    }
  }

  &__top{
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }

  &__name-wrap{
    display: flex;
  }

  &__badge{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    // background-color: var(--color-gray-chess);
    background-color: var(--color-red-chess);
    margin-right: 3px;
    &._active{
      background-color: var(--color-green-chess);
    }

    &._reserved{
      background-color: var(--color-orange-chess);
    }

    &._sold{
      background-color: var(--color-red-chess);
    }
  }

  &__flat-number{
    color: var(--color-gray-dark);
  }

  &__type-name{
    color: var(--color-primary-dark);
    font-weight: 500;
  }

  &__price{
    color: var(--color-primary-dark);
    font-weight: 700;
    padding-bottom: 5px;
    text-align: left;
  }

  &__area, &__floor, &__status, &__section{
    font-size: 13px;
  }

  &__bottom{
    display: flex;
    align-items: center;
    padding-top: 10px;
    justify-content: space-between;
  }

  &__status{
    // background-color: var(--color-gray-chess);
    background-color: var(--color-red-chess);
    padding: 5px;
    border-radius: 3px;
    color: #fff;
    &._active{
      background-color: var(--color-green-chess);
    }

    &._reserved{
      background-color: var(--color-orange-chess);
    }

    &._sold{
      background-color: var(--color-red-chess);
    }
  }
}