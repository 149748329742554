.about-personal {
  &__employees {
    display: grid;
  }

  &__dept-title {
    font-style: normal;
    font-weight: 500;
  }

  &__employee-name {
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }

  &__employee-working-position {
    font-style: normal;
    font-weight: normal;
    line-height: 1.25em;
    color: var(--color-gray-dark);
    margin-top: 12px;
    padding-bottom: 12px;
  }

  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 112%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__delimiter {
    display: none;
  }
}

@include respond-up('large') {
  .about-personal {
    padding-top: 120px;

    &__dept {
      border-top: 1px solid var(--color-border);
      padding-top: 40px;
      position: relative;

      &:not(:first-child) {
        margin-top: 120px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 600px;
        width: 1px;
        height: 34px;
        background-color: var(--color-border);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 293px;
        width: 1px;
        height: 34px;
        background-color: var(--color-border);
      }
    }

    &__dept-title {
      grid-column: 1/4;
    }

    &__employees {
      grid-column: 4/13;
      grid-column-gap: 28px;
      grid-row-gap: 70px;
    }

    &__employee-name {
      font-size: 17px;
    }

    &__picture {
      width: 278px;
      height: 312px;
    }
  }
}

@include respond-up('medium') {
  .about-personal {
    &__employees {
      grid-template-columns: repeat(3, 1fr);
    }

    &__dept-title {
      font-size: 22px;
      line-height: 1.35em;
    }

    &__employee-name {
      line-height: 1.35em;
    }

    &__employee-working-position {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .about-personal {
    padding-top: 70px;

    &__dept {
      &:not(:first-child) {
        margin-top: 70px;
      }
    }

    &__dept-title {
      grid-column: 1/7;
    }

    &__employees {
      grid-column: 1/7;

      &:first-child {

      }
    }

    &__employees {
      grid-column-gap: 24px;
      grid-row-gap: 50px;
      margin-top: 30px;
      padding-top: 30px;
    }

    &__picture {
      height: calc((240 / 768) * 100vw);
    }

    &__delimiter {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 31px;
        left: 50%;
        width: 1px;
        height: 30px;
        background-color: var(--color-border);
      }

      &_first {
        grid-column: 2/4;
      }

      &_second {
        grid-column: 4/6;
      }
    }
  }
}

@include respond-down('medium') {
  .about-personal {
    &__employee-name {
      font-size: 16px;
    }

    &__employees {
      border-top: 1px solid var(--color-border);
    }

    &__picture {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .about-personal {
    padding-top: 30px;

    &__dept {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__dept-title,
    &__employees {
      grid-column: 1/5;
    }

    &__employees {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 14px;
      grid-row-gap: 30px;
      margin-top: 20px;
      padding-top: 20px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        background-color: var(--color-border);
        left: 50%;
        height: 21px;
      }
    }

    &__dept-title {
      font-size: 16px;
      line-height: 1.4em;
    }

    &__employee-name {
      line-height: 1.4em;
    }

    &__employee-working-position {
      font-size: 14px;
    }

    &__picture {
      height: calc((182 / 375) * 100vw);
    }
  }
}