.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 19px;
    height: 19px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/checkbox-checked.svg");
      }
    }
  }

  label {
    color: var(--color-white-opacuty-50);
    padding-left: 33px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      background-image: url("../images/svg/checkbox-unchecked.svg");
      transition: background 0.2s;
    }
  }

  &.news-page-policy{
    input {
      position: absolute;
      width: 19px;
      height: 19px;

      &:checked + label {
        &:before {
          background-image: url("../images/svg/checkbox-dark-checked.svg");
        }
      }
    }

    label {
      color: var(--color-white-opacuty-50);
      padding-left: 33px;
      max-width: 320px;
      margin-top: 26px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 19px;
        height: 19px;
        background-image: url("../images/svg/checkbox-dark-unchecked.svg");
        transition: background 0.2s;
      }
    }

    .policy-checkbox{
      &__policy-link{
        color: var(--color-blue-dark);
      }
    }
  }

  &__policy-link {
    color: white;
  }
}
