// Тема - как выглядят те или иные элементы
.genplan {
  position: relative;

  --preloader-bg-color: #eee;
  --preloader-color: #000;

  [data-gen-loader] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2000;
    background-color: var(--preloader-bg-color);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &:before {
      content: 'Загрузка';
      color: var(--preloader-color);
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-size: 12px;
      transition: 0.8s;
      pointer-events: none;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100px;
      height: 100px;
      border-radius: 1000px;
      border: 3px solid var(--preloader-color);
      border-bottom: none;
      border-top: none;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      z-index: 110;
      animation-duration: 1s;
      animation-name: gen-loader-animation;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transition: 0.8s;
      pointer-events: none;
    }

    &._opened {
      opacity: 1;
      visibility: visible;
    }
  }

  &__building-placemark {
    display: flex;
  }

  &__building-placemark-title {
    padding: 5px 2px 5px 8px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25em;
    width: max-content;
  }

  &__building-placemark-corner,
  &__infra-balloon-corner {
    position: relative;
    width: 6px;
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      width: 7px;
      height: calc(100% - 6px);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      border-color: #fff #fff transparent;
      border-style: solid;
      border-width: 0 0 6px 7px;
    }
  }

  &__infra-placemark {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    pointer-events: auto;
    opacity: 1;
    transition: opacity .4s;

    &._hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__infra-balloon {
    display: flex;
  }

  &__infra-balloon-title {
    font-style: normal;
    font-weight: 500;
    line-height: 1.25em;
    width: max-content;
  }

  &__object-balloon-head-wrap{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__object-balloon-room-number{
    color: var(--color-gray-dark);
  }

  &__building-balloon{
    &_floor{
      box-shadow: 0 10px 15px 5px rgba(125, 131, 146, 0.25);
    }
  }

  &__building-balloon-top {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__building-balloon-empty{
    padding-top: 20px;
  }

  &__building-balloon-bottom {
    background-color: var(--color-background);
  }

  &__building-balloon-flat-info-item{
    padding-bottom: 5px;
    font-size: 14px;
  }

  &__minimap {
    height: 50px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    border: 2px solid #fff;
    border-radius: 2px;
    z-index: 1000;
    padding: 4px;
  }

  &__minimap-bar {

  }

  &__minimap-handle {
    border-radius: 2px;
    background-color: #fff;
  }

  // Позиционирование балуна на мобильнике
  .genplan__object-balloon._modal {
    position: absolute;
    z-index: 1000;
  }

  // Балун на десктопе
  &__object-balloon._popover {
    background-color: var(--t);
    //padding: 10px;
    transition: all 0.3s;

    // Отступ балуна от точки привязки
    --balloon-offset: 13px;
    // Цвет "Ножки балуна"
    --balloon-pin-color: var(--t);

    &.to-right {
      margin-left: var(--balloon-offset);

      &:before {
        left: calc(-1 * var(--balloon-pin-size));
        border-width: var(--balloon-pin-size) var(--balloon-pin-size) var(--balloon-pin-size) 0;
        border-color: transparent var(--balloon-pin-color) transparent transparent;
      }
    }

    &.to-left {
      margin-right: var(--balloon-offset);

      &:before {
        right: calc(-1 * var(--balloon-pin-size));
        border-width: var(--balloon-pin-size) 0 var(--balloon-pin-size) var(--balloon-pin-size);
        border-color: transparent transparent transparent var(--balloon-pin-color);
      }
    }

    &.to-top:before {
      bottom: calc(var(--pin-y-offset) + var(--placemark-height)/2 - var(--balloon-pin-size));
    }

    &.to-bottom:before {
      top: calc(var(--pin-y-offset) + var(--placemark-height)/2 - var(--balloon-pin-size));
    }

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  &__scene-modals {
    // Черная подложка над картой на мобильнике, когда открыт балун
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.4s;
    }

    &._opened:before {
      opacity: 1;
    }
  }

  &__layer-polygon {
    svg {
      path {
        fill: var(--primary-color);
        transition: opacity 0.4s;
        opacity: 0;
      }
    }
  }

  // Переходы и прозрачности для полигонов
  &__layer-substrate {
    svg {
      path {
        fill: var(--primary-color);
        transition: all 0.4s;
        opacity: 0;

        &._hover {
          opacity: 0.35;
        }
      }
    }
  }

  &__scene._scrollable {
    .genplan {
      &__layer-substrate {
        svg {
          path {
            transition: all 0.4s;
            opacity: 0.5;

            &._hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }


  &__scenes {
    //min-height: 500px;
    transform: translate3d(0,0,0);
  }

  &__scene {
    &._show {
      animation-name: gen-scene-show;
      animation-fill-mode: forwards;
      animation-duration: 0.7s;
    }

    &._hide {
      animation-name: gen-scene-hide;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    }
  }

  &__scene-building{
    .genplan__layer-polygon svg path {
      opacity: 0.4;

      &._hover {
        opacity: 0.6;
      }
    }
  }

  &__building-balloon-flats {
    display: grid;
    margin-top: 21px;
  }

  &__building-balloon-flats-item {
    display: grid;
    padding: 6px 0;
  }

  &__building-balloon-flats-item-title {
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.25em;
  }

  &__building-balloon-flats-list {
    margin: -6px 0;
  }

  &__building-balloon-price{
    font-weight: 500;
    color: var(--color-primary-dark);
  }

  &__object-balloon-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.25em;
    border-bottom: 1px solid transparent;
    transition: border 0.3s;
    padding-bottom: 3px;
    width: fit-content;
    &:hover{
      border-bottom: 1px solid var(--color-primary-dark);
    }
  }

  &__object-balloon-ready {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
  }

  &__object-balloon-ready-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__object-balloon-ready-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.25em;
    color: var(--color-gray-dark);
  }

  &__object-balloon-ready-percent {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25em;
    color: var(--primary-color);
  }

  &__object-balloon-ready-line {
    margin-top: 5px;
    height: 2px;
    background-color: var(--color-blue-gray);
  }

  &__object-balloon-ready-line-fill {
    display: flex;
    height: 100%;
    background-color: var(--primary-color);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 8px;
      background-color: var(--primary-color);
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__building-balloon-flats-on-sale-value,
  &__building-balloon-flats-reserved-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25em;
  }

  &__building-balloon-flats-on-sale-title,
  &__building-balloon-flats-reserved-title {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.25em;
    color: var(--color-gray-dark);
    margin-top: 3px;
  }

  &__top {
    display: flex;
  }

  &__infra-off-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2em;
    flex: 0 1 auto;
  }

  &__infra-off-icon {
    padding: 3px;
    width: 37px;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 100px;
    margin-right: 15px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .4s;

    &._off {
      background-color: var(--color-blue-gray);
      .genplan__infra-off-icon-active {
        transform: translate3d(-17px, 0 ,0);
      }
    }
  }

  &__infra-off-icon-active {
    background-color: #fff;
    border-radius: 100%;
    height: 14px;
    width: 14px;
    transition: transform .4s;
  }

  &__infra-off {
    display: flex;
    align-items: center;
  }

  &__building-balloon {
    //z-index: 200;
  }

  &__object-balloon-link-wrap {
    &.button {
      margin-top: 10px;
    }
  }
}

@include respond-up('large') {
  .genplan {
    height: 900px;

    &__object-balloon-link-wrap {
      width: 100%;
    }

    &__top {
      margin-bottom: 40px;
    }

    &__infra-balloon-title {
      background-color: #fff;
      max-width: 187px;
      padding: 5px 2px 5px 8px;
    }

    &__building-balloon-flats {
      grid-template-columns: 84px 1fr;
      grid-column-gap: 48px;
    }

    &__building-balloon-inner {
      padding-bottom: 18px;
      min-width: 250px;
      &_floor{
        padding-bottom: 0;
      }
    }

    &__building-balloon-inner-corner {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - 18px);
        height: 18px;
        background-color: #fff;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-color: #fff #fff transparent;
        border-style: solid;
        border-width: 0 0 18px 18px;
      }

      &._gray {
        &::before {
          background-color: var(--color-background);
        }

        &::after {
          border-color: var(--color-background) var(--color-background) transparent;
        }
      }
    }

    &__building-balloon-flats-item {
      grid-template-columns: 84px 1fr;
      grid-column-gap: 63px;
    }

    &__building-balloon-flats-item-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.25em;
      white-space: nowrap;
    }

    &__layer-polygon {
      svg {
        will-change: opacity;
        path {
          &._hover {
            opacity: 0.35;
          }
        }
      }
    }

    &__object-balloon-ready-line {
      width: 225px;
    }

    &__building-balloon-top {
      padding: 20px 28px 12px;
    }

    &__building-balloon-bottom {
      padding: 16px 28px 10px;
      &_floor{
        padding-bottom: 20px;
      }
    }
  }
}

@include respond-up('medium') {
  .genplan {
    &__building-placemark-title {
      max-width: 187px;
      font-size: 13px;
    }

    &__infra-balloon-title {
      font-size: 13px;
    }

    &__infra-placemark {
      width: 46px;
      height: 46px;
    }

    &__top {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .genplan {
    height: 700px;

    &__object-balloon-link-wrap {
      width: max-content;
    }

    &__top {
      margin-bottom: 30px;
    }

    &__building-balloon-flats {
      grid-template-columns: repeat(2, 1fr);
    }

    &__building-balloon-flats-item {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 70px;
    }

    &__building-balloon-inner {
      display: flex;
    }

    &__building-balloon-top {
      flex: 0 1 auto;
      width: 100%;
      padding: 37px 30px 41px #{$spacer-medium}px;
    }

    &__building-balloon-bottom {
      flex: 0 1 auto;
      width: 100%;
      padding: 37px #{$spacer-medium}px 41px 30px;
    }

    &__infra-balloon {
      padding: 17px 61px 17px #{$spacer-medium}px;
      min-width: calc((372 / 768) * 100vw);
    }

    &__infra-balloon-close {
      padding: 20px 15px;
    }

    &__infra-balloon-close-line {
      height: 37px;
    }
  }
}

@include respond-down('medium') {
  .genplan {
    &__building-balloon-inner-corner,
    &__infra-balloon-corner {
      display: none;
    }

    &__layer-polygon {
      svg {
        path {
          opacity: 0.35;
        }
      }
    }

    &__minimap {
      width: 95px;
      height: 30px;
      border: 1px solid #FFFFFF;
      border-radius: 0;
      padding: 0;
      top: 30px;
    }

    &__minimap-handle {
      background-color: var(--color-white-opacity-35);
    }

    &__building-balloon {
      opacity: 0;
      width: 100%;
      bottom: 0;
      left: 0;
      transition: opacity .4s, transform .4s;
      transform: translate3d(0, 30px, 0);

      &._opened {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    &__infra-balloon {
      position: relative;
      display: flex;
      align-items: center;
      bottom: 0;
      left: 0;
      background-color: var(--color-background);
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: opacity .4s, transform .4s;

      &._opened {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    &__infra-balloon-title {
      font-weight: bold;
      font-size: 15px;
    }

    &__building-balloon-close {
      position: absolute;
      right: 4px;
      top: 0;
      padding: 15px;
      background-color: var(--t);
      border: none;

      svg {
        transform: rotate(45deg);
        path {
          stroke: var(--color-border);
        }
      }
    }

    &__infra-balloon-close-line {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 1px;
      background-color: var(--color-border);
    }

    &__infra-balloon-close {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--t);
      border: none;

      svg {
        transform: rotate(45deg);
        path {
          stroke: var(--color-border);
        }
      }
    }
  }
}

@include respond-down('small') {
  .genplan {
    height: 500px;

    &__infra-off {
      display: none;
    }

    &__top {
      margin-bottom: 22px;
    }

    &__infra-balloon-close-line {
      height: 31px;
    }

    &__infra-balloon-close {
      padding: 15px 12px;
    }

    &__building-balloon-flats-on-sale,
    &__building-balloon-flats-item-title {
      grid-column: 1/3;
    }

    &__building-balloon-flats-reserved,
    &__building-balloon-flats-item-value {
      grid-column: 3/5;
    }

    &__building-balloon-flats,
    &__building-balloon-flats-item {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 92px;
    }

    &__object-balloon-ready-line {
      width: 100%;
    }

    &__building-balloon-top {
      padding: 37px #{$spacer-small}px 12px;
    }

    &__building-balloon-bottom {
      padding: 16px #{$spacer-small}px 28px;
    }

    &__building-placemark-title {
      font-size: 11px;
    }

    &__building-balloon {

    }

    &__infra-balloon {
      padding: 12px 58px 12px #{$spacer-small}px;
      width: 95vw;
    }

    &__infra-balloon-title {
      font-size: 11px;
    }

    &__infra-placemark {
      width: 38px;
      height: 38px;
    }
  }
}

@keyframes gen-scene-hide {
  from {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2);
  }
}

@keyframes gen-scene-show {
  from {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

@keyframes gen-loader-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}