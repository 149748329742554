.compilation-card-footer {
  padding-top: 20px;
  border-top: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__info {
    display: flex;
    justify-content: flex-start;
  }

  &__flat-number {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 135%;
    color: var(--color-gray-dark);
    padding-right: 18px;
    margin-right: 18px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: var(--color-border);
    }
  }

  &__range {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: var(--color-primary-dark);
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    &>* {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

@include respond-up('large') {
  .compilation-card-footer {

  }
}

@include respond-down('medium') {
  .compilation-card-footer {

  }
}
