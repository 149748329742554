.review {
  &__content-wrap{
    display: flex;
  }

  &__rounds-container{
    display: flex;
  }

  &__round {
    border-radius: 100px;
    overflow: hidden;

    &_quotes{
      background-color: var(--color-primary-dark);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_noimage{
      background-color: var(--color-blue-gray);
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 100%;
      color: #fff;
    }
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content{
    width: 100%;
  }

  &__content-head{
    border-bottom: 1px solid var(--color-border);
  }

  &__content-name{
    font-style: normal;
    font-weight: 500;
    color: var(--color-primary-dark)
  }

  &__content-text{
    font-weight: normal;
    color: var(--color-primary-dark);
  }

  &__content-button {
    display: flex;
    align-items: center;
    background-color: var(--color-background);
    max-width: 220px;
    padding: 6px 22px 6px 6px;
  }

  &__button-picture {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 56px;
    height: 42px;
  }

  &__button-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-button-image-wrap {
    flex: 0 0 auto;
    margin-right: 22px;
  }

  &__content-button-title {
    flex: 0 1 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2em;
    position: relative;
    padding-bottom: 4px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      background: var(--color-primary-dark);
      bottom: 0;
      width: 100%;
      transform-origin: right;
      transition: transform 0.4s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      background: var(--color-primary-dark);
      bottom: 0;
      width: 100%;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.4s ease-out;
    }
  }
}

@include respond-up('large') {
  .review {
    &__content-button {
      &:hover {
        .review {
          &__content-button-title {
            &::after {
              transition: transform .3s var(--default-bezier) .33s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform .3s var(--default-bezier);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__content-wrap{
      column-gap: 254px;
    }

    &__before-content{
      flex: 0 0 154px;
    }

    &__content{
      flex: 0 0 788px;
    }

    &__content-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &__content-date{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: var(--color-black-opacity);
    }

    &__content-text{
      font-size: 18px;
      line-height: 135%;
    }
  }
}

@include respond-up('medium') {
  .review {
    &__round{
      width: 77px;
      height: 77px;
    }

    &__content-name{
      font-size: 19px;
      line-height: 135%;
    }
  }
}

@include respond('medium') {
  .review {
    &__content-wrap{
      column-gap: 84px;
    }

    &__content-date{
      display: none;
    }

    &__content-head{
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__content-text{
      font-size: 17px;
      line-height: 150%;
    }
  }
}

@include respond-down('medium') {
  .review {
    &__content-date{
      display: none;
    }
  }
}

@include respond-down('small') {
  .review {

    &__content-wrap{
      flex-direction: column;
    }

    &__rounds-container{
      padding-bottom: 20px;
    }

    &__round{
      width: 54px;
      height: 54px;
    }

    &__content-head{
      padding-bottom: 18px;
      margin-bottom: 20px;
    }

    &__content-name{
      font-size: 16px;
      line-height: 140%;
    }

    &__content-text{
      font-size: 15px;
      line-height: 135%;
    }
  }
}