.cookie-confirm {
  background-color: #ffffff; /* фон белый */
  text-align: center;
  font-size: 14px;
  color: #333;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999999;

  &-content {
    width: 100%;
    max-width: calc(var(--max-row-width) * 1px);
    padding: 20px 0;
    margin: 0 auto;

    gap: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.cookie-confirm-content__text {
  max-width: 85%;
  display: inline-block;
  vertical-align: middle;
}

.cookie-confirm a {
  color: #007bff; /* ссылка синяя, как во всем проекте */
  text-decoration: underline;
}

.cookie-confirm-content__action {
  background-color: #007bff; /* кнопка синяя */
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  transition: background-color 0.3s ease;
}

.cookie-confirm-content__action:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .cookie-confirm-content {
    gap: 7px;
    padding: 10px 0;
  }

  .cookie-confirm-content__text {
    font-size: 8px;
  }

  .cookie-confirm-content__action {
    font-size: 12px;
  }
}
