.header-container {
  &__description-wrapper {
    display: grid;
  }

  &__description {
    grid-row: 1;
    line-height: 135%;
    font-size: 16px;
  }

  &__title {
    font-weight: bold;
    line-height: 125%;
  }

  &__head-button {
    grid-row: 2;
  }
}

@include respond-up('large') {
  .header-container {
    &__title {
      grid-column: 1/6;
      font-size: 36px;

    }

    &__description-wrapper {
      grid-column: 7/13;
      grid-template-columns: repeat(6, 1fr);
    }

    &__description {
      margin-bottom: 28px;
      grid-column: 1/7;

    }

  }
}

@include respond-up('medium') {
  .header-container {
    &__head-button {
      width: 223px;
    }
  }
}

@include respond('medium') {
  .header-container {
    &__title {
      grid-column: 1/4;
      font-size: 30px;
    }

    &__description {
      margin-bottom: 28px;
      grid-row: 1;
      grid-column: 1/4;
    }

    &__description-wrapper {
      grid-column: 4/7;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px;
    }

    &__head-button {
      grid-row: 2;
      grid-column: 1/3;
    }
  }
}

@include respond-down('small') {
  .header-container {
    &__title {
      grid-row: 1;
      grid-column: 1/5;
      margin-bottom: 12px;
      font-size: 22px;
    }

    &__description {
      margin-bottom: 20px;
      font-size: 15px;
    }

    &__description-wrapper {
      grid-row: 2;
      grid-column: 1/5;
    }

    &__head-button {
      .button__link {
        width: 100%;
        justify-content: center;
      }
    }
  }
}