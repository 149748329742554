.advantage {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  position: relative;

  .tns-nav {
    border-bottom: 1px solid var(--color-border);
    display: flex;

    button {
      border: none;
    }
  }

  .tns-nav-active {
    display: block !important;
    border-bottom: 2px solid var(--color-primary-dark) !important;
    margin-bottom: -1px;
  }

  &__slider-item {
    position: relative;
  }

  &__slider-wrapper {
    height: 100%;
    transition: background-color var(--default-transition-duration);
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
  }

  &__key {
    transition: opacity var(--default-transition-duration);
    font-weight: bold;
    font-size: 45px;
    line-height: 125%;
    color: white;
    position: absolute;
    top: 30px;
    left: 30px;
  }

  &__circle-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__circle {
    transition: transform var(--default-transition-duration);
    position: relative;
    height: 54px;
    width: 54px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 100%;
  }

  &__item-tab-icon-plus {
    position: absolute;
    left: 25px;
    top: 18px;
    width: 2px;
    height: 16px;
    background-color: white;
  }

  &__item-tab-icon-minus {
    position: absolute;
    height: 2px;
    width: 16px;
    top: 25px;
    left: 18px;
    background-color: white;
    opacity: 1;
    transition: opacity var(--default-transition-duration);
  }

  &__description {
    flex-grow: 1;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-gray-dark);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: var(--default-transition-duration);
      timing-function: ease-in-out;
    };
  }

  &__subtitle {
    transition: color var(--default-transition-duration);
    color: white;
    font-weight: 500;
    font-size: 19px;
    line-height: 135%;
  }

  &__strip {
    height: 1px;
    background-color: var(--color-border);
    overflow: hidden;
  }

  &__strip-filling {
    background-color: var(--color-primary-dark);
    height: 100%;
    transition: width .9s;
  }

  &__arrows {
    position: absolute;
  }
}

@include respond-up('large') {
  .advantage {
    .tns-nav {
      display: none;
    }

    &__title {
      padding: 120px 0 0 var(--large-indent);
      margin-bottom: 40px;
    }

    &__slider-container {
      padding-bottom: 132px;
    }

    &__slider-list {
      margin-bottom: 68px;
      margin-left: -408px;
    }

    &__strip {
      width: 900px;
      margin: 0 var(--large-indent);
    }

    &__slider-item {
      &:hover {
        .advantage {
          &__slider-wrapper {
            background-color: white;
          }

          &__key {
            opacity: 0;
          }

          &__subtitle {
            color: black;
          }

          &__circle {
            transform: rotate(-90deg);
          }

          &__item-tab-icon-minus {
            opacity: 0;
          }

          &__description {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }
      }
    }

    &__slider-wrapper {
      padding: 30px;
      display: flex;
      flex-direction: column;
      min-height: 335px;
    }

    &__description {
      transform: translate3d(0, 20px, 0);
      flex-grow: 1;
    }

    &__arrows {
      bottom: 120px;
      right: 0;
      margin-right: calc((var(--large-indent)) + 10px);
    }
  }
}

@include respond-down('medium') {
  .advantage {
    &__slider-wrapper {
      pointer-events: none;
    }

    &__slider-item {
      z-index: 10;

      &._hovered {
        .advantage {
          &__slider-wrapper {
            background-color: white;
          }

          &__key {
            opacity: 0;
          }

          &__subtitle {
            color: black;
          }

          &__circle {
            transform: rotate(-90deg);
          }

          &__item-tab-icon-minus {
            opacity: 0;
          }

          &__description {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            transition: {
              property: transform, opacity;
              duration: 0.4s;
              timing-function: ease-in-out;
            };
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .advantage {


    &__title {
      padding: 70px 0 0 var(--medium-indent);
      margin-bottom: 30px;

    }

    &__strip {
      width: 476px;
      margin: 0 var(--medium-indent);
    }

    .tns-nav {
      display: none;
    }

    &__slider-container {
      padding-bottom: 90px;
    }

    &__slider-list {
      margin-left: -167px;
      margin-bottom: 50px;
    }

    &__slider-item {
      z-index: 10;
    }

    &__slider-wrapper {
      pointer-events: none;
      padding: 30px;
      min-height: 295px;
    }

    &__arrows {
      bottom: 80px;
      right: 0;
      margin-right: var(--medium-indent);
    }
  }
}

@include respond-down('small') {
  .advantage {
    .tns-nav-active {
      border: none !important;
      background-color: var(--primary-color) !important;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        border-radius: 100%;
        border: 1px solid;
        border-color: var(--color-blue-gray);
        width: 21px;
        height: 21px;
        left: -7px;
        top: -6px;
        transform: translate3d(-50%, -50%, 0);
      }

      button {
        position: relative;
      }
    }

    .tns-nav {
      border-bottom: none;
      justify-content: center;

      button {
        border: none;
        margin: 0 8px;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background-color: var(--color-blue-gray);
      }
    }

    &__strip {
      display: none;
    }

    &__title {
      padding: 30px 0 0 var(--small-indent);
      margin-bottom: 22px;
    }

    &__slider-container {
      margin-bottom: 40px;
    }

    &__slider-list {
      margin-bottom: 25px;
    }

    &__slider-wrapper {
      padding: 22px;
      min-height: 294px;
    }

    &__key {
      font-size: 35px;
      top: 20px;
      left: 20px;
    }

    &__description {
      font-size: 15px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__arrows {
      display: none;
    }
  }
}