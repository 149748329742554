.payment-calc-block {
  &__example-price {
    color: var(--color-gray-dark);
  }
  &._hide{
    display: none;
  }
}

@include respond-up('large') {
  .payment-calc-block {
    padding-top: 120px;
    &._index-page{
      padding-top: 0;
      padding-bottom: 120px;
    }

    &._product-page{
      &_with-padding{
        padding-bottom: 120px;
      }
    }

    &__calc-wrap {
      margin-top: 40px;
    }

    &__example-price {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .payment-calc-block {
    &__example-price {
      margin-top: 15px;
    }
  }
}

@include respond('medium') {
  .payment-calc-block {
    padding-top: 90px;
    &._index-page{
      padding-top: 0;
      padding-bottom: 90px;
    }

    &._product-page{
      &_with-padding{
        padding-bottom: 90px;
      }
    }

    &__calc-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .payment-calc-block {
    padding-top: 40px;
    &._index-page{
      padding-top: 0;
      padding-bottom: 40px;
    }

    &._product-page{
      &_with-padding{
        padding-bottom: 40px;
      }
    }

    &__calc-wrap {
      margin-top: 22px;
    }

    &__example-price {
      margin-top: 8px;
    }
  }
}