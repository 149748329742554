.about-tabs {

}

@include respond-up('large') {
  .about-tabs {
    padding-top: 40px;
  }
}

@include respond-up('medium') {
  .about-tabs {
    //margin-top: 40px;
  }
}

@include respond('medium') {
  .about-tabs {
    padding-top: 30px;
  }
}

@include respond-down('medium') {
  .about-tabs {

  }
}

@include respond-down('small') {
  .about-tabs {
    //margin-top: 30px;
    margin: 0 -22px;
    padding: 20px 6px 0;
  }
}