.news-page {
  &__tabs {
    display: flex;
  }

  &__tab {
    &._active {
      .news-page {
        &__tab-link {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
  }

  &__tab-link {
    display: inline-block;
    font-size: 15px;
    line-height: 135%;
    border: 1px solid var(--color-border);
    transition: color 0.4s ease-in-out;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      padding-bottom: 0;
    }
  }

  &__form-wrapper {
    display: flex;
    position: relative;
  }

  &__form-description {
    border-left: 3px solid var(--primary-color);
  }

  &__submit-button {
    position: absolute;
  }

  &__form-success {
    color: white;
    background-color: var(--primary-color);
    right: -3px;
  }
}

@include respond-up('large') {
  .news-page {
    padding: 70px 0 130px;

    &__tabs {
      position: sticky;
      top: 120px;
      flex-direction: column;

    }

    &__tabs-wrapper {
      grid-column: 1/3;
    }

    &__tab-link {
      width: 105px;
      padding: 13px 34px;
      margin-bottom: -1px;
    }

    &__contents {
      grid-column: 3/13;
    }

    &__heading {
      padding-bottom: 80px;
    }

    &__form {
      flex: 1 0 320px;
      margin-right: 38px;
    }

    &__form-wrapper {
      flex-direction: row;
      align-items: center;
    }

    &__form-description {
      padding-right: 50px;
      flex: 1 0 240px;
      font-size: 18px;
      line-height: 135%;
    }

    &__input-wrapper {
      flex: 1 0 100%;
    }
  }
}

@include respond-up('medium') {
  .news-page {
    &__heading {
      flex-direction: row;
    }

    &__form-description {
      padding-left: 17px;
    }

    &__input-wrapper {
      --default-input-height: 60px;
    }

    &__submit-button {
      --button-size: calc(var(--default-input-height) - 6px);
      right: 3px;
      bottom: calc(50% - var(--button-size) / 2);
    }
  }
}

@include respond('medium') {
  .news-page {
    padding-bottom: 90px;

    &__tabs {
      grid-column: 1/7;
      padding: 40px 0 46px;
    }

    &__tab-link {
      min-width: 60px;
    }

    &__contents {
      grid-column: 1/7;
    }

    &__heading {
      padding-bottom: 37px;
    }

    &__form {
      margin-right: 12px;
      width: 320px;
    }

    &__form-description {
      margin-bottom: 14px;
      font-size: 17px;
      line-height: 150%;
    }
  }
}

@include respond-down('medium') {
  .news-page {
    &__tabs {
      grid-row: 1;
    }

    &__tab-link {
      padding: 10px 16px;
      margin-right: -1px;
    }

    &__contents {
      grid-row: 2;
    }

    &__form-wrapper {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .news-page {
    padding-bottom: 50px;

    &__tabs {
      grid-column: 1/5;
      padding: 30px 0;
    }

    &__contents,
    &__tabs-wrapper {
      grid-column: 1/5;
    }

    &__tabs-wrapper {
      overflow: scroll;
      padding: 0 22px;
      margin: 0 -22px;
    }

    &__heading {
      flex-direction: column;
      padding-bottom: 30px;

      h1 {
        padding-bottom: 22px;
      }
    }

    &__form-description {
      padding-left: 12px;
      margin-bottom: 12px;
      font-size: 15px;
      line-height: 135%;
    }

    &__form-wrapper {
      width: 100%;
      max-width: 338px;
    }

    &__submit-button {
      --button-size: calc(var(--default-input-height) - 10px);
      right: 5px;
      bottom: calc(50% - var(--button-size) / 2);
    }

    &__input-wrapper {
      --default-input-height: 54px;
    }
  }
}