@use "sass:math";

.seller-news-card {
  display: block;
  width: 100%;
  padding-top: 22px;

  &:hover {
    .seller-news-card {
      &__image {
        transform: scale(1.12);
      }

      &__icon {
        transform: translate3d(18px, -20px, 0);

        &_dub {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: math.div(155, 270) * 100%;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 2s cubic-bezier(0, 0, .2, 1);
    object-position: center;
    object-fit: cover
  }

  &__date {
    font-size: 14px;
    line-height: 135%;
    color: var(--color-gray-dark);
    margin: 25px 0 15px;
  }

  &__name {
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    color: var(--color-primary-dark);
  }

  &__description {
    font-size: 15px;
    line-height: 135%;
    color: var(--color-gray-dark);
    margin: 10px 0 30px;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 20px;
    position: relative;
  }

  &__icon {
    display: block;
    transition: transform .5s;
    transform: translate3d(0, 0, 0);

    svg {
      width: 100%;
      height: 100%;
    }

    &_dub {
      display: block;
      position: absolute;
      transform: translate3d(-18px, 20px, 0);
    }
  }
}

@include respond-up('large') {
  .seller-news-card {

  }
}

@include respond-up('medium') {
  .seller-news-card {

  }
}

@include respond('medium') {
  .seller-news-card {

  }
}

@include respond-down('medium') {
  .seller-news-card {

  }
}

@include respond-down('small') {
  .seller-news-card {

  }
}