@use "sass:math";

.customer-dashboard {
  &__recall {
    color: #fff;
    background: {
      image: url('../images/customer/call-to-us.jpg');
      position: 50% 50%;
      size: cover;
      repeat: no-repeat;
    };
  }

  &__catalog {
    background: #FFFFFF;
    box-shadow: 0px 12px 40px rgba(125, 131, 146, 0.21);

    .customer-dashboard__item-description {
      color: var(--color-gray-dark);
    }
  }

  &__catalog-image-container {
    display: block;
    position: relative;
  }

  &__catalog-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    background: {
      image: url('../images/customer/catalog-image.png');
      repeat: no-repeat;
      position: 50% 50%;
      size: contain;
    };
  }

  &__favorites-icon {
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &:first-child{
      background: #FFFFFF;
      box-shadow: 0px 12px 40px 0px rgba(125, 131, 146, 0.21);
      .customer-dashboard{
        &__favorites-icon{
          position: absolute;
          right: 0;
          bottom: 0;
          margin-bottom: unset;
          display: flex;
        }
      }
    }
    &:nth-child(n+2){
      color: #fff;
      background: var(--primary-color);
      .customer-dashboard{
        &__item-title{
          color: #FFFFFF;
        }
      }
    }
  }

  &__item-main,
  &__item-title,
  &__item-description {
    display: block;
  }

  &__item-title {
    font-weight: 500;
    line-height: math.div(26, 19);
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 500;
  }

  &__action-icon {
    margin-right: 15px;
    display: flex;
  }

  &__item-description {
    margin-top: 12px;
  }

  &__favorites-info {
    position: absolute;
    top: calc(50% + 10px);
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  &__favorites-stat {
    font-size: 16px;
    opacity: 0.5;
  }

  &__message-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-blue);
    border-radius: 100%;
    width: 54px;
    height: 54px;
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
}

@include respond-up('medium') {
  .customer-dashboard {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    &__item {
      padding: 30px;
      height: 240px;
    }

    &__item-title {
      font-size: 19px;
    }

    &__item-description {
      font-size: 16px;
      line-height: math.div(24, 16);
    }

    &__catalog-image-container {
      margin: 30px 0;
      padding-bottom: 80%;
    }
  }
}

@include respond-down('medium') {
  .customer-dashboard{
    &__message-icon{
      bottom: 20px;
      left: 20px;
    }
  }
}

@include respond-down('small') {
  .customer-dashboard {
    &__item {
      padding: 20px;
    }

    &__item-title {
      font-size: 16px;
    }

    &__item-description {
      font-size: 15px;
      line-height: math.div(20, 15);
    }

    &__catalog-image-container {
      margin: 20px 0;
      padding-bottom: 80%;
    }

    &__item {
      min-height: 180px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}