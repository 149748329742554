.range-input {
  position: relative;

  &__values {
    display: flex;
    position: relative;
    background-color: white;

    &_double {
    }

    &_single {
    }
  }

  &__value {
    position: relative;

    &_from, &_to {
      flex: 0 0 50%;
    }

    &_single {
      flex: 0 0 100%;
    }
  }

  &__show {
    display: none;
  }

  &__prefix {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 0;
    height: var(--default-input-height);
    align-items: center;
  }

  .range-input {
    &__line {
      height: 19px;
      padding: 8px 0;
      border: none;
      box-shadow: none;

      position: absolute;
      bottom: -9px;
      left: 0;
      right: 0;

      .noUi-connects {
        background-color: var(--t);
      }

      .noUi-connect {
        background-color: var(--primary-color);
        cursor: pointer;
      }

      .noUi-handle {
        position: absolute;
        height: 14px;
        width: 14px;
        top: -5px;
        right: -6px;
        border: 3px solid white;
        background: var(--primary-color);
        cursor: pointer;
        box-shadow: none;

        &::after, &::before {
          display: none;
        }
      }
    }

    &__input {
      padding: var(--range-input-padding);
      font-size: var(--range-placeholder-font-size);
    }

    &__value {
      .range-input {
        &__input {
          &:focus {
            border-color: var(--color-border);
          }
        }
      }

      &:first-child {
        &:before {
          position: absolute;
          content: "";
          height: 32px;
          width: 1px;
          right: 0;
          top: calc(50% - 16px);
          background: var(--color-border);
        }

        .range-input {
          &__input {
            border-right: none;
          }
        }
      }

      &:last-child {
        .range-input {
          &__input {
            border-left: none;
            text-align: right;
          }
        }
      }
    }
  }

  .noUi-target {
    background: var(--t);
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .noUi-connects {
    border-radius: 0;
  }

  .noUi-connect {
    background: var(--primary-color);
  }
}

@include respond-up('large') {
  :root {
    --range-input-padding: 17px 15px;
    --range-placeholder-font-size: 15px;
  }
}

@include respond-down('medium') {
  :root {
    --range-input-padding: 12px 15px;
    --range-placeholder-font-size: 15px;
  }
}
